<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-30 18:04:41
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="guanliandan" v-if="guanlian_dan">
        <div class="beijing_box">
            <p class="beijing_box_tit">
                关联审批单
                <i @click="guanbi" class="el-icon-close"></i>
            </p>
            <ul class="beijing_box_title">
                <li v-for='(i,index) in list' @click="num=index" :class="num==index?'zise':''" :key="index">{{i}}</li>
            </ul>
            <div class="con_tit">
                <i class="el-icon-search"></i>
                <input type="text" v-model="guanjian_zi" @keyup.enter='dianji_sousuo' placeholder="搜索姓名、标题">
            </div>
            <div class="con_xuanze_shenpi">
                审批类型
				<el-select v-model="leixing_name" @change="dianji_xuanze">
					<el-option
					v-for="(i,index) in leixing_list"
					:key="index"
					:label="i.flow_name"
					:value="i.flow_id">
					</el-option>
				</el-select>
            </div>
            <ul class="con_xuanze_liebiao">
                <li class="con_xuanze_liebiao_li" @click="i.zhi=!i.zhi" v-for="(i,index) in shenpi_con" :key="index">
                    <ul>
                        <li><img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""></li>
                        <li><p>{{i.start_user_name.substr(0, 1)}}</p></li>
                        <li>
                            <p>{{i.name}}</p>
                            <p>{{i.start_time}}</p>
                            <p v-if="i.appr_time">{{i.appr_time}}</p>
                            <p v-if="i.timelong">{{i.timelong}}</p>
                        </li>
                        <li>{{i.cust_name}}</li>
                        <li>{{i.caozuo}}</li>
                    </ul>
                </li>
            </ul>
            <div class="con_xuanze_foot">
                <span @click="dianji_queding">确定</span>
                <span @click="guanbi">取消</span>
            </div>
        </div>
    </div>
</template>

<script>
import { query_flow_list_have_appr_by_me, query_flow_list_copy_to_me, query_flow_list_start_by_me, query_flow_info_list } from '../../api/api'
export default {
  name: 'guanliandan',

  data () {
    return {
      list: ['我审批的', '我发起的', '抄送我的'],
      num: '',
      guanlian_dan: false,
      guanjian_zi: '',
      leixing_list: [],
      shenpi_con: [],
      shenpi_list: [],
      leixing_name: '',
      kongjian_idx: '',
      kongjian: ''
    }
  },
  watch: {
    num () {
      this.jianting_num_genggai_shuju()
    }
  },
  created () {},
  mounted () {},
  methods: {
    jichu (data) {
      this.guanlian_dan = true,
      this.num = '1'
      this.guanjian_zi = ''
      this.leixing_list = []
      this.kongjian = ''
      this.kongjian = data
      this.kongjian_idx = data.idx
      console.log(data)

      if (data.guanlian_liebiao_list.length == 0 || JSON.parse(data.guanlian_liebiao_list).length == 0) {
        query_flow_info_list({
          data: {
            ent_id: this.$ent_id()
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            const data = []
            date.forEach(item => {
              this.$set(item, 'name', item.flow_group_name)
              item.children.forEach(it => {
                data.push(it)
              })
            })
            this.leixing_list = data
            this.leixing_list.unshift({
              flow_name: '全部',
              flow_id: 'all'
            })
            this.leixing_name = this.leixing_name != undefined && this.leixing_name.length != 0 ? this.leixing_name : 'all'
            // this.keyu()
          }
        })
      } else {
        const guanlian_liebiao = JSON.parse(data.guanlian_liebiao_list)
        const leixing = []
        guanlian_liebiao.forEach(item => {
          leixing.push(item)
        })
        this.leixing_list = leixing
        this.leixing_name = leixing[0].flow_id
      }
    },
    // 监听num更改展示数据
    jianting_num_genggai_shuju () {
      if (this.num == 0) {
        query_flow_list_have_appr_by_me({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            const list = []
            for (let i = 0; i < date.length; i++) {
              if (date[i].appr_status) {
                date[i].caozuo = this.zhuangtai_name(date[i].appr_status, undefined, 0)
              }
              date[i].name = date[i].start_user_name + '发起了' + date[i].flow_name
              if (this.kongjian.value_list != undefined && this.kongjian.value_list.length != 0) {
                this.kongjian.value_list.forEach(item => {
                  if (item.flow_no == date[i].flow_no) {
                    this.$set(date[i], 'zhi', true)
                  } else {
                    this.$set(date[i], 'zhi', false)
                  }
                })
              } else {
                this.$set(date[i], 'zhi', false)
              }
            }
            this.shenpi_con = date
            this.shenpi_list = date
            this.dianji_sousuo()
          } else if (res.data.code == 500) {}
        })
      } else if (this.num == 1) {
        query_flow_list_start_by_me({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            const list = []
            for (let i = 0; i < date.length; i++) {
              if (date[i].flow_status) {
							    date[i].caozuo = this.zhuangtai_name(date[i].flow_status, undefined, 0)
              }
              if (this.kongjian.value_list != undefined && this.kongjian.value_list.length != 0) {
                this.kongjian.value_list.forEach(item => {
                  if (item.flow_no == date[i].flow_no) {
                    this.$set(date[i], 'zhi', true)
                  } else {
                    this.$set(date[i], 'zhi', false)
                  }
                })
              } else {
                this.$set(date[i], 'zhi', false)
              }
              date[i].name = '我发起了' + date[i].flow_name
            }
            this.shenpi_con = date
            this.shenpi_list = date
            this.dianji_sousuo()
          } else if (res.data.code == 500) {}
        })
      } else if (this.num == 2) {
        query_flow_list_copy_to_me({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            const list = []
            for (let i = 0; i < date.length; i++) {
              if (date[i].flow_status) {
                date[i].caozuo = this.zhuangtai_name(date[i].flow_status, undefined, 0)
              }
              date[i].name = date[i].start_user_name + '发起了' + date[i].flow_name
              if (this.kongjian.value_list != undefined && this.kongjian.value_list.length != 0) {
                this.kongjian.value_list.forEach(item => {
                  if (item.flow_no == date[i].flow_no) {
                    this.$set(date[i], 'zhi', true)
                  } else {
                    this.$set(date[i], 'zhi', false)
                  }
                })
              } else {
                this.$set(date[i], 'zhi', false)
              }
            }
            this.shenpi_con = date
            this.shenpi_list = date
            this.dianji_sousuo()
          } else if (res.data.code == 500) {}
        })
      }
    },
    dianji_xuanze () {
      const list = []
      let list2 = []
      list2 = this.shenpi_list
      for (let i = 0; i < list2.length; i++) {
        if (list2[i].flow_id == this.leixing_name) {
          list.push(list2[i])
        } else if (this.leixing_name == 'all') {
          list.push(list2[i])
        }
      }
      this.shenpi_con = list
    },
    zhuangtai_name (i, a) {
      if (i == 0) {
        return '发起审批'
      } else if (i == 1 && a != 2) {
        return '审批中'
      } else if (i == 1 && a == 2) {
        return '抄送中'
      } else if (i == 2) {
        return '已拒绝'
      } else if (i == 3) {
        return '已退回'
      } else if (i == 4) {
        return '审批通过'
      } else if (i == 5) {
        return '已归档'
      } else if (i == 6) {
        return '已转交'
      } else if (i == 7) {
        return '已撤销'
      } else if (i == 8) {
        return '已抄送'
      }
    },
    dianji_sousuo () {
      // guanjian_zi
      // this.num
      const list = []
      let list2 = []
      const list3 = []
      list2 = this.shenpi_list
      console.log(this.leixing_list)
      if (this.guanjian_zi.length == 0) {
        this.leixing_name = this.leixing_list[0].flow_id
        // list=list3
      }
      for (let i = 0; i < list2.length; i++) {
        if (list2[i].flow_id == this.leixing_name) {
          list3.push(list2[i])
        } else if (this.leixing_name == 'all') {
          list3.push(list2[i])
        }
      }
      for (let i = 0; i < list3.length; i++) {
        if (list3[i].name.indexOf(this.guanjian_zi) != -1 || list3[i].flow_desc.indexOf(this.guanjian_zi) != -1 || list3[i].start_time.indexOf(this.guanjian_zi) != -1 || (list3[i].cust_name != undefined && list3[i].cust_name.indexOf(this.guanjian_zi) != -1)) {
          list.push(list3[i])
        }
      }
      setTimeout(() => {
        this.zhanshi_shenpi_liebiao(list)
      }, 0)
    },
    zhanshi_shenpi_liebiao (list) {
      const list3 = []
      list.forEach(item => {
        if (item.flow_id == this.leixing_name) {
          list3.push(item)
        } else if (this.leixing_name == 'all') {
          list3.push(item)
        }
      })
      this.shenpi_con = list3
    },
    guanbi () {
      this.guanlian_dan = false
      this.num = ''
    },
    dianji_queding () {
      const list = []
      this.shenpi_con.forEach(item => {
        if (item.zhi) {
          list.push(item)
        }
      })
      const obj = {}
      this.$store.dispatch('shiyong_biaodan_danju_s', {
        idx: this.kongjian_idx,
        list: list
      })
      this.guanlian_dan = false
      this.num = ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.guanliandan {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    .beijing_box {
        min-height: 5.45rem;
        width: 6.76rem;
        background: #fff;
        position: relative;
        top: 1.42rem;
        left: 50%;
        margin-left: -3.38rem;
        border-radius: 0.08rem;
        margin-bottom: 1.4rem;
        .beijing_box_tit{
            background: #f0f3f5;
            padding:0.18rem 0.3rem;
            border-radius:  0.08rem 0.08rem 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.14rem;
            i{
                cursor: pointer;
                font-size: 0.16rem;
                cursor: pointer;
            }
        }
        .beijing_box_title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #e8e8e8;
            li{
                width: 34%;
                cursor: pointer;
                font-size: 0.12rem;
                color:#595959;
                text-align: center;
                padding: 0.14rem 0;
            }
            .zise{
                color:#9b84dc;
                border-bottom: 2px solid #9b84dc;
            }
        }
        .con_tit{
            width:6.46rem;
            height:0.32rem;
            margin: 0.1rem auto;
            input{
                width:5.86rem;
                height:0.32rem;
                padding: 0 0.3rem;
                background: #f5f6f7;
                border:none;
                border-radius: 0.04rem;
            }
            position: relative;
            i{
                position: absolute;
                font-size: 0.14rem;
                top:0.09rem;
                left:0.08rem;
                color:#c0c4c9;
            }
        }
        .con_xuanze_shenpi{
            width:6.46rem;
            margin: 0 auto;
            padding: 0.04rem 0;
            border-bottom:1px solid #f0f0f0;
        }
        .con_xuanze_liebiao{
            width:6.46rem;
            margin: 0 auto;
            height:2.9rem;
            overflow-y:auto;
            &::-webkit-scrollbar {
                width: 20px;
                height: 8px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 12px;
                border: 6px solid rgba(0, 0, 0, 0);
                box-shadow: 8px 0 0 #A5ADB7 inset;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 8px 0 0 #4A4A4A inset;
            }
            .con_xuanze_liebiao_li{
                border-bottom: 1px solid #f0f0f0;
                ul{
                    display: flex;
                    position: relative;
                    padding: 0.15rem 0;
                    cursor: pointer;
                    li{
                        &:nth-child(1){
                            img{
                                height:0.16rem;
                                width:0.16rem;
                            }
                            margin-right: 0.16rem;
                            margin-top: 0.08rem;
                        }
                        &:nth-child(2){
                            p{
                                width:0.3rem;
                                height:0.3rem;
                                border-radius: 50%;
                                background: #1890ff;
                                color:#fff;
                                font-size: 0.2rem;
                                text-align: center;
                                line-height: 0.3rem;
                                font-family: NSimSun;
                                margin-right:0.16rem;
                            }
                        }
                        &:nth-child(3){
                            p{
                                font-size: 0.12rem;
                                line-height: 0.18rem;

                                &:nth-child(1),&:nth-child(5){
                                    color:#5f5f5f;
                                }
                                &:nth-child(2),&:nth-child(3),&:nth-child(4){
                                    color:#7d8184;
                                }
                            }
                        }
                        &:nth-child(4){
                            position: absolute;
                            left: 50%;
                            width: 2.3rem;
                            margin-left: -1rem;
                            text-align: center;
                        }
                        &:nth-child(5){
                            position: absolute;
                            top:0.23rem;
                            right:0.32rem;
                        }
                    }
                }
            }
        }
        .con_xuanze_foot{
            border-top: 1px solid #f0f0f0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0.11rem 0.3rem;
            span{
                font-size: 0.12rem;
                padding: 0.08rem 0.16rem;
                border-radius: 0.16rem;
                cursor: pointer;
                &:nth-child(1){
                    border:1px solid #9b84dc;
                    background-color: #9b84dc;
                    color:#fff;
                    margin-right: 0.12rem;
                }
                &:nth-child(2){
                    color:#595959;
                    border:1px solid #d9d9d9;
                }
            }
        }
    }
}
</style>
