<template>
  <div class="my-tree">
    <!-- <el-input placeholder="输入关键字进行过滤" v-model="filterText"  @change="filterChange"> </el-input> -->
    <div class="my-tree-main">
    <el-tree
      :data="message"
      :props="defaultProps"
      accordion
      node-key="label"
      render-after-expand
       :filter-node-method="filterNode"
      @node-click="sendcurData"
      ref="tree"
    >
    </el-tree>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: Array,
      require: true,
      default: () => {
        return [
          {
            id: "",
            ent_id: "",
            label: "人力部",
            child_flag: " ",
            parent_id: " ",
            type: " ",
            children: [],
          },
          {
            id: " ",
            ent_id: " ",
            label: "销售部",
            child_flag: " ",
            parent_id: " ",
            type: " ",
            children: [],
          },
          {
            id: " ",
            ent_id: " ",
            label: "财务部",
            child_flag: " ",
            parent_id: " ",
            type: " ",
            children: [],
          },
        ];
      },
    },
  },
  data() {
    return {
      filterText: "",
      returnData: "这是返回的数据~~~~",
      defaultProps: {
        children: "children",
        label: "label",
      },
      dep:null
    };
  },
  methods: {
    filterChange () {
            // 这里每当输入的数据有变化就触发原生的过滤节点这个函数
            this.$refs.tree.filter(this.filterText);
        },
    sendcurData(node,x) {
       if(node.type == '1'){
         this.dep = node
       }else if(node.type == "2"){
           this.$emit("getTreeData", {...node,dep:this.dep});
       }
    },
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      }
  },
};
</script>

<style scoped lang="scss">
.my-tree{
  height: 100%;
  padding: 0.20rem;


  .el-input{
    height: 0.4rem;
  }
  &-main{
     height: 3rem;
     margin-top: 10px;

     overflow-y: scroll;
  }

}
</style>
