<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="usetable">
    <div class="tit">
      <p @click="dianji_fanhui">
        <span class="fanhui_tubiao"><i class="el-icon-back"></i></span
        >{{ date.flow_name }}
      </p>
    </div>
    <div class="con">
      <ul class="box">
        <li v-if="shifou_have_daixie">
          <div class="wai_div">
            <p class="p">提交人</p>
            <p>
              {{ jichuxinxi.name
              }}<span
                v-if="
                  this.zhuangtai_shiyong_name != '看板_编辑' &&
                  this.zhuangtai_shiyong_name != '看板' &&
                  this.zhuangtai_shiyong_name != '已分配_编辑'
                "
                class="dai_tijiao_kuang"
                @click="dai_taren_tijiao ? quxiao_dai_tijiao() : xuanze_tijiao_ren()"
                >{{ dai_taren_tijiao ? "取消待提交" : "代他人提交" }}</span
              >
            </p>
          </div>
        </li>
        <li v-for="(i, index) in list" :key="index">
          <div
            class="wai_div"
            v-if="i.componentName == 'textfield' && zhuangtai_shiyong_name !== '作废'"
          >
            <!--我是输入框-->
            <!-- 我是输入框 -->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <!-- 我告诉你最好不要随便碰这里的三目运算，不然   哈哈   你懂得 -->
            <input
              class="select-ywry-input"
              :disabled="
                ((date.flow_id != '7TvJHPyovM' ||
                  (zhuangtai_shiyong_name == '公海池_编辑' && !zhuguan)) &&
                  i.defaultLable != '客户' &&
                  i.defaultLable != '决策人' &&
                  i.defaultLable != '发票代码' &&
                  i.defaultLable != '发票号码' &&
                  i.defaultLable != '经营地址' &&
                  i.defaultLable != '母公司名称' &&
                  i.defaultLable != '开户行名称' &&
                  i.defaultLable != '银行账号' &&
                  i.defaultLable != '业务人员' &&
                  (i.siyou != 22 || zhuangtai_shiyong_name == '作废')) ||
                ((zhuangtai_shiyong_name == '已分配_编辑' ||
                  zhuangtai_shiyong_name == '看板_编辑' ||
                  zhuangtai_shiyong_name == '作废' ||
                  (zhuangtai_shiyong_name == '公海池_编辑' && !zhuguan) ||
                  zhuangtai_shiyong_name == '看板') &&
                  i.defaultLable == '客户')
                  ? true
                  : false
              "
              @click="
                (date.flow_id != '7TvJHPyovM' && i.defaultLable == '客户') ||
                i.defaultLable == '业务人员'
                  ? dianji_kehu(i)
                  : i.defaultLable == '决策人'
                  ? dakai_juece_ren_list(i)
                  : ''
              "
              type="text"
              v-model="i.value"
              @blur="
                date.flow_id == '7TvJHPyovM' &&
                i.defaultLable == '客户' &&
                ((zhuangtai_shiyong_name != '已分配_编辑' &&
                  zhuangtai_shiyong_name != '看板_编辑' &&
                  zhuangtai_shiyong_name != '作废' &&
                  zhuangtai_shiyong_name != '公海池_编辑') ||
                  (zhuangtai_shiyong_name == '公海池_编辑' && zhuguan))
                  ? kehu_sf_cunzai(i.value, i)
                  : ''
              "
              :placeholder="i.defaultProps"
            />
            <p
              class="tishi_xinxi animated fadeInRight"
              v-if="
                date.flow_id == '7TvJHPyovM' &&
                i.value_id &&
                i.defaultLable == '客户' &&
                ((zhuangtai_shiyong_name != '已分配_编辑' &&
                  zhuangtai_shiyong_name != '看板_编辑' &&
                  zhuangtai_shiyong_name != '作废' &&
                  zhuangtai_shiyong_name != '看板' &&
                  zhuangtai_shiyong_name != '公海池_编辑') ||
                  (zhuangtai_shiyong_name == '公海池_编辑' &&
                    zhuguan &&
                    jiu_kehu_name != i.value &&
                    i.value.length != 0))
              "
            >
              该客户已存在
            </p>
            <div
              class="beijing"
              v-if="i.teshu && i.defaultLable == '客户'"
              @click="i.teshu = false"
            >
              <div class="beijing_box" @click.stop>
                <p class="beijing_tit">选择客户</p>
                <p class="kehu_sousuo">
                  <i class="el-icon-search"></i>
                  <input
                    placeholder="请输入搜索内容"
                    v-model="sousuo_kehu_name"
                    @keyup.enter="dianji_sousuo_kehu(i)"
                    type="text"
                  />
                  <i
                    @click="
                      sousuo_kehu_name = '';
                      dianji_sousuo_kehu(i);
                    "
                    class="el-icon-circle-close"
                  ></i>
                </p>
                <ul>
                  <template v-for="(ia, inde) in i.kehu_list">
                    <li :key="inde" @click="i.zhanshi = inde">
                      <img
                        :src="
                          i.zhanshi == inde
                            ? require('../assets/red_quan.png')
                            : require('../assets/ccc_quan.png')
                        "
                      />
                      {{ ia.file_no }}--{{ ia.cust_name }}
                    </li>
                  </template>
                  <li
                    v-if="i.kehu_list.length == 0"
                    style="color: #333; font-size: 0.14rem"
                  >
                    暂无数据
                  </li>
                  <!-- <li v-for='(ia,inde) in i.kehu_list' @click="i.zhanshi=inde" :key="inde">
                                        <img :src="i.zhanshi==inde?require('../assets/red_quan.png'):require('../assets/ccc_quan.png')">
                                        {{ia.file_no}}--{{ia.cust_name}}</li>
                                    <li v-if="i.kehu_list.length==0" style="color:#333;font-size:0.14rem;">暂无数据</li> -->
                </ul>
                <!-- 回款单的选择选择客户按钮 -->
                <p class="beijing_foot">
                  <span
                    @click="
                      i.teshu = false;
                      i.value = '';
                    "
                    >取消</span
                  >&nbsp;&nbsp;&nbsp;<span @click="xuanze_kehu(i)">确定</span>
                </p>
              </div>
            </div>
            <div
              class="beijing"
              v-if="i.teshu && i.defaultLable == '决策人'"
              @click="i.teshu = false"
            >
              <div class="beijing_box" @click.stop>
                <p class="beijing_tit">选择决策人</p>
                <ul>
                  <li
                    v-for="(iat, indet) in i.juece_ren"
                    @click="xuan_juece_ren(i.juece_ren, indet)"
                    :key="indet"
                  >
                    <!-- <img :src="it.zhanshi==indet?require('../assets/red_quan.png'):require('../assets/ccc_quan.png')"> -->
                    <img
                      :src="
                        iat.zhi
                          ? require('../assets/okblue.png')
                          : require('../assets/noccc.png')
                      "
                    />
                    {{ iat.name }}
                  </li>
                </ul>
                <p class="beijing_foot">
                  <span @click="i.teshu = false">取消</span>&nbsp;&nbsp;&nbsp;<span
                    @click="xuanze_juece_ren(i)"
                    >确定</span
                  >
                </p>
              </div>
            </div>
            <div
              class="beijing"
              v-if="i.teshu && i.defaultLable == '业务人员'"
              @click="i.teshu = false"
              @click.stop
            >
              <!-- 选择业务人员 -->
              <div class="beijing_box" @click.stop>
                <p class="beijing_tit">选择业务人员</p>
                <!--  GetSelectYwry(item.label,items.label,item,item) -->
                <div class="beijing-main">
                  <people-tree
                    :message="BusinessPersonlList"
                    @getTreeData="getTreeData"
                  ></people-tree>
                </div>
                <p class="beijing_foot">
                  <span
                    @click="
                      i.teshu = false;
                      i.value = '';
                    "
                    >取消</span
                  >&nbsp;&nbsp;&nbsp;<span @click="xuanze_kehu(i, index)">确定</span>
                </p>
              </div>
            </div>
          </div>
          <div
            class="wai_div"
            v-if="i.componentName == 'textfield' && zhuangtai_shiyong_name == '作废'"
          >
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <input
              class="select-ywry-input"
              :disabled="true"
              type="text"
              v-model="i.value"
            />
          </div>
          <div class="wai_div" v-if="i.componentName == 'cust'">
            <!--我是客户框001 详情看usertable.ts-->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <input
              @click="dianji_kehu(i)"
              type="text"
              v-model="i.value"
              :disabled="zhuangtai_shiyong_name == '作废'"
              :placeholder="i.defaultProps"
            />
            <div class="beijing" v-if="i.teshu" @click="i.teshu = false">
              <div class="beijing_box" @click.stop>
                <p class="beijing_tit">选择客户</p>
                <p class="kehu_sousuo">
                  <i class="el-icon-search"></i>
                  <input
                    placeholder="请输入搜索内容"
                    v-model="sousuo_kehu_name"
                    @keyup.enter="dianji_sousuo_kehu(i)"
                    type="text"
                  />
                  <i
                    @click="
                      sousuo_kehu_name = '';
                      dianji_sousuo_kehu(i);
                    "
                    class="el-icon-circle-close"
                  ></i>
                </p>
                <ul>
                  <li
                    v-for="(ia, inde) in i.kehu_list"
                    @click="i.zhanshi = inde"
                    :key="inde"
                  >
                    <div style="display: flex">
                      <img
                        :src="
                          i.zhanshi == inde
                            ? require('../assets/red_quan.png')
                            : require('../assets/ccc_quan.png')
                        "
                      />
                      <p v-if="i.flow_id == 'hXstgwxhzh'">
                        {{ ia.file_no }}--{{ ia.cust_name }}
                      </p>
                      <p v-else>{{ ia.cust_name }}</p>
                    </div>
                  </li>
                  <li
                    v-if="i.kehu_list.length == 0"
                    style="color: #333; font-size: 0.14rem"
                  >
                    暂无数据
                  </li>
                </ul>
                <p class="beijing_foot">
                  <span
                    @click="
                      i.teshu = false;
                      i.value = '';
                    "
                    >取消</span
                  >&nbsp;&nbsp;&nbsp;<span @click="xuanze_kehu(i, index)">确定</span>
                </p>
              </div>
            </div>
          </div>
          <div class="wai_div" v-if="i.componentName == 'textareafield'">
            <!--我是多行输入框-->
            <!-- 我是输入框 -->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <input
              type="text"
              :disabled="zhuangtai_shiyong_name == '作废'"
              v-model="i.value"
              :placeholder="i.defaultProps"
            />
          </div>
          <div class="wai_div" v-if="i.componentName == 'serialnum'">
            <!--我是序列号-->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <input
              type="text"
              :disabled="zhuangtai_shiyong_name == '作废'"
              v-model="i.value"
              :placeholder="i.defaultProps"
            />
          </div>
          <div class="wai_div" v-if="i.componentName == 'suppliername'">
            <!--我是供应商标签-->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <!-- 5FotYgnu0Z -->
            <input
              v-if="date.flow_id == '5FotYgnu0Z'"
              type="text"
              @blur="chaxun_gongying_shang(i.value)"
              :disabled="zhuangtai_shiyong_name == '作废'"
              v-model="i.value"
              :placeholder="i.defaultProps"
            />
            <p v-if="date.flow_id != '5FotYgnu0Z'" style="width: 6rem">
              <el-select
                v-model="i.value_id"
                :disabled="
                  zhuangtai_shiyong_name == '作废' || zhuangtai_shiyong_name == '看板'
                "
                @change="xuanze_gongying_shang(i)"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in i.option"
                  :key="item.supply_id"
                  :label="item.supply_name"
                  :value="item.supply_id"
                >
                </el-option>
              </el-select>
            </p>
          </div>
          <div class="wai_div" v-if="i.componentName == 'contacts'">
            <!--  我是联系人  -->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <!-- ||zhuangtai_shiyong_name=='公海池' -->
            <!-- <input v-if="i.defaultLable=='负责人'" type="text" v-model="i.value" :placeholder="i.defaultProps" @click="dakai_renyuan"> -->
            <input
              v-if="i.defaultLable == '派工人员' && !gongying_shang_xiugai_fuze_ren"
              type="text"
              v-model="i.value"
              :placeholder="i.defaultProps"
              @click="dakai_renyuan(i.idx, i)"
            />
            <input
              v-if="i.defaultLable != '派工人员' && !gongying_shang_xiugai_fuze_ren"
              disabled
              type="text"
              v-model="i.value"
              :placeholder="i.defaultProps"
            />
            <p
              class="kehu_biaoqian"
              :style="
                i.value == undefined || i.value.length == 0
                  ? 'color:rgb(117, 117, 117);'
                  : ''
              "
              v-if="gongying_shang_xiugai_fuze_ren"
              @click="dakai_renyuan(i.idx, i)"
            >
              {{ i.value == undefined || i.value.length == 0 ? "请选择" : i.value }}
            </p>
            <!-- <input v-if="i.defaultLable=='负责人'&&zhuangtai_shiyong_name=='公海池'" type="text" v-model="i.value" :placeholder="i.defaultProps" @click="dakai_renyuan">
                        <input v-if="i.defaultLable=='派工人员'" type="text" v-model="i.value" :placeholder="i.defaultProps" @click="dakai_renyuan">
                        <input v-if="i.defaultLable!='派工人员'&&zhuangtai_shiyong_name!='公海池'" disabled type="text" v-model="i.value" :placeholder="i.defaultProps"> -->
          </div>
          <div v-if="i.componentName == 'assappform'">
            <!--我是关联审批单--->
            <div class="wai_div">
              <p class="p">
                <span class="tit_name">{{ i.defaultLable }}</span>
              </p>
              <!-- increase -->
              <p>请选择(可多选)</p>
            </div>
            <div class="wai_div">
              <p class="p"></p>
              <ul class="biaodan_guanlian_dan">
                <li
                  v-for="(it, inde) in i.value_list"
                  @click="biaodan_xiangqing(it)"
                  :key="inde"
                >
                  <p>{{ it.name }}</p>
                  <p>{{ it.caozuo }}</p>
                  <i
                    @click.stop="dianji_shanchu_biaodan(i, inde)"
                    class="el-icon-circle-close"
                  ></i>
                </li>
              </ul>
            </div>
            <div class="wai_div">
              <p class="p"></p>
              <div class="image-view">
                <div class="addbox">
                  <img
                    class="img_1"
                    src="../assets/increase.png"
                    @click="dianji_xuanze_shenpi_dan(i)"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <BiaodanXiangqing ref="biaodan_xiangqing_zhanshi"></BiaodanXiangqing>
            <GuanlianShenpiDan ref="shenpi_dan_guanlian"></GuanlianShenpiDan>

            <!-- <div class='beijing' v-if='i.zhanshi' @click="i.zhanshi=false">
                            <div class="beijing_box" @click.stop>

                            </div>
                        </div> -->
            <!-- {{i.value_obj.cust_name}} -->
            <!-- <ul style="border:0.01rem solid red;height:3rem;width:3rem;" v-if="i.zhanshi">
                           <li v-for="(ia,inde) in i.guanlian_liebiao_list" :key="inde" @click="i.value=inde">
                               <img :src="i.value==inde?require('../assets/red_quan.png'):require('../assets/ccc_quan.png')">
                               {{ia.cust_name}}</li>
                           <li><span @click="i.zhanshi=false">取消</span>&nbsp;&nbsp;&nbsp;<span @click="xuanze_biaodan_sure(i)">确定</span></li>
                        </ul> -->
          </div>
          <div
            class="wai_div"
            v-if="i.componentName == 'department' && zhuangtai_shiyong_name != '作废'"
          >
            <!--我是部门-->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <!--  v-if="date.flow_id=='7TvJHPyovM'" -->
            <input
              type="text"
              v-model="i.value"
              v-if="
                date.flow_id == '7TvJHPyovM' &&
                zhuangtai_shiyong_name != '已分配' &&
                zhuangtai_shiyong_name != '已分配_编辑' &&
                zhuangtai_shiyong_name != '看板_编辑' &&
                zhuangtai_shiyong_name != '作废' &&
                zhuangtai_shiyong_name != '公海池_编辑' &&
                zhuangtai_shiyong_name != '审批中心'
              "
              :placeholder="i.defaultProps"
              @focus="dakai_bumen_a"
            />
            <p
              v-if="
                date.flow_id != '7TvJHPyovM' ||
                zhuangtai_shiyong_name == '已分配' ||
                zhuangtai_shiyong_name == '已分配_编辑' ||
                zhuangtai_shiyong_name == '看板_编辑' ||
                zhuangtai_shiyong_name == '作废' ||
                zhuangtai_shiyong_name == '公海池_编辑' ||
                zhuangtai_shiyong_name == '审批中心'
              "
              style="width: 6rem"
            >
              <el-select
                @change="xuanze_bumen(i)"
                :disabled="
                  zhuangtai_shiyong_name == '已分配_编辑' ||
                  zhuangtai_shiyong_name == '作废' ||
                  zhuangtai_shiyong_name == '公海池_编辑'
                "
                v-model="i.dept_id"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in i.option"
                  :key="item.dept_id"
                  :label="item.dept_name"
                  :value="item.dept_id"
                >
                </el-option>
              </el-select>
            </p>
          </div>
          <div
            class="wai_div"
            v-if="
              i.componentName == 'department' &&
              zhuangtai_shiyong_name == '作废' &&
              i.defaultLable == '部门'
            "
          >
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <!--  v-if="date.flow_id=='7TvJHPyovM'" -->
            <input type="text" v-model="i.value" disabled />
          </div>
          <!--我是bu  -->
          <div v-if="i.componentName == 'product'">
            <!--我是产品-->
            <div class="wai_div">
              <p class="p">
                <span style="color: red" v-if="i.defaultImportant">*</span>
                <span class="tit_name">{{ i.defaultLable }}</span>
              </p>
              <input
                :disabled="zhuangtai_shiyong_name == '作废'"
                type="text"
                v-model="i.value"
                :placeholder="i.defaultProps"
                @click="dakai_chanpin(undefined, i)"
              />
            </div>
            <div class="wai_div">
              <p class="p">
                <span class="tit_name" v-if="i.value != ''">产品编号</span>
              </p>
              <input
                type="text"
                v-if="i.value != ''"
                v-model="i.chanpin_list.prod_code"
                disabled
              />
            </div>
            <div class="wai_div">
              <p class="p">
                <span class="tit_name" v-if="i.value != ''">产品类型</span>
              </p>
              <input
                type="text"
                v-if="i.value != ''"
                v-model="i.chanpin_list.cat_name"
                disabled
              />
            </div>
            <div class="wai_div">
              <p class="p">
                <span class="tit_name" v-if="i.value != ''">规格</span>
              </p>
              <input
                type="text"
                v-if="i.value != ''"
                v-model="i.chanpin_list.spec"
                disabled
              />
            </div>
            <div class="wai_div">
              <p class="p">
                <span class="tit_name" v-if="i.value != ''">型号</span>
              </p>
              <input
                type="text"
                v-if="i.value != ''"
                v-model="i.chanpin_list.type"
                disabled
              />
            </div>
            <div class="wai_div">
              <p class="p">
                <span class="tit_name" v-if="i.value != ''">单位</span>
              </p>
              <input
                type="text"
                v-if="i.value != ''"
                v-model="i.chanpin_list.unit"
                disabled
              />
            </div>
            <div class="beijing" v-if="chanpin1" @click="chanpin1 = false">
              <div class="beijing_box" @click.stop>
                <i
                  class="el-icon-error guanbi_xuanze_chanpin"
                  @click="chanpin1 = false"
                ></i>
                <div class="beijing_box_tit">
                  产品分类：
                  <div class="beijing_box_tit_div">
                    <p class="beijing_box_tit_p">{{ dangqian_chanpin.label }}</p>
                    <div class="beijing_box_tit_tree">
                      <el-tree
                        :data="qiye_chanpin_leixing_tree"
                        :props="defaultProps"
                        @node-click="xuanze_chanpin_dalei"
                      ></el-tree>
                    </div>
                  </div>
                  搜索：
                  <p>
                    <i class="el-icon-search"></i>
                    <input
                      v-model="sousuo_chanpin_text"
                      @keyup.enter="chanpin_sousuo()"
                      type="text"
                    />
                    <i
                      @click="(sousuo_chanpin_text = ''), chanpin_sousuo()"
                      class="el-icon-circle-close"
                    ></i>
                  </p>
                  <span @click="dianji_xuanze_chanpin_baocun(i, undefined)">确定</span>
                </div>
                <div class="beijing_box_table">
                  <!-- @selection-change="handleSelectionChange"  -->
                  <el-table
                    :cell-style="liebiao_yangshi"
                    height="500"
                    :header-cell-style="biaotou_yangshi"
                    :data="chanpin_liebiao_list"
                    highlight-current-row
                    ref="Tablea"
                    style="width: 100%"
                    @select="xuanze_chanpin"
                  >
                    <!-- @current-change="chooseMcaterialChange" -->
                    <el-table-column type="selection" width="42"> </el-table-column>
                    <el-table-column prop="prod_code" label="产品编号"> </el-table-column>
                    <el-table-column prop="prod_name" label="产品"> </el-table-column>
                    <el-table-column prop="cat_name" label="类型"> </el-table-column>
                    <el-table-column prop="spec" label="规格"> </el-table-column>
                    <el-table-column prop="type" label="型号"> </el-table-column>
                    <el-table-column prop="unit" label="单位"> </el-table-column>
                    <el-table-column prop="price_basic" label="初始价"> </el-table-column>
                    <!-- <el-table-column  prop="qty" label="数量">
                                        </el-table-column> -->
                    <!-- <el-table-column  prop="price_cost" label="成本" width="80">
                                        </el-table-column>
                                        <el-table-column  prop="price_sale" label="售价" width="80">
                                        </el-table-column> -->
                    <el-table-column prop="disc_rate" label="调价率"> </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
          <div class="wai_div" v-if="zhuguan && i.componentName == 'department'">
            <p class="p"><span style="color: red">*</span>调整后部门</p>
            <input
              type="text"
              v-model="zhuguan_xuanze_bumen"
              placeholder="请选择调整后的部门"
              @focus="dakai_bumen_a"
            />
          </div>
          <div class="wai_div" v-if="i.componentName == 'calcform'">
            <!--我是计算公式--->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <input type="number" disabled v-model="i.value" />
          </div>
          <div class="wai_div" v-if="i.componentName == 'numberfield'">
            <!--我是数字框-->
            <!-- 我是数字框 -->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <input
              :maxlength="i.defaultLable == '手机' ? 11 : 999999"
              type="number"
              :disabled="
                i.defaultLable == '年累计外出时长（小时）' ||
                i.defaultLable == '年累计出差天数' ||
                zhuangtai_shiyong_name == '作废'
              "
              v-model="i.value"
              @keyup="shuzi_jianting(i)"
              @change="i.defaultLable == '漏斗折算比例' ? loudou_zhesuan_bili(i) : ''"
              @focus="i.defaultLable == '手机' ? (i.value_id = false) : ''"
              @blur="i.defaultLable == '手机' ? phone_num(i.value, i) : ''"
              :placeholder="i.defaultProps"
            />
            <p
              class="tishi_xinxi animated fadeInRight"
              v-if="i.value_id && i.defaultLable == '手机'"
            >
              手机号不规范
            </p>
          </div>
          <div class="wai_div" v-if="i.componentName == 'moneyfield'">
            <!--我是金额框 回款单的金额框-->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <!--1600334556634累计入库金额 1600332056576   入库金额    1600334301434  采购单发票累计未结账金额   1600334302490  采购单累计未付款金额-->
            <input
              type="number"
              @keyup="jine_jianting(i)"
              @blur="get_amt_input(i)"
              :disabled="
                i.idx == '1600332056576' ||
                i.idx == '1600334556634' ||
                i.idx == '1600334301434' ||
                i.idx == '1600334302490' ||
                i.defaultLable == '合计不含税金额' ||
                i.defaultLable == '合同金额' ||
                i.defaultLable == '合同未开发票金额' ||
                i.defaultLable == '累计回款金额' ||
                i.defaultLable == '累计发票金额' ||
                i.defaultLable == '应收金额' ||
                i.defaultLable == '累计付款金额' ||
                zhuangtai_shiyong_name == '作废' ||
                i.defaultLable == '会员卡金额' ||
                i.defaultLable == '会员卡余额'
              "
              v-model="i.value"
              :placeholder="i.defaultProps"
            />
          </div>
          <div class="wai_div" v-if="i.componentName == 'textnote'">
            <!--我是备注框-->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultProps }}</span>
            </p>
            <textarea
              class="shuoming_wenzi"
              type="text"
              maxlength="5000"
              :disabled="zhuangtai_shiyong_name == '作废'"
              v-model="i.value"
              placeholder="请输入"
            >
            </textarea>
          </div>
          <div class="wai_div" v-if="i.componentName == 'dddatefield'">
            <!--我是日期框-->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <el-date-picker
              v-model="i.value"
              align="right"
              @change="get_curr_Time(i.value)"
              type="datetime"
              :clearable="false"
              :format="i.defaultFormat"
              popper-class="shenpi_xuanze_rqi"
              placeholder="选择日期"
              :disabled="zhuangtai_shiyong_name == '作废'"
            >
            </el-date-picker>
          </div>
          <div v-if="i.componentName == 'dddaterangefield'">
            <!--我是日期区间框我是日期区间框-->
            <div class="wai_div">
              <p class="p">
                <span style="color: red" v-if="i.defaultImportant">*</span>
                <span class="tit_name">{{ i.defaultLable }}</span>
              </p>
              <el-date-picker
                v-model="i.value"
                type="datetimerange"
                class="rqi_qujian_kuang"
                :clearable="false"
                popper-class="shenpi_xuanze_rqi"
                :format="i.defaultFormat"
                :disabled="zhuangtai_shiyong_name == '作废'"
                range-separator="至"
                @change="xuanze_shijian_wanbi(i)"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
            <div
              class="wai_div"
              style="margin: 0.1rem 0"
              v-if="i.defaultLable == '拜访时间' || i.defaultLable == '出差时间'"
            >
              <p class="p"><span>时长</span></p>
              <input type="text" disabled v-model="i.value_id" />
            </div>
          </div>
          <div class="wai_div" v-if="i.componentName == 'ddphotofield'">
            <!--我是图片框-->
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span>
              <span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <div class="image-view">
              <div class="addbox" v-if="i.imgBase64.length == 0">
                <input type="file" @change="getImgBasea(i)" />
                <img class="img_1" src="../assets/increase.png" alt />
              </div>
              <div class="view">
                <div class="item" v-for="(item, indexa) in i.imgBase64" :key="indexa">
                  <span
                    class="cancel-btn"
                    @click="delImga(i, indexa)"
                    v-if="zhuangtai_shiyong_name != '作废'"
                    >x</span
                  >
                  <img @click.stop="tupian_dianji(item)" :src="item" class="img_3" />
                </div>
              </div>
            </div>
          </div>
          <!-- 我是单选框 -->
          <div
            class="wai_div"
            v-if="
              i.componentName == 'ddselectfield' &&
              i.defaultLable != '地区' &&
              i.defaultLable != '地址' &&
              i.defaultLable != '决策人' &&
              i.flow_id !== 'He5ln0bdDO'
            "
          >
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span
              ><span class="tit_name" @click="Get_pay()">{{ i.defaultLable }}</span>
            </p>
            <!--
                            disabled="zhuangtai_shiyong_name=='作废'" -->
            <p
              class="nei_danxuan"
              v-for="(it, inde) in i.defaultOptions"
              :key="inde"
              @click="
                zhuangtai_shiyong_name == '作废' ? wuyong() : danxuan(i, inde, index)
              "
            >
              <img
                :src="
                  i.value == inde
                    ? require('../assets/red_quan.png')
                    : require('../assets/ccc_quan.png')
                "
              />
              {{ it.text }}
            </p>
          </div>
          <!-- 我是-回款单-结算方式单选框 -->
          <div
            class="wai_div"
            v-if="i.componentName == 'ddselectfield' && i.flow_id == 'He5ln0bdDO'"
          >
            <!--   <template v-for="(item,index) in i.defaultOptions"> -->
            <div style="width: 100%; background: rgba(239, 239, 239, 0.3)">
              <el-row
                :key="index"
                type="flex"
                justify="center"
                :gutter="20"
                style="padding-top: 20px"
              >
                <el-col
                  :span="6"
                  style="min-height: 36px; line-heigh: 36px; text-align: center"
                  ><p>结算方式</p></el-col
                ><el-col
                  :span="6"
                  style="min-height: 36px; line-heigh: 36px; text-align: left"
                  ><p>结算金额</p></el-col
                ><el-col :span="6">结算占比</el-col>
              </el-row>
              <el-row
                type="flex"
                justify="center"
                :gutter="20"
                v-for="(item, index) in i.defaultOptions"
                :key="index"
              >
                <el-col
                  :span="6"
                  style="min-height: 36px; line-heigh: 36px; text-align: center"
                  >{{ item.text }}</el-col
                ><el-col
                  :span="6"
                  style="min-height: 36px; line-heigh: 36px; text-align: left"
                >
                  <input
                    @input="myjiansuan(i, item)"
                    type="number"
                    :disabled="i.shiyong_stauts"
                    v-model="item.amt_pay"
                    style="width: 1.5rem"
                /></el-col>
                <el-col :span="6"
                  ><p type="number">{{ item.rate_pay }}%</p></el-col
                >
              </el-row>
              <p>占比合计:{{ i.heji }}%{{ i.shiyong_stauts }}</p>
            </div>
          </div>
          <div
            class="wai_div"
            v-if="
              i.componentName == 'tablefield' &&
              i.flow_id == 'AY3cli7BAb' &&
              zhuangtai_shiyong_name == '作废'
            "
          >
            <!--   <template v-for="(item,index) in i.defaultOptions"> -->
            <div style="width: 100%; background: rgba(239, 239, 239, 0.3)">
              <el-row
                :key="index"
                type="flex"
                justify="center"
                :gutter="20"
                style="padding-top: 20px"
              >
                <el-col
                  :span="6"
                  style="min-height: 36px; line-heigh: 36px; text-align: center"
                  ><p>结算方式</p></el-col
                ><el-col
                  :span="6"
                  style="min-height: 36px; line-heigh: 36px; text-align: left"
                  ><p>结算金额</p></el-col
                ><el-col :span="6">结算占比</el-col>
              </el-row>
              <el-row
                type="flex"
                justify="center"
                :gutter="20"
                v-for="(item, index) in i.defaultOptions"
                :key="index"
              >
                <el-col
                  :span="6"
                  style="min-height: 36px; line-heigh: 36px; text-align: center"
                  >{{ item.pay_type }}</el-col
                ><el-col
                  :span="6"
                  style="min-height: 36px; line-heigh: 36px; text-align: left"
                >
                  <input
                    @input="myjiansuan(i, item)"
                    type="number"
                    :disabled="true"
                    v-model="item.amt_pay"
                    style="width: 1.5rem"
                /></el-col>
                <el-col :span="6"
                  ><p type="number">{{ item.rate_pay }}%</p></el-col
                >
              </el-row>
            </div>
          </div>

          <!-- 我是单选框 -->
          <div
            class="wai_div"
            @click="ceshi_diqu2(i)"
            v-if="
              i.componentName == 'ddselectfield' &&
              (i.defaultLable == '地区' || i.defaultLable == '地址')
            "
          >
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span
              ><span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <v-distpicker
              :disabled="zhuangtai_shiyong_name == '作废'"
              @selected="onSelected2"
              :city="i.value_id"
              :province="i.value"
              hide-area
            ></v-distpicker>
            <!--  :city='i.value_id' -->
          </div>
          <div
            class="wai_div"
            @click="ceshi_diqu2(i)"
            v-if="i.componentName == 'province'"
          >
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span
              ><span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <v-distpicker
              @selected="onSelected2"
              :province="i.value"
              :city="i.value_id"
              :area="i.value_ids"
            ></v-distpicker>
          </div>
          <!-- 我是多选框 -->
          <div
            class="wai_div"
            v-if="
              i.componentName == 'ddmultiselectfield' &&
              (i.defaultLable == '客户标签' || i.defaultLable == '供应商标签')
            "
          >
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span
              ><span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <p
              class="kehu_biaoqian"
              :style="
                i.value == undefined || i.value.length == 0
                  ? 'color:rgb(117, 117, 117);'
                  : ''
              "
              v-if="i.defaultLable == '客户标签' || i.defaultLable == '供应商标签'"
              @click="dianji_dakai_chanpin()"
            >
              {{ i.value == undefined || i.value.length == 0 ? "请选择" : i.value }}
            </p>
            <el-dialog
              title="选择产品"
              :center="true"
              :show-close="false"
              append-to-body
              :visible.sync="chanpin_lei"
            >
              <div class="tiao_bumen">
                <div class="xuanze">
                  <p class="tit">选择：</p>
                  <div class="nei_box">
                    <el-tree
                      :data="chanpin_lei_list"
                      show-checkbox
                      default-expand-all
                      ref="chanpintree2"
                      highlight-current
                      check-strictly
                      :props="tree_guize_chanpin"
                      :filter-node-method="filterNode_2"
                      style="background: #e7e8eb"
                      @check="fuxuan_2"
                    >
                    </el-tree>
                  </div>
                </div>
              </div>
              <div class="foote">
                <p class="queding" @click="chanpin_lei_sure(i)">确定</p>
                <p class="quxiao" @click="chanpin_lei = false">取消</p>
              </div>
            </el-dialog>
          </div>
          <!-- 我是多选框 -->
          <div
            class="wai_div"
            v-if="
              i.componentName == 'ddmultiselectfield' &&
              i.defaultLable != '客户标签' &&
              i.defaultLable != '供应商标签'
            "
          >
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span
              ><span class="tit_name">{{ i.defaultLable }}</span>
            </p>
            <p
              class="nei_danxuan"
              v-for="(it, inde) in i.defaultOptions"
              :key="inde"
              @click="
                zhuangtai_shiyong_name == '作废' ? wuyong() : (it.zhi = !it.zhi);
                liucheng_jiekou();
              "
            >
              <img
                :src="
                  it.zhi
                    ? require('../assets/okblue.png')
                    : require('../assets/noccc.png')
                "
              />
              {{ it.text }}
            </p>
          </div>
          <!-- 我是单选框 -->
          <div
            class="wai_div"
            v-if="i.componentName == 'ddselectfield' && i.defaultLable == '决策人'"
          >
            <p class="p">
              <span style="color: red" v-if="i.defaultImportant">*</span
              >{{ i.defaultLable }}
            </p>
            <p
              @click="
                zhuangtai_shiyong_name == '作废' ? wuyong() : dianji_xuanze_juece_ren(i)
              "
              v-if="i.value_id == ''"
            >
              请选择
            </p>
            <span>{{ i.value_id }}</span>
            <ul
              style="height: 3rem; width: 3rem; border: 0.01rem solid red"
              v-if="i.teshu"
            >
              <li
                v-for="(it, inde) in i.defaultOptions"
                :key="inde"
                @click="danxuan(i, inde)"
              >
                <img
                  :src="
                    i.value == inde
                      ? require('../assets/red_quan.png')
                      : require('../assets/ccc_quan.png')
                  "
                />
                {{ it.c_name }}
              </li>
              <li>
                <span @click="i.teshu = false">取消</span>&nbsp;&nbsp;&nbsp;<span
                  @click="xuanze_juece(i)"
                  >确定</span
                >
              </li>
            </ul>
          </div>
          <!-- 我是附件框 -->
          <!-- disabled="zhuangtai_shiyong_name=='作废'" -->
          <div
            v-if="zhuangtai_shiyong_name != '作废' && i.componentName == 'ddattachment'"
          >
            <div class="wai_div">
              <p class="p">
                <span style="color: red" v-if="i.defaultImportant">*</span>
                <span class="tit_name">{{ i.defaultLable }}</span>
              </p>
              <div class="fujian_anniu">
                <input type="file" @change="dianji_dakai_shangchuan_wenjian(i)" />
                点击上传
              </div>
            </div>
            <div class="wai_div">
              <p class="p"></p>
              <ul class="fujian_yangshi">
                <li v-for="(it, inde) in i.list" :key="inde">
                  <p>{{ it.name }}</p>
                  <i
                    @click="dianji_shanchu_fujian(i, inde)"
                    class="el-icon-circle-close"
                  ></i>
                </li>
              </ul>
            </div>
            <!-- <ul style='height:3rem;width:3rem;border:0.01rem solid red;' v-if="i.teshu">
                            <li v-for="(it,inde) in i.defaultOptions" :key="inde" @click="danxuan(i,inde)">
                                <img :src="i.value==inde?require('../assets/red_quan.png'):require('../assets/ccc_quan.png')">
                                {{it.c_name}}
                            </li>
                            <li><span @click="i.teshu=false">取消</span>&nbsp;&nbsp;&nbsp;<span @click="xuanze_juece(i)">确定</span></li>
                        </ul> -->
          </div>
          <!-- 明细框 -->
          <div v-if="i.componentName == 'tablefield' && !biao_shi">
            <div class="mingxi_kuang">
              <p
                class="zheng_fan_suan"
                v-if="
                  (i.defaultLable == '产品明细' ||
                    i.defaultLable == '产品' ||
                    i.defaultLable == '产品回款' ||
                    i.defaultLable == '发票内容') &&
                  zhuangtai_shiyong_name != '作废' &&
                  date.flow_id != 'H49d4edc3T'
                "
                @click="zheng_fan = !zheng_fan"
              >
                当前状态:<span>{{ zheng_fan ? "正算" : "反算" }}</span
                >(点击即可切换)
              </p>
              <!-- :cell-style="cellStyle"
                                :header-cell-style="headerCellStyle" -->
              <!-- <span v-if="biaotou_list[index].con=='prod_name'">{{props.row[biaotou_list[index].con]}}</span> -->
              <el-table
                :data="i.components_list"
                :header-cell-style="headerCellStyle"
                border
                ref="table"
              >
                <!--xb==props.$index-->
                <!--it==prop.row[index]-->
                <el-table-column
                  v-for="(item, index) in biaotou_list[i.idx]"
                  :key="index"
                  :label="item.name"
                  :fixed="item.con == '000000000' ? 'right' : false"
                >
                  <el-table-column v-if="item.componentName == 'product'" label="产品">
                    <template slot-scope="prop">
                      <div class="wai_div">
                        <span style="color: red" v-if="prop.row[index].defaultImportant"
                          >*</span
                        >
                        <input
                          :disabled="
                            zhuangtai_shiyong_name == '作废' || i.idx == '1600331810240'
                          "
                          type="text"
                          v-model="prop.row[index].value"
                          :placeholder="prop.row[index].defaultProps"
                          @click="
                            dakai_chanpin(
                              prop.$index,
                              prop.row[index],
                              i.components_list[prop.$index],
                              i.components_list,
                              i
                            )
                          "
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="item.componentName == 'product'"
                    label="产品编号"
                  >
                    <template slot-scope="prop">
                      <div class="wai_div">
                        <input
                          type="text"
                          v-if="prop.row[index].value != ''"
                          v-model="prop.row[index].chanpin_list.prod_code"
                          disabled
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column
                                        v-if="item.componentName=='product'"
                                        label="产品类型">
                                        <template slot-scope="prop">
                                            <div class="wai_div">
                                                <input type="text" v-if="prop.row[index].value!=''" v-model="prop.row[index].chanpin_list.cat_name" disabled>
                                            </div>
                                        </template>
                                    </el-table-column> -->
                  <el-table-column v-if="item.componentName == 'product'" label="规格">
                    <template slot-scope="prop">
                      <div class="wai_div">
                        <input
                          type="text"
                          v-if="prop.row[index].value != ''"
                          v-model="prop.row[index].chanpin_list.spec"
                          disabled
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column v-if="item.componentName == 'product'" label="型号">
                    <template slot-scope="prop">
                      <div class="wai_div">
                        <input
                          type="text"
                          v-if="prop.row[index].value != ''"
                          v-model="prop.row[index].chanpin_list.type"
                          disabled
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column v-if="item.componentName == 'product'" label="单位">
                    <template slot-scope="prop">
                      <div class="wai_div">
                        <input
                          type="text"
                          v-if="prop.row[index].value != ''"
                          v-model="prop.row[index].chanpin_list.unit"
                          disabled
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 我是日期区间框 -->
                  <el-table-column
                    v-if="
                      item.componentName == 'dddaterangefield' && i.defaultLable == '行程'
                    "
                    label="开始时间"
                  >
                    <template slot-scope="prop">
                      <div class="wai_div">
                        <span style="color: red" v-if="prop.row[index].defaultImportant"
                          >*</span
                        >
                        <el-date-picker
                          v-model="prop.row[index].value"
                          align="right"
                          type="datetime"
                          :clearable="false"
                          :format="prop.row[index].defaultFormat"
                          @change="xuanze_shang_xiawu(prop.row[index])"
                          class="xingcheng_rqi_qujian"
                          popper-class="shenpi_xuanze_rqi"
                          placeholder="开始日期"
                        >
                        </el-date-picker>
                      </div>

                      <el-select
                        v-model="prop.row[index].value_start"
                        class="xingcheng_rqi_sx"
                        @change="xuanze_shang_xiawu(prop.row[index])"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="ite in shang_xiawu"
                          :key="ite.value"
                          :label="ite.label"
                          :value="ite.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="
                      item.componentName == 'dddaterangefield' && i.defaultLable == '行程'
                    "
                    label="结束时间"
                  >
                    <template slot-scope="prop">
                      <div class="wai_div">
                        <span style="color: red" v-if="prop.row[index].defaultImportant"
                          >*</span
                        >
                        <el-date-picker
                          v-model="prop.row[index].value_id"
                          @change="xuanze_shang_xiawu(prop.row[index])"
                          align="right"
                          :clearable="false"
                          type="datetime"
                          :format="prop.row[index].defaultFormat"
                          class="xingcheng_rqi_qujian"
                          popper-class="shenpi_xuanze_rqi"
                          placeholder="结束日期"
                        >
                        </el-date-picker>
                      </div>

                      <el-select
                        v-model="prop.row[index].value_id_end"
                        class="xingcheng_rqi_sx"
                        @change="xuanze_shang_xiawu(prop.row[index])"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="ite in shang_xiawu"
                          :key="ite.value"
                          :label="ite.label"
                          :value="ite.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="
                      item.componentName == 'dddaterangefield' && i.defaultLable == '行程'
                    "
                    label="时长"
                  >
                    <template slot-scope="prop">
                      <div class="wai_div">
                        <span style="color: red" v-if="prop.row[index].defaultImportant"
                          >*</span
                        >
                        <input
                          type="text"
                          v-model="prop.row[index].day"
                          disabled
                          style="width: 1rem"
                        />
                      </div>
                    </template>
                  </el-table-column>

                  <template slot-scope="prop">
                    <!-- <div>
                                                {{item.componentName}}
                                                {{prop.$index}}
                                            </div> -->
                    <div class="wai_div" v-if="item.componentName == 'moneyfield'">
                      <!--我是金额框-->
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <!-- 1600416819906  入库单 的产品报价 1600481797979出库单的产品单价-->
                      <input
                        type="number"
                        @keyup="jine_jianting(prop.row[index])"
                        :disabled="
                          zhuangtai_shiyong_name == '作废'
                            ? true
                            : prop.row[index].defaultLable == '单价' ||
                              (zheng_fan && prop.row[index].defaultLable == '发票金额') ||
                              prop.row[index].defaultLable == '税额' ||
                              prop.row[index].defaultLable == '不含税金额' ||
                              prop.row[index].defaultLable == '不含税折后单价' ||
                              prop.row[index].idx == '1600416819906' ||
                              prop.row[index].idx == '1600481797979'
                            ? true
                            : false
                        "
                        v-model="prop.row[index].value"
                        :placeholder="prop.row[index].defaultProps"
                      />
                    </div>
                    <div
                      class="wai_div"
                      id="fuhao"
                      v-if="item.componentName == 'textfield'"
                    >
                      <!--我是输入框-->
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <!-- 1600416682842入库单的仓库   1600416710930入库单的货位号   1600481756924出库单的仓库  1600481783902出库单货位号   VMCizuVOXb 发票单  1605233266233 出库单的当前库存-->
                      <input
                        class="wai_div-input"
                        v-if="
                          prop.row[index].idx != '1600416682842' &&
                          prop.row[index].idx != '1600481756924'
                        "
                        type="text"
                        @focus="
                          prop.row[index].defaultLable == '邮箱' ||
                          prop.row[index].defaultLable == '纳税识别号'
                            ? (prop.row[index].value_id = false)
                            : ''
                        "
                        v-model="prop.row[index].value"
                        @change="jiansuan(prop.row, i)"
                        :maxlength="
                          prop.row[index].defaultLable == '纳税识别号'
                            ? 18
                            : prop.row[index].defaultLable == '手机'
                            ? 11
                            : 999999
                        "
                        :disabled="
                          zhuangtai_shiyong_name == '作废'
                            ? true
                            : (guanlian && prop.row[index].defaultLable == '客户') ||
                              prop.row[index].defaultLable == '漏斗现阶段' ||
                              prop.row[index].defaultLable == '客户' ||
                              prop.row[index].idx == '1600416710930' ||
                              prop.row[index].idx == '1600481783902' ||
                              prop.row[index].idx == '1605233266233' ||
                              (date.flow_id == 'VMCizuVOXb' &&
                                prop.row[index].defaultLable == '纳税识别号' &&
                                prop.row[index].daichu != undefined &&
                                prop.row[index].daichu)
                            ? true
                            : false
                        "
                        @click="
                          prop.row[index].defaultLable == '决策人'
                            ? dakai_juece_ren_list(prop.row[index])
                            : ''
                        "
                        @blur="
                          prop.row[index].defaultLable == '纳税识别号'
                            ? yingye_zhizhao(prop.row[index].value, prop.row[index])
                            : '' || prop.row[index].defaultLable == '邮箱'
                            ? youxiang_num(prop.row[index].value, prop.row[index])
                            : ''
                        "
                        :placeholder="prop.row[index].defaultProps"
                      />
                      <!-- &表单控制 -->
                      <span
                        v-if="
                          (list[0].flow_id === 'He5ln0bdDO' || 'AY3cli7BAb') &&
                          prop.row[index].defaultLable === '结算占比%'
                        "
                        class="wai_div-span"
                        >%</span
                      >
                      <el-select
                        :disabled="zhuangtai_shiyong_name == '作废'"
                        v-if="
                          prop.row[index].idx == '1600416682842' ||
                          prop.row[index].idx == '1600481756924'
                        "
                        @change="xuanze_cangku(prop.row[index], prop.row)"
                        class="fenpei_shunwei"
                        v-model="prop.row[index].value_id"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="ite in prop.row[index].value_list"
                          :key="ite.site_id"
                          :label="ite.site_name"
                          :value="ite.site_id"
                        >
                        </el-option>
                      </el-select>
                      <!-- <p v-if="prop.row[index].value_id&&prop.row[index].defaultLable=='纳税识别号'" class="tishi_xinxi animated fadeInRight">该纳税识别号不规范</p>
                                                <p v-if="prop.row[index].value_id2&&prop.row[index].defaultLable=='纳税识别号'" class="tishi_xinxi animated fadeInRight">该纳税识别号已被填写</p>
                                                <p class="tishi_xinxi animated fadeInRight" v-if="prop.row[index].value_id&&prop.row[index].defaultLable=='邮箱'">邮箱不规范</p> -->
                    </div>
                    <div class="wai_div" v-if="item.componentName == 'textareafield'">
                      <!--我是多行输入框-->
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <input
                        type="text"
                        v-model="prop.row[index].value"
                        :placeholder="prop.row[index].defaultProps"
                      />
                    </div>
                    <div class="wai_div" v-if="item.componentName == 'serialnum'">
                      <!--我是序列号-->
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <input
                        type="text"
                        v-model="prop.row[index].value"
                        :placeholder="prop.row[index].defaultProps"
                      />
                    </div>
                    <!--我是客户框（26号注释）-->
                    <!-- <div class="wai_div" v-if="item.componentName=='cust'">
                                                <span style='color:red;' v-if="prop.row[index].defaultImportant">*</span>
                                                <input @click="dianji_kehu(prop.row[index])" type="text"  v-model="prop.row[index].value"  :placeholder="prop.row[index].defaultProps">
                                            </div> -->

                    <div class="wai_div" v-if="item.componentName == 'calcform'">
                      <!--我是计算公式框-->
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <input
                        :disabled="
                          zhuangtai_shiyong_name == '作废'
                            ? true
                            : (!zheng_fan &&
                                (prop.row[index].defaultLable == '售价' ||
                                  prop.row[index].defaultLable == '发票金额')) ||
                              (zheng_fan &&
                                (prop.row[index].defaultLable == '折扣率' ||
                                  prop.row[index].defaultLable == '数量'))
                            ? false
                            : true
                        "
                        type="number"
                        v-model="prop.row[index].value"
                        @change="
                          prop.row[index].defaultLable == '折扣率'
                            ? bianji_zhekou(prop.row[index])
                            : ''
                        "
                        :placeholder="prop.row[index].defaultProps"
                      />
                    </div>
                    <div class="wai_div" v-if="item.componentName == 'numberfield'">
                      <!--我是数字框-->
                      <!-- 1605233266233  出库单的库存数量 -->
                      <!-- <span style='color:red;' v-if="prop.row[index].defaultImportant">*{{prop.row[index].defaultLable}}</span> -->
                      <span style='color:red;' v-if="prop.row[index].defaultImportant">*</span>
                      <input
                        type="number"
                        @focus="
                          prop.row[index].defaultLable == '手机'
                            ? (prop.row[index].value_id = false)
                            : ''
                        "
                        @blur="
                          prop.row[index].defaultLable == '手机'
                            ? phone_num(prop.row[index].value, prop.row[index])
                            : '';
                          jine_jianting(prop.row[index]);
                        "
                        :disabled="
                          zhuangtai_shiyong_name == '作废' ||
                          prop.row[index].idx == '1600151657906' ||
                          prop.row[index].idx == '1605233266233' ||
                          prop.row[index].defaultLable == '预计库存'
                            ? true
                            : false
                        "
                        v-model="prop.row[index].value"
                        @change="
                          prop.row[index].defaultLable == '折扣率'
                            ? bianji_zhekou(prop.row[index])
                            : ''
                        "
                        :placeholder="prop.row[index].defaultProps"
                        :oninput="
                          prop.row[index].defaultLable == '手机'
                            ? `if(value.length>11)value=value.slice(0,11)`
                            : ''
                        "
                      />
                      <!-- <p class="tishi_xinxi animated fadeInRight" v-if="prop.row[index].value_id&&prop.row[index].defaultLable=='手机'">手机号不规范</p> -->
                    </div>
                    <div class="wai_div" v-if="item.componentName == 'textnote'">
                      <!--我是备注框-->
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <textarea
                        class="shuoming_wenzi"
                        type="text"
                        maxlength="5000"
                        v-model="prop.row[index].value"
                        placeholder="请输入"
                      >
                                                    请输入说明文字
                                                </textarea
                      >
                    </div>
                    <div class="wai_div" v-if="item.componentName == 'dddatefield'">
                      <!--我是日期框-->
                      <!-- <p class="p"> -->
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <!-- </p> -->
                      <el-date-picker
                        v-model="prop.row[index].value"
                        align="right"
                        type="datetime"
                        :clearable="false"
                        class="rqi_qujian_kuang"
                        :format="prop.row[index].defaultFormat"
                        :disabled="zhuangtai_shiyong_name == '作废'"
                        popper-class="shenpi_xuanze_rqi"
                        placeholder="选择日期"
                      >
                      </el-date-picker>
                    </div>
                    <!-- 我是日期区间框 -->
                    <div
                      v-if="
                        item.componentName == 'dddaterangefield' &&
                        i.defaultLable != '行程'
                      "
                    >
                      <div class="wai_div">
                        <span style="color: red" v-if="prop.row[index].defaultImportant"
                          >*</span
                        >
                        <el-date-picker
                          v-model="prop.row[index].value"
                          class="rqi_qujian_kuang"
                          type="datetimerange"
                          :clearable="false"
                          :disabled="zhuangtai_shiyong_name == '作废'"
                          popper-class="shenpi_xuanze_rqi"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                        >
                        </el-date-picker>
                      </div>
                    </div>
                    <div class="wai_div" v-if="item.componentName == 'ddphotofield'">
                      <!--我是图片框-->
                      <div class="image-view">
                        <span style="color: red" v-if="prop.row[index].defaultImportant"
                          >*</span
                        >
                        <div class="addbox" v-if="prop.row[index].imgBase64.length == 0">
                          <input type="file" @change="getImgBasea(prop.row[index])" />
                          <img class="img_1" src="../assets/increase.png" alt />
                        </div>
                        <div class="view">
                          <div
                            class="itema"
                            v-for="(ite, indexa) in prop.row[index].imgBase64"
                            :key="indexa"
                          >
                            <span
                              class="cancel-btn"
                              @click="delImga(prop.row[index], indexa)"
                              >x</span
                            >
                            <img
                              @click.stop="tupian_dianji(ite)"
                              :src="ite"
                              class="img_3"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 我是单选框 -->
                    <div
                      class="wai_div"
                      v-if="
                        item.componentName == 'ddselectfield' &&
                        prop.row[index].defaultLable != '地区' &&
                        prop.row[index].defaultLable != '决策人' &&
                        prop.row[index].defaultLable != '地址' &&
                        prop.row[index].defaultLable != '出发城市' &&
                        prop.row[index].defaultLable != '目的城市'
                      "
                      :style="{display: 'block'}"
                    >
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <p
                        class="nei_danxuan asdfasdfasdf"
                        v-for="(itm, ind) in prop.row[index].defaultOptions"
                        :key="ind"
                        @click="
                          i.defaultLable == '拜访目标' &&
                          prop.row[index].defaultLable == '漏斗现阶段'
                            ? xiayi_jieduan(prop.row[index], ind)
                            : prop.row[index].defaultLable == '漏斗阶段变化'
                            ? jieduan_bianhua(prop.row[index], ind)
                            : zhuangtai_shiyong_name == '作废'
                            ? wuyong()
                            : danxuan(prop.row[index], ind)
                        "
                      >
                        <img
                          :src="
                            prop.row[index].value == ind
                              ? require('../assets/red_quan.png')
                              : require('../assets/ccc_quan.png')
                          "
                        />
                        <!-- 内单选 -->{{ itm.text }}
                      </p>
                    </div>
                    <!--供应商 -->
                    <div class="wai_div" v-if="item.componentName == 'suppliername'">
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <el-select
                        class="fenpei_shunwei"
                        :disabled="zhuangtai_shiyong_name == '作废'"
                        @change="xuanze_liebiao_gongying_shang(prop.row[index], prop.row)"
                        v-model="prop.row[index].value_id"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="ite in prop.row[index].value_list"
                          :key="ite.supply_id"
                          :label="ite.supply_name"
                          :value="ite.supply_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <!-- 我是单选框 -->
                    <div
                      class="wai_div"
                      @click="ceshi_diqu(prop.row[index], i, prop.$index)"
                      v-if="
                        item.componentName == 'ddselectfield' &&
                        prop.row[index].defaultLable == '地址'
                      "
                    >
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <v-distpicker @selected="onSelected"></v-distpicker>
                    </div>
                    <div
                      class="wai_div"
                      @click="ceshi_diqu(prop.row[index], i, prop.$index)"
                      v-if="item.componentName == 'province'"
                    >
                      <!--省市区 -->
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <v-distpicker
                        @selected="onSelected"
                        :province="prop.row[index].value"
                        :city="prop.row[index].value_id"
                        :area="prop.row[index].value_ids"
                      ></v-distpicker>
                    </div>
                    <!-- 我是单选框 -->
                    <div
                      class="wai_div"
                      @click="ceshi_diqu(prop.row[index], i, prop.$index)"
                      v-if="
                        item.componentName == 'ddselectfield' &&
                        (prop.row[index].defaultLable == '出发城市' ||
                          prop.row[index].defaultLable == '目的城市')
                      "
                    >
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <v-distpicker @selected="onSelected"></v-distpicker>
                    </div>
                    <!-- 我是单选框 -->
                    <div
                      class="wai_div"
                      v-if="
                        item.componentName == 'ddselectfield' &&
                        prop.row[index].defaultLable == '决策人'
                      "
                    >
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <p
                        @click="dianji_xuanze_juece_ren(prop.row[index])"
                        v-if="prop.row[index].value_id == ''"
                      >
                        请选择
                      </p>
                      <span>{{ prop.row[index].value_id }}</span>
                    </div>
                    <!-- 多选框 -->
                    <div
                      class="wai_div"
                      v-if="item.componentName == 'ddmultiselectfield'"
                    >
                      <span style="color: red" v-if="prop.row[index].defaultImportant"
                        >*</span
                      >
                      <p
                        class="nei_danxuan"
                        v-for="(itm, ind) in prop.row[index].defaultOptions"
                        :key="ind"
                        @click="itm.zhi = !itm.zhi"
                      >
                        <img
                          :src="
                            itm.zhi
                              ? require('../assets/okblue.png')
                              : require('../assets/noccc.png')
                          "
                        />
                        {{ itm.text }}
                      </p>
                    </div>
                    <span
                      @click="i.components_list.splice(prop.$index, 1)"
                      v-if="
                        zhuangtai_shiyong_name == '作废'
                          ? false
                          : prop.column.label == '操作' && i.components_list.length > 1
                      "
                      >删除</span
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 1600331810240  采购发票单的列表 -->
              <span
                class="zengjia_mingxi"
                @click="tianjia_mingxi(i, index)"
                v-if="
                  date.flow_id == 'H49d4edc3T' && i.defaultLable == '产品明细'
                    ? false
                    : zhuangtai_shiyong_name != '作废' &&
                      i.defaultLable != '公司信息' &&
                      i.defaultLable != '付款信息' &&
                      i.defaultLable != '发票信息' &&
                      (!guanlian || i.defaultLable != '发票内容') &&
                      i.defaultLable != '发票信息' &&
                      i.defaultLable != '产品列表' &&
                      i.defaultLable != '拜访目标' &&
                      i.defaultLable != '拜访目标结果' &&
                      i.idx != '1600331810240'
                "
              >
                +&nbsp;增加明细
              </span>
              <ul v-for="(mx, xb) in i.components_list" :key="xb">
                <!--标题-->
                <!-- <p class="mingxi_tit">
                                    <span>{{i.defaultLable}}{{i.components_list.length!=1?'(':''}}{{i.components_list.length!=1?+Number(xb)+1+')':''}}</span>
                                    <span v-if="zhuangtai_shiyong_name=='作废'?false:i.components_list.length!=1" @click="i.components_list.splice(xb,1)">删除</span>
                                </p> -->
                <li v-for="(it, inde) in mx" :key="inde">
                  <!-- <div class="wai_div" v-if="it.componentName=='title'">
                                        <p class="p"><span style='color:red;' v-if="it.defaultImportant">*</span>{{it.defaultLable}}</p>
                                    </div> -->
                  <div v-if="it.componentName == 'product'">
                    <!--我是产品-->
                    <div class="beijing" v-if="chanpin2" @click="chanpin2 = false">
                      <div class="beijing_box" @click.stop>
                        <i
                          class="el-icon-error guanbi_xuanze_chanpin"
                          @click="chanpin2 = false"
                        ></i>
                        <div class="beijing_box_tit">
                          产品分类--1：
                          <!-- <el-select v-model="xuanze_chanpin_id" clearable @change="xuanze_chanpin_dalei" class='shiyong_biaodan_xiala_kuang' placeholder="请选择">
                                                        <el-option
                                                        v-for="item in qiye_chanpin_leixing_list"
                                                        :key="item.cat_id"
                                                        :label="item.cat_name"
                                                        :value="item.cat_id">
                                                        </el-option>
                                                    </el-select> -->
                          <div class="beijing_box_tit_div">
                            <p class="beijing_box_tit_p">{{ dangqian_chanpin.label }}</p>
                            <div class="beijing_box_tit_tree">
                              <el-tree
                                :data="qiye_chanpin_leixing_tree"
                                :props="defaultProps"
                                @node-click="xuanze_chanpin_dalei"
                              ></el-tree>
                            </div>
                          </div>
                          搜索：
                          <p>
                            <i class="el-icon-search"></i>
                            <input
                              v-model="sousuo_chanpin_text"
                              @keyup.enter="chanpin_sousuo()"
                              type="text"
                            />
                            <i
                              @click="(sousuo_chanpin_text = ''), chanpin_sousuo()"
                              class="el-icon-circle-close"
                            ></i>
                          </p>
                          <span @click="dianji_xuanze_chanpin_baocun(it, mx)">确定</span>
                          <span
                            v-if="date.flow_id == 'Xsk2pLBS9a'"
                            @click="dianji_quanbu_ke_caigou_chanpin(it, mx)"
                            >全部可采购产品</span
                          >
                        </div>
                        <ul class="mingxi_chanpin_yangshi">
                          <li
                            v-for="(cp, ind) in dangqian_mingxi_kuang_chanpin"
                            :key="cp.prod_code"
                          >
                            <!--  @click="dangqian_mingxi_kuang_chanpin.splice(ind,1)"  -->
                            <i
                              @click="shanchu_yixuan_chanpin(cp, ind)"
                              class="el-icon-error"
                            ></i>
                            {{ cp.prod_name }}
                          </li>
                        </ul>
                        <div class="beijing_box_table">
                          <!-- @selection-change="handleSelectionChange"  -->
                          <el-table
                            :cell-style="liebiao_yangshi"
                            height="500"
                            :header-cell-style="biaotou_yangshi"
                            :data="chanpin_liebiao_list"
                            highlight-current-row
                            ref="Tablea"
                            style="width: 100%"
                            @select="xuanze_chanpin"
                            @select-all="xuanze_chanpin_quanxuan"
                          >
                            <!-- @current-change="chooseMcaterialChange" -->
                            <el-table-column type="selection" width="42">
                            </el-table-column>
                            <el-table-column prop="prod_code" label="产品编号">
                            </el-table-column>
                            <el-table-column prop="prod_name" label="产品">
                            </el-table-column>
                            <el-table-column prop="cat_name" label="类型">
                            </el-table-column>
                            <el-table-column prop="spec" label="规格"> </el-table-column>
                            <el-table-column prop="type" label="型号"> </el-table-column>
                            <el-table-column prop="unit" label="单位"> </el-table-column>
                            <el-table-column prop="price_basic" label="初始价">
                            </el-table-column>
                            <el-table-column prop="disc_rate" label="调价率">
                            </el-table-column>
                          </el-table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="it.componentName == 'textfield'">
                    <!--我是输入框-->
                    <div
                      class="beijing"
                      v-if="it.teshu && it.defaultLable == '决策人'"
                      @click="it.teshu = false"
                    >
                      <div class="beijing_box" @click.stop>
                        <p class="beijing_tit">选择决策人</p>
                        <ul class="beijing_box_ul">
                          <li
                            v-for="(iat, indet) in it.juece_ren"
                            @click="xuan_juece_ren(it.juece_ren, indet)"
                            :key="indet"
                          >
                            <!-- <img :src="it.zhanshi==indet?require('../assets/red_quan.png'):require('../assets/ccc_quan.png')"> -->
                            <img
                              :src="
                                iat.zhi
                                  ? require('../assets/okblue.png')
                                  : require('../assets/noccc.png')
                              "
                            />
                            {{ iat.name }}
                          </li>
                        </ul>
                        <p class="beijing_foot">
                          <span @click="it.teshu = false">取消</span
                          >&nbsp;&nbsp;&nbsp;<span @click="xuanze_juece_ren(it)"
                            >确定</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-if="it.componentName == 'cust'">
                    <!--我是客户框-->
                    <div class="beijing" v-if="it.teshu" @click="it.teshu = false">
                      <div class="beijing_box" @click.stop>
                        <p class="beijing_tit">选择客户</p>
                        <p class="kehu_sousuo">
                          <it class="el-icon-search"></it>
                          <input
                            placeholder="请输入搜索内容"
                            v-model="sousuo_kehu_name"
                            @keyup.enter="dianji_sousuo_kehu(it)"
                            type="text"
                          />
                          <it
                            @click="
                              sousuo_kehu_name = '';
                              dianji_sousuo_kehu(it);
                            "
                            class="el-icon-circle-close"
                          ></it>
                        </p>
                        <ul>
                          <li
                            v-for="(ia, inde) in it.kehu_list"
                            @click="it.zhanshi = inde"
                            :key="inde"
                          >
                            <img
                              :src="
                                it.zhanshi == inde
                                  ? require('../assets/red_quan.png')
                                  : require('../assets/ccc_quan.png')
                              "
                            />
                            {{ ia.file_no }}--{{ ia.cust_name }}
                          </li>
                          <li
                            v-if="it.kehu_list.length == 0"
                            style="color: #333; font-size: 0.14rem"
                          >
                            暂无数据
                          </li>
                        </ul>
                        <p class="beijing_foot">
                          <span @click="it.teshu = false">取消</span
                          >&nbsp;&nbsp;&nbsp;<span @click="xuanze_kehu(it)">确定</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- 我是单选框 -->
                  <div
                    v-if="
                      it.componentName == 'ddselectfield' && it.defaultLable == '决策人'
                    "
                  >
                    <ul
                      style="height: 3rem; width: 3rem; border: 0.01rem solid red"
                      v-if="it.teshu"
                    >
                      <li
                        v-for="(itm, ind) in it.defaultOptions"
                        :key="ind"
                        @click="danxuan(it, ind)"
                      >
                        <img
                          :src="
                            it.value == ind
                              ? require('../assets/red_quan.png')
                              : require('../assets/ccc_quan.png')
                          "
                        />
                        {{ itm.c_name }}
                      </li>
                      <li>
                        <span @click="it.teshu = false">取消</span>&nbsp;&nbsp;&nbsp;<span
                          @click="xuanze_juece(it)"
                          >确定</span
                        >
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- <div class="box_ul" v-if="zhuangtai_shiyong_name == '作废'">
                  <div class="wai_div">
                          <p class="p">
                            <span class="tit_name">当前部门</span>
                        </p>
                        <p style='width:6rem;'>
                            <el-select v-model="liucheng_dept_id" @change="liucheng_huoqu" placeholder="请选择">
                                <el-option
                                    v-for="item in liucheng_bumen_list"
                                    :key="item.dept_id"
                                    :label="item.dept_name"
                                    :value="item.dept_id"
                                    >
                                </el-option>
                            </el-select>
                        </p>
                  </div>
                </div> -->
        <ul class="box_ul">
          <div class="wai_div">
            <p class="p">
              <span class="tit_name">审批流程</span>
            </p>
            <p v-if="dangqian_danju_gaoji_shezhi.distinct_flag != 3">已启用自动去重</p>
          </div>
          <div class="wai_div">
            <!--我是部门-->
            <p class="p">
              <span class="tit_name">当前部门</span>
            </p>
            <p style="width: 6rem">
              <el-select
                v-model="liucheng_dept_id"
                @change="liucheng_huoqu"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in liucheng_bumen_list"
                  :key="item.dept_id"
                  :label="item.dept_name"
                  :value="item.dept_id"
                >
                </el-option>
              </el-select>
            </p>
          </div>
          <li class="box_li" v-for="(i, index) in liucheng_list" :key="index">
            <div>
              <p>{{ i.name }}</p>
              <p v-if="i.shenpi_ren_kong == undefined && !i.zidong_tongguo">
                {{ i.zixuan_list.length }}人{{ i.shunxu }}
              </p>
              <p v-if="i.shenpi_ren_kong != undefined && !i.zidong_tongguo">
                当前审批人为空，将自动通过审批
              </p>
              <p v-if="i.zidong_tongguo">将自动通过</p>
            </div>
            <ul class="box_li_ul">
              <li v-for="(it, idx) in i.zixuan_list" :key="idx">
                <p>{{ it.staff_name[0] }}</p>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="it.staff_name"
                  placement="bottom"
                >
                  <p>{{ it.staff_name }}</p>
                </el-tooltip>
                <i
                  v-if="it.buke_shanchu == 0"
                  @click="shanchu_ren(it, i)"
                  class="el-icon-error"
                ></i>
                <span>
                  {{
                    (i.appr_sort != undefined &&
                      i.zixuan_list.length != 1 &&
                      (i.select_type != 4 ? idx != i.zixuan_list.length - 1 : true)) ||
                    (i.copy_self_select != undefined
                      ? i.copy_self_select == 1
                        ? true
                        : false
                      : false)
                      ? i.appr_sort == 1
                        ? ">"
                        : i.appr_sort == 3
                        ? "/"
                        : "+"
                      : ""
                  }}
                </span>
              </li>
              <i
                v-if="
                  (i.select_type == 4 || i.copy_self_select == 1) &&
                  i.shenpi_ren_kong == undefined
                "
                @click="dianji_tianjia_ren(i)"
                class="el-icon-circle-plus-outline"
              ></i>
              <el-dialog
                title="选择人员"
                :center="true"
                :show-close="false"
                append-to-body
                :visible.sync="i.zhi"
                v-if="
                  i.user_type == 2 ||
                  (i.user_type == 1 && i.select_type == 4 && i.self_select_type == 1)
                "
              >
                <div class="tiao_bumen">
                  <div class="xuanze_left">
                    <p class="tit">选择：</p>
                    <div class="xuanze_sousuo">
                      <i class="el-icon-search"></i>
                      <input
                        type="text"
                        placeholder="搜索"
                        v-model="chaosong_sousuo_text"
                        @keyup="chaosong_sousuo"
                      />
                      <!--  @keyup="keyu" -->
                    </div>
                    <div class="mianbaoxie">
                      <div
                        v-for="(a, idx) in mianbao_chaosong_list"
                        :key="idx"
                        @click="mianbao_chaosong_dian(a, idx)"
                      >
                        <p :class="mianbao_chaosong_list.length - 1 == idx ? 'p1' : 'p2'">
                          {{ a.name }}
                        </p>
                        <i class="el-icon-arrow-right"></i>
                      </div>
                    </div>
                    <!-- date.user_type==1&&date.select_type==4&&date.self_select_type==1&&date.select_flag==1 -->
                    <div
                      class="quanxuan"
                      v-if="
                        (chaosong_qiye_bumen_list.length != 0 ||
                          chaosong_qiye_renyuan_list.length != 0) &&
                        i.select_flag != 1
                      "
                    >
                      <img
                        @click="dianji_chaosong_quanxuan"
                        :src="
                          renyuan_chaosong_quanxuan
                            ? require('../assets/okblue.png')
                            : require('../assets/noccc.png')
                        "
                        alt=""
                      />
                      <span>全选</span>
                    </div>
                    <div class="nei_box">
                      <div class="liebiao">
                        <ul>
                          <li
                            v-for="(a, idx) in chaosong_qiye_bumen_list"
                            :key="idx"
                            @click="dianji_chaosong_bumen(a)"
                          >
                            <img
                              v-if="i.select_flag != 1"
                              class="img2"
                              @click.stop="chaosong_bumen(a)"
                              :src="
                                a.zhi
                                  ? require('../assets/okblue.png')
                                  : require('../assets/noccc.png')
                              "
                              alt=""
                            />
                            <p style="margin-left: 0.16rem">{{ a.dept_name }}</p>
                          </li>
                        </ul>
                        <ul>
                          <li
                            v-for="(a, idx) in chaosong_qiye_renyuan_list"
                            :key="idx"
                            style="justify-content: left; margin-left: -0.07rem"
                          >
                            <img
                              class="img2"
                              @click="dianji_chaosong_xuanren(a, i)"
                              :src="
                                a.zhi
                                  ? require('../assets/okblue.png')
                                  : require('../assets/noccc.png')
                              "
                              alt=""
                            />
                            <p>{{ a.name }}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="xuanze_right">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                      <div class="liebiao">
                        <ul>
                          <li v-for="(a, idx) in chaosong_renyuan_list_xuan" :key="idx">
                            <p>{{ a.name }}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="foote">
                  <p class="queding" @click="chaosong_renyuan_sure(i)">确定</p>
                  <p
                    class="quxiao"
                    @click="(i.zhi = false), (chaosong_renyuan_list_xuan = [])"
                  >
                    取消
                  </p>
                </div>
              </el-dialog>
              <div
                class="beijing"
                v-if="i.zhi && i.user_type == 1 && i.self_select_type != 1"
                @click="i.zhi = false"
              >
                <div class="beijing_box" @click.stop>
                  <p class="beijing_tit">选择人员</p>
                  <ul>
                    <li v-for="(a, idx) in i.user_list" :key="idx">
                      <img
                        @click="shenpi_xuanze(a, i)"
                        :src="
                          a.zhi
                            ? require('../assets/okblue.png')
                            : require('../assets/noccc.png')
                        "
                      />
                      {{ a.staff_name }}
                    </li>
                  </ul>
                  <p class="beijing_foot">
                    <span @click="shenpi_xuanze_sure(i)">确定</span
                    >&nbsp;&nbsp;&nbsp;<span @click="i.zhi = false">取消</span>
                  </p>
                </div>
              </div>
            </ul>
          </li>
        </ul>
        <div @click="dianji_tijiao" class="tijiao_btn" style="margin-top: 300px;">提交</div>
      </ul>
    </div>
    <el-dialog
      title="选择人员"
      :center="true"
      :show-close="false"
      append-to-body
      :visible.sync="lianxiren"
    >
      <div class="tiao_bumen">
        <div class="xuanze_left">
          <p class="tit">选择：</p>
          <div class="xuanze_sousuo">
            <i class="el-icon-search"></i>
            <!-- .enter   2020、11、17把搜索框点击回车触发事件去掉了 -->
            <input type="text" placeholder="搜索" @keyup="keyu" v-model="text" />
          </div>
          <div class="mianbaoxie">
            <div
              v-for="(i, index) in mianbao_list"
              :key="index"
              @click="mianbao_dian(i, index)"
            >
              <p :class="mianbao_list.length - 1 == index ? 'p1' : 'p2'">{{ i.name }}</p>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div
            class="quanxuan"
            v-if="qiye_renyuan_list.length != 0 && dangqian_lianxi_ren_idx != 'tidai'"
          >
            <!-- <input type="checkbox" @click="dianji_quanxuan" ref='quan'> -->
            <img
              @click="dianji_quanxuan"
              :src="
                renyuan_quanxuan
                  ? require('../assets/okblue.png')
                  : require('../assets/noccc.png')
              "
              alt=""
            />
            <span>全选</span>
          </div>
          <div class="nei_box">
            <div class="liebiao">
              <ul v-if="!text">
                <li
                  v-for="(i, index) in qiye_bumen_list"
                  :key="index"
                  @click="dianji_bumen(i)"
                >
                  <p style="margin-left: 0.16rem">{{ i.dept_name }}</p>
                  <!-- <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt=""> -->
                </li>
              </ul>
              <ul>
                <li
                  v-for="(i, index) in qiye_renyuan_list"
                  @click="dianji_xuanren(i)"
                  :key="index"
                  style="justify-content: left; margin-left: -0.07rem"
                >
                  <!-- <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)"> -->
                  <img
                    class="img2"
                    :src="
                      i.zhi
                        ? require('../assets/okblue.png')
                        : require('../assets/noccc.png')
                    "
                    alt=""
                  />
                  <p>{{ i.name }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="xuanze_right">
          <p class="tit">已选：</p>
          <div class="nei_box">
            <div class="liebiao">
              <ul>
                <li v-for="(i, index) in qiye_renyuan_list_xuan" :key="index">
                  <p>{{ i.name }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="foote">
        <p class="queding" @click="renyuan_sure">确定</p>
        <p class="quxiao" @click="lianxiren = false">取消</p>
      </div>
    </el-dialog>
    <el-dialog
      title="选择部门"
      :center="true"
      :show-close="false"
      append-to-body
      :visible.sync="bumen"
    >
      <div class="tiao_bumen">
        <div class="xuanze">
          <!-- <p class="tit">选择：</p> -->
          <div class="nei_box">
            <el-tree
              :data="tree"
              show-checkbox
              default-expand-all
              ref="tree"
              highlight-current
              check-strictly
              :props="tree_guize"
              :filter-node-method="filterNode"
              style="background: #e7e8eb"
              @check="fuxuan"
            >
            </el-tree>
          </div>
        </div>
      </div>
      <div class="foote">
        <p class="queding" @click="bumen_sure">确定</p>
        <p class="quxiao" @click="bumen = false">取消</p>
      </div>
    </el-dialog>
    <div class="beijing_dandu" v-if="kehu_chongfu.shifou == false">
      <!-- 已建立是否需要建立新的，若不建立，请到我发起的审批列表中找到再进行编辑 -->
      <div class="beijing_dandu_box" @click.stop>
        <p v-if="date.flow_id != 'qIwxmsMTgv'" class="beijing_dandu_box_con">
          已建立{{ kehu_chongfu.name }}，是否需要建立新的{{ kehu_chongfu.name }}
          <br />
          若不新建，请到我发起的审批列表中找到原单据进行编辑
        </p>
        <p v-if="date.flow_id == 'qIwxmsMTgv'" class="beijing_dandu_box_con">
          该客户已建立合同单，请先去建立新漏斗或者选择其他客户建立合同单
        </p>
        <div class="foote">
          <p class="queding" @click="dianji_shi">是</p>
          <p class="quxiao" @click="dianji_fou">否</p>
        </div>
      </div>
    </div>
    <div class="beijing_dandu" v-if="kehu_you_loudou">
      <div class="beijing_dandu_box" @click.stop>
        <p class="beijing_dandu_box_con">
          该客户已建立漏斗单且正在进行中，未完结时不能新建
        </p>
        <div class="foote">
          <p class="queding" @click="dianji_shi_loudou">是</p>
          <p class="quxiao" @click="dianji_fou">否</p>
        </div>
      </div>
    </div>
    <div class="beijing_dandu" v-if="shifou_you_hetong">
      <div class="beijing_dandu_box" @click.stop>
        <p class="beijing_dandu_box_con">此归档号未建立合同，是否建立合同？</p>
        <div class="foote">
          <p class="queding" @click="dianji_fou">是</p>
          <p class="quxiao" @click="jiaofu_dianji_fou">否</p>
        </div>
      </div>
    </div>
    <tupian ref="bigphote"></tupian>
  </div>
</template>

<script>
import tree from "@c/zujian/Tree";
import peopleTree from "@c/userTableC/tree";
import VDistpicker from "v-distpicker";
import GuanlianShenpiDan from "./biaodan/guanliandan";
import BiaodanXiangqing from "./biaodan/biaodanxq";
import tupian from "./bigphote/bigphote";
import {
  query_form_appr_flow_list,
  query_category_info_list,
  query_form_appr_set,
} from "../api/noapi";
import {
  query_prod_stock_num,
  query_uscc_of_cust,
  query_supply_list_of_power,
  query_staff_list_of_dept_3,
  query_cust_form_detail,
  query_all_prod_list_of_category_out_store,
  update_supply,
  query_amts_of_payment,
  query_all_prod_list_of_category_pur_inv,
  query_all_prod_list_of_category_in_store,
  query_supply_list,
  query_prod_stock_info,
  query_all_prod_list_of_category_rule_pur,
  query_quotation_list,
  insert_supply,
  insert_out_store,
  insert_in_store,
  insert_payment,
  insert_pur_inv,
  set_replace_write_user,
  query_form_replace_write,
  query_ent_prod_cat_list_tree,
  trinity_deliver,
  query_left_inv_amt,
  query_left_rec_amt,
  query_left_deliver_amt,
  insert_cust_form_data,
  contract_have_exist_file_no,
  customer_if_can_creat_funn,
  update_funn,
  update_contract,
  form_cancel,
  get_prod_list_like,
  query_all_prod_list_of_category,
  no_sale_prod_list,
  query_user_info,
  update_customer_form,
  add_appr_flow_step_list,
  query_dept_list_of_staff,
  query_all_staff_list_of_dept,
  query_staff_dept_list,
  get_prod_detail_for_deliver,
  query_rec_amt_sum_and_inv_amt_sum,
  query_con_amt_and_inv_amt_sum,
  funn_contract_have_exist_file_no,
  query_stage_from_record_and_funn,
  trinity_contract,
  trinity_invoice,
  trinity_received_cash,
  query_ent_prod_list_tree,
  query_year_trip_days,
  query_go_out_year_hour_long,
  insert_customer_form,
  query_funn_stage_for_funn,
  query_stage_from_plan,
  insert_s_visit_record,
  insert_funn,
  insert_s_dispatch,
  insert_contract,
  insert_invoice,
  insert_received_cash,
  insert_s_biz_trip,
  insert_s_deliver,
  insert_s_go_out,
  insert_customer,
  insert_s_visit_plan,
  query_ent_dept_list_aim,
  biz_lic_no_have_exist,
  customer_have_exist,
  query_contract_rel_info_list,
  query_rel_invoice_list,
  query_rel_contract_list,
  query_rel_funn_list,
  query_prod_info_list,
  query_rel_visit_record_list,
  query_rel_customer_decision_list,
  query_rel_customer_list,
  start_flow_action,
  query_flow_info_all,
  query_ent_dept_list,
  query_dept_staff_list,
  get_staff_list_like,
  delete_dept_info,
  update_edit_flag,
  supply_have_exist,
  insert_purchase,
} from "../api/api";
import {
  insert_member_topup,
  get_amt_bgn_of_date,
  get_amt_end_of_cust,
  get_amt_give_of_amt_topup,
  query_dept_staff_tree_aim_not,
  query_mobile_list_of_customer,
  query_pay_type_list,
  query_cust_mc_level_discount_of_topup,
  query_cust_amt_topup_sum,
  query_cust_mc_level_discount,
  query_mc_pay_rate,
  vipRule,
} from "@api/vip.js";
import mytools from "@/plugins/tools";
import { mapGetters } from "vuex";
import { type } from "os";
export default {
  name: "usetable",
  data() {
    return {
      TreeData: null,
      showall: true,
      zuofei_tijiao_option: [],
      zuofei_tijiao_value: "",
      BusinessPersonlValue: "",
      BusinessPersonlList: null,
      defaultProps: {
        children: "children",
        label: "label",
      },
      date: "",
      list: [],
      lianxiren: false,
      mianbao_list: [
        {
          name: "",
          dept_id: "0",
        },
      ],
      qiye_bumen_list: [],
      qiye_renyuan_list: [],
      text: "",
      renyuan: "0",
      bumen: false,
      tree: [],
      first_num_staus: 0,
      tree_guize: {
        children: "children",
        label: "label",
      },
      chanpin_lei_list: [],
      tree_guize_chanpin: {
        children: "children",
        label: "label",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      temp: {
        addressprovince: "",
        addresscity: "",
        addarea: "",
      },
      temp2: {
        addressprovince: "",
        addresscity: "",
        addarea: "",
      },
      chanpin1: false,
      chanpin2: false,
      qiye_chanpin_leixing_list: [],
      qiye_chanpin_leixing_tree: [],
      dangqian_chanpin: {
        // 树状图点击选择产品
        label: "",
        id: "",
      },
      qiye_chanpin_list: [],
      chanpin_num: "0",
      dangqian_chanpin_id: "",
      zheng_fan: true,
      guanlian: false,
      diqu_xuyao_ziduan: {
        big: "",
        small: "",
        dingji: "",
        inde: "",
      },
      shang_xiawu: [
        {
          label: "上午",
          value: 0.5,
        },
        {
          label: "下午",
          value: 1,
        },
      ],
      chanpin_lei: false,
      kehu_chongfu: {
        shifou: true,
        name: "",
      }, // 漏斗合同判断该客户是否建立过
      fapiao_huikuan_shifou_tijiao: true, // 发票回款是否能提交
      shifou_you_hetong: false, // 交付单是否有合同  展示
      bumen_list: [],
      // 当前使用状态
      zhuangtai_shiyong_name: "",
      renyuan_quanxuan: false,
      qiye_renyuan_list_xuan: [],
      // 流程列表
      liucheng_list: [],
      // 抄送人自选
      chaosong_ren: true,
      chaosong_sousuo_text: "",
      mianbao_chaosong_list: [
        {
          name: "",
          dept_id: "0",
        },
      ],
      renyuan_chaosong_quanxuan: false,
      chaosong_qiye_bumen_list: [],
      chaosong_qiye_renyuan_list: [],
      chaosong_renyuan_list_xuan: [],
      // 4.23
      liucheng_bumen_list: [],
      liucheng_dept_id: "",
      suoyou_bumen: [],
      // 5.8已分配编辑
      yi_fenpei_bianji: [],
      zhuguan: false,
      zhuguan_xuanze_bumen: "",
      zhuguan_xuanze_bumen_id: [],
      buneng_xuan_chanpin: [],
      xuanze_chanpin_id: "",
      chanpin_liebiao_list: [],
      chanpin_xuanze: [],
      sousuo_chanpin_text: "",
      chanpin_mingxi: "",
      // 2020/5/30增加客户漏斗单是否可以建立
      kehu_you_loudou: false,
      // 2020/6/9客户旧的名字
      jiu_kehu_name: "",
      sousuo_kehu_name: "",
      bumen_zhuguan: false,
      zi_dingyi: false,
      dangqian_danju_gaoji_shezhi: "", // 当前单据高级设置
      xuanze_shunxu_ren: [],
      chanpin_danxuan: true, // 产品可以单选还是多选
      dangqian_mingxi_kuang_chanpin: [],
      dangqian_mingxi_liebiao: "", // 打开产品的明细列表
      dangqian_mingxi: "", // 点击当前产品的明细框
      liucheng_jiazai_wanbi: false,
      // 提交时判断交付金额
      jiaofu_jine_cha: "",
      hetong_duibi_jine: "",
      huikuan_jine_cha: "",
      dangqian_lianxi_ren_idx: "",
      kehu_shifou_bitian: false, // 客户是否必填
      zaici_tijiao_dept_id: "",
      shifou_shi_loudou_dan: false,
      jichuxinxi: {},
      shifou_have_daixie: false, // 用来判断是否有代写权限
      dai_taren_tijiao: false, // 如果为true的话   说明有被代写人

      // 明细更改成列表
      biaotou_list: {},
      youwu_kucun: "0", // 有无库存    1有  0 无
      caigou_fapiao_jine: "", // 审核通过的采购发票金额
      caigou_dan_jine: "", // 审核通过的采购单金额
      leiji_fukuan_jine: "", // 审核通过的付款单金额（不含本次）
      // 供应商修改负责人
      gongying_shang_xiugai_fuze_ren: false,
      chuku_kehu_no_chanpin: true, // 出库单客户没有产品   false有  true没有

      // 2020/11/06待提交增加选择范围   啊啊啊  脑壳疼  没办法   继续奥利给吧  加油
      daixuan_fanwei: [], // 这个就是用来展示
      daixuan_fanwei_all: [], // all的意思就是就页面初始价值出最外层那册
      defaultProps: {
        children: "children",
        label: "label",
      },
      cust_id: null,
      //充值单
      vip: {
        amt_topup: null,
        amt_give: null,
      },
      chongzhi: "",
      val: "",
      vipData: {
        flag_cancel: this.zhuangtai_shiyong_name == "作废" ? 1 : 0,
        flow_no_orig: "",
        flow_no: "",
        ent_id: this.$ent_id(), //
        cust_id: "",
        cust_name: "", //从客户列表进行选择
        mobile: "", //手机号
        staff_id: "", //
        staff_name: "", //业务人员
        dept_id: "", //
        dept_name: "", //业务人员部门名称
        amt_topup: "", //充值金额
        amt_give: "", //赠送金额
        amt_bgn: "", //期初金额
        amt_end: "",
        suiji: "", //期末金额
        operate_user_id: "", //操作人 ID
        operate_user_dept_id: "", //操作人部门id
        message: null,
        amt_topup_sum: "",
        level_name: "",
        discount: "",
      },
      hui_kuanData: {
        ent_id: "", //	企业id
        user_id: "", //用户id
        cust_id: "", //	客户id
        cust_name: "", //客户名称
        file_no: "", //归档号
        staff_id: "", //负责人id
        dept_id: "", //负责人部门
        flow_no: "", //流程号
        extra: "", //自定义控件
        date_rec: "", //回款日期
        inv_code: "", //发票代码
        inv_no: "", //	发票号码
        amt_rec: "", //回款金额
        amt_rec_sum: "", //累计回款金额
        amt_inv_sum: "", //累计开票金额
        amt_rec_unrec: "", //应收金额
        pay_type: "", //付款方式：vip会员卡，
        flag_cancel: "", //作废标志，1 作废，0不作废
        flow_no_orig: "", //原流程号
        mc_level: "", //会员等级
        mc_discount: "", //会员折扣
        amt_bgn_mc: "", //会员卡余额
        amt_end_mc: "", //会员卡余额支付后
        list: [
          {
            prod_id: "", //产品id
            prod_code: "", //产品编码
            prod_name: "", //产品名称
            cat_id: "", //类型id
            cat_name: "", //类型名称
            spec: "", //规格
            type: "", //型号
            unit: "", //单位
            price_unit: "", //单价
            qty: "", //数量
            disc_rate: "", //折扣率
            price_sale: "", //售价
            price_orig: "", //原价
          },
        ],
        list_pay: [
          //支付方式列表
        ],
        zhekou: "",
      },
      huikuandan: "会员卡",
      huikuandanYUe: 0,
      curr_time: null,
      biao_shi: null,
      //业务人员组织架构树
      //  BusinessPersonlList:null,
      errorList: []
    };
  },
  components: {
    VDistpicker,
    GuanlianShenpiDan,
    BiaodanXiangqing,
    tupian,
    tree,
    peopleTree,
  },
  created() {
    this.chushi_chaxun();

    this.zhuangtai_shiyong_name = JSON.parse(
      sessionStorage.getItem("mlbb_shenpi_biaodan_3")
    );

    this.panduan_dangqian_tianxie_ren();

    this.jichu();

    this.mianbao_list[0].name = JSON.parse(
      sessionStorage.getItem("pc_mlbb_ent_info")
    ).ent_name;
    // this.mianbao_list_chanpin[0].name=JSON.parse(sessionStorage.getItem("pc_mlbb_ent_info")).ent_name;
    this.mianbao_chaosong_list[0].name = JSON.parse(
      sessionStorage.getItem("pc_mlbb_ent_info")
    ).ent_name;
    this.gongying_shang_bianji(); // 如果是供应商编辑  查看是否可以编辑负责人
    this.query_dept_staff_tree_aim_not();

    //获取回款单会员卡结算金额
  },
  mounted() {
    //获取客户手机号
    this.ceshi_jichu();
    this.zuifei();

    //回款单折扣判断
    //  this.query_staff_dept_list();
    ////console.log(this.list)
  },
  watch: {
    list: {
      handler(newValue, oldValue) {
        this.jisuan();
      },
      deep: true,
    },
    temp: {
      handler(newValue, oldValue) {
        this.zhi_on_api();
      },
      deep: true,
    },
    temp2: {
      handler(newValue, oldValue) {
        this.zhi_on_api2();
      },
      deep: true,
    },
    lianxiren() {
      if (!this.lianxiren) {
        this.qiye_renyuan_list_xuan = [];
      }
    },
    liucheng_dept_id() {
      this.liucheng_jiekou();
    },
    chanpin1() {
      if (!this.chanpin1) {
        this.dangqian_chanpin = {
          label: "",
          id: "",
        };
        this.chanpin_liebiao_list = [];
        this.sousuo_chanpin_text = "";
      }
    },
    chanpin2() {
      if (!this.chanpin2) {
        this.dangqian_chanpin = {
          label: "",
          id: "",
        };
        this.chanpin_liebiao_list = [];
        this.sousuo_chanpin_text = "";
      }
    },
    guanlian_biaodan_zhi: {
      handler(newValue, oldValue) {
        this.guanlian_biaodan_chuli(newValue);
      },
      deep: true,
    },
    dai_taren_tijiao() {
      // 代写人更改
      this.jichu();
    },
  },
  computed: {
    guanlian_biaodan_zhi() {
      return this.$store.state.biaodan_list;
    },
    ...mapGetters({
      infoData: "infoData",
    }),
  },
  methods: {
    zuifei() {
      const list = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_2", "暂时");
      const xiangqing = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_4", "暂时");
      if (this.zhuangtai_shiyong_name == "作废") {
        //query_dept_list_of_staff //查询员工部门一个员工可能存在多个部门
        this.liucheng_dept_id = xiangqing.operate_user_dept_name;
        for (let i of this.list) {
          if (i.defaultLable == "日期") {
            i.value = new Date(xiangqing.date_topup);
          }
          if (i.defaultLable == "业务人员") {
            console.log(i, "这是业务人员");
            i.defaultImportant = true;
            i.defaultPrint = true;
            // i.value = xiangqing.operate_user_name
          }
          if (i.defaultLable == "业务人员归属部门") {
            console.log(i, "这是业务人员归属部门");
            //  i.value=xiangqing.operate_user_dept_name
          }
          if (i.defaultLable == "明细（1）") {
            xiangqing.pay_detail.map((item) => {
              item.amt_pay = 0 - item.amt_pay;
            });
            i.defaultOptions = xiangqing.pay_detail;
            this.biao_shi = 1;
            console.log(this.biao_shi);
          }
        }
      } else if (this.zhuangtai_shiyong_name == "看板_编辑") {
        // this.liucheng_dept_id = xiangqing.dept_name;
      }
    },
    myjiansuan(val, item) {
      console.log(val, "你好我的世界");
      console.log(item, "item");
      let hui_kuan_sum, vip_sum, vip_zhekou, vip_end;
      let jsfs_add = 0;
      let flag_mc_level = this.hui_kuanData.flag_mc_level; //会员等级是否启用
      for (let i of this.list) {
        if (i.defaultLable === "回款金额") hui_kuan_sum = Number(i.value);
        if (i.defaultLable === "会员卡金额") vip_sum = Number(i.value);
        if (i.defaultLable === "会员折扣%") {
          vip_zhekou = i.value;

          // console.log(i.value,'折扣是否启用')
        } //这里需要和后台获取到到整单的折扣进行判断
      }
      if (!hui_kuan_sum) {
        this.$message.warning("没有选择产品");
      }
      // alert('1')
      item.rate_pay = (item.amt_pay / hui_kuan_sum).toFixed(2) * 100;
      console.log(item.rate_pay);
      //合-计不能超过100%
      if (jsfs_add > hui_kuan_sum) {
        for (let i of val.defaultOptions) {
          if (!i.rate_pay) {
            i.rate_pay = 0;
          } else {
            jsfs_add += Number(i.rate_pay);
          }
          //console.log(Number(i.jsje),'这是结算金额')
        }
        this.$message.warning("结算金额不能超过100%");

        val.heji = jsfs_add;
      } else {
      }
      // console.log(val.defaultOptions[0].jszb , this.hui_kuanData.zhekou,'两个折扣')
      console.log(
        val.defaultOptions[0].rate_pay,
        this.hui_kuanData.zhekou,
        "这是两个折扣"
      );
      if (this.hui_kuanData.zhekou === "null") {
      } else {
        if (val.defaultOptions[0].rate_pay > this.hui_kuanData.zhekou) {
          this.$message.warning(`使用会员卡不能超过整单的${this.hui_kuanData.zhekou}%`);
        }
      }

      // 会员卡余额=会员卡金额-使用会员卡的结算金额*会员折扣
      if (item.text == "会员卡") {
        vip_end = vip_sum - (Number(item.amt_pay) * vip_zhekou) / 100;
        //nsole.log(vip_end,vip_zhekou, vip_sum, item.jsje)
        for (let i of this.list) {
          if (i.defaultLable === "会员卡余额") i.value = vip_end;
        }
      }
      for (let i of val.defaultOptions) {
        if (!i.rate_pay) {
          i.rate_pay = 0;
        }
        //console.log(Number(i.jsje),'这是结算金额')
        jsfs_add += Number(i.rate_pay);
      }
      val.heji = jsfs_add;
    },
    //回款单-获取折扣比列
    async query_mc_pay_rate() {
      const res = await query_mc_pay_rate({ data: { ent_id: this.$ent_id() } });
      if (res.data.code === "200") {
        console.log(
          JSON.parse(res.data.body.data).rate_limit,
          "这是服务器出来的折扣1111"
        );
        this.hui_kuanData.zhekou = JSON.parse(res.data.body.data).rate_limit;
      }
    },
    //回款单--查询会员状态
    async vipRule() {
      const rest = await vipRule({ data: { ent_id: this.$ent_id() } });
      if (rest.data.code === "200") {
        this.hui_kuanData.flag_mc_level = JSON.parse(rest.data.body.data).flag_mc_level;
      }
    },
    //回款单--获取会员卡余额
    async get_amt_end_of_cust(cust_id) {
      const param = {
        ent_id: this.$ent_id(),
        cust_id,
      };
      //console.log(param,'这是custid--------------param')
      try {
        const data = await get_amt_end_of_cust({ data: param });
        if (data.data.code == 200) {
          const amt_end = JSON.parse(data.data.body.data).amt_end;
          this.hui_kuanData.amt_bgn_mc = amt_end;
          this.set_data("会员卡金额", amt_end);
        }
      } catch {}
    },
    //回款单-的各种方法
    async hui_kuan_dan(i) {
      let cust_id = i.kehu_list[Number(i.zhanshi)].cust_id;
      this.get_amt_end_of_cust(cust_id);
      this.query_mc_pay_rate();
      this.vipRule();
      this.get_huikuan_status(cust_id);
    },
    //充值单-的各种方法
    async vip_chuzhi_dan(i) {
      this.get_amt_data(i);
      switch (i.defaultLable) {
        case "客户":
          this.vipData.cust_id = i.kehu_list[Number(i.zhanshi)].cust_id;
          if (!this.vipData.cust_name) {
            this.vipData.cust_name = i.kehu_list[Number(i.zhanshi)].cust_name;
          }
          if (!this.vipData.ent_id) {
            this.vipData.ent_id = i.kehu_list[Number(i.zhanshi)].end_id;
          }
          this.get_kehu(i.kehu_list[Number(i.zhanshi)].cust_id);
          break;
      }
      //隐藏选择业务人员
      if (this.TreeData) {
        this.$nextTick(() => {
          this.set_input(this.TreeData.label, "业务人员", "value");
          this.set_input(this.vipData.dept_name, "业务人员归属部门", "value");
        });
      }
    },
    //回款单--状态和折扣
    async get_huikuan_status(cust_id) {
      let flag_mc_level = this.hui_kuanData.flag_mc_level;
      const param_hk_ = {
        ent_id: this.$ent_id(),
        cust_id,
      };
      console.log(param_hk_, "00------------");
      const vip_level = await query_cust_mc_level_discount({ data: param_hk_ });
      if (vip_level.data.code === "200") {
        let { date_valid, discount, level_id, level_name, status_mc } = JSON.parse(
          vip_level.data.body.data
        );
        this.hui_kuanData.mc_level = level_name;
        this.hui_kuanData.mc_status = status_mc;
        this.hui_kuanData.mc_valid_date = date_valid;
        if (date_valid === "null") {
          date_valid = "-";
        }
        console.log(flag_mc_level, discount, "状态和值");
        this.hui_kuanData.mc_discount = discount;
        if (level_id === "null") {
          level_id = "-";
        }
        if (level_name === "null") {
          level_name = "-";
        }
        if (status_mc === "null") {
          status_mc = "-";
        } else if (status_mc === "1") {
          status_mc = "正常";
        } else {
          status_mc = "过期";
        }
        this.set_input(date_valid, "会员截止日", "value");
        this.set_input(discount, "会员折扣%", "value");
        this.set_input(level_name, "会员等级", "value");
        this.set_input(status_mc, "会员状态", "value");
      }
    },

    //充值单--获得折扣

    get_vip_amt_end(i, x) {
      //   期末金额   期初金额
      //console.log(i,x,'期初期末余额')
      let amt_end = Number(i) + Number(x) + Number(this.vip.amt_bgn);
      this.vipData.amt_topup = i; //充值金额
      this.vipData.amt_give = x; //赠送金额
      this.vipData.amt_bgn = this.vip.amt_bgn; //期初金额
      this.vipData.amt_end = JSON.stringify(amt_end); //期末金额
      this.set_input(amt_end, "期末余额", "value");
    },
    //给框子赋值
    set_data(val, name) {
      for (let i of this.list) {
        if (i.defaultLable === val) {
          i.value = name;
          return;
        }
      }
    },
    //获取vip赠送金额
    get_amt_input(i) {
      if (this.list[0].flow_id === "AY3cli7BAb") {
        for (let i of this.list) {
          if (i.defaultLable === "客户" && i.value === "") {
            this.$message.warning("请先选择客户");
            for (let i of this.list) {
              if (i.defaultLable === "充值金额") return (i.value = "");
            }
          }
        }
        this.get_vip_amt_topup(i.value);
      }
    },
    async get_vip_amt_topup(i) {
      this.chongzhi = i;
      const param = {
        ent_id: this.$ent_id(),
        amt_topup: i,
      };
      const param_start = {
        ent_id: this.$ent_id(),
        cust_id: this.vipData.cust_id,
        amt_topup: i,
      };

      try {
        const data_amt_give = await get_amt_give_of_amt_topup({ data: param });
        //this.query_cust_mc_level_discount_of_topup(i)
        let amt_give = JSON.parse(data_amt_give.data.body.data).amt_give;
        this.set_input(amt_give, "赠送金额", "value");
        this.get_vip_amt_end(i, amt_give);

        const data_leve_start = await query_cust_mc_level_discount_of_topup({
          data: param_start,
        }); //会员等级，会员折扣
        const data_top_sum = await query_cust_amt_topup_sum({ data: param_start }); //客户累计金额
        console.log(data_leve_start, "10002220202020020202");
        console.log(data_leve_start, "会员等级2");
        this.vipData.amt_topup_sum = JSON.parse(
          data_top_sum.data.body.data
        ).amt_topup_sum;
        this.vipData.level_name = JSON.parse(data_leve_start.data.body.data).level_name;
        this.vipData.discount = JSON.parse(data_leve_start.data.body.data).discount;
        // console.log(JSON.parse(data_top_sum.data.body.data).amt_topup_sum,'累计充值金额')
        //     console.log(data_leve_start,'会员等级，会员折扣',data_top_sum)
        let level_name = JSON.parse(data_leve_start.data.body.data).level_name;
        let date_valid = JSON.parse(data_leve_start.data.body.data).discount;
        if (date_valid === "null") {
          date_valid = "-";
        }
        if (level_name === "null") {
          level_name = "-";
        }
        this.set_input(level_name, "会员等级", "value");
        this.set_input(date_valid, "会员折扣%", "value");
        this.set_input(
          JSON.parse(data_top_sum.data.body.data).amt_topup_sum,
          "累计充值金额",
          "value"
        );
        //   //console.log(data_leve_start,'asdasdasdsa00000')
      } catch (e) {}
    },
    async query_insert_member_topup(fasong_list) {
      //提交充值单

      console.log("2324432424");
      const { name, user_id } = this.$jichuxinxi();
      const suiji = this.$func.randomstring(25);
      let param = {
        ent_id: this.$ent_id(),
        user_id: this.jichuxinxi.user_id,
        dept_id: this.liucheng_dept_id,
        cust_name: this.vipData.cust_name,
        flow_id: this.date.flow_id,
        flow_no: suiji,
      };
      const Approve = {
        list: fasong_list,
        flow_no: suiji,
      };
      //获取用户充值的时间
      let nowtime = null;
      for (let time of this.list) {
        if (time.defaultLable === "日期") {
          nowtime = time.value;
        }
      }
      const d = new Date(nowtime);
      const list = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_2", "暂时");
      let xiangqing = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_4", "暂时");
      this.vipData.date_topup = `${d.getFullYear()}-${this.$func.pa(
        d.getMonth() + 1
      )}-${this.$func.pa(d.getDate())}`;
      //获取到operate_user_dept_id
      //获取到operate_user_id
      //获取操作人的id
      let label, id;
      if (!this.TreeData) {
        label = xiangqing.staff_name;
        id = xiangqing.staff_id;
      } else {
        label = this.TreeData.label;
        id = this.TreeData.id;
      }

      this.vipData.operate_user_id = user_id;
      this.vipData.operate_user_name = this.infoData.staff_name;
      this.vipData.operate_user_dept_name = this.infoData.dept_name;
      this.vipData.operate_user_dept_id = this.infoData.dept_id;
      //获取到staff_name
      this.vipData.staff_name = label;
      this.vipData.suiji = suiji;
      //获取到staff_id
      this.vipData.staff_id = id;
      this.vipData.flow_no = suiji;
      this.vipData.flow_no_orig =
        this.zhuangtai_shiyong_name == "作废" ? xiangqing.flow_no : "null"; //作废标志
      this.vipData.flag_cancel = this.zhuangtai_shiyong_name == "作废" ? "1" : "0"; //作废标志
      //  this.vipData.pay_type = this.vipData.viptype_pay.pay_type

      //获取支付方式
      //获取累计充值金额
      let list_pay = [];
      for (let i of this.list) {
        if (i.defaultLable === "明细（1）") {
          i.components_list.forEach((item) => {
            let obj = {};
            obj.pay_type = item[0].defaultOptions[item[0].value].text;
            obj.rate_pay = item[2].value;
            obj.amt_pay = item[1].value;
            list_pay.push(obj);
          });
        }
      }

      this.vipData.list_pay = list_pay;
      console.log(xiangqing, "---------------xiangqing");
      if (this.zhuangtai_shiyong_name == "作废") {
        for (let i of this.list) {
          if (i.defaultLable == "期初余额") {
            this.vipData.amt_bgn = i.value;
          }
          if (i.defaultLable == "充值金额") {
            this.vipData.amt_topup = i.value + "";
          }
          if (i.defaultLable == "赠送金额") {
            this.vipData.amt_give = i.value + "";
          }
          if (i.defaultLable == "期末余额") {
            this.vipData.amt_end = i.value + "";
          }
          if (i.defaultLable == "累计充值金额") {
            this.vipData.amt_topup_sum = i.value;
          }
          if (i.defaultLable == "会员等级") {
            this.vipData.level_name = i.value;
          }
          if (i.defaultLable == "会员折扣%") {
            this.vipData.discount = i.value;
          }
        }
        this.vipData.cust_id = xiangqing.cust_id;
        this.vipData.cust_name = xiangqing.cust_name;
        this.vipData.dept_id = xiangqing.dept_id;
        this.vipData.dept_name = xiangqing.dept_name;

        xiangqing.pay_detail.forEach((item) => {
          item.amt_pay = 0 - Number(item.amt_pay) + "";
        });
        this.vipData.list_pay = xiangqing.pay_detail;
        console.log(this.vipData.list_pay, "222222222444445");
        this.vipData.date_topup = xiangqing.date_topup;

        this.vipData.message = xiangqing.message;
        this.vipData.mobile = xiangqing.mobile;
      }
      console.log(this.vipData, "这是我的vipdata");
      if (this.zhuangtai_shiyong_name !== "作废") {
        if (
          list_pay.reduce((a, b) => (a = a + Number(b.amt_pay)), 0) !=
          Number(this.vipData.amt_topup)
        )
          return this.$message.warning("结算金额必须等于充值金额");
      }

      // if(this.vipData.amt_topup != )

      let data = await insert_member_topup({ data: this.vipData }); //提交审批
      // console.log(Approve,'这是我要发送的list')
      let insert_Approve = await add_appr_flow_step_list({ data: Approve }); //插入审批流程
      console.log(param, "这是我的审批提交");
      param.cust_name = param.cust_name || xiangqing.cust_name;
      let ex_approval = await start_flow_action({ data: param }); //提交审批
      // //console.log(insert_Approve,'sasasasssss')
      if (ex_approval.data.code == "200") {
        this.$message({
          message: "提交成功",
          type: "success",
        });
        // this.bianji_jiekou()
        window.history.go(-1);
      }
    },
    //充值单 -- 查询客户的会员等级，会员折扣
    query_cust_mc_level_discount_of_topup(amt_topup) {},
    //回款单--获取用户查询客户的会员等级，会员折扣 获取查询列表：支付方式
    query_pay_type_list(num) {
      const param = {
        ent_id: this.vipData.ent_id,
        form_type: JSON.stringify(num),
      };
      query_pay_type_list({ data: param }).then((res) => {
        if (res.data.code == 200) {
          this.vipData.message = JSON.parse(res.data.body.data);
          //console.log('2222222')
        }
      });
    },
    //获取当前的时间
    get_curr_Time(time) {
      this.curr_time = time;
    },
    set_input(i, x, y) {
      let inputName;
      this.list.filter((item, index) => {
        if (item.defaultLable == x) {
          inputName = index;
        }
      });
      this.$set(this.list[inputName], y, i);
    },
    //选择客户按钮
    xuanze_kehu(i, index) {
      console.log('--------i', i)
      i.teshu = false;
      switch (this.list[0].flow_id) {
        case "AY3cli7BAb":
          this.vip_chuzhi_dan(i);
          break;
        case "He5ln0bdDO":
          this.hui_kuan_dan(i);
          break;
      }
      if (i.kehu_list[Number(i.zhanshi)].file_status != 2) {
        //////console.log(i.kehu_list[Number(i.zhanshi)])
        i.value = i.kehu_list[Number(i.zhanshi)].cust_name;
        i.value_id = i.kehu_list[Number(i.zhanshi)].cust_id;
        i.teshu = false;
        const xg = this.list;
        // hXstgwxhzh
        if (this.date.flow_id != "hXstgwxhzh") {
          // 不等于出库单
          query_staff_dept_list({
            data: {
              ent_id: this.$ent_id(),
              staff_id: this.jichuxinxi.user_id,
            },
          }).then((res) => {
            //////console.log(res)

            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data);
              let liebiao = [];
              for (let a = 0; a < xg.length; a++) {
                //////console.log(xg[a])
                if (
                  this.date.flow_id == "5FotYgnu0Z" ||
                  (this.zi_dingyi && !this.kehu_shifou_bitian)
                ) {
                  // 是自定义表单且客户不是必填项
                  if (xg[a].componentName == "department") {
                    this.$set(xg[a], "option", date);
                    this.$set(xg[a], "dept_id", "");
                    liebiao = date;
                  } else {
                    liebiao = date;
                  }
                } else {
                  if (
                    xg[a].componentName == "department" &&
                    (this.date.flow_id != "7TvJHPyovM" ||
                      this.zhuangtai_shiyong_name == "已分配" ||
                      this.zhuangtai_shiyong_name == "审批中心" ||
                      this.zhuangtai_shiyong_name == "已分配_编辑" ||
                      this.zhuangtai_shiyong_name == "看板")
                  ) {
                    date.forEach((item) => {
                      if (i.kehu_list[Number(i.zhanshi)].dept_id == item.dept_id) {
                        liebiao = [item];
                      }
                    });
                    this.$set(xg[a], "dept_id", "");
                    this.$set(xg[a], "option", liebiao);
                  } else {
                    date.forEach((item) => {
                      if (i.kehu_list[Number(i.zhanshi)].dept_id == item.dept_id) {
                        // let bumen=[]
                        // bumen.push(item)
                        liebiao = [item];
                      }
                    });
                  }
                }
              }
              setTimeout(() => {
                let bumen_list = [];
                if (
                  this.date.flow_id == "5FotYgnu0Z" &&
                  this.zhuangtai_shiyong_name == "已分配_编辑"
                ) {
                  liebiao.forEach((item) => {
                    if (item.dept_id == this.yi_fenpei_bianji.dept_id) {
                      bumen_list.push(item);
                    }
                  });
                } else {
                  bumen_list = liebiao;
                }
                bumen_list = this.$func.Es5duplicate(bumen_list, "dept_id");
                this.liucheng_bumen_list = bumen_list;
                this.suoyou_bumen = bumen_list;
              }, 0);
            } else if (res.data.code == 500) {
            }
          });
        }
        if (this.date.flow_id == "hXstgwxhzh") {
          query_all_prod_list_of_category_out_store({
            data: {
              ent_id: this.$ent_id(),
              file_no: i.kehu_list[Number(i.zhanshi)].file_no,
              search_type: "1", // 1第一次查询  2  没有交付单的时候所有产品查询
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data);
              //////console.log(date)
              this.chuku_kehu_no_chanpin = date.length == 0; // 出库单客户没有产品   false有  true没有
              this.xuanze_gongying_shang_jiazai_chanpin(date);
            } else if (res.data.code == 500) {
            }
          });
        }
        if (
          i.kehu_list[Number(i.zhanshi)].file_no != undefined &&
          this.date.flow_id == "Z7VDOK9HGu"
        ) {
          query_stage_from_record_and_funn({
            data: {
              ent_id: this.$ent_id(),
              file_no: i.kehu_list[Number(i.zhanshi)].file_no,
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data);
              //////console.log(date)
              if (date != null) {
                // let shifou='0'
                // 标记
                // //////console.log(this.list[i])
                for (let z = 0; z < this.list.length; z++) {
                  if (this.list[z].defaultLable == "拜访目标") {
                    const shuzu = this.list[z].components_list;
                    for (let a = 0; a < shuzu.length; a++) {
                      for (let b = 0; b < shuzu[a].length; b++) {
                        // if(shifou==0){
                        if (
                          shuzu[a][b].componentName == "ddselectfield" &&
                          shuzu[a][b].defaultLable == "漏斗现阶段"
                        ) {
                          let suoyou = [
                            {
                              idx: 1,
                              text: "初步接触",
                            },
                            {
                              idx: 2,
                              text: "需求确认",
                            },
                            {
                              idx: 3,
                              text: "报价及方案展示",
                            },
                            {
                              idx: 4,
                              text: "商务谈判",
                            },
                            {
                              idx: 5,
                              text: "赢单",
                            },
                            {
                              idx: 6,
                              text: "输单",
                            },
                          ];
                          //////console.log(shuzu[a][b])
                          for (let d = 0; d < suoyou.length; d++) {
                            // if((this.jieduan_hanzi(date.funn_stage)=='赢单'||this.jieduan_hanzi(date.funn_stage)=='输单')&&suoyou[d].text==this.jieduan_hanzi(date.funn_stage)){
                            //     suoyou=suoyou.splice(d,1)
                            // }
                            // else
                            if (suoyou[d].text == this.jieduan_hanzi(date.funn_stage)) {
                              suoyou = suoyou.splice(d, 1);
                            }
                          }
                          //////console.log(suoyou)
                          shuzu[a][b].defaultOptions = suoyou;
                          shuzu[a][b].value = 0;
                          //////console.log(shuzu[a][b])
                        }
                        if (
                          shuzu[a][b].componentName == "ddselectfield" &&
                          shuzu[a][b].defaultLable == "预计下一漏斗阶段"
                        ) {
                          let suoyou = [
                            {
                              idx: 1,
                              text: "初步接触",
                            },
                            {
                              idx: 2,
                              text: "需求确认",
                            },
                            {
                              idx: 3,
                              text: "报价及方案展示",
                            },
                            {
                              idx: 4,
                              text: "商务谈判",
                            },
                            {
                              idx: 5,
                              text: "赢单",
                            },
                            {
                              idx: 6,
                              text: "输单",
                            },
                          ];
                          //////console.log(shuzu[a][b])
                          for (let d = 0; d < suoyou.length; d++) {
                            if (
                              (this.jieduan_hanzi(date.funn_stage) == "赢单" ||
                                this.jieduan_hanzi(date.funn_stage) == "输单") &&
                              suoyou[d].text == this.jieduan_hanzi(date.funn_stage)
                            ) {
                              suoyou = suoyou.splice(d, 1);
                            } else if (
                              suoyou[d].text == this.jieduan_hanzi(date.funn_stage)
                            ) {
                              suoyou.splice(0, d);
                            }
                          }
                          //////console.log(suoyou)
                          shuzu[a][b].defaultOptions = suoyou;
                          shuzu[a][b].value = 0;
                          //////console.log(shuzu[a][b])
                        }
                        // }else{
                        //     if(shuzu[a][b].componentName=='ddselectfield'&&shuzu[a][b].defaultLable=='漏斗现阶段'){
                        //         for(let b=0;b<suoyou.length;b++){
                        //             if((this.jieduan_hanzi(date.current_stage)=='赢单'||this.jieduan_hanzi(date.current_stage)=='输单')&&suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                        //                 suoyou=suoyou.splice(b,1)
                        //             }else if(suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                        //                 suoyou.splice(0,b)
                        //             }
                        //         }
                        //         shuzu[a][b].defaultOptions=suoyou
                        //         shuzu[a][b].value=0
                        //     }
                        // }
                      }

                      // if(shifou==0){
                      //     if(this.list[i].components[a].componentName=='ddselectfield'&&this.list[i].components[a].defaultLable=='漏斗达成阶段'){
                      //         for(let b=0;b<suoyou.length;b++){
                      //             if((this.jieduan_hanzi(date.current_stage)=='赢单'||this.jieduan_hanzi(date.current_stage)=='输单')&&suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                      //                 suoyou=suoyou.splice(b,1)
                      //             }else if(suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                      //                 suoyou.splice(0,b+1)
                      //             }
                      //         }
                      //         this.list[i].components[a].defaultOptions=suoyou
                      //         this.list[i].components[a].value=0
                      //     }
                      // }else{
                      //     if(this.list[i].components[a].componentName=='ddselectfield'&&this.list[i].components[a].defaultLable=='漏斗达成阶段'){
                      //         for(let b=0;b<suoyou.length;b++){
                      //             if((this.jieduan_hanzi(date.current_stage)=='赢单'||this.jieduan_hanzi(date.current_stage)=='输单')&&suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                      //                 suoyou=suoyou.splice(b,1)
                      //             }else if(suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                      //                 suoyou.splice(0,b)
                      //             }
                      //         }
                      //         this.list[i].components[a].defaultOptions=suoyou
                      //         this.list[i].components[a].value=0
                      //     }
                      // }
                    }
                  }
                }
              }
            } else if (res.data.code == 500) {
            }
          });
        }
        if (
          i.kehu_list[Number(i.zhanshi)].file_no != undefined &&
          this.date.flow_id == "PLOQ9ku3Ip"
        ) {
          customer_if_can_creat_funn({
            data: {
              ent_id: this.$ent_id(),
              cust_id: i.kehu_list[Number(i.zhanshi)].cust_id,
              dept_id: i.kehu_list[Number(i.zhanshi)].dept_id,
            },
          }).then((chenggong) => {
            //////console.log(chenggong)
            if (chenggong.data.code == 200) {
              const chenggonga = JSON.parse(chenggong.data.body.data);
              //////console.log(chenggonga)
              if (chenggonga.funn_all_stage == 1) {
                funn_contract_have_exist_file_no({
                  data: {
                    ent_id: this.$ent_id(),
                    file_no: i.kehu_list[Number(i.zhanshi)].file_no,
                    form_type: "1",
                  },
                }).then((ras) => {
                  //////console.log(ras)
                  if (ras.data.code == 600) {
                    this.kehu_chongfu.shifou = false;
                    this.kehu_chongfu.name = "漏斗单";
                  } else if (ras.data.code == 700) {
                    query_funn_stage_for_funn({
                      data: {
                        ent_id: this.$ent_id(),
                        file_no: i.kehu_list[Number(i.zhanshi)].file_no,
                      },
                    }).then((res) => {
                      //////console.log(res)
                      if (res.data.code == 200) {
                        const date = JSON.parse(res.data.body.data);
                        for (let i = 0; i < this.list.length; i++) {
                          if (
                            this.list[i].componentName == "ddselectfield" &&
                            this.list[i].defaultLable == "漏斗阶段"
                          ) {
                            if (date != null) {
                              //////console.log(date)
                              // this.list[i].defaultOptions.splice(Number(date.funn_stage),this.list[i].defaultOptions.length-1)
                              // this.list[i].value='0'
                              const name = this.jieduan_hanzi(date.funn_stage);
                              //////console.log(name)
                              let suoyou = [
                                {
                                  idx: 1,
                                  text: "初步接触",
                                },
                                {
                                  idx: 2,
                                  text: "需求确认",
                                },
                                {
                                  idx: 3,
                                  text: "报价及方案展示",
                                },
                                {
                                  idx: 4,
                                  text: "商务谈判",
                                },
                                {
                                  idx: 5,
                                  text: "赢单",
                                },
                                {
                                  idx: 6,
                                  text: "输单",
                                },
                              ];
                              for (let a = 0; a < suoyou.length; a++) {
                                if (
                                  (name == "赢单" || name == "输单") &&
                                  suoyou[a].text == name
                                ) {
                                  suoyou = suoyou.splice(a, 1);
                                } else if (suoyou[a].text == name) {
                                  suoyou.splice(0, a);
                                }
                              }
                              this.list[i].defaultOptions = suoyou;
                              this.list[i].value = "0";
                            } else {
                              this.list[i].value = "0";
                            }
                          }
                        }
                      } else if (res.data.code == 500) {
                      }
                    });
                  }
                });
                this.kehu_you_loudou = false;
              } else {
                this.kehu_you_loudou = true;
              }
            } else if (chenggong.data.code == 500) {
            }
          });
        }
        if (
          i.kehu_list[Number(i.zhanshi)].file_no != undefined &&
          this.date.flow_id == "453y6pLD52"
        ) {
          // -----------------------------------------------------------漏斗现阶段
          query_stage_from_plan({
            data: {
              ent_id: this.$ent_id(),
              file_no: i.kehu_list[Number(i.zhanshi)].file_no,
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data);
              if (date != null && date.length != 0) {
                let suoyou = [
                  {
                    idx: 1,
                    text: "初步接触",
                  },
                  {
                    idx: 2,
                    text: "需求确认",
                  },
                  {
                    idx: 3,
                    text: "报价及方案展示",
                  },
                  {
                    idx: 4,
                    text: "商务谈判",
                  },
                  {
                    idx: 5,
                    text: "赢单",
                  },
                  {
                    idx: 6,
                    text: "输单",
                  },
                ];
                let shifou = "0";
                for (let i = 0; i < this.list.length; i++) {
                  if (
                    this.list[i].componentName == "tablefield" &&
                    this.list[i].defaultLable == "拜访目标结果"
                  ) {
                    const shuzu = this.list[i].components_list;
                    for (let a = 0; a < shuzu.length; a++) {
                      for (let b = 0; b < shuzu[a].length; b++) {
                        if (
                          shuzu[a][b].componentName == "textfield" &&
                          shuzu[a][b].defaultLable == "漏斗现阶段"
                        ) {
                          shuzu[a][b].value = this.jieduan_hanzi(date.current_stage);
                        }
                      }
                      // if(this.list[i].components[a].componentName=='textfield'&&this.list[i].components[a].defaultLable=='漏斗现阶段'){
                      //     this.list[i].components[a].value=this.jieduan_hanzi(date.current_stage)
                      // }
                    }
                    for (let a = 0; a < shuzu.length; a++) {
                      for (let b = 0; b < shuzu[a].length; b++) {
                        if (
                          shuzu[a][b].componentName == "ddselectfield" &&
                          shuzu[a][b].defaultLable == "漏斗阶段变化"
                        ) {
                          shifou = shuzu[a][b].value;
                        }
                      }
                      // if(this.list[i].components[a].componentName=='ddselectfield'&&this.list[i].components[a].defaultLable=='漏斗阶段变化'){
                      //     shifou=this.list[i].components[a].value
                      // }
                    }
                    for (let a = 0; a < shuzu.length; a++) {
                      for (let b = 0; b < shuzu[a].length; b++) {
                        if (shifou == 0) {
                          if (
                            shuzu[a][b].componentName == "ddselectfield" &&
                            shuzu[a][b].defaultLable == "漏斗达成阶段"
                          ) {
                            for (let b = 0; b < suoyou.length; b++) {
                              if (
                                (this.jieduan_hanzi(date.current_stage) == "赢单" ||
                                  this.jieduan_hanzi(date.current_stage) == "输单") &&
                                suoyou[b].text == this.jieduan_hanzi(date.current_stage)
                              ) {
                                suoyou = suoyou.splice(b, 1);
                              } else if (
                                suoyou[b].text == this.jieduan_hanzi(date.current_stage)
                              ) {
                                suoyou.splice(0, b + 1);
                              }
                            }
                            shuzu[a][b].defaultOptions = suoyou;
                            shuzu[a][b].value = 0;
                          }
                        } else {
                          if (
                            shuzu[a][b].componentName == "ddselectfield" &&
                            shuzu[a][b].defaultLable == "漏斗达成阶段"
                          ) {
                            for (let b = 0; b < suoyou.length; b++) {
                              if (
                                (this.jieduan_hanzi(date.current_stage) == "赢单" ||
                                  this.jieduan_hanzi(date.current_stage) == "输单") &&
                                suoyou[b].text == this.jieduan_hanzi(date.current_stage)
                              ) {
                                suoyou = suoyou.splice(b, 1);
                              } else if (
                                suoyou[b].text == this.jieduan_hanzi(date.current_stage)
                              ) {
                                suoyou.splice(0, b);
                              }
                            }
                            shuzu[a][b].defaultOptions = suoyou;
                            shuzu[a][b].value = 0;
                          }
                        }
                      }
                    }
                    // if(shifou==0){
                    //     if(this.list[i].components[a].componentName=='ddselectfield'&&this.list[i].components[a].defaultLable=='漏斗达成阶段'){
                    //         for(let b=0;b<suoyou.length;b++){
                    //             if((this.jieduan_hanzi(date.current_stage)=='赢单'||this.jieduan_hanzi(date.current_stage)=='输单')&&suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                    //                 suoyou=suoyou.splice(b,1)
                    //             }else if(suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                    //                 suoyou.splice(0,b+1)
                    //             }
                    //         }
                    //         this.list[i].components[a].defaultOptions=suoyou
                    //         this.list[i].components[a].value=0
                    //     }
                    // }else{
                    //     if(this.list[i].components[a].componentName=='ddselectfield'&&this.list[i].components[a].defaultLable=='漏斗达成阶段'){
                    //         for(let b=0;b<suoyou.length;b++){
                    //             if((this.jieduan_hanzi(date.current_stage)=='赢单'||this.jieduan_hanzi(date.current_stage)=='输单')&&suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                    //                 suoyou=suoyou.splice(b,1)
                    //             }else if(suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                    //                 suoyou.splice(0,b)
                    //             }
                    //         }
                    //         this.list[i].components[a].defaultOptions=suoyou
                    //         this.list[i].components[a].value=0
                    //     }
                    // }
                  }
                }
              } else {
                const suoyou = [
                  {
                    idx: 1,
                    text: "初步接触",
                  },
                  {
                    idx: 2,
                    text: "需求确认",
                  },
                  {
                    idx: 3,
                    text: "报价及方案展示",
                  },
                  {
                    idx: 4,
                    text: "商务谈判",
                  },
                  {
                    idx: 5,
                    text: "赢单",
                  },
                  {
                    idx: 6,
                    text: "输单",
                  },
                ];
                const shifou = "0";
                for (let i = 0; i < this.list.length; i++) {
                  if (
                    this.list[i].componentName == "tablefield" &&
                    this.list[i].defaultLable == "拜访目标结果"
                  ) {
                    const shuzu = this.list[i].components_list;
                    for (let a = 0; a < shuzu.length; a++) {
                      for (let b = 0; b < shuzu[a].length; b++) {
                        if (
                          shuzu[a][b].componentName == "textfield" &&
                          shuzu[a][b].defaultLable == "漏斗现阶段"
                        ) {
                          shuzu[a][b].value = "初步接触";
                        }
                      }
                      // if(this.list[i].components[a].componentName=='textfield'&&this.list[i].components[a].defaultLable=='漏斗现阶段'){
                      //     this.list[i].components[a].value=this.jieduan_hanzi(date.current_stage)
                      // }
                    }
                    // if(shifou==0){
                    //     if(this.list[i].components[a].componentName=='ddselectfield'&&this.list[i].components[a].defaultLable=='漏斗达成阶段'){
                    //         for(let b=0;b<suoyou.length;b++){
                    //             if((this.jieduan_hanzi(date.current_stage)=='赢单'||this.jieduan_hanzi(date.current_stage)=='输单')&&suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                    //                 suoyou=suoyou.splice(b,1)
                    //             }else if(suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                    //                 suoyou.splice(0,b+1)
                    //             }
                    //         }
                    //         this.list[i].components[a].defaultOptions=suoyou
                    //         this.list[i].components[a].value=0
                    //     }
                    // }else{
                    //     if(this.list[i].components[a].componentName=='ddselectfield'&&this.list[i].components[a].defaultLable=='漏斗达成阶段'){
                    //         for(let b=0;b<suoyou.length;b++){
                    //             if((this.jieduan_hanzi(date.current_stage)=='赢单'||this.jieduan_hanzi(date.current_stage)=='输单')&&suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                    //                 suoyou=suoyou.splice(b,1)
                    //             }else if(suoyou[b].text==this.jieduan_hanzi(date.current_stage)){
                    //                 suoyou.splice(0,b)
                    //             }
                    //         }
                    //         this.list[i].components[a].defaultOptions=suoyou
                    //         this.list[i].components[a].value=0
                    //     }
                    // }
                  }
                }
              }
            } else if (res.data.code == 500) {
            }
          });
        }
        if (this.date.flow_id == "cvRyWGiUQH") {
          query_go_out_year_hour_long({
            data: {
              ent_id: this.$ent_id(),
              staff_id: i.kehu_list[Number(i.zhanshi)].staff_id,
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data);
              for (let a = 0; a < this.list.length; a++) {
                if (
                  this.list[a].componentName == "numberfield" &&
                  this.list[a].defaultLable == "年累计外出时长（小时）"
                ) {
                  this.list[a].value = date + "";
                }
              }
            } else if (res.data.code == 500) {
            }
          });
        }
        if (this.date.flow_id == "GBh7N2jMsi") {
          query_year_trip_days({
            data: {
              ent_id: this.$ent_id(),
              staff_id: i.kehu_list[Number(i.zhanshi)].staff_id,
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data);
              for (let a = 0; a < this.list.length; a++) {
                if (
                  this.list[a].componentName == "numberfield" &&
                  this.list[a].defaultLable == "年累计出差天数"
                ) {
                  this.list[a].value = date;
                }
              }
            } else if (res.data.code == 500) {
            }
          });
        }
        if (!this.guanlian) {
          for (let a = 0; a < this.list.length; a++) {
            if (this.list[a].defaultLable == "归档号") {
              this.list[a].value = i.kehu_list[Number(i.zhanshi)].file_no;
            }
            // if(this.list[a].defaultLable=='负责人'){
            //     if(i.kehu_list[Number(i.zhanshi)].staff_name!=undefined){
            //         this.list[a].value=i.kehu_list[Number(i.zhanshi)].staff_name
            //     }else{
            //         this.list[a].value=''
            //     }
            // }
            // if(this.list[a].defaultLable=='部门'){
            //     query_staff_dept_list({data:{
            //         ent_id:this.$ent_id(),
            //         staff_id:i.kehu_list[Number(i.zhanshi)].staff_id
            //     }}).then(res=>{
            //         //////console.log(res)
            //         if(res.data.code==200){
            //             let date=JSON.parse(res.data.body.data)
            //             //////console.log(date)
            //             this.list[a]['option']=date
            //         }else if(res.data.code==500){}
            //     })
            //     // if(i.kehu_list[Number(i.zhanshi)].dept_name!=undefined){
            //     //     this.list[a].value=i.kehu_list[Number(i.zhanshi)].dept_name
            //     // }else{
            //     //     this.list[a].value=''
            //     // }
            // }
          }
        }
        if (this.date.flow_id == "qIwxmsMTgv") {
          contract_have_exist_file_no({
            data: {
              ent_id: this.$ent_id(),
              file_no: i.kehu_list[Number(i.zhanshi)].file_no,
              form_type: "2",
            },
          }).then((raas) => {
            //////console.log(raas)
            if (raas.data.code == 600) {
              this.kehu_chongfu.shifou = false;
              this.kehu_chongfu.name = "合同单";
            } else if (raas.data.code == 700) {
              // funn_contract_have_exist_file_no({data:{
              //     ent_id:this.$ent_id(),
              //     file_no:i.kehu_list[Number(i.zhanshi)].file_no,
              //     form_type:'2'
              // }}).then(ras=>{
              //     //////console.log(ras)
              //     if(ras.data.code==600){
              //         this.kehu_chongfu.shifou=false
              //         this.kehu_chongfu.name='合同单'
              //     }else if(ras.data.code==700){
              trinity_contract({
                data: {
                  ent_id: this.$ent_id(),
                  file_no: i.kehu_list[Number(i.zhanshi)].file_no,
                },
              }).then((res) => {
                //////console.log(res)

                if (res.data.code == 200) {
                  const date = JSON.parse(res.data.body.data);
                  //////console.log(date)

                  for (let a = 0; a < this.list.length; a++) {
                    if (
                      this.list[a].componentName == "tablefield" &&
                      this.list[a].defaultLable == "产品"
                    ) {
                      for (let b = 0; b < date.length - 1; b++) {
                        this.tianjia_mingxi(this.list[a], 1);
                      }
                      // let length=''
                      // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                      // for(let c=0;c<data.length;c++){
                      //     if(data[c].componentName=='tablefield'&&this.list[a].defaultLable==data[c].defaultLable&&this.list[a].idx==data[c].idx){
                      //         length=Number(data[a].components.length)+1
                      //     }
                      // }
                      // let shuzu=this.group(this.list[a].components,length)
                      const shuzu = this.list[a].components_list;
                      //////console.log(shuzu)
                      for (let dangqian = 0; dangqian < date.length; dangqian++) {
                        //////console.log(1)
                        for (let b = 0; b < shuzu[dangqian].length; b++) {
                          //////console.log(shuzu[dangqian])
                          if (
                            shuzu[dangqian][b].componentName == "product" &&
                            shuzu[dangqian][b].defaultLable == "产品"
                          ) {
                            shuzu[dangqian][b].chanpin_list = date[dangqian];
                            shuzu[dangqian][b].value =
                              shuzu[dangqian][b].chanpin_list.prod_name;
                          }
                          if (
                            shuzu[dangqian][b].componentName == "moneyfield" &&
                            shuzu[dangqian][b].defaultLable == "单价"
                          ) {
                            shuzu[dangqian][b].value = date[dangqian].price_unit;
                          }
                          if (
                            shuzu[dangqian][b].componentName == "numberfield" &&
                            shuzu[dangqian][b].defaultLable == "数量"
                          ) {
                            shuzu[dangqian][b].value = date[dangqian].qty;
                          }
                          if (
                            shuzu[dangqian][b].componentName == "calcform" &&
                            shuzu[dangqian][b].defaultLable == "折扣率"
                          ) {
                            shuzu[dangqian][b].value = Number(
                              date[dangqian].disc_rate
                            ).toFixed(2);
                          }
                        }
                      }
                    }
                  }
                  let hetong_jine = 0;
                  date.forEach((item) => {
                    hetong_jine = hetong_jine + Number(item.price_sale);
                  });
                  this.shifou_shi_loudou_dan = res.data.body.data_from == 1;
                  this.hetong_duibi_jine = date.length != 0 ? hetong_jine : null;
                } else if (res.data.code == 500) {
                }
              });
              //     }
              // })
            }
          });
        }
        if (this.date.flow_id == "VMCizuVOXb") {
          trinity_invoice({
            data: {
              ent_id: this.$ent_id(),
              file_no: i.kehu_list[Number(i.zhanshi)].file_no,
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data);
              console.log('产品列表------:', date)
              for (let jian = 0; jian < date.length; jian++) {
                if (date[jian].qty == 0) {
                  date.splice(jian, 1);
                  jian = jian - 1;
                }
              }
              query_con_amt_and_inv_amt_sum({
                data: {
                  ent_id: this.$ent_id(),
                  file_no: i.kehu_list[Number(i.zhanshi)].file_no,
                },
              }).then((ras) => {
                //////console.log(ras)
                if (ras.data.code == 200) {
                  const data = JSON.parse(ras.data.body.data);
                  console.log('合同金额和发票金额累计-------', data)
                  for (let a = 0; a < this.list.length; a++) {
                    if (
                      this.list[a].componentName == "moneyfield" &&
                      this.list[a].defaultLable == "累计发票金额"
                    ) {
                      this.list[a].value = data.amt_inv_sum;
                      this.list[a].dangqian_hetong_jine = data.amt_con;
                      // if(this.zhuangtai_shiyong_name!='作废'){
                      query_left_inv_amt({
                        data: {
                          ent_id: this.$ent_id(),
                          file_no: i.kehu_list[Number(i.zhanshi)].file_no,
                        },
                      }).then((rbs) => {
                        if (rbs.data.code == 200) {
                          const hetong_jine = JSON.parse(rbs.data.body.data);
                          //////console.log(hetong_jine)

                          this.list[a].hetong_jine = hetong_jine;
                          this.jisuan();
                        } else if (rbs.data.code == 500) {
                        }
                      });
                      // }else{
                      //     this.list[a]['hetong_jine']=data.amt_con
                      // }
                    }
                  }
                } else if (res.data.code == 500) {
                }
              });
              for (let a = 0; a < this.list.length; a++) {
                if (
                  this.list[a].componentName == "tablefield" &&
                  this.list[a].defaultLable == "发票内容"
                ) {
                  if (this.zhuangtai_shiyong_name != "作废") {
                    for (let b = 0; b < date.length - 1; b++) {
                      this.tianjia_mingxi(this.list[a], 1);
                    }
                  }
                  //////console.log(date)

                  // let length=''
                  // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                  // for(let c=0;c<data.length;c++){
                  //     if(data[c].componentName=='tablefield'&&this.list[a].defaultLable==data[c].defaultLable&&this.list[a].idx==data[c].idx){
                  //         length=Number(data[a].components.length)+1
                  //     }
                  // }
                  // let shuzu=this.group(this.list[a].components,length)
                  const shuzu = this.list[a].components_list;
                  //////console.log(shuzu)
                  for (let dangqian = 0; dangqian < date.length; dangqian++) {
                    //////console.log(1)
                    for (let b = 0; b < shuzu[dangqian].length; b++) {
                      //////console.log(shuzu[dangqian])
                      if (
                        shuzu[dangqian][b].componentName == "product" &&
                        shuzu[dangqian][b].defaultLable == "产品"
                      ) {
                        shuzu[dangqian][b].chanpin_list = date[dangqian];
                        shuzu[dangqian][b].value =
                          shuzu[dangqian][b].chanpin_list.prod_name;
                      }
                      if (
                        shuzu[dangqian][b].componentName == "moneyfield" &&
                        shuzu[dangqian][b].defaultLable == "单价"
                      ) {
                        shuzu[dangqian][b].value = date[dangqian].price_unit;
                      }
                      if (
                        shuzu[dangqian][b].componentName == "numberfield" &&
                        shuzu[dangqian][b].defaultLable == "数量" &&
                        this.zhuangtai_shiyong_name != "作废"
                      ) {
                        shuzu[dangqian][b].value = date[dangqian].qty;
                      }
                      if (
                        shuzu[dangqian][b].componentName == "calcform" &&
                        shuzu[dangqian][b].defaultLable == "折扣率"
                      ) {
                        shuzu[dangqian][b].value = Number(
                          date[dangqian].disc_rate
                        ).toFixed(2);
                      }
                    }
                  }
                }
              }
            } else if (res.data.code == 500) {
            }
          });

          const _this = this;

          xg.forEach((item) => {
            if (item.defaultLable == "发票信息") {
              item.components_list.forEach((ite) => {
                ite.forEach((it) => {
                  if (it.defaultLable == "纳税识别号") {
                    // let hao=this.nashui(i.value_id)
                    // if(hao.length!=0){
                    //     this.$set(it,'value',hao)
                    //     this.$set(it,'daichu',true)
                    // }else{
                    //     this.$set(it,'value','')
                    //     this.$set(it,'daichu',false)
                    // }
                    if (this.zhuangtai_shiyong_name != "作废") {
                      query_uscc_of_cust({
                        data: {
                          ent_id: this.$ent_id(),
                          cust_id: i.value_id,
                        },
                      }).then((chuanzhi) => {
                        //////console.log(chuanzhi)
                        if (chuanzhi.data.code == 200) {
                          const hao = JSON.parse(chuanzhi.data.body.data);
                          //////console.log(hao)
                          if (hao != null && hao.length != 0) {
                            this.$set(it, "value", hao);
                            this.$set(it, "daichu", true);
                          } else {
                            this.$set(it, "value", "");
                            this.$set(it, "daichu", false);
                          }
                        } else if (chuanzhi.data.code == 500) {
                        }
                      });
                    }
                  }
                });
              });
            }
          });
        }
        if (this.date.flow_id == "He5ln0bdDO") {
          trinity_received_cash({
            data: {
              ent_id: this.$ent_id(),
              file_no: i.kehu_list[Number(i.zhanshi)].file_no,
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data);
              //////console.log(date)

              for (let jian = 0; jian < date.length; jian++) {
                if (date[jian].qty == 0) {
                  date.splice(jian, 1);
                  jian = jian - 1;
                }
              }
              query_rec_amt_sum_and_inv_amt_sum({
                data: {
                  ent_id: this.$ent_id(),
                  file_no: i.kehu_list[Number(i.zhanshi)].file_no,
                },
              }).then((ras) => {
                //////console.log(ras)
                if (ras.data.code == 200) {
                  const data = JSON.parse(ras.data.body.data);
                  //////console.log(data)
                  for (let a = 0; a < this.list.length; a++) {
                    if (
                      this.list[a].componentName == "moneyfield" &&
                      this.list[a].defaultLable == "累计回款金额"
                    ) {
                      this.list[a].value = data.amt_rec_sum + "";
                    }
                    if (
                      this.list[a].componentName == "moneyfield" &&
                      this.list[a].defaultLable == "累计发票金额"
                    ) {
                      this.list[a].value = data.amt_inv_sum + "";
                    }
                  }
                } else if (ras.data.code == 500) {
                }
              });
              for (let a = 0; a < this.list.length; a++) {
                if (
                  this.list[a].componentName == "tablefield" &&
                  this.list[a].defaultLable == "产品回款"
                ) {
                  if (this.zhuangtai_shiyong_name != "作废") {
                    for (let b = 0; b < date.length - 1; b++) {
                      this.tianjia_mingxi(this.list[a], 1);
                    }
                  }
                  // let length=''
                  // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                  // for(let c=0;c<data.length;c++){
                  //     if(data[c].componentName=='tablefield'&&this.list[a].defaultLable==data[c].defaultLable&&this.list[a].idx==data[c].idx){
                  //         length=Number(data[a].components.length)+1
                  //     }
                  // }
                  // let shuzu=this.group(this.list[a].components,length)
                  const shuzu = this.list[a].components_list;
                  //////console.log(shuzu)
                  for (let dangqian = 0; dangqian < date.length; dangqian++) {
                    //////console.log(1)
                    for (let b = 0; b < shuzu[dangqian].length; b++) {
                      //////console.log(shuzu[dangqian])
                      if (
                        shuzu[dangqian][b].componentName == "product" &&
                        shuzu[dangqian][b].defaultLable == "产品"
                      ) {
                        shuzu[dangqian][b].chanpin_list = date[dangqian];
                        shuzu[dangqian][b].value =
                          shuzu[dangqian][b].chanpin_list.prod_name;
                      }
                      if (
                        shuzu[dangqian][b].componentName == "moneyfield" &&
                        shuzu[dangqian][b].defaultLable == "单价"
                      ) {
                        shuzu[dangqian][b].value = date[dangqian].price_unit;
                      }
                      if (
                        shuzu[dangqian][b].componentName == "numberfield" &&
                        shuzu[dangqian][b].defaultLable == "数量" &&
                        this.zhuangtai_shiyong_name != "作废"
                      ) {
                        shuzu[dangqian][b].value = date[dangqian].qty;
                      }
                      if (
                        shuzu[dangqian][b].componentName == "calcform" &&
                        shuzu[dangqian][b].defaultLable == "折扣率"
                      ) {
                        shuzu[dangqian][b].value = Number(
                          date[dangqian].disc_rate
                        ).toFixed(2);
                      }
                    }
                  }
                }
              }
            } else if (res.data.code == 500) {
            }
          });
          if (this.zhuangtai_shiyong_name != "作废") {
            query_left_rec_amt({
              data: {
                ent_id: this.$ent_id(),
                file_no: i.kehu_list[Number(i.zhanshi)].file_no,
              },
            }).then((res) => {
              //////console.log(res)
              //
              if (res.data.code == 200) {
                const date = JSON.parse(res.data.body.data);
                //////console.log('回款金额差', date)
                this.huikuan_jine_cha = date;
              } else if (res.data.code == 500) {
              }
            });
          }
        }
        if (this.date.flow_id == "H49d4edc3T") {
          // get_prod_detail_for_deliver   7.27小虎哥说没用了  换成下面的这个接口
          trinity_deliver({
            data: {
              ent_id: this.$ent_id(),
              file_no: i.kehu_list[Number(i.zhanshi)].file_no,
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200 || res.data.code == 700) {
              const date = JSON.parse(res.data.body.data);
              //////console.log(date)
              if (date != null) {
                for (let jian = 0; jian < date.length; jian++) {
                  if (date[jian].qty == 0) {
                    date.splice(jian, 1);
                    jian = jian - 1;
                  }
                }
                for (let a = 0; a < this.list.length; a++) {
                  if (
                    this.list[a].componentName == "tablefield" &&
                    this.list[a].defaultLable == "产品明细"
                  ) {
                    for (let b = 0; b < date.length - 1; b++) {
                      this.tianjia_mingxi(this.list[a], 1);
                    }
                    // let length=''
                    // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                    // for(let c=0;c<data.length;c++){
                    //     if(data[c].componentName=='tablefield'&&this.list[a].defaultLable==data[c].defaultLable&&this.list[a].idx==data[c].idx){
                    //         length=Number(data[a].components.length)+1
                    //     }
                    // }
                    // let shuzu=this.group(this.list[a].components,length)
                    const shuzu = this.list[a].components_list;
                    //////console.log(shuzu)
                    for (let dangqian = 0; dangqian < date.length; dangqian++) {
                      //////console.log(1)
                      for (let b = 0; b < shuzu[dangqian].length; b++) {
                        //////console.log(shuzu[dangqian])
                        if (
                          shuzu[dangqian][b].componentName == "product" &&
                          shuzu[dangqian][b].defaultLable == "产品"
                        ) {
                          shuzu[dangqian][b].chanpin_list = date[dangqian];
                          shuzu[dangqian][b].value =
                            shuzu[dangqian][b].chanpin_list.prod_name;
                        }
                        if (
                          shuzu[dangqian][b].componentName == "moneyfield" &&
                          shuzu[dangqian][b].defaultLable == "单价"
                        ) {
                          shuzu[dangqian][b].value = date[dangqian].price_unit;
                        }
                        if (
                          shuzu[dangqian][b].componentName == "numberfield" &&
                          shuzu[dangqian][b].defaultLable == "数量"
                        ) {
                          shuzu[dangqian][b].value = date[dangqian].qty;
                          this.$set(
                            shuzu[dangqian][b],
                            "value_max",
                            JSON.parse(JSON.stringify(date[dangqian].qty))
                          );
                        }
                        if (
                          shuzu[dangqian][b].componentName == "numberfield" &&
                          shuzu[dangqian][b].defaultLable == "折扣率"
                        ) {
                          shuzu[dangqian][b].value = Number(
                            date[dangqian].disc_rate
                          ).toFixed(2);
                        }
                      }
                    }
                  }
                }
              }
              // else{
              //     this.shifou_you_hetong=true
              // }
            } else if (res.data.code == 500) {
            }
            // else if(res.data.code==700){
            //     this.shifou_you_hetong=true
            // }
          });
          // query_left_deliver_amt({data:{
          //     ent_id:this.$ent_id(),
          //     file_no:i.kehu_list[Number(i.zhanshi)].file_no
          // }}).then(res=>{
          //     //////console.log(res)
          //     if(res.data.code==200){
          //         let date=JSON.parse(res.data.body.data)
          //         this.jiaofu_jine_cha=date
          //     }else if(res.data.code==500){}
          // })
        }
        for (let a = 0; a < this.list.length; a++) {
          if (this.list[a].componentName == "tablefield") {
            const shuzu = this.list[a].components_list;
            for (let b = 0; b < shuzu.length; b++) {
              for (let c = 0; c < shuzu[b].length; c++) {
                if (shuzu[b][c].defaultLable == "客户") {
                  shuzu[b][c].value = i.kehu_list[Number(i.zhanshi)].cust_name;
                }
              }

              // if(this.list[a].components[b].defaultLable=='客户'){
              //     this.list[a].components[b].value=i.kehu_list[Number(i.zhanshi)].cust_name
              // }
            }
          }
        }
      } else {
        this.$message({
          message: "该客户已归档，请选择未归档客户进行编辑",
          type: "warning",
        });
        i.teshu = false;
      }
    },
    //获取客户手机号
    async get_kehu(cust_id) {
      try {
        let data = {
          ent_id: this.$ent_id(),
          cust_id: cust_id,
        };

        data = await query_mobile_list_of_customer({ data });

        //  this.query_pay_type_list(item.ent_id,1)
        //  //console.log(data.data)
        if (data.data.code == 200) {
          this.vipData.mobile = JSON.parse(data.data.body.data)[0].mobile;
        } else {
          this.$message.error("网络出小差了");
        }
      } catch {}
    },
    //获取树
    getTreeData(x) {
      this.TreeData = x;
      this.vipData.dept_name = x.dep.label;
      this.vipData.dept_id = x.dep.id;
    },
    handleChange(value) {
      // //console.log(value);
    },
    //组织结构树，既有部门，又有人员 tree,不带目标
    query_dept_staff_tree_aim_not() {
      query_dept_staff_tree_aim_not({
        data: {
          ent_id: this.$ent_id(),
          //user_id:this.$jichuxinxi().user_id
        },
      }).then((res) => {
        this.BusinessPersonlList = JSON.parse(res.data.body.data);
      });
    },
    //获取期初余额
    get_amt_data(i, index) {
      console.log("1233333333333333333333");
      if (i.defaultLable != "客户") return;
      const NewDate = new Date();
      const [Years, Month, Day] = [
        NewDate.getFullYear(),
        NewDate.getMonth() + 1,
        NewDate.getDate(),
      ];
      console.log(Years, Month, Day);
      const param = {
        ent_id: this.$ent_id(),
        // date_topup:`${Years}-${this.$func.pa(Month)}-${this.$func.pa(Day)}`,
        cust_id: i.kehu_list[i.zhanshi].cust_id,
      };
      console.log(param);
      let myindex = null;
      this.list.filter((item, index) => {
        if (item.defaultLable == "期初余额") {
          myindex = index;
        }
      });
      console.log(param, "这是期初传的值");
      get_amt_end_of_cust({ data: param }).then((res) => {
        console.log(res, "获取的值");
        console.log(JSON.parse(res.data.body.data));
        this.vip.amt_bgn = JSON.parse(res.data.body.data).amt_end;
        this.$set(this.list[myindex], "value", JSON.parse(res.data.body.data).amt_end);
      });
    },
    gongying_shang_bianji() {
      if (
        this.zhuangtai_shiyong_name == "已分配_编辑" &&
        this.date.flow_id == "5FotYgnu0Z"
      ) {
        const date = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_5", "暂时");
        if (
          date.shuxing.staff_level == 1 ||
          date.shuxing.staff_level == 2 ||
          date.shuxing.dept_manager == 1
        ) {
          this.gongying_shang_xiugai_fuze_ren = true;
        } else {
          this.gongying_shang_xiugai_fuze_ren = false;
        }
      }
    },
    chushi_chaxun() {
      // 查询他有没有代写权限
      query_form_replace_write({
        data: {
          ent_id: this.$ent_id(),
        },
      }).then((res) => {
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data);
          const _this = this;

          if (date != undefined && date != null && date.length != 0) {
            date.forEach((item) => {
              if (item.staff_id == _this.$jichuxinxi().user_id) {
                _this.shifou_have_daixie = true;
                this.daixie_fanwei();
              }
            });
          } else {
            this.shifou_have_daixie = false;
          }
        } else if (res.data.code == 500) {
        }
      });
    },
    // 查询当前代写范围
    daixie_fanwei() {
      const id = this.date.flow_id;
      if (
        id != "7TvJHPyovM" &&
        id != "Z7VDOK9HGu" &&
        id != "453y6pLD52" &&
        id != "PLOQ9ku3Ip" &&
        id != "Eb3n7CKQzi" &&
        id != "qIwxmsMTgv" &&
        id != "VMCizuVOXb" &&
        id != "He5ln0bdDO" &&
        id != "cvRyWGiUQH" &&
        id != "GBh7N2jMsi" &&
        id != "5FotYgnu0Z" &&
        id != "vY9ubhTO89" &&
        id != "u1SCMMoUwK" &&
        id != "Xsk2pLBS9a" &&
        id != "fZ0x5qedzn" &&
        id != "hXstgwxhzh" &&
        id != "H49d4edc3T"
      ) {
        query_cust_form_detail({
          data: {
            ent_id: this.$ent_id(),
            flow_id: id,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data);
            let three = "";
            if (date.flow_set != null) {
              three = JSON.parse(date.flow_set);
            }
            if (three.flowPermission != undefined) {
              this.daixuan_fanwei = JSON.parse(JSON.stringify(three.flowPermission));
              this.daixuan_fanwei_all = JSON.parse(JSON.stringify(three.flowPermission));
            }
          } else if (res.data.code == 500) {
          }
        });
      } else {
        query_flow_info_all({
          data: {
            ent_id: this.$ent_id(),
            flow_id: id,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            let three = "";
            if (res.data.body.action_str != null) {
              three = JSON.parse(res.data.body.action_str);
            }
            // let two_zhi=JSON.parse(two.form_str)
            let three_zhi = "";
            if (three != null && three.length != 0) {
              three_zhi = JSON.parse(three.action_str);
              if (three_zhi.flowPermission != undefined) {
                this.daixuan_fanwei = JSON.parse(
                  JSON.stringify(three_zhi.flowPermission)
                );
                this.daixuan_fanwei_all = JSON.parse(
                  JSON.stringify(three_zhi.flowPermission)
                );
              }
            }
          } else if (res.data.code == 500) {
          }
        });
      }
    },
    panduan_dangqian_tianxie_ren() {
      if (
        this.zhuangtai_shiyong_name == "看板_编辑" ||
        this.zhuangtai_shiyong_name == "看板" ||
        this.zhuangtai_shiyong_name == "已分配_编辑"
      ) {
        const date = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_4", "暂时");
        const obj = {
          name: date.staff_name,
          user_id: date.staff_id,
        };
        if (date.staff_id == this.$jichuxinxi().user_id) {
          this.dai_taren_tijiao = false;
        } else {
          this.dai_taren_tijiao = true;
        }
        this.jichuxinxi = obj;
      } else {
        this.jichuxinxi = this.$jichuxinxi();
      }
    },
    dianji_ceshi(index, rows) {},
    // 表头颜色
    headerCellStyle(row) {
      // 根据报警级别显示颜色
      return "text-align:center;color:#1A2533;width:2.5rem;";
    },
    // 放大图片
    tupian_dianji(img) {
      this.$refs.bigphote.jichu(img);
    },
    //   基础文字
    jichu() {
      this.date = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan", "暂时");

      //这里取出了存储在seeionstore里的全部数据了
      this.shifou_you_gongdan();
      //删除了某某某
      const data = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_2", "暂时");
      const _this = this;
      if (data) {
        data.forEach((item) => {
          if (item.componentName == "tablefield") {
            _this.$set(_this.biaotou_list, item.idx, []);
            if (item.components) {
              item.components.forEach((ite) => {
                if (ite.componentName == "textnote") {
                  _this.biaotou_list[item.idx].push({
                    name: ite.defaultProps,
                    con: ite.idx,
                    componentName: ite.componentName,
                  });
                } else {
                  _this.biaotou_list[item.idx].push({
                    name: ite.defaultLable,
                    con: ite.idx,
                    componentName: ite.componentName,
                  });
                }
              });
              console.log(' _this.biaotou_list', _this.biaotou_list)
              _this.biaotou_list[item.idx].push({
                name: "操作",
                con: "000000000",
                componentName: "000000000",
              });
            }
          }
        });
      }

      // 下面没动   增加上面表格头部

      this.list = [];
      // query_dept_list_of_staff({data:{
      //     ent_id:this.$ent_id(),
      //     staff_id:this.$jichuxinxi().user_id
      // }}).then(res=>{

      //     if(res.data.code==200){
      //         let listdate=JSON.parse(res.data.body.data)
      //         this.liucheng_bumen_list=listdate
      //     }else if(res.data.code==500){}
      // })
      if (
        this.zhuangtai_shiyong_name != "已分配_编辑" &&
        this.zhuangtai_shiyong_name != "公海池_编辑" &&
        this.zhuangtai_shiyong_name != "看板_编辑" &&
        this.zhuangtai_shiyong_name != "作废" &&
        this.zhuangtai_shiyong_name != "再次提交"
      ) {
        for (let i = 0; i < data.length; i++) {
          if (
            this.zhuangtai_shiyong_name == "看板" &&
            (data[i].defaultLable == "客户" ||
              data[i].defaultLable == "归档号" ||
              data[i].defaultLable == "供应商" ||
              data[i].defaultLable == "供应商名称")
          ) {
          } else {
            data[i].value = "";
            (data[i].teshu = false), (data[i].zhanshi = "0");
            data[i].dangqian = 0;
            data[i].value_id = 0;
            data[i].value_ids = "";
          }
          if (data[i].componentName == "ddselectfield") {
            data[i].value = "0";
            data[i].teshu = false;
            data[i].value_id = "";
          }
          // fZ0x5qedzn  入库单
          if (
            data[i].componentName == "suppliername" &&
            this.date.flow_id != "5FotYgnu0Z" &&
            this.zhuangtai_shiyong_name != "看板"
          ) {
            data[i].value_id = "";
            if (this.date.flow_id == "fZ0x5qedzn") {
              query_supply_list({
                //查询供应商list
                data: {
                  ent_id: this.$ent_id(),
                },
              }).then((res) => {
                if (res.data.code == 200) {
                  const date = JSON.parse(res.data.body.data);
                  this.$set(data[i], "option", date);
                } else if (res.data.code == 500) {
                  alert("查询供应商list报错");
                }
              });
            } else {
              query_supply_list_of_power({
                data: {
                  ent_id: this.$ent_id(),
                  user_id: this.$jichuxinxi().user_id,
                  // search_str:val.text.length!=0?val.text:null,
                },
              }).then((res) => {
                if (res.data.code == 200) {
                  const date = JSON.parse(res.data.body.data);
                  this.$set(data[i], "option", date);
                } else if (res.data.code == 500) {
                  alert("查询供应商list报错");
                }
              });
            }
          }
          if (data[i].componentName == "ddattachment") {
            this.$set(data[i], "list", []);
          }
          if (data[i].componentName == "product") {
            this.$set(data[i], "chanpin_list", {});
          }
          if (data[i].componentName == "ddmultiselectfield") {
            data[i].value = "";
            data[i].teshu = false;
            data[i].value_id = "";
            data[i].defaultOptions.forEach((duoxuan) => {
              this.$set(duoxuan, "zhi", false);
            });
          }
          if (data[i].componentName == "assappform") {
            data[i].value = "";
            data[i].value_obj = {};
            data[i].zhanshi = false;
          }
          // &&this.zhuangtai_shiyong_name!='公海池'
          if (data[i].componentName == "contacts" && data[i].defaultLable != "派工人员") {
            data[i].value = this.jichuxinxi.name;
          }

          // 客户录入单，供应商录入单，采购单，采购发票单，付款单
          if (
            this.date.flow_id == "7TvJHPyovM" ||
            this.date.flow_id == "5FotYgnu0Z" ||
            this.date.flow_id == "Xsk2pLBS9a" ||
            this.date.flow_id == "vY9ubhTO89" ||
            this.date.flow_id == "fZ0x5qedzn" ||
            this.date.flow_id == "hXstgwxhzh" ||
            this.date.flow_id == "u1SCMMoUwK"
          ) {
            if (
              data[i].componentName == "department" &&
              (this.date.flow_id != "7TvJHPyovM" ||
                this.zhuangtai_shiyong_name == "已分配" ||
                this.zhuangtai_shiyong_name == "审批中心" ||
                this.zhuangtai_shiyong_name == "已分配_编辑")
            ) {
              query_staff_dept_list({
                data: {
                  ent_id: this.$ent_id(),
                  staff_id: this.jichuxinxi.user_id,
                },
              }).then((res) => {
                if (res.data.code == 200) {
                  const date = JSON.parse(res.data.body.data);
                  let bumen_list = [];
                  if (
                    this.date.flow_id == "5FotYgnu0Z" &&
                    this.zhuangtai_shiyong_name == "已分配_编辑"
                  ) {
                    date.forEach((item) => {
                      if (item.dept_id == this.yi_fenpei_bianji.dept_id) {
                        bumen_list.push(item);
                      }
                    });
                  } else {
                    bumen_list = date;
                  }
                  bumen_list = this.$func.Es5duplicate(bumen_list, "dept_id");
                  this.$set(data[i], "option", bumen_list);
                  // data[i]['option']=date
                  // data[i]['dept_id']=''
                  // if(date.length!=0){
                  //     date.map(item=>{item['zhi']=false})
                  // }
                  // this.bumen_list=date
                } else if (res.data.code == 500) {
                }
              });
            }
          }
          if (data[i].componentName == "cust") {
            if (data[i].defaultImportant) {
              this.kehu_shifou_bitian = true;
            }
          }
          if (data[i].componentName == "tablefield") {
            data[i].value = 1;
            for (let a = 0; a < data[i].components.length; a++) {
              data[i].components[a].dangqian = 0;
              data[i].components[a].value = "";
              data[i].components[a].value_id = "";
              (data[i].components[a].teshu = false),
                (data[i].components[a].zhanshi = "0");
              if (data[i].components[a].componentName == "ddselectfield") {
                data[i].components[a].value = "0";
                data[i].components[a].teshu = false;
              }
              if (
                data[i].components[a].componentName == "dddaterangefield" &&
                data[i].defaultLable == "行程"
              ) {
                data[i].components[a].value = new Date(new Date().toLocaleDateString());
                data[i].components[a].value_id = new Date(
                  new Date().toLocaleDateString()
                );
                data[i].components[a].value_start = 0.5;
                data[i].components[a].value_id_end = 0.5;
                data[i].components[a].day = 0.5;
              }
              if (
                data[i].components[a].componentName == "dddaterangefield" &&
                data[i].defaultLable != "行程"
              ) {
                data[i].components[a].value = "";
                data[i].components[a].value_id = "";
              }
              if (data[i].components[a].componentName == "ddphotofield") {
                data[i].components[a].imgBase64 = [];
                data[i].components[a].lei = "";
                data[i].components[a].xin = "";
              }
            }
            // data[i].components.unshift({
            //     componentName:'title',
            //     defaultLable:data[i].defaultLable+'(1)'
            // })
            this.$set(data[i], "components_list", data[i].components);
            data[i].components_list = [];
            data[i].components_list.push(data[i].components);
          }
          if (data[i].componentName == "ddphotofield") {
            data[i].imgBase64 = [];
            data[i].lei = "";
            data[i].xin = "";
          }
          this.list.push(data[i]);
        }
        if (this.zhuangtai_shiyong_name == "看板") {
          this.list.forEach((item) => {
            if (item.defaultLable == "客户" && item.componentName == "textfield") {
              this.xuanze_kehu(item);
            }
            if (item.componentName == "suppliername") {
              this.xuanze_gongying_shang(item);
            }
          });
        }
      } else {
        const xiangqing = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_4", "暂时");
        this.yi_fenpei_bianji = xiangqing;
        this.jiu_kehu_name = xiangqing.cust_name;

        for (let i = 0; i < data.length; i++) {
          if (
            this.zhuangtai_shiyong_name == "已分配_编辑" ||
            this.zhuangtai_shiyong_name == "公海池_编辑" ||
            this.zhuangtai_shiyong_name == "看板_编辑" ||
            this.zhuangtai_shiyong_name == "作废"
          ) {
            (data[i].teshu = false), (data[i].dangqian = 0);
          }
          if (this.zhuangtai_shiyong_name == "作废") {
            this.zheng_fan = false;
          }
          if (
            (data[i].componentName == "ddselectfield" ||
              data[i].componentName == "ddmultiselectfield") &&
            data[i].defaultLable != "地区" &&
            data[i].defaultLable != "地址" &&
            data[i].defaultLable != "行业" &&
            data[i].defaultLable != "客户来源" &&
            data[i].defaultLable != "客户等级" &&
            data[i].defaultLable != "客户标签" &&
            data[i].defaultLable != "供应商标签" &&
            data[i].defaultLable != "漏斗阶段" &&
            data[i].defaultLable != "合同类型" &&
            data[i].defaultLable != "发票类型" &&
            data[i].defaultLable != "供应商来源" &&
            data[i].defaultLable != "供应商等级" &&
            data[i].defaultLable != "所属公司"
          ) {
            data[i].value = "0";
            data[i].teshu = false;
            data[i].value_id = "";
          }
          // if(data[i].componentName == 'department' && this.zhuangtai_shiyong_name == '作废' &&data[i].defaultLable == '部门'){
          //     data[i].dept_id = '123'
          // }
          if (data[i].componentName == "cust") {
            if (data[i].defaultImportant) {
              this.kehu_shifou_bitian = true;
            }
          }
          if (data[i].componentName == "ddmultiselectfield") {
            data[i].defaultOptions.forEach((duoxuan) => {
              this.$set(duoxuan, "zhi", false);
            });
          }
          if (data[i].componentName == "product") {
            this.$set(data[i], "chanpin_list", {});
          }
          if (data[i].componentName == "ddattachment") {
            this.$set(data[i], "list", []);
          }
          if (
            data[i].componentName == "ddselectfield" &&
            data[i].defaultLable == "地区"
          ) {
            this.temp2.addressprovince = data[i].value;
            this.temp2.addresscity = data[i].value_id;
            this.temp2.addarea = data[i].value_ids;
          }
          // 1600333821889  付款单里的供应商idx
          if (data[i].componentName == "suppliername" && data[i].idx == "1600333821889") {
            this.xuanze_gongying_shang(data[i]);
          }
          // 1600333837481  付款单里的部门idx
          if (data[i].componentName == "department" && data[i].idx == "1600333837481") {
            this.liucheng_bumen_list = data[i].option;
            this.suoyou_bumen = data[i].option;
          }
          // 1600414886115  入库单里的部门idx
          if (data[i].componentName == "department" && data[i].idx == "1600414886115") {
            this.liucheng_bumen_list = data[i].option;
            this.suoyou_bumen = data[i].option;
          }
          // 1600331670479  采购发票单里的部门idx
          if (data[i].componentName == "department" && data[i].idx == "1600331670479") {
            this.liucheng_bumen_list = data[i].option;
            this.suoyou_bumen = data[i].option;
          }
          if (
            data[i].componentName == "ddselectfield" &&
            data[i].defaultLable == "地址"
          ) {
            this.temp2.addressprovince = data[i].value;
            this.temp2.addresscity = data[i].value_id;
            this.temp2.addarea = data[i].value_ids;
          }
          if (data[i].componentName == "calcform") {
            this.$set(data[i], "value", "");
          }
          if (data[i].componentName == "moneyfield" && data[i].value == undefined) {
            this.$set(data[i], "value", "");
          }
          if (
            data[i].componentName == "moneyfield" &&
            data[i].defaultLable == "累计发票金额"
          ) {
            let file_no = "";
            for (let it = 0; it < this.list.length; it++) {
              if (this.list[it].defaultLable == "归档号") {
                file_no = this.list[it].value;
              }
            }
            query_con_amt_and_inv_amt_sum({
              data: {
                ent_id: this.$ent_id(),
                file_no: file_no,
              },
            }).then((ras) => {
              if (ras.data.code == 200) {
                const datb = JSON.parse(ras.data.body.data);
                data[i].hetong_jine = datb.amt_con;
                this.jisuan();
              } else if (ras.data.code == 500) {
              }
            });
          }
          if (
            data[i].componentName == "dddatefield" &&
            data[i].defaultLable == "预计合同日期"
          ) {
            data[i].value =
              xiangqing.date_con_est != undefined ? new Date(xiangqing.date_con_est) : "";
          }
          // 1600333806050付款单的付款日期
          if (data[i].componentName == "dddatefield" && data[i].idx == "1600333806050") {
            data[i].value =
              xiangqing.operate_time != undefined ? new Date(xiangqing.operate_time) : "";
          }
          if (
            data[i].componentName == "dddatefield" &&
            data[i].defaultLable == "合同日期"
          ) {
            data[i].value =
              xiangqing.date_con != undefined ? new Date(xiangqing.date_con) : "";
          }
          if (
            data[i].componentName == "dddatefield" &&
            data[i].defaultLable == "发票日期"
          ) {
            data[i].value =
              xiangqing.date_inv != undefined ? new Date(xiangqing.date_inv) : "";
          }
          // 1600331674735采购发票单的发票日期
          if (data[i].componentName == "dddatefield" && data[i].idx == "1600331674735") {
            data[i].value =
              xiangqing.date_pay != undefined ? new Date(xiangqing.date_pay) : "";
          }
          // 1600414892081入库单的入库日期
          if (data[i].componentName == "dddatefield" && data[i].idx == "1600414892081") {
            data[i].value =
              xiangqing.date_in_store != undefined
                ? new Date(xiangqing.date_in_store)
                : "";
          }
          if (
            data[i].componentName == "dddatefield" &&
            data[i].defaultLable == "回款日期"
          ) {
            data[i].value =
              xiangqing.date_rec != undefined ? new Date(xiangqing.date_rec) : "";
          }
          if (data[i].componentName == "dddatefield" && data[i].defaultLable == "日期") {
            data[i].value =
              xiangqing.date_deliver != undefined ? new Date(xiangqing.date_deliver) : "";
          }
          if (
            data[i].componentName == "dddaterangefield" &&
            data[i].defaultLable == "合同有效时间"
          ) {
            if (xiangqing.com_valid_term != undefined) {
              const idx = xiangqing.com_valid_term.lastIndexOf("至");
              const one = new Date(
                xiangqing.com_valid_term.substring(xiangqing.com_valid_term, idx)
              );
              const two = new Date(xiangqing.com_valid_term.substring(idx + 1));
              const value = [];
              value.push(one);
              value.push(two);
              data[i].value = value;
            } else {
              data[i].value = "";
            }
          }
          if (data[i].componentName == "assappform") {
            data[i].value = "";
            data[i].value_obj = {};
            data[i].zhanshi = false;
          }
          // &&this.zhuangtai_shiyong_name!='公海池'
          if (data[i].componentName == "contacts" && data[i].defaultLable != "派工人员") {
            data[i].value = this.jichuxinxi.name;
          }

          if (
            data[i].componentName == "department" &&
            this.zhuangtai_shiyong_name == "已分配_编辑"
          ) {
            query_staff_dept_list({
              data: {
                ent_id: this.$ent_id(),
                staff_id: this.jichuxinxi.user_id,
              },
            }).then((res) => {
              if (res.data.code == 200) {
                const date = JSON.parse(res.data.body.data);
                let bumen_list = [];
                if (
                  this.date.flow_id == "5FotYgnu0Z" &&
                  this.zhuangtai_shiyong_name == "已分配_编辑"
                ) {
                  date.forEach((item) => {
                    if (item.dept_id == this.yi_fenpei_bianji.dept_id) {
                      bumen_list.push(item);
                    }
                  });
                } else {
                  bumen_list = date;
                }
                bumen_list = this.$func.Es5duplicate(bumen_list, "dept_id");
                this.$set(data[i], "option", bumen_list);
                // data[i]['option']=date
                // data[i]['dept_id']=''
                // if(date.length!=0){
                //     date.map(item=>{item['zhi']=false})
                // }
                // this.bumen_list=date
              } else if (res.data.code == 500) {
              }
            });
          }
          if (data[i].componentName == "tablefield") {
            data[i].value = 1;
            for (let a = 0; a < data[i].components.length; a++) {
              data[i].components[a].dangqian = 0;
              data[i].components[a].value = "";
              data[i].components[a].value_id = "";
              (data[i].components[a].teshu = false),
                (data[i].components[a].zhanshi = "0");
              if (data[i].components[a].componentName == "ddselectfield") {
                data[i].components[a].value = "0";
                data[i].components[a].teshu = false;
              }
              if (
                data[i].components[a].componentName == "dddaterangefield" &&
                data[i].defaultLable == "行程"
              ) {
                data[i].components[a].value = new Date(new Date().toLocaleDateString());
                data[i].components[a].value_id = new Date(
                  new Date().toLocaleDateString()
                );
                data[i].components[a].value_start = 0.5;
                data[i].components[a].value_id_end = 0.5;
                data[i].components[a].day = 0.5;
              }
              if (
                data[i].components[a].componentName == "dddaterangefield" &&
                data[i].defaultLable != "行程"
              ) {
                data[i].components[a].value = "";
                data[i].components[a].value_id = "";
              }
              if (data[i].components[a].componentName == "calcform") {
                this.$set(data[i].components[a], "value", "");
              }
              if (data[i].components[a].componentName == "ddphotofield") {
                data[i].components[a].imgBase64 = [];
                data[i].components[a].lei = "";
                data[i].components[a].xin = "";
              }
            }
            // data[i].components.unshift({
            //     componentName:'title',
            //     defaultLable:data[i].defaultLable+'(1)'
            // })
            this.$set(data[i], "components_list", data[i].components);
            data[i].components_list = [];
            data[i].components_list.push(data[i].components);
          }
          if (data[i].componentName == "ddphotofield") {
            data[i].imgBase64 = [];
            data[i].lei = "";
            data[i].xin = "";
          }
          if (
            data[i].componentName == "department" &&
            this.zhuangtai_shiyong_name == "作废"
          ) {
            data[i].dept_id == "123444444414444444499";
          }
          this.list.push(data[i]);
        }
        const zidingyi_kongjian =
          xiangqing.extra != undefined ? JSON.parse(xiangqing.extra) : [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].siyou == "22") {
            zidingyi_kongjian.forEach((zdy) => {
              if (zdy.type == "ddselectfield" && data[i].idx == zdy.idx) {
                data[i].defaultOptions.forEach((ite, ind) => {
                  if (ite.text == zdy.value) {
                    this.$set(data[i], "value", ind);
                    this.$set(data[i], "teshu", false);
                  }
                });
                if (data[i].value == undefined) {
                  this.$set(data[i], "value", "0");
                  this.$set(data[i], "teshu", false);
                }
              } else if (zdy.type == "ddmultiselectfield" && data[i].idx == zdy.idx) {
                const duoxuan_list =
                  zdy.value != undefined && zdy.value.length != 0
                    ? zdy.value.split(",")
                    : [];
                data[i].defaultOptions.forEach((ite, ind) => {
                  duoxuan_list.forEach((it) => {
                    if (ite.text == it) {
                      this.$set(ite, "zhi", true);
                    }
                  });
                });
              } else if (data[i].idx == zdy.idx && zdy.type == "dddatefield") {
                this.$set(
                  data[i],
                  "value",
                  zdy.value != undefined && zdy.value.length != 0
                    ? new Date(zdy.value)
                    : ""
                );
                //////console.log(data[i])
              } else if (zdy.type == "dddaterangefield" && data[i].idx == zdy.idx) {
                if (zdy.value != undefined && zdy.value.length != 0) {
                  const idx = zdy.value.lastIndexOf("至");
                  const one = new Date(zdy.value.substring(zdy.value, idx));
                  const two = new Date(zdy.value.substring(idx + 1));
                  const sj_qj = [];
                  sj_qj[0] = one;
                  sj_qj[1] = two;
                  setTimeout(() => {
                    this.$set(data[i], "value", sj_qj);
                    //////console.log(sj_qj)
                  }, 0);
                } else {
                  data[i].value = "";
                }
              } else if (zdy.type == "province" && data[i].idx == zdy.idx) {
                this.$set(data[i], "value", zdy.value_id);
                this.$set(data[i], "value_id", zdy.value_ids);
                this.$set(data[i], "value_ids", zdy.value_idss);
              } else if (zdy.type == "cust" && data[i].idx == zdy.idx) {
                this.$set(data[i], "value", zdy.value);
                this.$set(data[i], "kehu_list", zdy.liebiao);
                this.$set(data[i], "zhanshi", 0);
                if (data[i].defaultImportant) {
                  //////console.log(zdy.liebiao[0])
                  this.kehu_shifou_bitian = true;
                  this.zaici_tijiao_dept_id =
                    zdy.liebiao != undefined && zdy.liebiao.length != 0
                      ? zdy.liebiao[0].dept_id
                      : "";
                }
              } else if (zdy.type == "department" && data[i].idx == zdy.idx) {
                this.$set(data[i], "dept_id", zdy.value_ids);
              } else if (zdy.type == "ddphotofield" && data[i].idx == zdy.idx) {
                if (zdy.img_url != undefined && zdy.img_url.length != 0) {
                  const lei = zdy.img_url.substring(zdy.img_url.lastIndexOf("."));
                  data[i].lei = lei;
                  var http = require("https");
                  var url = zdy.img_url; // 一张网络图片
                  http.get(url, function (res) {
                    var chunks = [];
                    var size = 0;
                    res.on("data", function (chunk) {
                      chunks.push(chunk);
                      size += chunk.length; // 累加缓冲数据的长度
                    });
                    res.on("end", function (err) {
                      var data = Buffer.concat(chunks, size);
                      var base64Img = data.toString("base64");
                      data[i].xin = `${base64Img}`;
                    });
                  });
                  data[i].imgBase64.push(zdy.img_url);
                }
              } else if (zdy.type == "textnote" && data[i].idx == zdy.idx) {
                data[i].value = zdy.value;
              } else if (zdy.type == "product" && data[i].idx == zdy.idx) {
                data[i].value = zdy.value;
                data[i].chanpin_list = zdy.liebiao[0];
              } else if (zdy.type == "tablefield" && zdy.idx == data[i].idx) {
                for (let b = 0; b < zdy.mingxi_list.length - 1; b++) {
                  this.tianjia_mingxi(data[i], 1);
                }
                const shuzu = data[i].components_list;
                for (let dangqian = 0; dangqian < zdy.mingxi_list.length; dangqian++) {
                  for (let b = 0; b < shuzu[dangqian].length; b++) {
                    zdy.mingxi_list[dangqian].mingxi_list.forEach((mx) => {
                      //////console.log(mx)
                      if (shuzu[dangqian][b].idx == mx.idx) {
                        if (mx.type == "ddselectfield") {
                          this.$set(shuzu[dangqian][b], "value", mx.value_ids);
                          this.$set(shuzu[dangqian][b], "teshu", false);
                        } else if (mx.type == "serialnum") {
                          this.$set(shuzu[dangqian][b], "value", mx.value);
                        } else if (mx.type == "ddmultiselectfield") {
                          const duoxuan_list =
                            mx.value != undefined && mx.value.length != 0
                              ? mx.value.split(",")
                              : [];
                          shuzu[dangqian][b].defaultOptions.forEach((ite, ind) => {
                            duoxuan_list.forEach((it) => {
                              if (ite.text == it) {
                                this.$set(ite, "zhi", true);
                              }
                            });
                          });
                        } else if (mx.type == "dddatefield") {
                          this.$set(
                            shuzu[dangqian][b],
                            "value",
                            mx.value != undefined && mx.value.length != 0
                              ? new Date(mx.value)
                              : ""
                          );
                        } else if (mx.type == "dddaterangefield") {
                          if (mx.value != undefined && mx.value.length != 0) {
                            const idx = mx.value.lastIndexOf("至");
                            const one = new Date(mx.value.substring(mx.value, idx));
                            const two = new Date(mx.value.substring(idx + 1));
                            const sj_qj = [];
                            sj_qj[0] = one;
                            sj_qj[1] = two;
                            setTimeout(() => {
                              this.$set(shuzu[dangqian][b], "value", sj_qj);
                              //////console.log(sj_qj)
                            }, 0);
                          } else {
                            shuzu[dangqian][b].value = "";
                          }
                        } else if (mx.type == "ddphotofield") {
                          if (mx.img_url != undefined && mx.img_url.length != 0) {
                            const lei = mx.img_url.substring(mx.img_url.lastIndexOf("."));
                            shuzu[dangqian][b].lei = lei;
                            var http = require("https");

                            var url = mx.img_url; // 一张网络图片

                            http.get(url, function (res) {
                              var chunks = [];
                              var size = 0;
                              res.on("data", function (chunk) {
                                chunks.push(chunk);
                                size += chunk.length; // 累加缓冲数据的长度
                              });
                              res.on("end", function (err) {
                                var dataa = Buffer.concat(chunks, size);
                                var base64Img = dataa.toString("base64");
                                dataa[i].xin = `${base64Img}`;
                              });
                            });
                            shuzu[dangqian][b].imgBase64.push(mx.img_url);
                          }
                        } else if (mx.type == "province") {
                          this.$set(shuzu[dangqian][b], "value", mx.value_id);
                          this.$set(shuzu[dangqian][b], "value_id", mx.value_ids);
                          this.$set(shuzu[dangqian][b], "value_ids", mx.value_idss);
                        } else if (mx.type == "product") {
                          shuzu[dangqian][b].chanpin_list = mx.liebiao[0];
                          shuzu[dangqian][b].value = mx.value;
                        } else {
                          shuzu[dangqian][b].value = mx.value;
                        }
                      } else if (
                        mx.type == "textnote" &&
                        shuzu[dangqian][b].idx == mx.idx
                      ) {
                        shuzu[dangqian][b].value = mx.value;
                      }
                    });
                  }
                }
              } else if (data[i].idx == zdy.idx) {
                data[i].value = zdy.value;
              }
            });
          }

          if (data[i].componentName == "tablefield" && data[i].defaultLable == "决策人") {
            for (let b = 0; b < xiangqing.contract_detail.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (
              let dangqian = 0;
              dangqian < xiangqing.contract_detail.length;
              dangqian++
            ) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "姓名"
                ) {
                  shuzu[dangqian][b].value = xiangqing.contract_detail[dangqian].name;
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "部门"
                ) {
                  shuzu[dangqian][b].value = xiangqing.contract_detail[dangqian].dept;
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "职务"
                ) {
                  shuzu[dangqian][b].value = xiangqing.contract_detail[dangqian].post;
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "微信"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.contract_detail[dangqian].wechat != undefined
                      ? xiangqing.contract_detail[dangqian].wechat
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "邮箱"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.contract_detail[dangqian].email != undefined
                      ? xiangqing.contract_detail[dangqian].email
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "座机"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.contract_detail[dangqian].fixed_phone != undefined
                      ? xiangqing.contract_detail[dangqian].fixed_phone
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "手机"
                ) {
                  shuzu[dangqian][b].value = xiangqing.contract_detail[dangqian].mobile;
                }
                if (
                  shuzu[dangqian][b].componentName == "ddselectfield" &&
                  shuzu[dangqian][b].defaultLable == "性别"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.contract_detail[dangqian].sex == "男" ? "0" : "1";
                }
                if (
                  shuzu[dangqian][b].componentName == "dddatefield" &&
                  shuzu[dangqian][b].defaultLable == "生日"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.contract_detail[dangqian].birthday != undefined
                      ? new Date(xiangqing.contract_detail[dangqian].birthday)
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "textnote" &&
                  shuzu[dangqian][b].defaultProps == "爱好"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.contract_detail[dangqian].interest != undefined
                      ? xiangqing.contract_detail[dangqian].interest
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "QQ"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.contract_detail[dangqian].qq != undefined
                      ? xiangqing.contract_detail[dangqian].qq
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "ddphotofield" &&
                  shuzu[dangqian][b].defaultLable == "名片"
                ) {
                  // this.getImgBasea(xiangqing.contract_detail[dangqian].card)
                  if (xiangqing.contract_detail[dangqian].card != undefined) {
                    //////console.log(xiangqing.contract_detail[dangqian])
                    const lei = xiangqing.contract_detail[dangqian].card.substring(
                      xiangqing.contract_detail[dangqian].card.lastIndexOf(".")
                    );
                    shuzu[dangqian][b].lei = lei;
                    var http = require("https");

                    var url = xiangqing.contract_detail[dangqian].card; // 一张网络图片

                    http.get(url, function (res) {
                      var chunks = [];
                      var size = 0;
                      res.on("data", function (chunk) {
                        chunks.push(chunk);
                        size += chunk.length; // 累加缓冲数据的长度
                      });
                      res.on("end", function (err) {
                        var data = Buffer.concat(chunks, size);
                        var base64Img = data.toString("base64");
                        shuzu[dangqian][b].xin = `${base64Img}`;
                      });
                    });
                    shuzu[dangqian][b].imgBase64.push(
                      xiangqing.contract_detail[dangqian].card
                    );
                  }
                }
              }
            }
          }
          if (
            data[i].componentName == "tablefield" &&
            data[i].defaultLable == "业务联系人"
          ) {
            for (let b = 0; b < xiangqing.supply_detail.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (
              let dangqian = 0;
              dangqian < xiangqing.supply_detail.length;
              dangqian++
            ) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "姓名"
                ) {
                  shuzu[dangqian][b].value = xiangqing.supply_detail[dangqian].name;
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "部门"
                ) {
                  shuzu[dangqian][b].value = xiangqing.supply_detail[dangqian].dept;
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "职务"
                ) {
                  shuzu[dangqian][b].value = xiangqing.supply_detail[dangqian].post;
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "微信"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.supply_detail[dangqian].wechat != undefined
                      ? xiangqing.supply_detail[dangqian].wechat
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "邮箱"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.supply_detail[dangqian].email != undefined
                      ? xiangqing.supply_detail[dangqian].email
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "座机"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.supply_detail[dangqian].fixed_phone != undefined
                      ? xiangqing.supply_detail[dangqian].fixed_phone
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "手机"
                ) {
                  shuzu[dangqian][b].value = xiangqing.supply_detail[dangqian].mobile;
                }
                if (
                  shuzu[dangqian][b].componentName == "ddselectfield" &&
                  shuzu[dangqian][b].defaultLable == "性别"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.supply_detail[dangqian].sex == "男" ? "0" : "1";
                }
                if (
                  shuzu[dangqian][b].componentName == "dddatefield" &&
                  shuzu[dangqian][b].defaultLable == "生日"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.supply_detail[dangqian].birthday != undefined
                      ? new Date(xiangqing.supply_detail[dangqian].birthday)
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "textnote" &&
                  shuzu[dangqian][b].defaultProps == "爱好"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.supply_detail[dangqian].interest != undefined
                      ? xiangqing.supply_detail[dangqian].interest
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "QQ"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.supply_detail[dangqian].qq != undefined
                      ? xiangqing.supply_detail[dangqian].qq
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "ddphotofield" &&
                  shuzu[dangqian][b].defaultLable == "名片"
                ) {
                  // this.getImgBasea(xiangqing.contract_detail[dangqian].card)
                  if (xiangqing.supply_detail[dangqian].card != undefined) {
                    //////console.log(xiangqing.supply_detail[dangqian])
                    const lei = xiangqing.supply_detail[dangqian].card.substring(
                      xiangqing.supply_detail[dangqian].card.lastIndexOf(".")
                    );
                    shuzu[dangqian][b].lei = lei;
                    var http = require("https");

                    var url = xiangqing.supply_detail[dangqian].card; // 一张网络图片

                    http.get(url, function (res) {
                      var chunks = [];
                      var size = 0;
                      res.on("data", function (chunk) {
                        chunks.push(chunk);
                        size += chunk.length; // 累加缓冲数据的长度
                      });
                      res.on("end", function (err) {
                        var data = Buffer.concat(chunks, size);
                        var base64Img = data.toString("base64");
                        shuzu[dangqian][b].xin = `${base64Img}`;
                      });
                    });
                    shuzu[dangqian][b].imgBase64.push(
                      xiangqing.supply_detail[dangqian].card
                    );
                  }
                }
              }
            }
          }
          if (
            data[i].componentName == "tablefield" &&
            data[i].defaultLable == "公司信息"
          ) {
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < 1; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "注册地址"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.register_addr != undefined ? xiangqing.register_addr : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "法定代表人"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.legal_name != undefined ? xiangqing.legal_name : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "纳税识别号"
                ) {
                  this.$set(
                    shuzu[dangqian][b],
                    "value",
                    xiangqing.uscc != undefined ? xiangqing.uscc : ""
                  );
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "注册资本"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.register_capital != undefined
                      ? xiangqing.register_capital
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "dddatefield" &&
                  shuzu[dangqian][b].defaultLable == "成立日期"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.register_date != undefined
                      ? new Date(xiangqing.register_date)
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "dddaterangefield" &&
                  shuzu[dangqian][b].defaultLable == "营业期限"
                ) {
                  //////console.log(xiangqing.biz_term)
                  if (xiangqing.biz_term != undefined) {
                    const idx = xiangqing.biz_term.lastIndexOf("至");
                    const one = new Date(
                      xiangqing.biz_term.substring(xiangqing.biz_term, idx)
                    );
                    const two = new Date(xiangqing.biz_term.substring(idx + 1));
                    const value = [];
                    value.push(one);
                    value.push(two);
                    shuzu[dangqian][b].value = value;
                  } else {
                    shuzu[dangqian][b].value = "";
                  }
                }
                if (
                  shuzu[dangqian][b].componentName == "textnote" &&
                  (shuzu[dangqian][b].defaultProps == "营业范围" ||
                    shuzu[dangqian][b].defaultProps == "经营范围")
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.biz_scope != undefined ? xiangqing.biz_scope : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "ddphotofield" &&
                  shuzu[dangqian][b].defaultLable == "营业执照"
                ) {
                  if (
                    xiangqing.biz_lic_pic != undefined &&
                    xiangqing.biz_lic_pic.length != 0
                  ) {
                    //////console.log(xiangqing.biz_lic_pic)
                    const lei = xiangqing.biz_lic_pic_type;
                    shuzu[dangqian][b].lei = lei;
                    var http = require("https");

                    var url = xiangqing.biz_lic_pic; // 一张网络图片

                    http.get(url, function (res) {
                      var chunks = [];
                      var size = 0;
                      res.on("data", function (chunk) {
                        chunks.push(chunk);
                        size += chunk.length; // 累加缓冲数据的长度
                      });
                      res.on("end", function (err) {
                        var data = Buffer.concat(chunks, size);
                        var base64Img = data.toString("base64");
                        shuzu[dangqian][b].xin = `${base64Img}`;
                      });
                    });
                    shuzu[dangqian][b].imgBase64.push(xiangqing.biz_lic_pic);
                  }
                }
              }
            }
          }
          if (
            data[i].componentName == "tablefield" &&
            data[i].defaultLable == "付款信息"
          ) {
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < 1; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "开户行名称"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.bank_name != undefined ? xiangqing.bank_name : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "银行账号"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.bank_acct != undefined ? xiangqing.bank_acct : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "ddphotofield" &&
                  shuzu[dangqian][b].defaultLable == "开户许可证"
                ) {
                  if (
                    xiangqing.open_acct_pic != undefined &&
                    xiangqing.open_acct_pic.length != 0
                  ) {
                    //////console.log(xiangqing.open_acct_pic)
                    const lei = xiangqing.open_acct_pic_type;
                    shuzu[dangqian][b].lei = lei;
                    var http = require("https");

                    var url = xiangqing.open_acct_pic; // 一张网络图片

                    http.get(url, function (res) {
                      var chunks = [];
                      var size = 0;
                      res.on("data", function (chunk) {
                        chunks.push(chunk);
                        size += chunk.length; // 累加缓冲数据的长度
                      });
                      res.on("end", function (err) {
                        var data = Buffer.concat(chunks, size);
                        var base64Img = data.toString("base64");
                        shuzu[dangqian][b].xin = `${base64Img}`;
                      });
                    });
                    shuzu[dangqian][b].imgBase64.push(xiangqing.open_acct_pic);
                  }
                }
              }
            }
          }
          if (
            data[i].componentName == "tablefield" &&
            data[i].defaultLable == "产品明细" &&
            data[i].idx == 4
          ) {
            for (let b = 0; b < xiangqing.prod_detail.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < xiangqing.prod_detail.length; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "product" &&
                  shuzu[dangqian][b].defaultLable == "产品"
                ) {
                  //////console.log(xiangqing.prod_detail[dangqian])
                  //////console.log(xiangqing.prod_detail[dangqian].prod_id)
                  shuzu[dangqian][b].chanpin_list = {};
                  shuzu[dangqian][b].chanpin_list.prod_id =
                    xiangqing.prod_detail[dangqian].prod_id;
                  shuzu[dangqian][b].chanpin_list.prod_name =
                    xiangqing.prod_detail[dangqian].prod_name;
                  shuzu[dangqian][b].chanpin_list.prod_code =
                    xiangqing.prod_detail[dangqian].prod_code;
                  shuzu[dangqian][b].chanpin_list.cat_name =
                    xiangqing.prod_detail[dangqian].cat_name;
                  shuzu[dangqian][b].chanpin_list.cat_id =
                    xiangqing.prod_detail[dangqian].cat_id;
                  shuzu[dangqian][b].chanpin_list.spec =
                    xiangqing.prod_detail[dangqian].spec;
                  shuzu[dangqian][b].chanpin_list.type =
                    xiangqing.prod_detail[dangqian].type;
                  shuzu[dangqian][b].chanpin_list.unit =
                    xiangqing.prod_detail[dangqian].unit;
                  shuzu[dangqian][b].chanpin_list.price_unit =
                    xiangqing.prod_detail[dangqian].price_unit;
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].prod_name;
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "数量"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_detail[dangqian].qty))
                      : xiangqing.prod_detail[dangqian].qty;
                }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "折扣率"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].disc_rate;
                }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "售价"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_detail[dangqian].price_sale))
                      : xiangqing.prod_detail[dangqian].price_sale;
                }
                // if(shuzu[dangqian][b].componentName=='textfield'&&shuzu[dangqian][b].defaultLable=='原价'){
                //     shuzu[dangqian][b].value=xiangqing.prod_detail[dangqian].price_sale
                // }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "原价"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].price_sale;
                }
                if (
                  shuzu[dangqian][b].componentName == "moneyfield" &&
                  shuzu[dangqian][b].defaultLable == "单价"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].price_unit;
                }
              }
            }
          }
          if (
            data[i].componentName == "tablefield" &&
            data[i].defaultLable == "产品" &&
            data[i].idx == 10
          ) {
            for (let b = 0; b < xiangqing.prod_list.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < xiangqing.prod_list.length; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "product" &&
                  shuzu[dangqian][b].defaultLable == "产品"
                ) {
                  shuzu[dangqian][b].chanpin_list = {};
                  shuzu[dangqian][b].chanpin_list.prod_id =
                    xiangqing.prod_list[dangqian].prod_id;
                  shuzu[dangqian][b].chanpin_list.prod_name =
                    xiangqing.prod_list[dangqian].prod_name;
                  shuzu[dangqian][b].chanpin_list.prod_code =
                    xiangqing.prod_list[dangqian].prod_code;
                  shuzu[dangqian][b].chanpin_list.cat_name =
                    xiangqing.prod_list[dangqian].cat_name;
                  shuzu[dangqian][b].chanpin_list.cat_id =
                    xiangqing.prod_list[dangqian].cat_id;
                  shuzu[dangqian][b].chanpin_list.spec =
                    xiangqing.prod_list[dangqian].spec;
                  shuzu[dangqian][b].chanpin_list.type =
                    xiangqing.prod_list[dangqian].type;
                  shuzu[dangqian][b].chanpin_list.unit =
                    xiangqing.prod_list[dangqian].unit;
                  shuzu[dangqian][b].chanpin_list.price_unit =
                    xiangqing.prod_list[dangqian].price_unit;
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].prod_name;
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "数量"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_list[dangqian].qty))
                      : xiangqing.prod_list[dangqian].qty;
                }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "折扣率"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].disc_rate;
                }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "售价"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_list[dangqian].price_sale))
                      : xiangqing.prod_list[dangqian].price_sale;
                }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "原价"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].price_orig;
                }
                if (
                  shuzu[dangqian][b].componentName == "moneyfield" &&
                  shuzu[dangqian][b].defaultLable == "单价"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].price_unit;
                }
              }
            }
          }
          if (
            data[i].componentName == "tablefield" &&
            data[i].defaultLable == "开票" &&
            data[i].idx == 18
          ) {
            for (let b = 0; b < xiangqing.amt_list.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < xiangqing.amt_list.length; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "moneyfield" &&
                  shuzu[dangqian][b].defaultLable == "预计发票金额"
                ) {
                  // shuzu[dangqian][b].value=xiangqing.amt_list[dangqian].amt_inv_est
                  this.$set(
                    shuzu[dangqian][b],
                    "value",
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.amt_list[dangqian].amt_inv_est))
                      : xiangqing.amt_list[dangqian].amt_inv_est
                  );
                }
                if (
                  shuzu[dangqian][b].componentName == "dddatefield" &&
                  shuzu[dangqian][b].defaultLable == "预计发票日期"
                ) {
                  shuzu[dangqian][b].value = new Date(
                    xiangqing.amt_list[dangqian].date_inv_est
                  );
                }
              }
            }
          }
          if (
            data[i].componentName == "tablefield" &&
            data[i].defaultLable == "发票内容" &&
            data[i].idx == 7
          ) {
            for (let b = 0; b < xiangqing.prod_list.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            //////console.log(xiangqing.prod_list)

            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            //////console.log(xiangqing)
            for (let dangqian = 0; dangqian < xiangqing.prod_list.length; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "product" &&
                  shuzu[dangqian][b].defaultLable == "产品"
                ) {
                  shuzu[dangqian][b].chanpin_list = {};
                  shuzu[dangqian][b].chanpin_list.prod_id =
                    xiangqing.prod_list[dangqian].prod_id;
                  shuzu[dangqian][b].chanpin_list.prod_name =
                    xiangqing.prod_list[dangqian].prod_name;
                  shuzu[dangqian][b].chanpin_list.prod_code =
                    xiangqing.prod_list[dangqian].prod_code;
                  shuzu[dangqian][b].chanpin_list.cat_name =
                    xiangqing.prod_list[dangqian].cat_name;
                  shuzu[dangqian][b].chanpin_list.cat_id =
                    xiangqing.prod_list[dangqian].cat_id;
                  shuzu[dangqian][b].chanpin_list.spec =
                    xiangqing.prod_list[dangqian].spec;
                  shuzu[dangqian][b].chanpin_list.type =
                    xiangqing.prod_list[dangqian].type;
                  shuzu[dangqian][b].chanpin_list.unit =
                    xiangqing.prod_list[dangqian].unit;
                  shuzu[dangqian][b].chanpin_list.price_unit =
                    xiangqing.prod_list[dangqian].price_unit;
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].prod_name;
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "数量"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_list[dangqian].qty))
                      : xiangqing.prod_list[dangqian].qty;
                }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "折扣率"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].disc_rate;
                }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "售价"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_list[dangqian].price_sale))
                      : xiangqing.prod_list[dangqian].price_sale;
                }
                // if(shuzu[dangqian][b].componentName=='textfield'&&shuzu[dangqian][b].defaultLable=='原价'){
                //     shuzu[dangqian][b].value=xiangqing.prod_list[dangqian].price_sale
                // }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "原价"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].price_orig;
                }
                if (
                  shuzu[dangqian][b].componentName == "moneyfield" &&
                  shuzu[dangqian][b].defaultLable == "单价"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].price_unit;
                }
                if (
                  shuzu[dangqian][b].componentName == "ddselectfield" &&
                  shuzu[dangqian][b].defaultLable == "税率"
                ) {
                  // shuzu[dangqian][b].value=xiangqing.prod_list[dangqian].price_unit
                  //////console.log(xiangqing.prod_list[dangqian].rate_tax)
                  //
                  shuzu[dangqian][b].defaultOptions.forEach((ite, ind) => {
                    //////console.log(ite.text)
                    if (ite.text == xiangqing.prod_list[dangqian].rate_tax + "%") {
                      //////console.log(ite.text)
                      //////console.log(ind)
                      this.$set(shuzu[dangqian][b], "value", ind);
                      this.$set(shuzu[dangqian][b], "teshu", false);
                    }
                  });
                  //////console.log(shuzu[dangqian][b].value)
                  if (shuzu[dangqian][b].value == undefined) {
                    this.$set(shuzu[dangqian][b], "value", "0");
                    this.$set(shuzu[dangqian][b], "teshu", false);
                  }
                }
                if (
                  shuzu[dangqian][b].componentName == "moneyfield" &&
                  shuzu[dangqian][b].defaultLable == "发票金额"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_list[dangqian].amt_inv_detail))
                      : xiangqing.prod_list[dangqian].amt_inv_detail;
                }
              }
            }
          }
          if (
            data[i].componentName == "tablefield" &&
            data[i].defaultLable == "回款" &&
            data[i].idx == 24
          ) {
            for (let b = 0; b < xiangqing.amt_list.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < xiangqing.amt_list.length; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "moneyfield" &&
                  shuzu[dangqian][b].defaultLable == "预计回款金额"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.amt_list[dangqian].amt_rec_est))
                      : xiangqing.amt_list[dangqian].amt_rec_est;
                }
                if (
                  shuzu[dangqian][b].componentName == "dddatefield" &&
                  shuzu[dangqian][b].defaultLable == "预计回款日期"
                ) {
                  shuzu[dangqian][b].value = new Date(
                    xiangqing.amt_list[dangqian].date_rec_est
                  );
                }
              }
            }
          }
          if (
            data[i].componentName == "tablefield" &&
            data[i].defaultLable == "发票信息"
          ) {
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < 1; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "客户"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.cust_name != undefined ? xiangqing.cust_name : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "地址"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.addr != undefined ? xiangqing.addr : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "纳税识别号"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.tax_no != undefined ? xiangqing.tax_no : "";
                }
                if (
                  (shuzu[dangqian][b].componentName == "textfield" ||
                    shuzu[dangqian][b].componentName == "numberfield") &&
                  shuzu[dangqian][b].defaultLable == "电话"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.mobile != undefined ? xiangqing.mobile : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "开户行"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.bank != undefined ? xiangqing.bank : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "开户行账号"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.bank_acct != undefined ? xiangqing.bank_acct : "";
                }
              }
            }
          }
          if (
            data[i].componentName == "tablefield" &&
            data[i].defaultLable == "产品回款" &&
            data[i].idx == 7
          ) {
            for (let b = 0; b < xiangqing.prod_detail.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < xiangqing.prod_detail.length; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "product" &&
                  shuzu[dangqian][b].defaultLable == "产品"
                ) {
                  //////console.log(xiangqing.prod_detail[dangqian])
                  //////console.log(xiangqing.prod_detail[dangqian].prod_id)
                  shuzu[dangqian][b].chanpin_list = {};
                  shuzu[dangqian][b].chanpin_list.prod_id =
                    xiangqing.prod_detail[dangqian].prod_id;
                  shuzu[dangqian][b].chanpin_list.prod_name =
                    xiangqing.prod_detail[dangqian].prod_name;
                  shuzu[dangqian][b].chanpin_list.prod_code =
                    xiangqing.prod_detail[dangqian].prod_code;
                  shuzu[dangqian][b].chanpin_list.cat_name =
                    xiangqing.prod_detail[dangqian].cat_name;
                  shuzu[dangqian][b].chanpin_list.cat_id =
                    xiangqing.prod_detail[dangqian].cat_id;
                  shuzu[dangqian][b].chanpin_list.spec =
                    xiangqing.prod_detail[dangqian].spec;
                  shuzu[dangqian][b].chanpin_list.type =
                    xiangqing.prod_detail[dangqian].type;
                  shuzu[dangqian][b].chanpin_list.unit =
                    xiangqing.prod_detail[dangqian].unit;
                  shuzu[dangqian][b].chanpin_list.price_unit =
                    xiangqing.prod_detail[dangqian].price_unit;
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].prod_name;
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "数量"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_detail[dangqian].qty))
                      : xiangqing.prod_detail[dangqian].qty;
                }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "折扣率"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].disc_rate;
                }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "售价"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_detail[dangqian].price_sale))
                      : xiangqing.prod_detail[dangqian].price_sale;
                }
                // if(shuzu[dangqian][b].componentName=='textfield'&&shuzu[dangqian][b].defaultLable=='原价'){
                //     shuzu[dangqian][b].value=xiangqing.prod_detail[dangqian].price_sale
                // }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "原价"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].price_orig;
                }
                if (
                  shuzu[dangqian][b].componentName == "moneyfield" &&
                  shuzu[dangqian][b].defaultLable == "单价"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].price_unit;
                }
              }
            }
          }
          if (
            data[i].componentName == "tablefield" &&
            data[i].defaultLable == "产品明细" &&
            data[i].idx == 5
          ) {
            for (let b = 0; b < xiangqing.prod_detail.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < xiangqing.prod_detail.length; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "product" &&
                  shuzu[dangqian][b].defaultLable == "产品"
                ) {
                  //////console.log(xiangqing.prod_detail[dangqian])
                  //////console.log(xiangqing.prod_detail[dangqian].prod_id)
                  shuzu[dangqian][b].chanpin_list = {};
                  shuzu[dangqian][b].chanpin_list.prod_id =
                    xiangqing.prod_detail[dangqian].prod_id;
                  shuzu[dangqian][b].chanpin_list.prod_name =
                    xiangqing.prod_detail[dangqian].prod_name;
                  shuzu[dangqian][b].chanpin_list.prod_code =
                    xiangqing.prod_detail[dangqian].prod_code;
                  shuzu[dangqian][b].chanpin_list.cat_name =
                    xiangqing.prod_detail[dangqian].cat_name;
                  shuzu[dangqian][b].chanpin_list.cat_id =
                    xiangqing.prod_detail[dangqian].cat_id;
                  shuzu[dangqian][b].chanpin_list.spec =
                    xiangqing.prod_detail[dangqian].spec;
                  shuzu[dangqian][b].chanpin_list.type =
                    xiangqing.prod_detail[dangqian].type;
                  shuzu[dangqian][b].chanpin_list.unit =
                    xiangqing.prod_detail[dangqian].unit;
                  shuzu[dangqian][b].chanpin_list.price_unit =
                    xiangqing.prod_detail[dangqian].price_unit;
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].prod_name;
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "数量"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_detail[dangqian].qty))
                      : xiangqing.prod_detail[dangqian].qty;
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "折扣率"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].disc_rate;
                }
                if (
                  shuzu[dangqian][b].componentName == "calcform" &&
                  shuzu[dangqian][b].defaultLable == "售价"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_detail[dangqian].price_sale))
                      : xiangqing.prod_detail[dangqian].price_sale;
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "原价"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].price_sale;
                }
                if (
                  shuzu[dangqian][b].componentName == "moneyfield" &&
                  shuzu[dangqian][b].defaultLable == "单价"
                ) {
                  shuzu[dangqian][b].value = xiangqing.prod_detail[dangqian].price_unit;
                }
              }
            }
          }
          if (data[i].componentName == "tablefield" && data[i].idx == "1600331810240") {
            // 1600331810240采购发票单的作废明细
            for (let b = 0; b < xiangqing.prod_list.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < xiangqing.prod_list.length; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "product" &&
                  shuzu[dangqian][b].defaultLable == "产品"
                ) {
                  //////console.log(xiangqing.prod_list[dangqian])
                  //////console.log(xiangqing.prod_list[dangqian].prod_id)
                  shuzu[dangqian][b].chanpin_list = {};
                  shuzu[dangqian][b].chanpin_list.prod_id =
                    xiangqing.prod_list[dangqian].prod_id;
                  shuzu[dangqian][b].chanpin_list.prod_name =
                    xiangqing.prod_list[dangqian].prod_name;
                  shuzu[dangqian][b].chanpin_list.prod_code =
                    xiangqing.prod_list[dangqian].prod_code;
                  shuzu[dangqian][b].chanpin_list.cat_name =
                    xiangqing.prod_list[dangqian].cat_name;
                  shuzu[dangqian][b].chanpin_list.cat_id =
                    xiangqing.prod_list[dangqian].cat_id;
                  shuzu[dangqian][b].chanpin_list.spec =
                    xiangqing.prod_list[dangqian].spec;
                  shuzu[dangqian][b].chanpin_list.type =
                    xiangqing.prod_list[dangqian].type;
                  shuzu[dangqian][b].chanpin_list.unit =
                    xiangqing.prod_list[dangqian].unit;
                  shuzu[dangqian][b].chanpin_list.price_unit =
                    xiangqing.prod_list[dangqian].price_unit;
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].prod_name;
                }
                if (
                  shuzu[dangqian][b].componentName == "suppliername" &&
                  shuzu[dangqian][b].defaultLable == "供应商"
                ) {
                  shuzu[dangqian][b].value_list = [
                    {
                      supply_name: xiangqing.prod_list[dangqian].supply_name,
                      supply_id: xiangqing.prod_list[dangqian].supply_id,
                    },
                  ];
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].supply_name;
                  shuzu[dangqian][b].value_id = xiangqing.prod_list[dangqian].supply_id;
                }
                if (
                  shuzu[dangqian][b].componentName == "moneyfield" &&
                  shuzu[dangqian][b].defaultLable == "产品报价"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.prod_list[dangqian].price_quotation;
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "采购数量"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_list[dangqian].qty_pur))
                      : xiangqing.prod_list[dangqian].qty_pur;
                }
                if (
                  shuzu[dangqian][b].componentName == "ddselectfield" &&
                  shuzu[dangqian][b].defaultLable == "税率"
                ) {
                  // shuzu[dangqian][b].value=xiangqing.prod_list[dangqian].price_unit
                  //////console.log(xiangqing.prod_list[dangqian].rate_tax)
                  //
                  shuzu[dangqian][b].defaultOptions.forEach((ite, ind) => {
                    //////console.log(ite.text)
                    if (ite.text == xiangqing.prod_list[dangqian].rate_tax) {
                      //////console.log(ite.text)
                      //////console.log(ind)
                      this.$set(shuzu[dangqian][b], "value", ind);
                      this.$set(shuzu[dangqian][b], "teshu", false);
                    }
                  });
                  //////console.log(shuzu[dangqian][b].value)
                  if (shuzu[dangqian][b].value == undefined) {
                    this.$set(shuzu[dangqian][b], "value", "0");
                    this.$set(shuzu[dangqian][b], "teshu", false);
                  }
                }
              }
            }
          }
          if (data[i].componentName == "tablefield" && data[i].idx == "1604309189572") {
            // 1604309189572  采购发票单明细2作废
            for (let b = 0; b < xiangqing.amt_list.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < xiangqing.amt_list.length; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "dddatefield" &&
                  shuzu[dangqian][b].defaultLable == "预计付款日期"
                ) {
                  shuzu[dangqian][b].value = new Date(
                    xiangqing.amt_list[dangqian].date_pay_est
                  );
                }
                if (
                  shuzu[dangqian][b].componentName == "moneyfield" &&
                  shuzu[dangqian][b].defaultLable == "预计付款金额"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.amt_list[dangqian].amt_pay_est))
                      : xiangqing.amt_list[dangqian].amt_pay_est;
                }
              }
            }
          }
          if (data[i].componentName == "tablefield" && data[i].idx == "1600414902905") {
            // 1600414902905  入库单作废明细
            for (let b = 0; b < xiangqing.prod_list.length - 1; b++) {
              this.tianjia_mingxi(data[i], 1);
            }
            const shuzu = data[i].components_list;
            //////console.log(shuzu)
            for (let dangqian = 0; dangqian < xiangqing.prod_list.length; dangqian++) {
              //////console.log(1)
              for (let b = 0; b < shuzu[dangqian].length; b++) {
                //////console.log(shuzu[dangqian])
                if (
                  shuzu[dangqian][b].componentName == "product" &&
                  shuzu[dangqian][b].defaultLable == "产品"
                ) {
                  //////console.log(xiangqing.prod_list[dangqian])
                  //////console.log(xiangqing.prod_list[dangqian].prod_id)
                  shuzu[dangqian][b].chanpin_list = {};
                  shuzu[dangqian][b].chanpin_list.prod_id =
                    xiangqing.prod_list[dangqian].prod_id;
                  shuzu[dangqian][b].chanpin_list.prod_name =
                    xiangqing.prod_list[dangqian].prod_name;
                  shuzu[dangqian][b].chanpin_list.prod_code =
                    xiangqing.prod_list[dangqian].prod_code;
                  shuzu[dangqian][b].chanpin_list.cat_name =
                    xiangqing.prod_list[dangqian].cat_name;
                  shuzu[dangqian][b].chanpin_list.cat_id =
                    xiangqing.prod_list[dangqian].cat_id;
                  shuzu[dangqian][b].chanpin_list.spec =
                    xiangqing.prod_list[dangqian].spec;
                  shuzu[dangqian][b].chanpin_list.type =
                    xiangqing.prod_list[dangqian].type;
                  shuzu[dangqian][b].chanpin_list.unit =
                    xiangqing.prod_list[dangqian].unit;
                  shuzu[dangqian][b].chanpin_list.price_unit =
                    xiangqing.prod_list[dangqian].price_unit;
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].prod_name;
                }
                if (
                  shuzu[dangqian][b].componentName == "suppliername" &&
                  shuzu[dangqian][b].defaultLable == "供应商"
                ) {
                  shuzu[dangqian][b].value_list = [
                    {
                      supply_name: xiangqing.prod_list[dangqian].supply_name,
                      supply_id: xiangqing.prod_list[dangqian].supply_id,
                    },
                  ];
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].supply_name;
                  shuzu[dangqian][b].value_id = xiangqing.prod_list[dangqian].supply_id;
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "仓库"
                ) {
                  shuzu[dangqian][b].value_list = [
                    {
                      site_name: xiangqing.prod_list[dangqian].site_name,
                      site_id: xiangqing.prod_list[dangqian].site_id,
                    },
                  ];
                  shuzu[dangqian][b].value = xiangqing.prod_list[dangqian].site_name;
                  shuzu[dangqian][b].value_id = xiangqing.prod_list[dangqian].site_id;
                }
                if (
                  shuzu[dangqian][b].componentName == "textfield" &&
                  shuzu[dangqian][b].defaultLable == "货位号"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.prod_list[dangqian].site_no != undefined
                      ? xiangqing.prod_list[dangqian].site_no
                      : "";
                }
                if (
                  shuzu[dangqian][b].componentName == "moneyfield" &&
                  shuzu[dangqian][b].defaultLable == "产品报价"
                ) {
                  shuzu[dangqian][b].value =
                    xiangqing.prod_list[dangqian].price_quotation;
                }
                if (
                  shuzu[dangqian][b].componentName == "numberfield" &&
                  shuzu[dangqian][b].defaultLable == "入库数量"
                ) {
                  shuzu[dangqian][b].value =
                    this.zhuangtai_shiyong_name == "作废"
                      ? Number(-Number(xiangqing.prod_list[dangqian].qty_in_store))
                      : xiangqing.prod_list[dangqian].qty_in_store;
                }
              }
            }
          }
        }
        if (this.zhuangtai_shiyong_name == "作废" && this.date.flow_id != "qIwxmsMTgv") {
          //  console.log(this.date,'333333333333555555')
          // xuanze_kehu
          data.forEach((item) => {
            if (item.componentName == "textfield" && item.defaultLable == "客户") {
              this.xuanze_kehu(item);
            }
          });
        }
      }
      if (this.zhuangtai_shiyong_name == "公海池_编辑") {
        query_user_info({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
          },
        }).then((res) => {
          //////console.log(res)

          if (res.data.code == 200) {
            // ||res.data.body.staff_level==1
            if (res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
              this.zhuguan = true;
            } else {
              this.zhuguan = false;
            }
            if (res.data.body.dept_manager == 1) {
              this.bumen_zhuguan = true;
            } else {
              this.bumen_zhuguan = false;
            }
          } else if (res.data.code == 500) {
          }
        });
      }
      if (
        this.zi_dingyi &&
        !this.kehu_shifou_bitian &&
        this.zhuangtai_shiyong_name != "再次提交"
      ) {
        const xg = this.list;

        for (let a = 0; a < xg.length; a++) {
          //////console.log(xg[a])
          if (xg[a].componentName == "department") {
            query_staff_dept_list({
              data: {
                ent_id: this.$ent_id(),
                staff_id: this.jichuxinxi.user_id,
              },
            }).then((res) => {
              //////console.log(res)

              if (res.data.code == 200) {
                const date = JSON.parse(res.data.body.data);
                // let liebiao=[]
                // date.forEach(ite=>{
                //     if(item.kehu_list[Number(item.zhanshi)].dept_id==ite.dept_id){
                //         liebiao.push(ite)
                //     }
                // })
                let bumen_list = [];
                if (
                  this.date.flow_id == "5FotYgnu0Z" &&
                  this.zhuangtai_shiyong_name == "已分配_编辑"
                ) {
                  date.forEach((item) => {
                    if (item.dept_id == this.yi_fenpei_bianji.dept_id) {
                      bumen_list.push(item);
                    }
                  });
                } else {
                  bumen_list = date;
                }
                bumen_list = this.$func.Es5duplicate(bumen_list, "dept_id");
                //////console.log(bumen_list)
                this.$set(xg[a], "option", bumen_list);
                this.liucheng_bumen_list = bumen_list;
                this.suoyou_bumen = bumen_list;
                // data[i]['option']=date
                // data[i]['dept_id']=''
                // if(date.length!=0){
                //     date.map(item=>{item['zhi']=false})
                // }
                // this.bumen_list=date
              } else if (res.data.code == 500) {
              }
            });
          }
        }
      } else {
        if (
          this.zhuangtai_shiyong_name == "看板_编辑" ||
          this.zhuangtai_shiyong_name == "看板" ||
          this.zhuangtai_shiyong_name == "作废"
        ) {
          const data = this.list;

          data.forEach((item) => {
            if (
              item.componentName == "textfield" &&
              (item.defaultLable == "客户" ||
                item.defaultLable == "入库员" ||
                item.defaultLable == "出库员")
            ) {
              const xg = this.list;
              for (let a = 0; a < xg.length; a++) {
                if (
                  xg[a].componentName == "department" &&
                  !this.zhuangtai_shiyong_name == "作废"
                ) {
                  query_staff_dept_list({
                    //查询当前人的部门
                    data: {
                      ent_id: this.$ent_id(),
                      staff_id: this.jichuxinxi.user_id,
                    },
                  }).then((res) => {
                    if (res.data.code == 200) {
                      const date = JSON.parse(res.data.body.data);

                      const liebiao = [];
                      date.forEach((ite) => {
                        if (item.kehu_list[Number(item.zhanshi)].dept_id == ite.dept_id) {
                          liebiao.push(ite);
                        }
                      });
                      let bumen_list = [];
                      if (
                        this.date.flow_id == "5FotYgnu0Z" &&
                        this.zhuangtai_shiyong_name == "已分配_编辑"
                      ) {
                        liebiao.forEach((item) => {
                          if (item.dept_id == this.yi_fenpei_bianji.dept_id) {
                            bumen_list.push(item);
                          }
                        });
                      } else {
                        bumen_list = liebiao;
                      }
                      bumen_list = this.$func.Es5duplicate(bumen_list, "dept_id");
                      this.$set(xg[a], "option", bumen_list);
                      this.liucheng_bumen_list = bumen_list;
                      this.suoyou_bumen = bumen_list;
                    } else if (res.data.code == 500) {
                    }
                  });
                } else {
                }
              }
            }
          });
        }
        if (this.zhuangtai_shiyong_name == "再次提交") {
          const data = this.list;
          if (!this.kehu_shifou_bitian) {
            data.forEach((item) => {
              // if(item.componentName=='cust'&&item.kehu_list.length!=0){
              const xg = this.list;

              for (let a = 0; a < xg.length; a++) {
                //////console.log(xg[a])
                if (xg[a].componentName == "department") {
                  query_staff_dept_list({
                    data: {
                      ent_id: this.$ent_id(),
                      staff_id: this.jichuxinxi.user_id,
                    },
                  }).then((res) => {
                    if (res.data.code == 200) {
                      const date = JSON.parse(res.data.body.data);
                      // let liebiao=[]
                      // date.forEach(ite=>{
                      //     if(item.kehu_list[Number(item.zhanshi)].dept_id==ite.dept_id){
                      //         liebiao.push(ite)
                      //     }
                      // })
                      this.$set(xg[a], "option", date);
                      this.liucheng_bumen_list = date;
                      this.suoyou_bumen = date;
                    } else if (res.data.code == 500) {
                    }
                  });
                }
              }
              // }
            });
          } else {
            data.forEach((item) => {
              if (item.componentName == "cust" && item.kehu_list.length != 0) {
                const xg = this.list;

                for (let a = 0; a < xg.length; a++) {
                  //////console.log(xg[a])
                  if (xg[a].componentName == "department") {
                    query_staff_dept_list({
                      data: {
                        ent_id: this.$ent_id(),
                        staff_id: this.jichuxinxi.user_id,
                      },
                    }).then((res) => {
                      //////console.log(res)

                      if (res.data.code == 200) {
                        const date = JSON.parse(res.data.body.data);
                        const liebiao = [];
                        date.forEach((ite) => {
                          if (
                            item.kehu_list[Number(item.zhanshi)].dept_id == ite.dept_id
                          ) {
                            liebiao.push(ite);
                          }
                        });
                        let bumen_list = [];
                        if (
                          this.date.flow_id == "5FotYgnu0Z" &&
                          this.zhuangtai_shiyong_name == "已分配_编辑"
                        ) {
                          liebiao.forEach((item) => {
                            if (item.dept_id == this.yi_fenpei_bianji.dept_id) {
                              bumen_list.push(item);
                            }
                          });
                        } else {
                          bumen_list = liebiao;
                        }
                        bumen_list = this.$func.Es5duplicate(bumen_list, "dept_id");
                        this.$set(xg[a], "option", bumen_list);
                        this.liucheng_bumen_list = bumen_list;
                        this.suoyou_bumen = bumen_list;
                      } else if (res.data.code == 500) {
                      }
                    });
                  }
                }
              }
            });
          }
        }
      }

      this.chaxun_biaodan_gaoji_shezhi();
      this.gengxin_liucheng_bumen();
    },
    gengxin_liucheng_bumen() {
      if (
        this.zhuangtai_shiyong_name != "看板_编辑" &&
        this.zhuangtai_shiyong_name != "看板" &&
        this.zhuangtai_shiyong_name != "作废" &&
        !this.kehu_shifou_bitian
      ) {
        //////console.log(this.yi_fenpei_bianji)

        query_dept_list_of_staff({
          data: {
            ent_id: this.$ent_id(),
            staff_id: this.jichuxinxi.user_id,
          },
        }).then((res) => {
          //////console.log(res)
          if (res.data.code == 200) {
            const listdate = JSON.parse(res.data.body.data);
            let bumen_list = [];
            //////console.log(listdate)

            if (
              (this.date.flow_id == "5FotYgnu0Z" || this.date.flow_id == "7TvJHPyovM") &&
              this.zhuangtai_shiyong_name == "已分配_编辑"
            ) {
              listdate.forEach((item) => {
                if (item.dept_id == this.yi_fenpei_bianji.dept_id) {
                  bumen_list.push(item);
                }
              });
            } else {
              bumen_list = listdate;
            }
            bumen_list = this.$func.Es5duplicate(bumen_list, "dept_id");
            //////console.log(bumen_list)

            this.liucheng_bumen_list = bumen_list;
            this.suoyou_bumen = bumen_list;
          } else if (res.data.code == 500) {
          }
        });
      }
    },
    // 查询-表单审批流程的设置
    chaxun_biaodan_gaoji_shezhi() {
      query_form_appr_set({
        data: {
          ent_id: this.$ent_id(),
          flow_id: this.date.flow_id,
        },
      }).then((res) => {
        //////console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data);
          //////console.log(date)
          this.dangqian_danju_gaoji_shezhi = date;
        } else if (res.data.code == 500) {
        }
      });
    },
    // 判断是否有公有单
    shifou_you_gongdan() {
      const id = this.date.flow_id;
      if (
        id != "VMCizuVOXb" &&
        id != "He5ln0bdDO" &&
        id != "qIwxmsMTgv" &&
        id != "Eb3n7CKQzi" &&
        id != "PLOQ9ku3Ip" &&
        id != "GBh7N2jMsi" &&
        id != "cvRyWGiUQH" &&
        id != "7TvJHPyovM" &&
        id != "Z7VDOK9HGu" &&
        id != "453y6pLD52" &&
        id != "H49d4edc3T" &&
        id != "Xsk2pLBS9a" &&
        id != "u1SCMMoUwK" &&
        id != "vY9ubhTO89" &&
        id != "fZ0x5qedzn" &&
        id != "hXstgwxhzh" &&
        id != "AY3cli7BAb" &&
        id != "5FotYgnu0Z"
      ) {
        this.zi_dingyi = true;
      } else {
        this.zi_dingyi = false;
      }
    },
    chanpin_dalei_jiekou() {
      // query_category_info_list({data:{
      //     ent_id:this.$ent_id(),
      //     user_id:this.jichuxinxi.user_id,
      //     parent_id:'0',
      //     active:'1'
      // }}).then(res=>{
      //     //////console.log(4444444,res)
      //     if(res.data.code==10193){
      //         // let date=JSON.parse(res.data.body.data)
      //         // date.map(item=>{this.$set(item,'zhi',false)})
      //         // this.qiye_chanpin_list=date
      //         // this.renyuan_jiekou()
      //         let date=JSON.parse(res.data.body.data)
      //         //////console.log(date)
      //         var b = this.buneng_xuan_chanpin
      //         //////console.log(b)
      //         let deal = (arr1, arr2, key) => {
      //             let arr3 = []
      //             for (let index = 0; index < arr1.length; index++) {
      //                 let item = arr1[index]
      //                 if (!arr2.some(v => v[key] === item[key])) {
      //                     arr3.push(item)
      //                 }
      //             }
      //             return arr3
      //         }
      //         this.qiye_chanpin_leixing_list=deal(date,b,'cat_id')
      //         //////console.log(55555,this.qiye_chanpin_leixing_list)
      //     }else if(res.data.code==10194){
      //     }
      // })
      query_ent_prod_cat_list_tree({
        data: {
          ent_id: this.$ent_id(),
        },
      }).then((res) => {
        //////console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data);
          //////console.log(date)
          this.qiye_chanpin_leixing_tree = date;
        } else if (res.data.code == 500) {
        }
      });
    },
    liebiao_yangshi(row) {
      return "color:#4C4A4D;font-size:0.1rem;";
    },
    biaotou_yangshi(row) {
      return "color:#1A2533;";
    },
    // 添加明细
    tianjia_mingxi(item, index) {
      const length = "";
      const data = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_2", "暂时");
      //////console.log(item)
      //////console.log(index)
      for (let i = 0; i < this.list.length; i++) {
        if (
          this.list[i].idx == item.idx &&
          this.list[i].defaultLable == item.defaultLable
        ) {
          this.list[i].value = this.list[i].value + 1;
          this.list[i].dangqian = this.list[i].dangqian + 1;
          for (let a = 0; a < data.length; a++) {
            if (
              data[a].componentName == "tablefield" &&
              this.list[i].defaultLable == data[a].defaultLable &&
              this.list[i].idx == data[a].idx
            ) {
              this.list[i].components_list.push(data[a].components);
            }
          }
          const con_list = this.list[i].components_list[
            this.list[i].components_list.length - 1
          ];
          for (let a = 0; a < con_list.length; a++) {
            // this.$func.randomstring(10)
            this.$set(con_list[a], "idx", con_list[a].idx);
            this.$set(con_list[a], "value", "");
            this.$set(con_list[a], "value_id", "");
            this.$set(con_list[a], "dangqian", this.list[i].dangqian);
            if (con_list[a].componentName == "ddselectfield") {
              this.$set(con_list[a], "value", "0");
              this.$set(con_list[a], "teshu", false);
            }
            if (
              con_list[a].componentName == "dddaterangefield" &&
              data[i].defaultLable == "行程"
            ) {
              this.$set(con_list[a], "value", new Date());
              this.$set(con_list[a], "value_id", new Date());
              this.$set(con_list[a], "value_start", 0.5);
              this.$set(con_list[a], "value_id_end", 0.5);
              this.$set(con_list[a], "day", 0.5);
            }
            if (
              con_list[a].componentName == "dddaterangefield" &&
              data[i].defaultLable != "行程"
            ) {
              this.$set(con_list[a], "value", []);
              this.$set(con_list[a], "value_id", []);
            }
            if (con_list[a].componentName == "ddphotofield") {
              this.$set(con_list[a], "imgBase64", []);
              this.$set(con_list[a], "lei", "");
              this.$set(con_list[a], "xin", "");
            }
          }
          // this.list[i].components_list.push(con_list)
          //////console.log(this.list[i])
          //////console.log(con_list.length)
          // let jieduan=''
          // for(let a=0;a<this.list[i].components.length;a++){
          //     if(this.list[i].components[a].defaultLable=='漏斗现阶段'&&this.list[i].components[a].componentName=='textfield'){
          //         jieduan=this.list[i].components[a].value
          //         break
          //     }
          // }
          // for(let a=0;a<this.list[i].components.length;a++){
          //     if(this.list[i].components[a].defaultLable=='漏斗现阶段'&&this.list[i].components[a].componentName=='textfield'){
          //         this.list[i].components[a].value=jieduan
          //     }
          // }

          // this.list[i].components_list.push(data[i].components)
          // //////console.log(this.list[i])
        }
      }
      // for(let i=0;i<data.length;i++){
      //     if(item.idx==data[i].idx){
      //         length=data[i].components.length
      //     }
      // }
      // for(let i=0;i<this.list.length;i++){
      //     if(this.list[i].idx==item.idx&&this.list[i].defaultLable==item.defaultLable){
      //         this.list[i].components.push({
      //             componentName:'title',
      //             defaultLable:this.list[i].defaultLable+'('+(this.list[i].value+1)+')'
      //         })
      //         this.list[i].value=this.list[i].value+1
      //         this.list[i].dangqian=this.list[i].dangqian+1
      //         for(let a=0;a<length;a++){
      //             data[i].components[a].idx=this.$func.randomstring(10)
      //             data[i].components[a]['value']=''
      //             data[i].components[a]['value_id']=''
      //             data[i].components[a]['dangqian']=this.list[i].dangqian
      //             if(data[i].components[a].componentName=='ddselectfield'){
      //                 data[i].components[a]['value']='0'
      //                 data[i].components[a]['teshu']=false
      //             }
      //             if(data[i].components[a].componentName=='dddaterangefield'&&data[i].defaultLable=='行程'){
      //                 data[i].components[a].value=new Date()
      //                 data[i].components[a].value_id=new Date()
      //                 data[i].components[a]['value_start']=0.5
      //                 data[i].components[a]['value_id_end']=0.5
      //                 data[i].components[a]['day']=0.5
      //             }
      //             if(data[i].components[a].componentName=='dddaterangefield'&&data[i].defaultLable!='行程'){
      //                 data[i].components[a].value=[]
      //                 data[i].components[a].value_id=[]
      //             }
      //             if(data[i].components[a].componentName=='ddphotofield'){
      //                 data[i].components[a]['imgBase64']=[]
      //                 data[i].components[a]['lei']=''
      //                 data[i].components[a]['xin']=''
      //             }
      //             this.list[i].components.push(data[i].components[a])
      //         }
      //         //////console.log(this.list[i])
      //         let jieduan=''
      //         for(let a=0;a<this.list[i].components.length;a++){
      //             if(this.list[i].components[a].defaultLable=='漏斗现阶段'&&this.list[i].components[a].componentName=='textfield'){
      //                 jieduan=this.list[i].components[a].value
      //                 break
      //             }
      //         }
      //         for(let a=0;a<this.list[i].components.length;a++){
      //             if(this.list[i].components[a].defaultLable=='漏斗现阶段'&&this.list[i].components[a].componentName=='textfield'){
      //                 this.list[i].components[a].value=jieduan
      //             }
      //         }
      //     }
      // }
    },
    //
    ceshi_diqu(it, i, inde) {
      this.diqu_xuyao_ziduan.big = i;
      this.diqu_xuyao_ziduan.small = it;
      this.diqu_xuyao_ziduan.inde = inde;
    },
    ceshi_diqu2(i) {
      //////console.log(i)
      this.diqu_xuyao_ziduan.dingji = i;
      //console.log(this.diqu_xuyao_ziduan.dingji)
    },
    //   把值都写进去
    zhi_on_api() {
      for (let i = 0; i < this.list.length; i++) {
        if (
          this.list[i].idx == this.diqu_xuyao_ziduan.big.idx &&
          this.list[i].defaultLable == this.diqu_xuyao_ziduan.big.defaultLable
        ) {
          const shuzu = this.list[i].components_list;
          // for(let a=0;a<shuzu.length;a++){
          //////console.log(this.diqu_xuyao_ziduan.inde)
          for (let b = 0; b < shuzu[this.diqu_xuyao_ziduan.inde].length; b++) {
            if (
              shuzu[this.diqu_xuyao_ziduan.inde][b].defaultLable ==
                this.diqu_xuyao_ziduan.small.defaultLable &&
              shuzu[this.diqu_xuyao_ziduan.inde][b].idx ==
                this.diqu_xuyao_ziduan.small.idx
            ) {
              shuzu[this.diqu_xuyao_ziduan.inde][b].value = this.temp.addressprovince;
              shuzu[this.diqu_xuyao_ziduan.inde][b].value_id = this.temp.addresscity;
              shuzu[this.diqu_xuyao_ziduan.inde][b].value_ids = this.temp.addarea;
              // this.temp.addresscity=''
              // this.temp.addressprovince=''
              //////console.log(shuzu[this.diqu_xuyao_ziduan.inde][b])
            }
          }

          // if(this.list[i].components[a].defaultLable==this.diqu_xuyao_ziduan.small.defaultLable){
          //     this.list[i].components[a].value=this.temp.addressprovince
          //     this.list[i].components[a].value_id=this.temp.addresscity
          //     // this.temp.addresscity=''
          //     // this.temp.addressprovince=''
          //     //////console.log(this.list[i].components[a])
          // }
          // }
        }
      }
    },
    //   把值都写进去
    zhi_on_api2() {
      for (let i = 0; i < this.list.length; i++) {
        if (
          this.list[i].idx == this.diqu_xuyao_ziduan.dingji.idx &&
          this.list[i].defaultLable == this.diqu_xuyao_ziduan.dingji.defaultLable
        ) {
          this.list[i].value = this.temp2.addressprovince;
          this.list[i].value_id = this.temp2.addresscity;
          this.list[i].value_ids = this.temp2.addarea;
        }
      }
    },
    ceshi_jichu() {},
    onSelected(data) {
      this.temp.addressprovince = "";
      this.temp.addresscity = "";
      this.temp.addarea = "";
      this.temp.addressprovince = data.province.value;
      this.temp.addresscity = data.city.value;
      this.temp.addarea = data.area.value;
    },
    onSelected2(data) {
      this.temp2.addressprovince = "";
      this.temp2.addresscity = "";
      this.temp2.addarea = "";
      this.temp2.addressprovince = data.province.value;
      this.temp2.addresscity = data.city.value;
      this.temp2.addarea = data.area.value;
    },
    jiansuan(val, it) {
      console.log(val, it, "asdad0333030994949");
      if (this.list[0].flow_id === "AY3cli7BAb") {
        let chongzhi_mun = 0;
        let input_num = Number(val[1].value);
        for (let i of this.list) {
          if (i.defaultLable === "充值金额") chongzhi_mun = Number(i.value);
        }
        if (!chongzhi_mun) return this.$message.warning("请输入充值金额");
        val[2].value = ((input_num / chongzhi_mun) * 100).toFixed(2);
        //底下代码不能删
        //   let first_num =  Number(it.components_list[0][2].value)
        //   if(chongzhi_mun < first_num){
        //     this.$message.warning('结算金额不能超过充值金额,请重新填写')
        //     it.components_list[0][2].value = ''
        //     it.components_list[0][1].value = ''
        //     return
        //   }else if(it.components_list.length > 1 ){
        //     let jie_suan = it.components_list.reduce((a,b)=> {
        //           return a  += Number(b[1].value)
        //      },0)
        //     let jie_sum = it.components_list.reduce((a,b)=>{
        //        return a  += Number(b[2].value)
        //     },0)
        //     if(chongzhi_mun<jie_sum){
        //        console.log(jie_sum)
        //         this.$message.warning('结算金额不能超过充值金额,请重新填写')
        //         val[1].value = ''
        //         val[2].value = ''
        //     }else if(chongzhi_mun>jie_sum){
        //       if(it.components_list.length > 2 ){
        //         if(it.components_list.every(item=>item[2].value)){
        //           console.log(chongzhi_mun,jie_sum)
        //            val[2].value =  chongzhi_mun - (jie_sum - input_num)
        //         }
        //       }else{
        //         val[2].value = chongzhi_mun- first_num
        //       }
        //     }else{
        //     }
        //   }
        //   let oldarray = []
        //   oldarray=  val[0].defaultOptions.map((item)=>{
        //    return  item.text
        // })

        //校验是否有相同的选项 // 校验总金额是否等于1000
        //let date = it.components_list.filter(item => val[0].defaultOptionsindexOf(item.id) > -1)
      }
    },
    // 单选事件
    danxuan(item, index, indexx) {
      
      console.log(item, index, indexx, "这是单机啊");
      console.log(this.list, "所有信息");
      if (index === 0) {
      }
      //console.log(index)
      //console.log(item, index,indexx,'这是内单选传的值')
      if (this.list[0].flow_id === "AY3cli7BAb") {
      }
      if (this.list[0].flow_id === "He5ln0bdDO") {
        ////console.log(item.defaultOptions[index].text,index,'asdasdasasdasdas')
        this.huikuandan = item.defaultOptions[index].text;
      }
      item.value = index;
      (this.vipData.pay_type = item.defaultOptions[index].text), index;
      //////console.log(item)
      if (item.defaultLable == "发票类型") {
        // defaultImportant//是否必填
        this.list.forEach((ite) => {
          if (ite.componentName == "tablefield" && ite.defaultLable == "发票信息") {
            ite.components_list.forEach((it) => {
              it.forEach((i) => {
                if (i.componentName == "textfield" && i.defaultLable == "客户") {
                  this.$set(i, "defaultImportant", !!(index == 0 || index == 1));
                }
                if (i.componentName == "textfield" && i.defaultLable == "地址") {
                  this.$set(i, "defaultImportant", index == 0);
                }
                if (i.componentName == "textfield" && i.defaultLable == "纳税识别号") {
                  this.$set(i, "defaultImportant", !!(index == 0 || index == 1));
                }
                if (i.componentName == "textfield" && i.defaultLable == "电话") {
                  this.$set(i, "defaultImportant", index == 0);
                }
                if (i.componentName == "textfield" && i.defaultLable == "开户行") {
                  this.$set(i, "defaultImportant", index == 0);
                }
                if (i.componentName == "numberfield" && i.defaultLable == "开户行账号") {
                  this.$set(i, "defaultImportant", index == 0);
                }
              });
            });
          }
        });
      }
      this.liucheng_jiekou();
      // //////console.log(i)
      // //////console.log(index)
    },
    // 获取图片base64实现预览
    getImgBasea(i) {
      var event = event || window.event;
      var file = event.target.files[0];
      var reader = new FileReader();
      i.lei = file.name.substring(file.name.lastIndexOf("."), file.name.length);
      // 转base64
      reader.onload = function (e) {
        i.imgBase64.push(e.target.result);
        i.xin = e.target.result.substring(
          e.target.result.indexOf(",") + 1,
          e.target.result.length
        );
        //////console.log(i.xin)
      };
      reader.readAsDataURL(file);
    },
    // 删除该图片
    delImga(i, index) {
      i.imgBase64.splice(index, 1);
    },
    //   返回按钮
    dianji_fanhui() {
      // this.$router.push('/Initiateapp')
      window.history.go(-1);
    },
    dakai_renyuan(index, i) {
      this.dangqian_lianxi_ren_idx = index;
      //////console.log(index)
      //////console.log(i)
      const list = [];

      if (i.staff_id != undefined && i.staff_id.length != 0) {
        i.staff_id.split(",").forEach((item, inde) => {
          list.push({
            name: i.value.split(",")[inde],
            staff_id: i.staff_id.split(",")[inde],
          });
        });
      }
      this.qiye_renyuan_list_xuan = list;
      this.dakai_bumen();
      this.lianxiren = true;
    },
    // 部门列表   自己的
    bumen_liebiao() {
      if (this.daixuan_fanwei_all.length != 0) {
        const bumen_list = [];
        const ren_list = [];
        this.daixuan_fanwei_all.forEach((item) => {
          if (item.type == 1) {
            this.$set(item, "dept_name", item.name);
            this.$set(item, "dept_id", item.targetId);
            bumen_list.push(item);
          } else if (item.type == 2) {
            this.$set(item, "name", item.name);
            this.$set(item, "staff_name", item.name);
            this.$set(item, "staff_id", item.targetId);
            this.$set(item, "zhi", false);
            ren_list.push(item);
          }
        });
        this.qiye_bumen_list = bumen_list;
        this.qiye_renyuan_list = ren_list;
        this.panduan_yixuan();
      } else {
        query_ent_dept_list({
          data: {
            ent_id: this.$ent_id(),
            active: "1",
            parent_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id,
          },
        }).then((res) => {
          //////console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data);
            //////console.log(date)
            this.qiye_bumen_list = date;
          } else if (res.data.code == 10169) {
          }
        });
        query_dept_staff_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            dept_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id,
            active: "1",
          },
        }).then((ras) => {
          if (ras.data.code == 10149) {
            const listdata = JSON.parse(ras.data.body.data);
            listdata.forEach((item) => {
              item.zhi = false;
            });
            //////console.log(99999, listdata)
            this.qiye_renyuan_list = listdata;
            this.panduan_yixuan();
          } else if (ras.data.code == 10150) {
          }
        });
      }
    },
    dakai_bumen() {
      this.mianbao_list.splice(1, this.mianbao_list.length);
      this.bumen_liebiao();
    },
    // 点击部门
    dianji_bumen(i) {
      //////console.log(44444, i)
      // if(i.child_flag==1){
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.jichuxinxi.user_id,
          parent_id: i.dept_id,
          active: "1",
        },
      }).then((res) => {
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data);
          //////console.log(date)
          this.qiye_bumen_list = date;
          this.mianbao_list.push({ name: i.dept_name, dept_id: i.dept_id });
          query_dept_staff_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.jichuxinxi.user_id,
              dept_id: i.dept_id,
              active: "1",
            },
          }).then((ras) => {
            if (ras.data.code == 10149) {
              const listdata = JSON.parse(ras.data.body.data);
              listdata.map((item) => {
                item.zhi = false;
              });
              //////console.log(99999, listdata)
              this.qiye_renyuan_list = listdata;
              this.panduan_yixuan();
            } else if (ras.data.code == 10150) {
            }
          });
        } else if (res.data.code == 10169) {
        }
      });
      // }else{
      //     this.$message({
      //         message: '该部门没有子部门了',
      //         type: 'warning'
      //     });
      // }
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian(i, index) {
      //////console.log(i.dept_id)
      if (this.mianbao_list.length - 1 != index) {
        if (this.daixuan_fanwei_all.length != 0 && index == 0) {
          const bumen_list = [];
          const ren_list = [];
          this.daixuan_fanwei_all.forEach((item) => {
            if (item.type == 1) {
              this.$set(item, "dept_name", item.name);
              this.$set(item, "dept_id", item.targetId);
              bumen_list.push(item);
            } else if (item.type == 2) {
              this.$set(item, "name", item.name);
              this.$set(item, "staff_name", item.name);
              this.$set(item, "staff_id", item.targetId);
              this.$set(item, "zhi", false);
              ren_list.push(item);
            }
          });
          this.qiye_bumen_list = bumen_list;
          this.qiye_renyuan_list = ren_list;
          this.panduan_yixuan();
        } else {
          query_ent_dept_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.jichuxinxi.user_id,
              parent_id: i.dept_id,
              active: "1",
            },
          }).then((res) => {
            if (res.data.code == 10168) {
              var date = JSON.parse(res.data.body.data);
              // //////console.log(date)
              this.qiye_bumen_list = date;
              this.mianbao_list.splice(index + 1, this.mianbao_list.length);
              query_dept_staff_list({
                data: {
                  ent_id: this.$ent_id(),
                  user_id: this.jichuxinxi.user_id,
                  dept_id: i.dept_id,
                  active: "1",
                },
              }).then((ras) => {
                //////console.log(ras)
                if (ras.data.code == 10149) {
                  const listdata = JSON.parse(ras.data.body.data);
                  listdata.map((item) => {
                    item.zhi = false;
                  });
                  //////console.log(99999, listdata)
                  this.qiye_renyuan_list = listdata;
                  this.panduan_yixuan();
                } else if (ras.data.code == 10150) {
                }
              });
            } else if (res.data.code == 10169) {
            }
          });
        }
      }
    },
    // 发起人里成员点击确定
    renyuan_sure() {
      for (let i = 0; i < this.list.length; i++) {
        if (
          this.list[i].componentName == "contacts" &&
          this.date.flow_id != "Eb3n7CKQzi" &&
          this.dangqian_lianxi_ren_idx == this.list[i].idx
        ) {
          // qiye_renyuan_list_xuan
          this.list[i].value = this.qiye_renyuan_list_xuan
            .map((item) => item.name)
            .join(",");
          this.list[i].staff_id = this.qiye_renyuan_list_xuan
            .map((item) => item.staff_id)
            .join(",");
          this.lianxiren = false;
        }
        if (
          this.list[i].componentName == "contacts" &&
          this.date.flow_id == "Eb3n7CKQzi" &&
          this.list[i].defaultLable == "派工人员" &&
          this.dangqian_lianxi_ren_idx == this.list[i].idx
        ) {
          this.list[i].value = this.qiye_renyuan_list_xuan
            .map((item) => item.name)
            .join(",");
          this.list[i].staff_id = this.qiye_renyuan_list_xuan
            .map((item) => item.staff_id)
            .join(",");
          this.lianxiren = false;
        }
      }
      if (this.dangqian_lianxi_ren_idx == "tidai") {
        if (
          this.qiye_renyuan_list_xuan.length == 0 ||
          this.qiye_renyuan_list_xuan[0].staff_id == this.$jichuxinxi().user_id
        ) {
          this.dai_taren_tijiao = false;
        } else {
          this.dai_taren_tijiao = true;
          this.$set(
            this.qiye_renyuan_list_xuan[0],
            "user_id",
            this.qiye_renyuan_list_xuan[0].staff_id
          );
        }
        this.jichuxinxi =
          this.qiye_renyuan_list_xuan.length != 0
            ? this.qiye_renyuan_list_xuan[0]
            : this.$jichuxinxi();
        //////console.log(this.qiye_renyuan_list_xuan)
        for (let i = 0; i < this.list.length; i++) {
          if (
            this.list[i].componentName == "contacts" &&
            this.list[i].defaultLable != "派工人员"
          ) {
            this.list[i].value =
              this.qiye_renyuan_list_xuan.length != 0
                ? this.qiye_renyuan_list_xuan[0].name
                : this.$jichuxinxi().name;
            this.list[i].staff_id =
              this.qiye_renyuan_list_xuan.length != 0
                ? this.qiye_renyuan_list_xuan[0].staff_id
                : this.$jichuxinxi().staff_id;
          }
        }
        this.lianxiren = false;
      }
    },
    keyu() {
      if (this.text.length == 0) {
        // this.qiye_renyuan_list=[]
        if (this.daixuan_fanwei_all.length != 0) {
          const bumen_list = [];
          const ren_list = [];
          this.daixuan_fanwei_all.forEach((item) => {
            if (item.type == 1) {
              this.$set(item, "dept_name", item.name);
              this.$set(item, "dept_id", item.targetId);
              bumen_list.push(item);
            } else if (item.type == 2) {
              this.$set(item, "name", item.name);
              this.$set(item, "staff_name", item.name);
              this.$set(item, "staff_id", item.targetId);
              this.$set(item, "zhi", false);
              ren_list.push(item);
            }
          });
          this.qiye_bumen_list = bumen_list;
          this.qiye_renyuan_list = ren_list;
          this.panduan_yixuan();
        } else {
          query_dept_staff_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.jichuxinxi.user_id,
              dept_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id,
              active: "1",
            },
          }).then((ras) => {
            if (ras.data.code == 10149) {
              const listdata = JSON.parse(ras.data.body.data);
              listdata.forEach((item) => {
                item.zhi = false;
              });
              //////console.log(99999, listdata)
              this.qiye_renyuan_list = listdata;
              this.panduan_yixuan();
            } else if (ras.data.code == 10150) {
            }
          });
        }
      } else {
        if (this.daixuan_fanwei_all.length != 0) {
          query_staff_list_of_dept_3({
            data: {
              ent_id: this.$ent_id(),
              flow_id: this.date.flow_id,
              search_str:
                this.text != undefined && this.text != null && this.text.length != 0
                  ? this.text
                  : null,
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              //////console.log(JSON.parse(res.data.body.data))
              const date = JSON.parse(res.data.body.data);
              date.forEach((item) => {
                this.$set(item, "zhi", false);
                this.$set(item, "name", item.staff_name);
              });
              this.qiye_renyuan_list = date;
              this.panduan_yixuan();
            } else if (res.data.code == 500) {
            }
          });
        } else {
          get_staff_list_like({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.jichuxinxi.user_id,
              staff_name:
                this.text != undefined && this.text != null && this.text.length != 0
                  ? this.text
                  : null,
              active: "1",
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 10027) {
              //////console.log(JSON.parse(res.data.body.data))
              const date = JSON.parse(res.data.body.data);
              date.forEach((item) => {
                this.$set(item, "zhi", false);
                this.$set(item, "name", item.staff_name);
              });
              this.qiye_renyuan_list = date;
              this.panduan_yixuan();
            } else if (res.data.code == 10028) {
            }
          });
        }
      }
    },
    // 打开部门
    dakai_bumen_a() {
      this.bumen = true;
      // query_ent_dept_list_aim({data:{
      //     ent_id:this.$ent_id()
      // }}).then(res=>{
      //     //////console.log(res)
      //     if(res.data.code==10168){
      //         let date=JSON.parse(res.data.body.data)
      //         //////console.log(date)
      //         this.tree=date
      //     }else if(res.data.code==10169){}
      // })
      query_ent_dept_list_aim({
        data: {
          ent_id: this.$ent_id(),
          // user_id:this.$jichuxinxi().user_id
        },
      }).then((res) => {
        //////console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data);
          //////console.log(date)
          this.tree = date;
        } else if (res.data.code == 10169) {
        }
      });
      // query_staff_dept_list({data:{
      //     ent_id:this.$ent_id(),
      //     staff_id:this.$jichuxinxi().user_id,
      // }}).then(res=>{
      //     //////console.log(res)
      //     if(res.data.code==200){
      //         let date=JSON.parse(res.data.body.data)
      //         if(date.length!=0){
      //             date.map(item=>{item['zhi']=false})
      //         }
      //         this.bumen_list=date
      //     }else if(res.data.code==500){}
      // })
    },
    // 点击复选框
    fuxuan() {
      //////console.log(this.$refs.tree.getCheckedNodes())
    },
    fuxuan_2() {
      // //////console.log(this.$refs.chanpintree[1].getCheckedNodes())
    },
    // 复选部门的时候用到的
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    filterNode_2(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 部门点击确定
    bumen_sure() {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].componentName == "department") {
          // this.list[i].value=
          // this.list[i]['dept_id']=this.$refs.tree.getCheckedNodes().map((item,index)=>item.dept_id).join(",")
          if (this.zhuguan) {
            // let name_list=[]
            // let id_list=[]
            // for(let a=0;a<this.bumen_list.length;a++){
            //     if(this.bumen_list[a].zhi){
            //         name_list.push(this.bumen_list[a].dept_name)
            //         id_list.push(this.bumen_list[a].dept_id)
            //     }
            // }
            this.zhuguan_xuanze_bumen = this.$refs.tree
              .getCheckedNodes()
              .map((item, index) => item.label)
              .join(",");
            this.zhuguan_xuanze_bumen_id = this.$refs.tree
              .getCheckedNodes()
              .map((item, index) => item.dept_id);
          } else {
            // let name_list=[]
            // let id_list=[]
            // for(let a=0;a<this.bumen_list.length;a++){
            //     if(this.bumen_list[a].zhi){
            //         name_list.push(this.bumen_list[a].dept_name)
            //         id_list.push(this.bumen_list[a].dept_id)
            //     }
            // }
            this.list[i].value = this.$refs.tree
              .getCheckedNodes()
              .map((item, index) => item.label)
              .join(",");
            this.list[i].dept_id = this.$refs.tree
              .getCheckedNodes()
              .map((item, index) => item.dept_id)
              .join(",");
          }
          this.bumen = false;
        }
      }
    },
    panduan(data, liucheng) {
      //////console.log(data)
      const list = [];
      //console.log(data)
      //console.log(liucheng)
      for (let i = 0; i < data.length; i++) {
        if (data[i].defaultImportant && data[i].componentName == "textfield") {
          if (data[i].value.length == 0) {
            list.push(data[i].defaultLable + "框问题");
          }
        }
        if (data[i].defaultImportant && data[i].componentName == "serialnum") {
          if (data[i].value == undefined || data[i].value.length == 0) {
            list.push(data[i].defaultLable + "框问题");
          }
        }
        if (data[i].defaultImportant && data[i].componentName == "cust") {
          if (data[i].value.length == 0) {
            list.push(data[i].defaultLable + "框问题");
          }
        }
        if (data[i].defaultImportant && data[i].componentName == "dddaterangefield") {
          if (data[i].value.length == 0) {
            list.push(data[i].defaultLable + "框问题");
          }
        }
        if (data[i].defaultImportant && data[i].componentName == "suppliername") {
          if (
            (this.date.flow_id == "5FotYgnu0Z" && data[i].value.length == 0) ||
            (this.date.flow_id != "5FotYgnu0Z" && data[i].value_id.length == 0)
          ) {
            list.push(data[i].defaultLable + "框问题");
          }
        }
        if (data[i].defaultImportant && data[i].componentName == "province") {
          if (
            data[i].value == undefined ||
            data[i].value.length == 0 ||
            data[i].value_id == "市"
          ) {
            list.push(data[i].defaultLable + "框问题");
          }
        }
        if (
          data[i].defaultImportant &&
          data[i].componentName == "ddselectfield" &&
          (data[i].defaultLable == "地址" ||
            data[i].defaultLable == "地区" ||
            data[i].defaultLable == "出发城市" ||
            data[i].defaultLable == "目的城市")
        ) {
          if (
            data[i].value == undefined ||
            data[i].value.length == 0 ||
            data[i].value_id == "市"
          ) {
            list.push(data[i].defaultLable + "输入框问题");
          }
        }
        if (data[i].defaultImportant && data[i].componentName == "numberfield") {
          if (data[i].value.length == 0) {
            list.push(data[i].defaultLable + "输入框问题");
          }
        }
        if (data[i].defaultImportant && data[i].componentName == "textnote") {
          if (data[i].value.length == 0) {
            list.push(data[i].defaultLable + "输入框问题");
          }
        }
        if (data[i].defaultImportant && data[i].componentName == "moneyfield") {
          if (data[i].value.length == 0) {
            list.push(data[i].defaultLable + "输入框问题");
          }
        }
        if (data[i].defaultImportant && data[i].componentName == "ddattachment") {
          if (data[i].list != undefined && data[i].list.length == 0) {
            list.push(data[i].defaultLable + "框问题");
          }
        }
        if (data[i].defaultImportant && data[i].componentName == "textareafield") {
          if (data[i].value != undefined && data[i].value.length == 0) {
            list.push(data[i].defaultLable + "框问题");
          }
        }
        if (data[i].defaultImportant && data[i].componentName == "contacts") {
          if (data[i].siyou != undefined) {
            if (data[i].value.length == 0) {
              list.push(data[i].defaultLable + "框问题");
            }
          } else if (data[i].defaultLable != "负责人" && data[i].value.length == 0) {
            list.push(data[i].defaultLable + "框问题");
          }
          if (
            this.date.flow_id == "5FotYgnu0Z" &&
            this.zhuangtai_shiyong_name == "已分配_编辑" &&
            (data[i].value == undefined || data[i].value.length == 0)
          ) {
            list.push(data[i].defaultLable + "框问题");
          }
          // if(data[i].staff_id.length==0){
          //     list.push(data[i].defaultLable+'框问题')
          // }
        }
        if (
          data[i].defaultImportant &&
          data[i].componentName == "客户" &&
          data[i].componentName == "textfield"
        ) {
          if (data[i].value_id) {
            list.push(data[i].defaultLable + "框问题");
          }
        }
        if (data[i].componentName == "tablefield") {
          const shuzu = data[i].components_list;
          for (let a = 0; a < shuzu.length; a++) {
            for (let b = 0; b < shuzu[a].length; b++) {
              if (shuzu[a][b].defaultImportant) {
                if (
                  (shuzu[a][b].value == undefined || shuzu[a][b].value.length == 0) &&
                  shuzu[a][b].componentName == "textfield" &&
                  shuzu[a][b].defaultLable != "仓库"
                ) {
                  list.push(shuzu[a][b].defaultLable + "的问题");
                }
                if (
                  (shuzu[a][b].value_id == undefined ||
                    shuzu[a][b].value_id.length == 0) &&
                  shuzu[a][b].componentName == "textfield" &&
                  shuzu[a][b].defaultLable == "仓库"
                ) {
                  list.push(shuzu[a][b].defaultLable + "的问题");
                }
                if (
                  (shuzu[a][b].value == undefined ||
                    shuzu[a][b].value.length == 0 ||
                    shuzu[a][b].value == "省") &&
                  shuzu[a][b].componentName == "province"
                ) {
                  list.push(shuzu[a][b].defaultLable + "的问题");
                }
                if (
                  (shuzu[a][b].value == undefined || shuzu[a][b].value.length == 0) &&
                  shuzu[a][b].componentName == "serialnum"
                ) {
                  list.push(shuzu[a][b].defaultLable + "的问题");
                }
                if (
                  (shuzu[a][b].value == undefined || shuzu[a][b].value.length == 0) &&
                  shuzu[a][b].componentName == "calcform"
                ) {
                  list.push(shuzu[a][b].defaultLable + "的问题");
                }
                if (
                  (shuzu[a][b].value == undefined || shuzu[a][b].value.length == 0) &&
                  shuzu[a][b].componentName == "suppliername"
                ) {
                  list.push(shuzu[a][b].defaultLable + "的问题");
                }
                if (
                  (shuzu[a][b].value == undefined || shuzu[a][b].value.length == 0) &&
                  shuzu[a][b].componentName == "dddatefield"
                ) {
                  list.push("明细" + shuzu[a][b].defaultLable + "的问题");
                }
                if (
                  (shuzu[a][b].value == undefined || shuzu[a][b].value.length == 0) &&
                  shuzu[a][b].componentName == "numberfield"
                ) {
                  list.push(shuzu[a][b].defaultLable + "的问题");
                }
                if (
                  shuzu[a][b].value_id.length == 0 &&
                  shuzu[a][b].componentName == "ddselectfield" &&
                  (shuzu[a][b].defaultLable == "出发城市" ||
                    shuzu[a][b].defaultLable == "目的城市")
                ) {
                  list.push(shuzu[a][b].defaultLable + "地区选择框的问题");
                }
                if (
                  (shuzu[a][b].value == undefined || shuzu[a][b].value.length == 0) &&
                  shuzu[a][b].componentName == "product"
                ) {
                  list.push(shuzu[a][b].defaultLable + "的问题");
                }
                if (
                  (shuzu[a][b].value == undefined || shuzu[a][b].value.length == 0) &&
                  shuzu[a][b].componentName == "moneyfield"
                ) {
                  list.push(shuzu[a][b].defaultLable + "的问题");
                }
                if (
                  shuzu[a][b].componentName == "ddselectfield" &&
                  shuzu[a][b].defaultLable == "决策人"
                ) {
                  if (shuzu[a][b].value_id.length == 0) {
                    list.push(shuzu[a][b].defaultLable + "的问题");
                  }
                }
                if (shuzu[a][b].defaultLable == "手机" && shuzu[a][b].value_id) {
                  list.push("手机号问题");
                }
                if (shuzu[a][b].defaultLable == "邮箱" && shuzu[a][b].value_id) {
                  list.push("邮箱问题");
                }
                if (
                  shuzu[a][b].defaultLable == "纳税识别号" &&
                  (shuzu[a][b].value_id || shuzu[a][b].value_id2)
                ) {
                  list.push("纳税识别号问题");
                }
              }
            }

            // if(data[i].components[a].defaultImportant){
            //     //////console.log(data[i].components[a])
            //     if(data[i].components[a].value.length==0&&data[i].components[a].componentName=='textfield'){
            //         list.push(data[i].components[a].defaultLable+'的问题')
            //     }
            //     if(data[i].components[a].value.length==0&&data[i].components[a].componentName=='dddatefield'){
            //         list.push('明细'+data[i].components[a].defaultLable+'的问题')
            //     }
            //     if(data[i].components[a].value.length==0&&data[i].components[a].componentName=='numberfield'){
            //         list.push(data[i].components[a].defaultLable+'的问题')
            //     }
            //     if(data[i].components[a].value_id.length==0&&data[i].components[a].componentName=='ddselectfield'&&(data[i].components[a].defaultLable=='出发城市'||data[i].components[a].defaultLable=='目的城市')){
            //         list.push(data[i].components[a].defaultLable+'地区选择框的问题')
            //     }
            //     if(data[i].components[a].value.length==0&&data[i].components[a].componentName=='product'){
            //         list.push(data[i].components[a].defaultLable+'的问题')
            //     }
            //     if(data[i].components[a].value==''&&data[i].components[a].componentName=='moneyfield'){
            //         list.push(data[i].components[a].defaultLable+'的问题')
            //     }
            //     if(data[i].components[a].componentName=='ddselectfield'&&data[i].components[a].defaultLable=='决策人'){
            //         if(data[i].components[a].value_id==''){
            //             list.push(data[i].components[a].defaultLable+'的问题')
            //         }
            //     }
            // }
            // if(this.list[i].components[a].defaultLable=='手机'&&this.list[i].components[a].value_id){
            //     list.push('手机号问题')
            // }
            // if(this.list[i].components[a].defaultLable=='邮箱'&&this.list[i].components[a].value_id){
            //     list.push('邮箱问题')
            // }
            // if(this.list[i].components[a].defaultLable=='纳税识别号'&&(this.list[i].components[a].value_id||this.list[i].components[a].value_id2)){
            //     list.push('纳税识别号问题')
            // }
          }
        }

        if (data[i].defaultImportant) {
          if (data[i].componentName == "dddatefield") {
            if (data[i].value.length == 0) {
              list.push("时间选择问题");
            }
          }
          if (data[i].componentName == "department" && data[i].defaultLable == "部门") {
            if (data[i].dept_id == undefined) {
              list.push("部门未选择问题");
            }
          }
          if (
            data[i].componentName == "ddselectfield" &&
            data[i].defaultLable == "地区"
          ) {
            //////console.log(data[i])

            if (
              data[i].value_id.length == 0 ||
              data[i].value_id == undefined ||
              data[i].value_id == 0
            ) {
              list.push("地区问题");
            }
          }
          if (
            data[i].componentName == "ddmultiselectfield" &&
            this.date.flow_id != "7TvJHPyovM"
          ) {
            const duoxuan = [];
            for (let a = 0; a < data[i].defaultOptions.length; a++) {
              if (!data[i].defaultOptions[a].zhi) {
                duoxuan.push("1111");
              }
            }
            if (duoxuan.length == data[i].defaultOptions.length) {
              list.push("多选框问题");
            }
          }
          if (
            data[i].componentName == "ddmultiselectfield" &&
            data[i].defaultLable == "客户标签" &&
            this.date.flow_id == "7TvJHPyovM"
          ) {
            if (data[i].value_id == 0 || data[i].value_id == undefined) {
              list.push("客户标签问题");
            }
          }
        }
      }
      if (this.zhuguan) {
        if (this.zhuguan_xuanze_bumen_id.length == 0) {
          list.push("请选择现部门");
        }
      }

      this.errorList = list
      // if (list.length != 0) {
      //   // if(liucheng==undefined){
      //   //     alert(list)
      //   // }
      // }

      // console.log('listlistlistlistlistlistlistlistlist', list)

      if (list.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    // 时间小于10前面加0
    pa(s) {
      return s < 10 ? "0" + s : s;
    },
    // 查看客户是否存在
    kehu_sf_cunzai(i, it) {
      if (i.length != 0) {
        this.kehu_shifou_cunzai(i, it);
      }
    },
    kehu_shifou_cunzai(i, it) {
      const kehu_name = i;
      // for(let i=0;i<this.list.length;i++){
      //     if(this.list[i].componentName=='textfield'&&this.list[i].defaultLable=='客户'&&this.date.flow_id=='7TvJHPyovM'&&this.list[i].value.length!=0){
      //         kehu_name=this.list[i].value
      //     }
      // }
      if (kehu_name.length != 0) {
        if (kehu_name != this.jiu_kehu_name) {
          customer_have_exist({
            data: {
              ent_id: this.$ent_id(),
              cust_name: kehu_name,
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 600) {
              it.value_id = true;
            } else if (res.data.code == 700) {
              it.value_id = false;
            } else if (res.data.code == 500) {
              alert("小虎哥，报错了");
            }
          });
        } else {
          it.value_id = false;
        }
      }
    },
    // 查看纳税识别号
    yingye_zhizhao(i, it) {
      // /^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$/
      //////console.log('纳税识别号')
      if (/^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$/.test(i)) {
        //
        if (this.date.flow_id != "VMCizuVOXb") {
          biz_lic_no_have_exist({
            data: {
              ent_id: this.$ent_id(),
              biz_lic_no: i,
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 600) {
              it.value_id2 = true;
            } else if (res.data.code == 700) {
              it.value_id2 = false;
            } else if (res.data.code == 500) {
              alert("小虎哥，报错了");
            }
          });
        }
      } else if (i.length != 0) {
        it.value_id = true;
      } else if (i.length == 0) {
        it.value_id = "";
      }
    },
    group(array, subGroupLength) {
      let index = 0;
      const newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    dangqian_jieduan(i) {
      if (i == "初步接触") {
        return "1";
      } else if (i == "需求确认") {
        return "2";
      } else if (i == "报价及方案展示") {
        return "3";
      } else if (i == "商务谈判") {
        return "4";
      } else if (i == "赢单") {
        return "5";
      } else if (i == "输单") {
        return "6";
      }
    },
    jieduan_hanzi(i) {
      if (i == "1") {
        return "初步接触";
      } else if (i == "2") {
        return "需求确认";
      } else if (i == "3") {
        return "报价及方案展示";
      } else if (i == "4") {
        return "商务谈判";
      } else if (i == "5") {
        return "赢单";
      } else if (i == "6") {
        return "输单";
      }
    },
    // 编辑接口给他付标签
    bianji_jiekou() {
      update_edit_flag({
        data: {
          ent_id: this.$ent_id(),
          flow_no: this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_4", "暂时").flow_no,
        },
      });
    },

    // 点击提交
    dianji_tijiao() {
      // //console.log(this.huikuandan,this.jichuxinxi.user_id,'adsadadadsadasdaadsad')
      //  //console.log( this.vipData,'这是data的数据')
      // const  len = this.list[0].flow_id
      // switch ( len ){
      //       case 'AY3cli7BAb':
      //         this.query_insert_member_topup(fasong_list);
      //         return
      //       break;
      // }
      // 流程里是否有人
      // if(this.liucheng_jiazai_wanbi){
      const fasong_list = [
        {
          ent_id: this.$ent_id(),
          user_type: "0",
          flow_id: this.date.flow_id,
          user_list: [
            {
              staff_id: this.jichuxinxi.user_id,
              staff_name: this.jichuxinxi.name,
            },
          ],
          // flag_replace_write:this.dai_taren_tijiao?'1':'0',//代写标志：1代写，0不代写
          // replace_write_user_id:this.$jichuxinxi().user_id,
          // replace_write_user_name:this.$jichuxinxi().name
        },
      ];
      //////console.log(this.liucheng_list)
      for (let fs = 0; fs < this.liucheng_list.length; fs++) {
        this.liucheng_list[fs].zixuan_list.forEach((item) => {
          if (this.liucheng_list[fs].edit_list != undefined) {
            this.$set(item, "edit_list", this.liucheng_list[fs].edit_list);
          }
          this.$set(item, "auto_appr_flag", item.zidong_tongguo ? "1" : "0");
        });
        if (this.liucheng_list[fs].shenpi_ren_kong == undefined) {
          //console.log(this.liucheng_list[fs].flow_id, 921323)
          //////console.log(this.liucheng_list[fs].zixuan_list)
          fasong_list.push({
            ent_id: this.$ent_id(),
            flow_id: this.liucheng_list[fs].flow_id,
            user_type:
              this.liucheng_list[fs].user_type != undefined
                ? this.liucheng_list[fs].user_type
                : null,
            appr_sort:
              this.liucheng_list[fs].appr_sort != undefined
                ? this.liucheng_list[fs].zixuan_list.length == 1
                  ? "1"
                  : this.liucheng_list[fs].appr_sort
                : null,
            select_type:
              this.liucheng_list[fs].select_type != undefined
                ? this.liucheng_list[fs].select_type
                : null,
            self_select_type:
              this.liucheng_list[fs].self_select_type != undefined
                ? this.liucheng_list[fs].self_select_type
                : null,
            role_name:
              this.liucheng_list[fs].role_name != undefined
                ? this.liucheng_list[fs].role_name
                : null,
            user_list: this.liucheng_list[fs].zixuan_list,
            edit_list:
              this.liucheng_list[fs].edit_list != undefined
                ? this.liucheng_list[fs].edit_list
                : null,
            // .map(usfs=>usfs.staff_id).join(','),
            select_flag:
              this.liucheng_list[fs].select_flag != undefined
                ? this.liucheng_list[fs].select_flag
                : null,
            copy_self_select:
              this.liucheng_list[fs].copy_self_select != undefined
                ? this.liucheng_list[fs].copy_self_select
                : null,
          });
        }
      }
      const fasong_cuowu = [];
      for (let fscw = 0; fscw < fasong_list.length; fscw++) {
        if (
          fasong_list[fscw].user_list.length == 0 &&
          fasong_list[fscw].shenpi_ren_kong == undefined
        ) {
          fasong_cuowu.push("11111");
        }
      }
      // if(fasong_list.length!=1){
      if (
        this.liucheng_dept_id != undefined &&
        this.liucheng_dept_id.length != 0 &&
        fasong_cuowu.length == 0
      ) {
        const len = this.list[0].flow_id;
        console.log(fasong_list);
        switch (len) {
          case "AY3cli7BAb":
            this.query_insert_member_topup(fasong_list);
            return;
            break;
        }
        if (this.date.flow_id == "7TvJHPyovM") {
          // 客户录入单
          let shifou_fenpei = "0";
          if (
            this.zhuangtai_shiyong_name == "已分配" ||
            this.zhuangtai_shiyong_name == "已分配_编辑" ||
            this.zhuangtai_shiyong_name == "审批中心"
          ) {
            shifou_fenpei = "1";
          }
          if (this.zhuangtai_shiyong_name == "公海池_编辑") {
            shifou_fenpei = "0";
          }
          const suiji = this.$func.randomstring(25);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id,
            flag_allot: shifou_fenpei, /// /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            flow_no: suiji,
            cust_name: "", // 客户
            staff_id: null, // 负责人
            dept_id: "", // 部门
            province: "", // 省份
            city: "", // 城市
            industry: "", // 行业
            source: "", // 客户来源
            level: "", // 客户等级
            tag: "", // 客户标签
            tag_name: "",
            biz_addr: "", // 经营地址
            rel_p_com: "", // 关联母公司
            register_addr: "", // 注册地址
            legal_name: "", // 法定代表人
            biz_lic_no: "", // 纳税识别号
            register_capital: "", // 注册资本
            register_date: "", // 成立日期
            biz_term: "", // 营业期限
            biz_scope: "", // 经营范围
            biz_lic_pic: "", // img64
            biz_lic_pic_type: "", // type
            list: [],
            extra: [],
          };
          // setTimeout(() => {
          // }, 0)
          // //////console.log(this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_4','暂时').flow_no)
          // //////console.log(obj.flow_no)

          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "客户"
              ) {
                obj.cust_name = this.list[i].value;
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                if (this.zhuangtai_shiyong_name != "公海池") {
                  obj.staff_id = this.jichuxinxi.user_id;
                } else {
                  obj.staff_id = null;
                  // this.list[i].staff_id
                }
                // if(this.list[i].staff_id==''||this.list[i].staff_id==undefined){
                //     obj.staff_id=null
                // }else{
                //     obj.staff_id=this.list[i].staff_id
                // }
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  if (this.zhuguan) {
                    // let arr=[]
                    // this.zhuguan_xuanze_bumen_id.forEach(ite=>{
                    //     arr.push({id:ite})
                    // })
                    // //////console.log(this.list[i])
                    // this.list[i].dept_idid.forEach(it=>{
                    //     arr.push({
                    //         id:it
                    //     })
                    // })
                    // // arr.push({id:this.list[i].dept_id})
                    // //////console.log(this.$func.Es5duplicate(arr,'id'))
                    // obj.dept_id=this.$func.Es5duplicate(arr,'id').map(it=>it.id).join(',')
                    obj.dept_id = this.zhuguan_xuanze_bumen_id
                      .map((ite) => ite)
                      .join(",");
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else if (this.bumen_zhuguan) {
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                    obj.dept_id = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else {
                    obj.dept_id = this.list[i].dept_id;
                  }
                }
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "行业"
              ) {
                obj.industry = this.list[i].defaultOptions[
                  Number(this.list[i].value)
                ].text;
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "地区"
              ) {
                obj.province = this.list[i].value;
                obj.city = this.list[i].value_id;
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "客户来源"
              ) {
                obj.source = this.list[i].defaultOptions[Number(this.list[i].value)].text;
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "客户等级"
              ) {
                obj.level = this.list[i].defaultOptions[Number(this.list[i].value)].text;
              }
              if (
                this.list[i].componentName == "ddmultiselectfield" &&
                this.list[i].defaultLable == "客户标签"
              ) {
                obj.tag = this.list[i].value_id;
                obj.tag_name = this.list[i].value;
              }
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "经营地址"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.biz_addr = null;
                } else {
                  obj.biz_addr = this.list[i].value;
                }
              }
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "母公司名称"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.rel_p_com = null;
                } else {
                  obj.rel_p_com = this.list[i].value;
                }
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "决策人"
              ) {
                // let length=''
                // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                // for(let a=0;a<data.length;a++){
                //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                //         length=Number(data[a].components.length)+1
                //     }
                // }
                // let shuzu=this.group(this.list[i].components,length)
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                // let length_a=Number(this.list[i].components.length)/length
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    name: null,
                    sex: null,
                    dept: null,
                    post: null,
                    fixed_phone: null,
                    // mobile: null,
                    birthday: null,
                    interest: null,
                    qq: null,
                    wechat: null,
                    email: null,
                    card: null,
                    card_type: null,
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "姓名"
                    ) {
                      obj_a.name = shuzu[d][e].value;
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "部门"
                    ) {
                      obj_a.dept = shuzu[d][e].value;
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "职务"
                    ) {
                      obj_a.post = shuzu[d][e].value;
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "微信"
                    ) {
                      if (shuzu[d][e].value.length != 0) {
                        obj_a.wechat = shuzu[d][e].value;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "邮箱"
                    ) {
                      if (shuzu[d][e].value.length != 0) {
                        obj_a.email = shuzu[d][e].value;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "座机"
                    ) {
                      if (shuzu[d][e].value.length != 0) {
                        obj_a.fixed_phone = shuzu[d][e].value;
                      }
                    }
                    // if (shuzu[d][e].componentName == 'numberfield' && shuzu[d][e].defaultLable == '手机') {
                    //   obj_a.mobile = shuzu[d][e].value
                    // }
                    if (
                      shuzu[d][e].componentName == "ddselectfield" &&
                      shuzu[d][e].defaultLable == "性别"
                    ) {
                      obj_a.sex =
                        shuzu[d][e].defaultOptions[Number(shuzu[d][e].value)].text;
                    }
                    if (
                      shuzu[d][e].componentName == "dddatefield" &&
                      shuzu[d][e].defaultLable == "生日"
                    ) {
                      if (
                        shuzu[d][e].value.length == 0 ||
                        shuzu[d][e].value == undefined
                      ) {
                      } else {
                        obj_a.birthday =
                          this.pa(shuzu[d][e].value.getDate()) != NaN
                            ? shuzu[d][e].value.getFullYear() +
                              "-" +
                              this.pa(shuzu[d][e].value.getMonth() + 1) +
                              "-" +
                              this.pa(shuzu[d][e].value.getDate())
                            : null;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "textnote" &&
                      shuzu[d][e].defaultProps == "爱好"
                    ) {
                      if (
                        shuzu[d][e].value.length == 0 ||
                        shuzu[d][e].value == undefined
                      ) {
                      } else {
                        obj_a.interest = shuzu[d][e].value;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "textnote" &&
                      shuzu[d][e].defaultProps == "营业范围"
                    ) {
                      if (shuzu[d][e].value == "" || shuzu[d][e].value == undefined) {
                      } else {
                        obj_a.interest = shuzu[d][e].value;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "QQ"
                    ) {
                      if (shuzu[d][e].value == "" || shuzu[d][e].value == undefined) {
                      } else {
                        obj_a.qq = shuzu[d][e].value;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "ddphotofield" &&
                      shuzu[d][e].defaultLable == "名片"
                    ) {
                      if (
                        shuzu[d][e].imgBase64 == "" ||
                        shuzu[d][e].imgBase64 == undefined
                      ) {
                      } else {
                        obj_a.card = shuzu[d][e].xin;
                        obj_a.card_type = shuzu[d][e].lei;
                      }
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.list = list_a;
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "公司信息"
              ) {
                const shuzu = this.list[i].components_list;
                for (let a = 0; a < shuzu.length; a++) {
                  for (let b = 0; b < shuzu[a].length; b++) {
                    if (
                      shuzu[a][b].componentName == "textfield" &&
                      shuzu[a][b].defaultLable == "注册地址"
                    ) {
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.register_addr = null;
                      } else {
                        obj.register_addr = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "textfield" &&
                      shuzu[a][b].defaultLable == "法定代表人"
                    ) {
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.legal_name = null;
                      } else {
                        obj.legal_name = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "textfield" &&
                      shuzu[a][b].defaultLable == "纳税识别号"
                    ) {
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.biz_lic_no = null;
                      } else {
                        obj.biz_lic_no = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "textfield" &&
                      shuzu[a][b].defaultLable == "注册资本"
                    ) {
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.register_capital = null;
                      } else {
                        obj.register_capital = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "dddatefield" &&
                      shuzu[a][b].defaultLable == "成立日期"
                    ) {
                      if (
                        shuzu[a][b].value.length == 0 ||
                        shuzu[a][b].value == undefined
                      ) {
                        obj.register_date = null;
                      } else {
                        if (isNaN(shuzu[a][b].value.getFullYear())) {
                          obj.register_date = null;
                        } else {
                          obj.register_date =
                            shuzu[a][b].value.getFullYear() +
                            "-" +
                            this.pa(shuzu[a][b].value.getMonth() + 1) +
                            "-" +
                            this.pa(shuzu[a][b].value.getDate());
                        }
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "dddaterangefield" &&
                      shuzu[a][b].defaultLable == "营业期限"
                    ) {
                      if (
                        shuzu[a][b].value.length == 0 ||
                        shuzu[a][b].value == undefined
                      ) {
                        obj.biz_term = null;
                      } else {
                        if (isNaN(shuzu[a][b].value[0].getFullYear())) {
                          obj.biz_term = null;
                        } else {
                          obj.biz_term =
                            shuzu[a][b].value[0].getFullYear() +
                            "-" +
                            this.pa(shuzu[a][b].value[0].getMonth() + 1) +
                            "-" +
                            this.pa(shuzu[a][b].value[0].getDate()) +
                            "至" +
                            shuzu[a][b].value[1].getFullYear() +
                            "-" +
                            this.pa(shuzu[a][b].value[1].getMonth() + 1) +
                            "-" +
                            this.pa(shuzu[a][b].value[1].getDate());
                        }
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "textnote" &&
                      shuzu[a][b].defaultProps == "营业范围"
                    ) {
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.biz_scope = null;
                      } else {
                        obj.biz_scope = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "ddphotofield" &&
                      shuzu[a][b].defaultLable == "营业执照"
                    ) {
                      if (
                        shuzu[a][b].imgBase64 == "" ||
                        shuzu[a][b].imgBase64 == undefined
                      ) {
                        obj.biz_lic_pic = null;
                        obj.biz_lic_pic_type = null;
                      } else {
                        obj.biz_lic_pic = shuzu[a][b].xin;
                        obj.biz_lic_pic_type = shuzu[a][b].lei;
                      }
                    }
                  }
                }
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)
            query_user_info({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.jichuxinxi.user_id,
              },
            }).then((bumen) => {
              //////console.log(bumen)
              if (bumen.data.code == 200) {
                this.$set(obj, "operate_dept_id", bumen.data.body.dept_ids);
                if (
                  this.zhuangtai_shiyong_name == "公海池_编辑" &&
                  this.zhuguan &&
                  obj.cust_name != this.jiu_kehu_name
                ) {
                  customer_have_exist({
                    data: {
                      ent_id: this.$ent_id(),
                      cust_name: obj.cust_name,
                    },
                  }).then((res) => {
                    //////console.log(res)
                    if (res.data.code == 600) {
                      this.$message({
                        message: "客户姓名重复",
                        type: "warning",
                      });
                    } else if (res.data.code == 700) {
                      this.$set(obj, "cust_id", this.yi_fenpei_bianji.cust_id);
                      update_customer_form({ data: obj }).then((ras) => {
                        //console.log(ras)
                        if (ras.data.code == 200) {
                          //////console.log(ras)
                          add_appr_flow_step_list({
                            data: {
                              list: fasong_list,
                              flow_no: suiji,
                            },
                          })
                            .then((rfs) => {
                              //////console.log(rfs)
                              if (rfs.data.code == 200) {
                                start_flow_action({
                                  data: {
                                    ent_id: this.$ent_id(),
                                    user_id: this.jichuxinxi.user_id,
                                    dept_id: this.liucheng_dept_id,
                                    flow_id: this.date.flow_id,
                                    flow_no: suiji,
                                    cust_name: obj.cust_name,
                                  },
                                }).then((rbs) => {
                                  //////console.log(rbs)
                                  if (rbs.data.code == 200) {
                                    this.dai_tijiao_ren(suiji);
                                    this.$message({
                                      message: "提交成功",
                                      type: "success",
                                    });
                                    this.bianji_jiekou();
                                    window.history.go(-1);
                                  } else if (rbs.data.code == 500) {
                                  } else if (rbs.data.code == 10246) {
                                    this.$message({
                                      message: "没有设置审批流程",
                                      type: "warning",
                                    });
                                  }
                                });
                              } else if (rfs.data.code == 500) {
                              }
                            })
                            .catch((error) => {
                              //console.log(error)
                            });
                        } else if (ras.data.code == 500) {
                        } else if (ras.data.code == 12100) {
                          this.$message({
                            message: "纳税识别号已存在",
                            type: "warning",
                          });
                        }
                      });
                    } else if (res.data.code == 500) {
                      alert("小虎哥，报错了");
                    }
                  });
                } else if (
                  this.zhuangtai_shiyong_name == "已分配_编辑" ||
                  (this.zhuangtai_shiyong_name == "公海池_编辑" &&
                    (!this.zhuguan || this.jiu_kehu_name == obj.cust_name))
                ) {
                  this.$set(obj, "cust_id", this.yi_fenpei_bianji.cust_id);
                  update_customer_form({ data: obj }).then((ras) => {
                    if (ras.data.code == 200) {
                      //////console.log(ras)
                      add_appr_flow_step_list({
                        data: {
                          list: fasong_list,
                          flow_no: suiji,
                        },
                      }).then((rfs) => {
                        //////console.log(rfs)
                        if (rfs.data.code == 200) {
                          start_flow_action({
                            data: {
                              ent_id: this.$ent_id(),
                              user_id: this.jichuxinxi.user_id,
                              dept_id: this.liucheng_dept_id,
                              flow_id: this.date.flow_id,
                              flow_no: suiji,
                              cust_name: obj.cust_name,
                            },
                          }).then((rbs) => {
                            //////console.log(rbs)
                            if (rbs.data.code == 200) {
                              this.dai_tijiao_ren(suiji);
                              this.$message({
                                message: "提交成功",
                                type: "success",
                              });
                              this.bianji_jiekou();
                              window.history.go(-1);
                            } else if (rbs.data.code == 500) {
                            } else if (rbs.data.code == 10246) {
                              this.$message({
                                message: "没有设置审批流程",
                                type: "warning",
                              });
                            }
                          });
                        } else if (rfs.data.code == 500) {
                        }
                      });
                    } else if (ras.data.code == 500) {
                    } else if (ras.data.code == 12100) {
                      this.$message({
                        message: "纳税识别号已存在",
                        type: "warning",
                      });
                    }
                  });
                } else {
                  customer_have_exist({
                    data: {
                      ent_id: this.$ent_id(),
                      cust_name: obj.cust_name,
                    },
                  }).then((res) => {
                    //////console.log(res)
                    if (res.data.code == 600) {
                      this.$message({
                        message: "客户姓名重复",
                        type: "warning",
                      });
                    } else if (res.data.code == 700) {
                      insert_customer_form({ data: obj }).then((ras) => {
                        if (ras.data.code == 200) {
                          //////console.log(ras)
                          add_appr_flow_step_list({
                            data: {
                              list: fasong_list,
                              flow_no: suiji,
                            },
                          }).then((rfs) => {
                            //////console.log(rfs)
                            if (rfs.data.code == 200) {
                              start_flow_action({
                                data: {
                                  ent_id: this.$ent_id(),
                                  user_id: this.jichuxinxi.user_id,
                                  dept_id: this.liucheng_dept_id,
                                  flow_id: this.date.flow_id,
                                  flow_no: suiji,
                                  cust_name: obj.cust_name,
                                },
                              }).then((rbs) => {
                                //////console.log(rbs)
                                if (rbs.data.code == 200) {
                                  this.dai_tijiao_ren(suiji);
                                  this.$message({
                                    message: "提交成功",
                                    type: "success",
                                  });
                                  window.history.go(-1);
                                } else if (rbs.data.code == 500) {
                                } else if (rbs.data.code == 10246) {
                                  this.$message({
                                    message: "没有设置审批流程",
                                    type: "warning",
                                  });
                                }
                              });
                            } else if (rfs.data.code == 500) {
                            }
                          });
                        } else if (ras.data.code == 500) {
                        } else if (ras.data.code == 600) {
                          this.$message({
                            message: "客户姓名重复",
                            type: "warning",
                          });
                        } else if (ras.data.code == 12100) {
                          this.$message({
                            message: "纳税识别号已存在",
                            type: "warning",
                          });
                        }
                      });
                    } else if (res.data.code == 500) {
                      alert("小虎哥，报错了");
                    }
                  });
                }
              } else if (bumen.data.code == 500) {
              }
            });
          }
        } else if (this.date.flow_id == "Z7VDOK9HGu") {
          // 访客计划单
          const suiji = new Date().getTime() + this.$func.randomstring(5);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id,
            flow_no: suiji,
            file_no: "",
            cust_name: "",
            cust_id: "",
            staff_id: "",
            dept_id: "",
            visit_type: "",
            visit_time: "",
            contact: "",
            current_stage: "",
            next_stage: "",
            remarks: "",
            extra: [],
          };
          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "客户"
              ) {
                obj.cust_name = this.list[i].value;
                obj.cust_id = this.list[i].kehu_list[this.list[i].zhanshi].cust_id;
                obj.file_no = this.list[i].kehu_list[this.list[i].zhanshi].file_no;
                // obj.staff_id=this.list[i].kehu_list[this.list[i].zhanshi].staff_id
                // obj.dept_id=this.list[i].kehu_list[this.list[i].zhanshi].dept_id
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
                // if(this.list[i].staff_id==''||this.list[i].staff_id==undefined){
                //     obj.staff_id=null
                // }else{
                //     obj.staff_id=this.list[i].staff_id
                // }
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  obj.dept_id = this.list[i].dept_id;
                }
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "拜访方式"
              ) {
                obj.visit_type = this.list[i].defaultOptions[
                  Number(this.list[i].value)
                ].text;
              }
              if (
                this.list[i].componentName == "dddatefield" &&
                this.list[i].defaultLable == "拜访时间"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.visit_time = null;
                } else {
                  obj.visit_time =
                    this.list[i].value.getFullYear() +
                    "-" +
                    this.pa(this.list[i].value.getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value.getDate()) +
                    "     " +
                    this.pa(this.list[i].value.getHours()) +
                    ":" +
                    this.pa(this.list[i].value.getMinutes());
                }
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "拜访目标"
              ) {
                // let length=''
                // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                // for(let a=0;a<data.length;a++){
                //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                //         length=Number(data[a].components.length)+1
                //     }
                // }
                // let shuzu=this.group(this.list[i].components,length)
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                for (let d = 0; d < shuzu.length; d++) {
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "决策人"
                    ) {
                      obj.contact = shuzu[d][e].value;
                    }
                    if (
                      shuzu[d][e].componentName == "ddselectfield" &&
                      shuzu[d][e].defaultLable == "漏斗现阶段"
                    ) {
                      //////console.log(shuzu[d][e].defaultOptions[Number(shuzu[d][e].value)].text)
                      obj.current_stage = this.dangqian_jieduan(
                        shuzu[d][e].defaultOptions[Number(shuzu[d][e].value)].text
                      );
                    }
                    if (
                      shuzu[d][e].componentName == "ddselectfield" &&
                      shuzu[d][e].defaultLable == "预计下一漏斗阶段"
                    ) {
                      obj.next_stage = this.dangqian_jieduan(
                        shuzu[d][e].defaultOptions[Number(shuzu[d][e].value)].text
                      );
                    }
                    if (
                      shuzu[d][e].componentName == "textnote" &&
                      shuzu[d][e].defaultProps == "备注"
                    ) {
                      if (shuzu[d][e].value == "" || shuzu[d][e].value == undefined) {
                        obj.remarks = null;
                      } else {
                        obj.remarks = shuzu[d][e].value;
                      }
                    }
                  }
                }
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)
            insert_s_visit_plan({ data: obj }).then((res) => {
              if (res.data.code == 200) {
                add_appr_flow_step_list({
                  data: {
                    list: fasong_list,
                    flow_no: suiji,
                  },
                }).then((rfs) => {
                  //////console.log(rfs)
                  if (rfs.data.code == 200) {
                    start_flow_action({
                      data: {
                        ent_id: this.$ent_id(),
                        user_id: this.jichuxinxi.user_id,
                        dept_id: this.liucheng_dept_id,
                        flow_id: this.date.flow_id,
                        flow_no: suiji,
                        cust_name: obj.cust_name,
                      },
                    }).then((rbs) => {
                      //////console.log(rbs)
                      if (rbs.data.code == 200) {
                        this.dai_tijiao_ren(suiji);
                        this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        window.history.go(-1);
                      } else if (rbs.data.code == 500) {
                      } else if (rbs.data.code == 10246) {
                        this.$message({
                          message: "没有设置审批流程",
                          type: "warning",
                        });
                      }
                    });
                  } else if (rfs.data.code == 500) {
                  }
                });
              } else if (res.data.code == 500) {
              }
            });
          }
        } else if (this.date.flow_id == "5FotYgnu0Z") {
          // 供应商录入单
          // let shifou_fenpei='0'
          // if(this.zhuangtai_shiyong_name=='已分配'||this.zhuangtai_shiyong_name=='已分配_编辑'||this.zhuangtai_shiyong_name=='审批中心'){
          //     shifou_fenpei='1'
          // }
          // if(this.zhuangtai_shiyong_name=='公海池_编辑'){
          //     shifou_fenpei='0'
          // }
          const suiji = this.$func.randomstring(25);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id, /// /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            flow_no: suiji,
            staff_id: null,
            supply_name: "", // 客户
            dept_id: "", // 部门
            province: "", // 省份
            city: "", // 城市
            industry: "", // 行业
            source: "", // 客户来源
            level: "", // 客户等级
            tag: "", // 客户标签
            tag_name: "",
            biz_addr: "", // 经营地址
            rel_p_com: "", // 关联母公司
            register_addr: "", // 注册地址
            legal_name: "", // 法定代表人
            uscc: "", // 纳税识别号
            register_capital: "", // 注册资本
            register_date: "", // 成立日期
            biz_term: "", // 营业期限
            biz_scope: "", // 经营范围
            biz_lic_pic: "", // img64
            biz_lic_pic_type: "", // type

            ent_nature: "", // 企业性质
            bank_name: "", // 开户行名称
            bank_acct: "", // 银行账号
            open_acct_pic: "", // 开户许可证
            open_acct_pic_type: "", // 许可证的类型
            list: [],
            extra: [],
          };
          // setTimeout(() => {
          // }, 0)
          // //////console.log(this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_4','暂时').flow_no)
          // //////console.log(obj.flow_no)

          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "suppliername" &&
                this.list[i].defaultLable == "供应商名称"
              ) {
                obj.supply_name = this.list[i].value;
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                if (this.zhuangtai_shiyong_name == "公海池_编辑") {
                  obj.staff_id = this.list[i].staff_id;
                  obj.staff_name = this.list[i].value;
                } else {
                  obj.staff_id = this.jichuxinxi.user_id;
                }
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  if (this.zhuguan) {
                    obj.dept_id = this.zhuguan_xuanze_bumen_id
                      .map((ite) => ite)
                      .join(",");
                    //////console.log(this.list[i])

                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else if (this.bumen_zhuguan) {
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                    obj.dept_id = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else {
                    obj.dept_id = this.list[i].dept_id;
                  }
                }
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "行业"
              ) {
                obj.industry = this.list[i].defaultOptions[
                  Number(this.list[i].value)
                ].text;
              }
              if (
                this.list[i].componentName == "province" &&
                this.list[i].defaultLable == "省市区"
              ) {
                obj.province = this.list[i].value;
                obj.city = this.list[i].value_id;
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "供应商来源"
              ) {
                obj.source = this.list[i].defaultOptions[Number(this.list[i].value)].text;
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "供应商等级"
              ) {
                obj.level = this.list[i].defaultOptions[Number(this.list[i].value)].text;
              }
              if (
                this.list[i].componentName == "ddmultiselectfield" &&
                this.list[i].defaultLable == "供应商标签"
              ) {
                obj.tag =
                  this.list[i].value_id != undefined && this.list[i].value_id.length != 0
                    ? this.list[i].value_id
                    : null;
                obj.tag_name =
                  this.list[i].value_id != undefined && this.list[i].value.length != 0
                    ? this.list[i].value
                    : null;
              }
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "经营地址"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.biz_addr = null;
                } else {
                  obj.biz_addr = this.list[i].value;
                }
              }
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "母公司名称"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.rel_p_com = null;
                } else {
                  obj.rel_p_com = this.list[i].value;
                }
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "业务联系人"
              ) {
                // let length=''
                // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                // for(let a=0;a<data.length;a++){
                //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                //         length=Number(data[a].components.length)+1
                //     }
                // }
                // let shuzu=this.group(this.list[i].components,length)
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                // let length_a=Number(this.list[i].components.length)/length
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    name: null,
                    sex: null,
                    dept: null,
                    post: null,
                    fixed_phone: null,
                    mobile: null,
                    birthday: null,
                    interest: null,
                    qq: null,
                    wechat: null,
                    email: null,
                    card: null,
                    card_type: null,
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "姓名"
                    ) {
                      obj_a.name = shuzu[d][e].value;
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "部门"
                    ) {
                      obj_a.dept =
                        shuzu[d][e].value != undefined && shuzu[d][e].value.length != 0
                          ? shuzu[d][e].value
                          : null;
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "职务"
                    ) {
                      obj_a.post =
                        shuzu[d][e].value != undefined && shuzu[d][e].value.length != 0
                          ? shuzu[d][e].value
                          : null;
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "微信"
                    ) {
                      if (
                        shuzu[d][e].value != undefined &&
                        shuzu[d][e].value.length != 0
                      ) {
                        obj_a.wechat = shuzu[d][e].value;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "邮箱"
                    ) {
                      if (
                        shuzu[d][e].value != undefined &&
                        shuzu[d][e].value.length != 0
                      ) {
                        obj_a.email = shuzu[d][e].value;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "座机"
                    ) {
                      if (
                        shuzu[d][e].value != undefined &&
                        shuzu[d][e].value.length != 0
                      ) {
                        obj_a.fixed_phone = shuzu[d][e].value;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "手机"
                    ) {
                      obj_a.mobile =
                        shuzu[d][e].value != undefined && shuzu[d][e].value.length != 0
                          ? shuzu[d][e].value
                          : null;
                    }
                    if (
                      shuzu[d][e].componentName == "ddselectfield" &&
                      shuzu[d][e].defaultLable == "性别"
                    ) {
                      obj_a.sex =
                        shuzu[d][e].defaultOptions[Number(shuzu[d][e].value)].text;
                    }
                    if (
                      shuzu[d][e].componentName == "dddatefield" &&
                      shuzu[d][e].defaultLable == "生日"
                    ) {
                      if (
                        shuzu[d][e].value.length == 0 ||
                        shuzu[d][e].value == undefined
                      ) {
                      } else {
                        obj_a.birthday =
                          this.pa(shuzu[d][e].value.getDate()) != NaN
                            ? shuzu[d][e].value.getFullYear() +
                              "-" +
                              this.pa(shuzu[d][e].value.getMonth() + 1) +
                              "-" +
                              this.pa(shuzu[d][e].value.getDate())
                            : null;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "textnote" &&
                      shuzu[d][e].defaultProps == "爱好"
                    ) {
                      if (
                        shuzu[d][e].value.length == 0 ||
                        shuzu[d][e].value == undefined
                      ) {
                      } else {
                        obj_a.interest = shuzu[d][e].value;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "textnote" &&
                      shuzu[d][e].defaultProps == "营业范围"
                    ) {
                      if (shuzu[d][e].value == "" || shuzu[d][e].value == undefined) {
                      } else {
                        obj_a.biz_scope = shuzu[d][e].value;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "QQ"
                    ) {
                      if (shuzu[d][e].value == "" || shuzu[d][e].value == undefined) {
                      } else {
                        obj_a.qq = shuzu[d][e].value;
                      }
                    }
                    if (
                      shuzu[d][e].componentName == "ddphotofield" &&
                      shuzu[d][e].defaultLable == "名片"
                    ) {
                      if (
                        shuzu[d][e].imgBase64 == "" ||
                        shuzu[d][e].imgBase64 == undefined
                      ) {
                      } else {
                        obj_a.card = shuzu[d][e].xin;
                        obj_a.card_type = shuzu[d][e].lei;
                      }
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.list = list_a;
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "公司信息"
              ) {
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                for (let a = 0; a < shuzu.length; a++) {
                  for (let b = 0; b < shuzu[a].length; b++) {
                    if (
                      shuzu[a][b].componentName == "textfield" &&
                      shuzu[a][b].defaultLable == "注册地址"
                    ) {
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.register_addr = null;
                      } else {
                        obj.register_addr = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "textfield" &&
                      shuzu[a][b].defaultLable == "法定代表人"
                    ) {
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.legal_name = null;
                      } else {
                        obj.legal_name = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "textfield" &&
                      shuzu[a][b].defaultLable == "纳税识别号"
                    ) {
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.uscc = null;
                      } else {
                        obj.uscc = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "textfield" &&
                      shuzu[a][b].defaultLable == "注册资本"
                    ) {
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.register_capital = null;
                      } else {
                        obj.register_capital = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "ddselectfield" &&
                      shuzu[a][b].defaultLable == "企业性质"
                    ) {
                      obj.ent_nature =
                        shuzu[a][b].defaultOptions[Number(shuzu[a][b].value)].text;
                    }
                    if (
                      shuzu[a][b].componentName == "dddatefield" &&
                      shuzu[a][b].defaultLable == "成立日期"
                    ) {
                      if (
                        shuzu[a][b].value.length == 0 ||
                        shuzu[a][b].value == undefined
                      ) {
                        obj.register_date = null;
                      } else {
                        if (isNaN(shuzu[a][b].value.getFullYear())) {
                          obj.register_date = null;
                        } else {
                          obj.register_date =
                            shuzu[a][b].value.getFullYear() +
                            "-" +
                            this.pa(shuzu[a][b].value.getMonth() + 1) +
                            "-" +
                            this.pa(shuzu[a][b].value.getDate());
                        }
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "dddaterangefield" &&
                      shuzu[a][b].defaultLable == "营业期限"
                    ) {
                      if (
                        shuzu[a][b].value.length == 0 ||
                        shuzu[a][b].value == undefined
                      ) {
                        obj.biz_term = null;
                      } else {
                        if (isNaN(shuzu[a][b].value[0].getFullYear())) {
                          obj.biz_term = null;
                        } else {
                          obj.biz_term =
                            shuzu[a][b].value[0].getFullYear() +
                            "-" +
                            this.pa(shuzu[a][b].value[0].getMonth() + 1) +
                            "-" +
                            this.pa(shuzu[a][b].value[0].getDate()) +
                            "至" +
                            shuzu[a][b].value[1].getFullYear() +
                            "-" +
                            this.pa(shuzu[a][b].value[1].getMonth() + 1) +
                            "-" +
                            this.pa(shuzu[a][b].value[1].getDate());
                        }
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "textnote" &&
                      shuzu[a][b].defaultProps == "经营范围"
                    ) {
                      //////console.log(shuzu[a][b])
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.biz_scope = null;
                      } else {
                        obj.biz_scope = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "ddphotofield" &&
                      shuzu[a][b].defaultLable == "营业执照"
                    ) {
                      if (
                        shuzu[a][b].imgBase64 == "" ||
                        shuzu[a][b].imgBase64 == undefined
                      ) {
                        obj.biz_lic_pic = null;
                        obj.biz_lic_pic_type = null;
                      } else {
                        obj.biz_lic_pic = shuzu[a][b].xin;
                        obj.biz_lic_pic_type = shuzu[a][b].lei;
                      }
                    }
                  }
                }
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "付款信息"
              ) {
                const shuzu = this.list[i].components_list;
                for (let a = 0; a < shuzu.length; a++) {
                  for (let b = 0; b < shuzu[a].length; b++) {
                    if (
                      shuzu[a][b].componentName == "textfield" &&
                      shuzu[a][b].defaultLable == "开户行名称"
                    ) {
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.bank_name = null;
                      } else {
                        obj.bank_name = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "textfield" &&
                      shuzu[a][b].defaultLable == "银行账号"
                    ) {
                      if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                        obj.bank_acct = null;
                      } else {
                        obj.bank_acct = shuzu[a][b].value;
                      }
                    }
                    if (
                      shuzu[a][b].componentName == "ddphotofield" &&
                      shuzu[a][b].defaultLable == "开户许可证"
                    ) {
                      if (
                        shuzu[a][b].imgBase64 == "" ||
                        shuzu[a][b].imgBase64 == undefined
                      ) {
                        obj.open_acct_pic = null;
                        obj.open_acct_pic_type = null;
                      } else {
                        obj.open_acct_pic = shuzu[a][b].xin;
                        obj.open_acct_pic_type = shuzu[a][b].lei;
                      }
                    }
                  }
                }
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)
            if (this.zhuangtai_shiyong_name == "公海池_编辑") {
              this.$set(obj, "supply_id", this.yi_fenpei_bianji.supply_id);
              const zhiqian_shuxing = this.$bendi_naqu_cunchu(
                "mlbb_shenpi_biaodan_4",
                "暂时"
              );
              //////console.log(zhiqian_shuxing)
              const _this = this;
              function diaoyong() {
                update_supply({ data: obj }).then((ras) => {
                  if (ras.data.code == 200) {
                    //////console.log(ras)
                    add_appr_flow_step_list({
                      data: {
                        list: fasong_list,
                        flow_no: suiji,
                      },
                    }).then((rfs) => {
                      //////console.log(rfs)
                      if (rfs.data.code == 200) {
                        // this.dai_tijiao_ren(suiji)
                        // this.$message({
                        //     message: '提交成功',
                        //     type: 'success'
                        // });
                        // window.history.go(-1);
                        start_flow_action({
                          data: {
                            ent_id: _this.$ent_id(),
                            user_id:
                              _this.jichuxinxi.user_id != undefined
                                ? _this.jichuxinxi.user_id
                                : _this.$jichuxinxi().user_id,
                            dept_id: _this.liucheng_dept_id,
                            flow_id: _this.date.flow_id,
                            flow_no: suiji,
                            cust_name: obj.supply_name,
                          },
                        }).then((rbs) => {
                          //////console.log(rbs)
                          if (rbs.data.code == 200) {
                            _this.bianji_jiekou();
                            _this.dai_tijiao_ren(suiji);
                            _this.$message({
                              message: "提交成功",
                              type: "success",
                            });
                            window.history.go(-1);
                          } else if (rbs.data.code == 500) {
                          } else if (rbs.data.code == 10246) {
                            _this.$message({
                              message: "没有设置审批流程",
                              type: "warning",
                            });
                          }
                        });
                      } else if (rfs.data.code == 500) {
                      }
                    });
                  } else if (ras.data.code == 500) {
                  } else if (ras.data.code == 600) {
                    this.$message({
                      message: "供应商重复",
                      type: "warning",
                    });
                  }
                });
              }
              if (zhiqian_shuxing.supply_name != obj.supply_name) {
                supply_have_exist({
                  data: {
                    ent_id: this.$ent_id(),
                    supply_name: obj.supply_name,
                  },
                }).then((res) => {
                  //////console.log(res)
                  if (res.data.code == 600) {
                    this.$message({
                      message: "供应商名称重复",
                      type: "warning",
                    });
                  } else if (res.data.code == 700) {
                    diaoyong();
                  } else if (res.data.code == 500) {
                    alert("小虎哥，报错了");
                  }
                });
              } else {
                diaoyong();
              }
            } else {
              supply_have_exist({
                data: {
                  ent_id: this.$ent_id(),
                  supply_name: obj.supply_name,
                },
              }).then((res) => {
                //////console.log(res)
                if (res.data.code == 600) {
                  this.$message({
                    message: "供应商名称重复",
                    type: "warning",
                  });
                } else if (res.data.code == 700) {
                  insert_supply({ data: obj }).then((ras) => {
                    if (ras.data.code == 200) {
                      //////console.log(ras)
                      add_appr_flow_step_list({
                        data: {
                          list: fasong_list,
                          flow_no: suiji,
                        },
                      }).then((rfs) => {
                        //////console.log(rfs)
                        if (rfs.data.code == 200) {
                          // this.dai_tijiao_ren(suiji)
                          // this.$message({
                          //     message: '提交成功',
                          //     type: 'success'
                          // });
                          // window.history.go(-1);
                          start_flow_action({
                            data: {
                              ent_id: this.$ent_id(),
                              user_id: this.jichuxinxi.user_id,
                              dept_id: this.liucheng_dept_id,
                              flow_id: this.date.flow_id,
                              flow_no: suiji,
                              cust_name: obj.supply_name,
                            },
                          }).then((rbs) => {
                            //////console.log(rbs)
                            if (rbs.data.code == 200) {
                              this.dai_tijiao_ren(suiji);
                              this.$message({
                                message: "提交成功",
                                type: "success",
                              });
                              window.history.go(-1);
                            } else if (rbs.data.code == 500) {
                            } else if (rbs.data.code == 10246) {
                              this.$message({
                                message: "没有设置审批流程",
                                type: "warning",
                              });
                            }
                          });
                        } else if (rfs.data.code == 500) {
                        }
                      });
                    } else if (ras.data.code == 500) {
                    } else if (ras.data.code == 600) {
                      this.$message({
                        message: "供应商重复",
                        type: "warning",
                      });
                    }
                  });
                } else if (res.data.code == 500) {
                  alert("小虎哥，报错了");
                }
              });
            }
          }
        } else if (this.date.flow_id == "Xsk2pLBS9a") {
          // 采购单
          // let shifou_fenpei='0'
          // if(this.zhuangtai_shiyong_name=='已分配'||this.zhuangtai_shiyong_name=='已分配_编辑'||this.zhuangtai_shiyong_name=='审批中心'){
          //     shifou_fenpei='1'
          // }
          // if(this.zhuangtai_shiyong_name=='公海池_编辑'){
          //     shifou_fenpei='0'
          // }
          const suiji = this.$func.randomstring(25);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id, /// /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            flow_no: suiji,
            staff_id: null,

            dept_id: "",

            date_pur: "",
            qty_pur_sum: "",
            amt_pur_sum: "",

            list: [],
            extra: [],
            flag_cancel: this.zhuangtai_shiyong_name == "作废" ? "1" : "0", // 作废标志  1作废  0  不作废
            flow_no_orig:
              this.zhuangtai_shiyong_name == "作废"
                ? this.yi_fenpei_bianji.flow_no
                : null, // 源流称号
          };
          // setTimeout(() => {
          // }, 0)
          // //////console.log(this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_4','暂时').flow_no)
          // //////console.log(obj.flow_no)

          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  if (this.zhuguan) {
                    obj.dept_id = this.zhuguan_xuanze_bumen_id
                      .map((ite) => ite)
                      .join(",");
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else if (this.bumen_zhuguan) {
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                    obj.dept_id = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else {
                    obj.dept_id = this.list[i].dept_id;
                  }
                }
              }
              if (
                this.list[i].componentName == "dddatefield" &&
                this.list[i].defaultLable == "采购日期"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.date_pur = null;
                } else {
                  obj.date_pur =
                    this.list[i].value.getFullYear() +
                    "-" +
                    this.pa(this.list[i].value.getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value.getDate());
                }
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "采购数量合计"
              ) {
                obj.qty_pur_sum = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "采购金额合计"
              ) {
                obj.amt_pur_sum = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "采购单列表"
              ) {
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    ent_id: this.$ent_id(),
                    prod_id: "",
                    prod_name: "",
                    prod_code: "", // 产品编码
                    spec: "", // 规格
                    type: "", // 型号
                    unit: "", // 单位
                    qty_stock: "", // 库存数量
                    supply_name: "", // 供应商
                    supply_id: "", // 供应商
                    price_quotation: "", // 产品报价
                    qty_pur: "", // 采购数量
                    amt_pur: "", // 采购金额
                    qty_stock_est: "", // 预计库存数量
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "product" &&
                      shuzu[d][e].defaultLable == "产品"
                    ) {
                      obj_a.prod_id = shuzu[d][e].chanpin_list.prod_id;
                      obj_a.prod_name = shuzu[d][e].chanpin_list.prod_name;
                      obj_a.prod_code = shuzu[d][e].chanpin_list.prod_code;
                      obj_a.spec = shuzu[d][e].chanpin_list.spec;
                      obj_a.type = shuzu[d][e].chanpin_list.type;
                      obj_a.unit = shuzu[d][e].chanpin_list.unit;
                      obj_a.price_unit = shuzu[d][e].chanpin_list.price_unit + "";
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "库存数量"
                    ) {
                      obj_a.qty_stock = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "moneyfield" &&
                      shuzu[d][e].defaultLable == "产品报价"
                    ) {
                      obj_a.price_quotation = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "采购数量"
                    ) {
                      obj_a.qty_pur = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "预计库存"
                    ) {
                      obj_a.qty_stock_est = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "suppliername" &&
                      shuzu[d][e].defaultLable == "供应商"
                    ) {
                      obj_a.supply_id =
                        shuzu[d][e].value_id.length != 0
                          ? shuzu[d][e].value_id + ""
                          : null;
                      obj_a.supply_name =
                        shuzu[d][e].value_id.length != 0 ? shuzu[d][e].value + "" : null;
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "采购金额"
                    ) {
                      obj_a.amt_pur = Number(shuzu[d][e].value) + "";
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.list = list_a;
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)
            insert_purchase({ data: obj }).then((ras) => {
              if (ras.data.code == 200) {
                //////console.log(ras)
                add_appr_flow_step_list({
                  data: {
                    list: fasong_list,
                    flow_no: suiji,
                  },
                }).then((rfs) => {
                  //////console.log(rfs)
                  if (rfs.data.code == 200) {
                    // this.dai_tijiao_ren(suiji)
                    // this.$message({
                    //     message: '提交成功',
                    //     type: 'success'
                    // });
                    // window.history.go(-1);
                    start_flow_action({
                      data: {
                        ent_id: this.$ent_id(),
                        user_id: this.jichuxinxi.user_id,
                        dept_id: this.liucheng_dept_id,
                        flow_id: this.date.flow_id,
                        flow_no: suiji,
                      },
                    }).then((rbs) => {
                      //////console.log(rbs)
                      if (rbs.data.code == 200) {
                        this.dai_tijiao_ren(suiji);
                        this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        window.history.go(-1);
                      } else if (rbs.data.code == 500) {
                      } else if (rbs.data.code == 10246) {
                        this.$message({
                          message: "没有设置审批流程",
                          type: "warning",
                        });
                      }
                    });
                  } else if (rfs.data.code == 500) {
                  }
                });
              } else if (ras.data.code == 500) {
              } else if (ras.data.code == 600) {
                this.$message({
                  message: "供应商重复",
                  type: "warning",
                });
              }
            });
          }
        } else if (this.date.flow_id == "u1SCMMoUwK") {
          // 采购发票单
          // let shifou_fenpei='0'
          // if(this.zhuangtai_shiyong_name=='已分配'||this.zhuangtai_shiyong_name=='已分配_编辑'||this.zhuangtai_shiyong_name=='审批中心'){
          //     shifou_fenpei='1'
          // }
          // if(this.zhuangtai_shiyong_name=='公海池_编辑'){
          //     shifou_fenpei='0'
          // }
          const suiji = this.$func.randomstring(25);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id, /// /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            flow_no: suiji,
            staff_id: null,

            dept_id: "",

            date_pay: "",
            supply_name: "",
            inv_type: "",
            amt_tax_sum: "", // 税额合计
            amt_in_store: "", // 入库金额
            amt_inv_sum: "", // 发票金额合计
            extra: [],
            flag_cancel: this.zhuangtai_shiyong_name == "作废" ? "1" : "0", // 作废标志  1作废  0  不作废
            flow_no_orig:
              this.zhuangtai_shiyong_name == "作废"
                ? this.yi_fenpei_bianji.flow_no
                : null, // 源流称号
            list: [],
            inv_code: "", // 发票代码
            inv_no: "", // 发票号码
            amt_list: [], // 预计付款明细
            amt_pay_est_sum: "", // 预计付款金额合计
          };
          // setTimeout(() => {
          // }, 0)
          // //////console.log(this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_4','暂时').flow_no)
          // //////console.log(obj.flow_no)

          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  if (this.zhuguan) {
                    obj.dept_id = this.zhuguan_xuanze_bumen_id
                      .map((ite) => ite)
                      .join(",");
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else if (this.bumen_zhuguan) {
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                    obj.dept_id = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else {
                    obj.dept_id = this.list[i].dept_id;
                  }
                }
              }
              if (
                this.list[i].componentName == "suppliername" &&
                this.list[i].defaultLable == "供应商"
              ) {
                obj.supply_name =
                  this.list[i].value.length != 0 ? this.list[i].value : null;
                obj.supply_id =
                  this.list[i].value.length != 0 ? this.list[i].value_id : null;
              }
              if (
                this.list[i].componentName == "dddatefield" &&
                this.list[i].defaultLable == "发票日期"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.date_pay = null;
                } else {
                  obj.date_pay =
                    this.list[i].value.getFullYear() +
                    "-" +
                    this.pa(this.list[i].value.getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value.getDate());
                }
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "发票类型"
              ) {
                obj.inv_type = Number(this.list[i].value) + 1 + "";
              }
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "发票代码"
              ) {
                obj.inv_code = this.list[i].value.length != 0 ? this.list[i].value : null;
              }
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "发票号码"
              ) {
                obj.inv_no = this.list[i].value.length != 0 ? this.list[i].value : null;
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "税额合计"
              ) {
                obj.amt_tax_sum = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "moneyfield" &&
                this.list[i].defaultLable == "入库金额"
              ) {
                obj.amt_in_store =
                  this.list[i].value != undefined && this.list[i].value.length != 0
                    ? Number(this.list[i].value).toFixed(2) + ""
                    : null;
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "发票金额合计"
              ) {
                obj.amt_inv_sum = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "预计付款金额合计"
              ) {
                obj.amt_pay_est_sum = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "列表"
              ) {
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    prod_id: "",
                    prod_name: "",
                    prod_code: "", // 产品编码
                    spec: "", // 规格
                    type: "", // 型号
                    unit: "", // 单位
                    supply_name: "", // 供应商
                    supply_id: "", // 供应商
                    price_quotation: "", // 产品报价
                    qty_pur: "", // 采购数量
                    amt_inv: "", // 发票金额
                    rate_tax: "", // 税率
                    amt_tax: "", // 税额
                    amt_no_tax: "", // 不含税金额,
                    pur_id: "", //
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "product" &&
                      shuzu[d][e].defaultLable == "产品"
                    ) {
                      obj_a.prod_id = shuzu[d][e].chanpin_list.prod_id;
                      obj_a.prod_name = shuzu[d][e].chanpin_list.prod_name;
                      obj_a.prod_code = shuzu[d][e].chanpin_list.prod_code;
                      obj_a.spec = shuzu[d][e].chanpin_list.spec;
                      obj_a.type = shuzu[d][e].chanpin_list.type;
                      obj_a.unit = shuzu[d][e].chanpin_list.unit;
                      obj_a.price_unit = shuzu[d][e].chanpin_list.price_unit + "";
                    }
                    if (
                      shuzu[d][e].componentName == "moneyfield" &&
                      shuzu[d][e].defaultLable == "产品报价"
                    ) {
                      obj_a.price_quotation = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "采购数量"
                    ) {
                      obj_a.qty_pur = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "预计库存"
                    ) {
                      obj_a.qty_stock_est = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "moneyfield" &&
                      shuzu[d][e].defaultLable == "税额"
                    ) {
                      obj_a.amt_tax = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "suppliername" &&
                      shuzu[d][e].defaultLable == "供应商"
                    ) {
                      obj_a.supply_id = shuzu[d][e].value_id + "";
                      obj_a.supply_name = shuzu[d][e].value + "";
                      obj_a.pur_id = shuzu[d][e].value_ids + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "采购金额"
                    ) {
                      obj_a.amt_pur = Number(shuzu[d][e].value) + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "不含税金额"
                    ) {
                      obj_a.amt_no_tax = Number(shuzu[d][e].value) + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "发票金额"
                    ) {
                      obj_a.amt_inv = Number(shuzu[d][e].value) + "";
                    }
                    if (
                      shuzu[d][e].componentName == "ddselectfield" &&
                      shuzu[d][e].defaultLable == "税率"
                    ) {
                      obj_a.rate_tax =
                        shuzu[d][e].defaultOptions[Number(shuzu[d][e].value)].text;
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.list = list_a;
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "付款金额明细"
              ) {
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    date_pay_est: "", // 日期
                    amt_pay_est: "", // 金额
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "dddatefield" &&
                      shuzu[d][e].defaultLable == "预计付款日期"
                    ) {
                      obj_a.date_pay_est =
                        shuzu[d][e].value.getFullYear() +
                        "-" +
                        this.pa(shuzu[d][e].value.getMonth() + 1) +
                        "-" +
                        this.pa(shuzu[d][e].value.getDate());
                    }
                    if (
                      shuzu[d][e].componentName == "moneyfield" &&
                      shuzu[d][e].defaultLable == "预计付款金额"
                    ) {
                      obj_a.amt_pay_est = shuzu[d][e].value + "";
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.amt_list = list_a;
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)

            if (
              obj.amt_pay_est_sum.length != 0 &&
              obj.amt_pay_est_sum == obj.amt_inv_sum
            ) {
              insert_pur_inv({ data: obj }).then((ras) => {
                if (ras.data.code == 200) {
                  //////console.log(ras)
                  add_appr_flow_step_list({
                    data: {
                      list: fasong_list,
                      flow_no: suiji,
                    },
                  }).then((rfs) => {
                    //////console.log(rfs)
                    if (rfs.data.code == 200) {
                      // this.dai_tijiao_ren(suiji)
                      // this.$message({
                      //     message: '提交成功',
                      //     type: 'success'
                      // });
                      // window.history.go(-1);
                      start_flow_action({
                        data: {
                          ent_id: this.$ent_id(),
                          user_id: this.jichuxinxi.user_id,
                          dept_id: this.liucheng_dept_id,
                          flow_id: this.date.flow_id,
                          flow_no: suiji,
                          cust_name: obj.supply_name,
                        },
                      }).then((rbs) => {
                        //////console.log(rbs)
                        if (rbs.data.code == 200) {
                          this.dai_tijiao_ren(suiji);
                          this.$message({
                            message: "提交成功",
                            type: "success",
                          });
                          window.history.go(-1);
                        } else if (rbs.data.code == 500) {
                        } else if (rbs.data.code == 10246) {
                          this.$message({
                            message: "没有设置审批流程",
                            type: "warning",
                          });
                        }
                      });
                    } else if (rfs.data.code == 500) {
                    }
                  });
                } else if (ras.data.code == 500) {
                } else if (ras.data.code == 600) {
                  this.$message({
                    message: "供应商重复",
                    type: "warning",
                  });
                }
              });
            } else {
              this.$message({
                message: "您当前发票金额合计与预计付款金额合计不符，请重新填写",
                type: "warning",
              });
            }
          }
        } else if (this.date.flow_id == "vY9ubhTO89") {
          // 付款单
          // let shifou_fenpei='0'
          // if(this.zhuangtai_shiyong_name=='已分配'||this.zhuangtai_shiyong_name=='已分配_编辑'||this.zhuangtai_shiyong_name=='审批中心'){
          //     shifou_fenpei='1'
          // }
          // if(this.zhuangtai_shiyong_name=='公海池_编辑'){
          //     shifou_fenpei='0'
          // }
          const suiji = this.$func.randomstring(25);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id, /// /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            flow_no: suiji,
            staff_id: null,

            dept_id: "",

            operate_time: "",
            amt_pur_inv_un_pay: "",
            amt_pur_un_pay: "",
            amt_in_store: "",
            amt_pay: "",
            amt_pay_sum: "",
            bank_name: "",
            bank_acct: "",
            extra: [],
            flag_cancel: this.zhuangtai_shiyong_name == "作废" ? "1" : "0",
            flow_no_orig:
              this.zhuangtai_shiyong_name == "作废"
                ? this.yi_fenpei_bianji.flow_no
                : null,
          };

          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  if (this.zhuguan) {
                    obj.dept_id = this.zhuguan_xuanze_bumen_id
                      .map((ite) => ite)
                      .join(",");
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else if (this.bumen_zhuguan) {
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                    obj.dept_id = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else {
                    obj.dept_id = this.list[i].dept_id;
                  }
                }
              }
              if (
                this.list[i].componentName == "suppliername" &&
                this.list[i].defaultLable == "供应商"
              ) {
                obj.supply_name =
                  this.list[i].value_id.length != 0 ? this.list[i].value : null;
                obj.supply_id =
                  this.list[i].value_id.length != 0 ? this.list[i].value_id : null;
              }
              if (
                this.list[i].componentName == "dddatefield" &&
                this.list[i].defaultLable == "付款日期"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.operate_time = null;
                } else {
                  obj.operate_time =
                    this.list[i].value.getFullYear() +
                    "-" +
                    this.pa(this.list[i].value.getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value.getDate());
                }
              }
              if (
                this.list[i].componentName == "moneyfield" &&
                this.list[i].defaultLable == "采购发票累计未结账金额"
              ) {
                obj.amt_pur_inv_un_pay =
                  this.list[i].value != undefined && this.list[i].value.length != 0
                    ? this.list[i].value + ""
                    : null;
              }
              if (
                this.list[i].componentName == "moneyfield" &&
                this.list[i].defaultLable == "采购单累计未付款金额"
              ) {
                obj.amt_pur_un_pay =
                  this.list[i].value != undefined && this.list[i].value.length != 0
                    ? this.list[i].value + ""
                    : null;
              }
              if (
                this.list[i].componentName == "moneyfield" &&
                this.list[i].defaultLable == "累计入库金额"
              ) {
                obj.amt_in_store =
                  this.list[i].value != undefined && this.list[i].value.length != 0
                    ? this.list[i].value + ""
                    : null;
              }
              if (
                this.list[i].componentName == "moneyfield" &&
                this.list[i].defaultLable == "付款金额"
              ) {
                obj.amt_pay =
                  this.list[i].value != undefined && this.list[i].value.length != 0
                    ? this.list[i].value + ""
                    : null;
              }
              if (
                this.list[i].componentName == "moneyfield" &&
                this.list[i].defaultLable == "累计付款金额"
              ) {
                obj.amt_pay_sum =
                  this.list[i].value != undefined && this.list[i].value.length != 0
                    ? this.list[i].value + ""
                    : null;
              }
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "开户行名称"
              ) {
                obj.bank_name =
                  this.list[i].value != undefined && this.list[i].value.length != 0
                    ? this.list[i].value + ""
                    : null;
              }
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "银行账号"
              ) {
                obj.bank_acct =
                  this.list[i].value != undefined && this.list[i].value.length != 0
                    ? this.list[i].value + ""
                    : null;
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)

            insert_payment({ data: obj }).then((ras) => {
              if (ras.data.code == 200) {
                //////console.log(ras)
                add_appr_flow_step_list({
                  data: {
                    list: fasong_list,
                    flow_no: suiji,
                  },
                }).then((rfs) => {
                  //////console.log(rfs)
                  if (rfs.data.code == 200) {
                    // this.dai_tijiao_ren(suiji)
                    // this.$message({
                    //     message: '提交成功',
                    //     type: 'success'
                    // });
                    // window.history.go(-1);
                    start_flow_action({
                      data: {
                        ent_id: this.$ent_id(),
                        user_id: this.jichuxinxi.user_id,
                        dept_id: this.liucheng_dept_id,
                        flow_id: this.date.flow_id,
                        flow_no: suiji,
                        cust_name: obj.supply_name,
                      },
                    }).then((rbs) => {
                      //////console.log(rbs)
                      if (rbs.data.code == 200) {
                        this.dai_tijiao_ren(suiji);
                        this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        window.history.go(-1);
                      } else if (rbs.data.code == 500) {
                      } else if (rbs.data.code == 10246) {
                        this.$message({
                          message: "没有设置审批流程",
                          type: "warning",
                        });
                      }
                    });
                  } else if (rfs.data.code == 500) {
                  }
                });
              } else if (ras.data.code == 500) {
              } else if (ras.data.code == 600) {
                this.$message({
                  message: "供应商重复",
                  type: "warning",
                });
              }
            });
          }
        } else if (this.date.flow_id == "fZ0x5qedzn") {
          // 入库单
          // let shifou_fenpei='0'
          // if(this.zhuangtai_shiyong_name=='已分配'||this.zhuangtai_shiyong_name=='已分配_编辑'||this.zhuangtai_shiyong_name=='审批中心'){
          //     shifou_fenpei='1'
          // }
          // if(this.zhuangtai_shiyong_name=='公海池_编辑'){
          //     shifou_fenpei='0'
          // }
          const suiji = this.$func.randomstring(25);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id, /// /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            flow_no: suiji,
            staff_id: null,

            dept_id: "",

            date_in_store: "",
            qty_in_store_sum: "",
            amt_in_store_sum: "",
            extra: [],
            flag_cancel: this.zhuangtai_shiyong_name == "作废" ? "1" : "0", // 作废标志  1作废  0  不作废
            flow_no_orig:
              this.zhuangtai_shiyong_name == "作废"
                ? this.yi_fenpei_bianji.flow_no
                : null, // 源流称号
          };

          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "入库员"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  if (this.zhuguan) {
                    obj.dept_id = this.zhuguan_xuanze_bumen_id
                      .map((ite) => ite)
                      .join(",");
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else if (this.bumen_zhuguan) {
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                    obj.dept_id = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else {
                    obj.dept_id = this.list[i].dept_id;
                  }
                }
              }
              if (
                this.list[i].componentName == "suppliername" &&
                this.list[i].defaultLable == "供应商名称"
              ) {
                obj.supply_name =
                  this.list[i].value_id.length != 0 ? this.list[i].value : null;
                obj.supply_id =
                  this.list[i].value_id.length != 0 ? this.list[i].value_id : null;
              }
              if (
                this.list[i].componentName == "dddatefield" &&
                this.list[i].defaultLable == "入库日期"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.date_in_store = null;
                } else {
                  obj.date_in_store =
                    this.list[i].value.getFullYear() +
                    "-" +
                    this.pa(this.list[i].value.getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value.getDate());
                }
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "入库数量合计"
              ) {
                obj.qty_in_store_sum = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "入库金额合计"
              ) {
                obj.amt_in_store_sum = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "列表"
              ) {
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    prod_id: "",
                    prod_name: "",
                    prod_code: "", // 产品编码
                    spec: "", // 规格
                    type: "", // 型号
                    unit: "", // 单位
                    site_id: "", // 仓库id
                    site_name: "", // 仓库id
                    site_no: "", // 货拉号
                    supply_id: "", // 供应商
                    supply_name: "", // 供应商
                    price_quotation: "", // 产品报价
                    qty_in_store: "", // 入库数量
                    amt_in_store: "", // 入库金额
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "product" &&
                      shuzu[d][e].defaultLable == "产品"
                    ) {
                      obj_a.prod_id = shuzu[d][e].chanpin_list.prod_id;
                      obj_a.prod_name = shuzu[d][e].chanpin_list.prod_name;
                      obj_a.prod_code = shuzu[d][e].chanpin_list.prod_code;
                      obj_a.spec = shuzu[d][e].chanpin_list.spec;
                      obj_a.type = shuzu[d][e].chanpin_list.type;
                      obj_a.unit = shuzu[d][e].chanpin_list.unit;
                      obj_a.price_unit = shuzu[d][e].chanpin_list.price_unit + "";
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "入库数量"
                    ) {
                      obj_a.qty_in_store = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "moneyfield" &&
                      shuzu[d][e].defaultLable == "产品报价"
                    ) {
                      obj_a.price_quotation = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "suppliername" &&
                      shuzu[d][e].defaultLable == "供应商"
                    ) {
                      obj_a.supply_id = shuzu[d][e].value_id + "";
                      obj_a.supply_name = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "仓库"
                    ) {
                      obj_a.site_id = shuzu[d][e].value_id + "";
                      obj_a.site_name = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "货位号"
                    ) {
                      obj_a.site_no =
                        shuzu[d][e].value.length != 0 ? shuzu[d][e].value + "" : null;
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "入库金额"
                    ) {
                      obj_a.amt_in_store = Number(shuzu[d][e].value) + "";
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.list = list_a;
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)

            insert_in_store({ data: obj }).then((ras) => {
              if (ras.data.code == 200) {
                //////console.log(ras)
                add_appr_flow_step_list({
                  data: {
                    list: fasong_list,
                    flow_no: suiji,
                  },
                }).then((rfs) => {
                  //////console.log(rfs)
                  if (rfs.data.code == 200) {
                    // this.dai_tijiao_ren(suiji)
                    // this.$message({
                    //     message: '提交成功',
                    //     type: 'success'
                    // });
                    // window.history.go(-1);
                    start_flow_action({
                      data: {
                        ent_id: this.$ent_id(),
                        user_id: this.jichuxinxi.user_id,
                        dept_id: this.liucheng_dept_id,
                        flow_id: this.date.flow_id,
                        flow_no: suiji,
                        cust_name: obj.supply_name,
                      },
                    }).then((rbs) => {
                      //////console.log(rbs)
                      if (rbs.data.code == 200) {
                        this.dai_tijiao_ren(suiji);
                        this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        window.history.go(-1);
                      } else if (rbs.data.code == 500) {
                      } else if (rbs.data.code == 10246) {
                        this.$message({
                          message: "没有设置审批流程",
                          type: "warning",
                        });
                      }
                    });
                  } else if (rfs.data.code == 500) {
                  }
                });
              } else if (ras.data.code == 500) {
              } else if (ras.data.code == 600) {
                this.$message({
                  message: "供应商重复",
                  type: "warning",
                });
              }
            });
          }
        } else if (this.date.flow_id == "hXstgwxhzh") {
          // 出库单
          const suiji = this.$func.randomstring(25);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id, /// /!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            flow_no: suiji,
            staff_id: null,

            dept_id: "",

            cust_id: "",
            cust_name: "",
            file_no: "",
            date_out_store: "",
            qty_out_store_sum: "",
            amt_out_store_sum: "",
            extra: [],
            flag_cancel: this.zhuangtai_shiyong_name == "作废" ? "1" : "0", // 作废标志  1作废  0  不作废
            flow_no_orig:
              this.zhuangtai_shiyong_name == "作废"
                ? this.yi_fenpei_bianji.flow_no
                : null, // 源流称号
          };

          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "cust" &&
                this.list[i].defaultLable == "客户"
              ) {
                obj.cust_name = this.list[i].value;
                obj.cust_id = this.list[i].kehu_list[this.list[i].zhanshi].cust_id;
                obj.file_no = this.list[i].kehu_list[this.list[i].zhanshi].file_no;
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "出库员"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  if (this.zhuguan) {
                    obj.dept_id = this.zhuguan_xuanze_bumen_id
                      .map((ite) => ite)
                      .join(",");
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else if (this.bumen_zhuguan) {
                    obj.dept_id_orig = this.list[i].dept_idid.map((ite) => ite).join(",");
                    obj.dept_id = this.list[i].dept_idid.map((ite) => ite).join(",");
                  } else {
                    obj.dept_id = this.list[i].dept_id;
                  }
                }
              }
              if (
                this.list[i].componentName == "dddatefield" &&
                this.list[i].defaultLable == "出库日期"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.date_out_store = null;
                } else {
                  obj.date_out_store =
                    this.list[i].value.getFullYear() +
                    "-" +
                    this.pa(this.list[i].value.getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value.getDate());
                }
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "出库数量合计"
              ) {
                obj.qty_out_store_sum = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "出库金额合计"
              ) {
                obj.amt_out_store_sum = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "列表"
              ) {
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    prod_id: "",
                    prod_name: "",
                    prod_code: "", // 产品编码
                    spec: "", // 规格
                    type: "", // 型号
                    unit: "", // 单位
                    site_id: "", // 仓库id
                    site_name: "", // 仓库id
                    site_no: "", // 货拉号
                    price_unit: "", // 产品单价
                    qty_out_store: "", // 出库数量
                    amt_out_store: "", // 出库金额
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "product" &&
                      shuzu[d][e].defaultLable == "产品"
                    ) {
                      obj_a.prod_id = shuzu[d][e].chanpin_list.prod_id;
                      obj_a.prod_name = shuzu[d][e].chanpin_list.prod_name;
                      obj_a.prod_code = shuzu[d][e].chanpin_list.prod_code;
                      obj_a.spec = shuzu[d][e].chanpin_list.spec;
                      obj_a.type = shuzu[d][e].chanpin_list.type;
                      obj_a.unit = shuzu[d][e].chanpin_list.unit;
                      obj_a.price_unit = shuzu[d][e].chanpin_list.price_unit + "";
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "出库数量"
                    ) {
                      obj_a.qty_out_store = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "moneyfield" &&
                      shuzu[d][e].defaultLable == "产品单价"
                    ) {
                      obj_a.price_unit = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "仓库"
                    ) {
                      obj_a.site_id = shuzu[d][e].value_id + "";
                      obj_a.site_name = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "货位号"
                    ) {
                      obj_a.site_no = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "出库金额"
                    ) {
                      obj_a.amt_out_store = Number(shuzu[d][e].value) + "";
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.list = list_a;
              }
            }
            const chuku_no_0 = []; // 出库列表里如果  出库数量为零的话  不能提交
            obj.list.forEach((it) => {
              if (it.qty_out_store == 0) {
                chuku_no_0.push(it);
              }
            });
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)
            if (chuku_no_0.length == 0) {
              insert_out_store({ data: obj }).then((ras) => {
                if (ras.data.code == 200) {
                  //////console.log(ras)
                  add_appr_flow_step_list({
                    data: {
                      list: fasong_list,
                      flow_no: suiji,
                    },
                  }).then((rfs) => {
                    //////console.log(rfs)
                    if (rfs.data.code == 200) {
                      // this.dai_tijiao_ren(suiji)
                      // this.$message({
                      //     message: '提交成功',
                      //     type: 'success'
                      // });
                      // window.history.go(-1);
                      start_flow_action({
                        data: {
                          ent_id: this.$ent_id(),
                          user_id: this.jichuxinxi.user_id,
                          dept_id: this.liucheng_dept_id,
                          flow_id: this.date.flow_id,
                          flow_no: suiji,
                          cust_name: obj.cust_name,
                        },
                      }).then((rbs) => {
                        //////console.log(rbs)
                        if (rbs.data.code == 200) {
                          this.dai_tijiao_ren(suiji);
                          this.$message({
                            message: "提交成功",
                            type: "success",
                          });
                          window.history.go(-1);
                        } else if (rbs.data.code == 500) {
                        } else if (rbs.data.code == 10246) {
                          this.$message({
                            message: "没有设置审批流程",
                            type: "warning",
                          });
                        }
                      });
                    } else if (rfs.data.code == 500) {
                    }
                  });
                } else if (ras.data.code == 500) {
                } else if (ras.data.code == 600) {
                  this.$message({
                    message: "供应商重复",
                    type: "warning",
                  });
                }
              });
            } else {
              this.$message({
                message: "出库数量不能为0",
                type: "warning",
              });
            }
          }
        } else if (this.date.flow_id == "453y6pLD52") {
          // 访客记录单
          const suiji = new Date().getTime() + this.$func.randomstring(5);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id,
            flow_no: suiji,
            file_no: "",
            cust_name: "",
            cust_id: "",
            staff_id: "",
            dept_id: "",
            start_time: "",
            end_time: "",
            hour_long: "",
            extra: "",
            funn_stage_change_flag: null,
            funn_stage: null,
            change_cause: null,
            remarks: null,
            current_stage: null,
          };
          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "客户"
              ) {
                obj.cust_name = this.list[i].value;
                obj.cust_id = this.list[i].kehu_list[this.list[i].zhanshi].cust_id;
                obj.file_no = this.list[i].kehu_list[this.list[i].zhanshi].file_no;
                // obj.staff_id=this.list[i].kehu_list[this.list[i].zhanshi].staff_id
                // obj.dept_id=this.list[i].kehu_list[this.list[i].zhanshi].dept_id
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
                // if(this.list[i].staff_id==''||this.list[i].staff_id==undefined){
                //     obj.staff_id=null
                // }else{
                //     obj.staff_id=this.list[i].staff_id
                // }
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  obj.dept_id = this.list[i].dept_id;
                }
              }
              if (
                this.list[i].componentName == "dddaterangefield" &&
                this.list[i].defaultLable == "拜访时间"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.start_time = null;
                  obj.end_time = null;
                  obj.hour_long = null;
                } else {
                  obj.start_time =
                    this.list[i].value[0].getFullYear() +
                    "-" +
                    this.pa(this.list[i].value[0].getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value[0].getDate()) +
                    " " +
                    this.pa(this.list[i].value[0].getHours()) +
                    ":" +
                    this.pa(this.list[i].value[0].getMinutes());
                  obj.end_time =
                    this.list[i].value[1].getFullYear() +
                    "-" +
                    this.pa(this.list[i].value[1].getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value[1].getDate()) +
                    " " +
                    this.pa(this.list[i].value[1].getHours()) +
                    ":" +
                    this.pa(this.list[i].value[1].getMinutes());
                  obj.hour_long = this.list[i].value_id + "";
                }
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "拜访目标结果"
              ) {
                // let length=''
                // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                // for(let a=0;a<data.length;a++){
                //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                //         length=Number(data[a].components.length)+1
                //     }
                // }
                // let shuzu=this.group(this.list[i].components,length)
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                for (let d = 0; d < shuzu.length; d++) {
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "漏斗现阶段"
                    ) {
                      obj.current_stage = this.dangqian_jieduan(shuzu[d][e].value);
                    }
                    if (
                      shuzu[d][e].componentName == "ddselectfield" &&
                      shuzu[d][e].defaultLable == "漏斗阶段变化"
                    ) {
                      obj.funn_stage_change_flag =
                        shuzu[d][e].defaultOptions[Number(shuzu[d][e].value)].text;
                    }
                    if (
                      shuzu[d][e].componentName == "ddselectfield" &&
                      shuzu[d][e].defaultLable == "漏斗达成阶段"
                    ) {
                      obj.funn_stage = this.dangqian_jieduan(
                        shuzu[d][e].defaultOptions[Number(shuzu[d][e].value)].text
                      );
                    }
                    if (
                      shuzu[d][e].componentName == "textfield" &&
                      shuzu[d][e].defaultLable == "阶段变化原因"
                    ) {
                      obj.change_cause = shuzu[d][e].value;
                    }
                    if (
                      shuzu[d][e].componentName == "textnote" &&
                      shuzu[d][e].defaultProps == "备注"
                    ) {
                      if (shuzu[d][e].value == "" || shuzu[d][e].value == undefined) {
                        obj.remarks = null;
                      } else {
                        obj.remarks = shuzu[d][e].value;
                      }
                    }
                  }
                }
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)
            insert_s_visit_record({ data: obj }).then((res) => {
              if (res.data.code == 200) {
                add_appr_flow_step_list({
                  data: {
                    list: fasong_list,
                    flow_no: suiji,
                  },
                }).then((rfs) => {
                  //////console.log(rfs)
                  if (rfs.data.code == 200) {
                    start_flow_action({
                      data: {
                        ent_id: this.$ent_id(),
                        user_id: this.jichuxinxi.user_id,
                        dept_id: this.liucheng_dept_id,
                        flow_id: this.date.flow_id,
                        flow_no: suiji,
                        cust_name: obj.cust_name,
                      },
                    }).then((rbs) => {
                      //////console.log(rbs)
                      if (rbs.data.code == 200) {
                        this.dai_tijiao_ren(suiji);
                        this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        window.history.go(-1);
                      } else if (rbs.data.code == 500) {
                      } else if (rbs.data.code == 10246) {
                        this.$message({
                          message: "没有设置审批流程",
                          type: "warning",
                        });
                      }
                    });
                  } else if (rfs.data.code == 500) {
                  }
                });
              } else if (res.data.code == 500) {
              }
            });
          }
        } else if (this.date.flow_id == "PLOQ9ku3Ip") {
          // 漏斗单
          const suiji = new Date().getTime() + this.$func.randomstring(5);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id,
            flow_no: suiji,
            file_no: "",
            cust_name: "",
            cust_id: "",
            staff_id: "",
            dept_id: "",
            amt_funn: "", // 漏斗金额
            date_con_est: "", // 日期控件
            stage_funn: "", // 漏斗阶段
            rate_funn_disc: "", // 漏斗折算比例
            list: [],
            extra: "",
          };
          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "客户"
              ) {
                obj.cust_name = this.list[i].value;
                obj.cust_id = this.list[i].kehu_list[this.list[i].zhanshi].cust_id;
                if (
                  this.kehu_chongfu.name.length != 0 ||
                  this.list[i].kehu_list[this.list[i].zhanshi].file_no == undefined
                ) {
                  obj.file_no = "null";
                } else {
                  obj.file_no = this.list[i].kehu_list[this.list[i].zhanshi].file_no;
                }
                // obj.staff_id=this.list[i].kehu_list[this.list[i].zhanshi].staff_id
                // obj.dept_id=this.list[i].kehu_list[this.list[i].zhanshi].dept_id
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
                // if(this.list[i].staff_id==''||this.list[i].staff_id==undefined){
                //     obj.staff_id=null
                // }else{
                //     obj.staff_id=this.list[i].staff_id
                // }
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  obj.dept_id = this.list[i].dept_id;
                }
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "漏斗阶段"
              ) {
                // obj.stage_funn=this.dangqian_jieduan(this.list[i].value)
                // obj.stage_funn=this.list[i].value
                obj.stage_funn = this.dangqian_jieduan(
                  this.list[i].defaultOptions[Number(this.list[i].value)].text
                );
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "漏斗金额"
              ) {
                obj.amt_funn = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "numberfield" &&
                this.list[i].defaultLable == "漏斗折算比例"
              ) {
                //////console.log(this.list[i].value)
                obj.rate_funn_disc = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "dddatefield" &&
                this.list[i].defaultLable == "预计合同日期"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.date_con_est = null;
                } else {
                  obj.date_con_est =
                    this.list[i].value.getFullYear() +
                    "-" +
                    this.pa(this.list[i].value.getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value.getDate());
                }
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "产品明细"
              ) {
                // let length=''
                // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                // for(let a=0;a<data.length;a++){
                //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                //         length=Number(data[a].components.length)+1
                //     }
                // }
                // let shuzu=this.group(this.list[i].components,length)
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    prod_id: null,
                    prod_name: null,
                    qty: null,
                    price_sale: null,
                    prod_code: null,
                    cat_id: null,
                    cat_name: null,
                    spec: null,
                    type: null,
                    unit: null,
                    price_unit: null,
                    price_orig: null,
                    disc_rate: null,
                    // prod_name:null,
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "product" &&
                      shuzu[d][e].defaultLable == "产品"
                    ) {
                      obj_a.prod_id = shuzu[d][e].chanpin_list.prod_id;
                      obj_a.prod_name = shuzu[d][e].chanpin_list.prod_name;
                      obj_a.prod_code = shuzu[d][e].chanpin_list.prod_code;
                      obj_a.cat_id = shuzu[d][e].chanpin_list.cat_id;
                      obj_a.cat_name = shuzu[d][e].chanpin_list.cat_name;
                      obj_a.spec = shuzu[d][e].chanpin_list.spec;
                      obj_a.type = shuzu[d][e].chanpin_list.type;
                      obj_a.unit = shuzu[d][e].chanpin_list.unit;
                      obj_a.price_unit = shuzu[d][e].chanpin_list.price_unit + "";
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "数量"
                    ) {
                      obj_a.qty = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "折扣率"
                    ) {
                      obj_a.disc_rate = Number(shuzu[d][e].value) + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "售价"
                    ) {
                      obj_a.price_sale = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "原价"
                    ) {
                      obj_a.price_orig = shuzu[d][e].value + "";
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.list = list_a;
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)

            if (this.zhuangtai_shiyong_name != "看板_编辑") {
              insert_funn({ data: obj }).then((res) => {
                if (res.data.code == 200) {
                  add_appr_flow_step_list({
                    data: {
                      list: fasong_list,
                      flow_no: suiji,
                    },
                  }).then((rfs) => {
                    //////console.log(rfs)
                    if (rfs.data.code == 200) {
                      start_flow_action({
                        data: {
                          ent_id: this.$ent_id(),
                          user_id: this.jichuxinxi.user_id,
                          flow_id: this.date.flow_id,
                          dept_id: this.liucheng_dept_id,
                          flow_no: suiji,
                          cust_name: obj.cust_name,
                        },
                      }).then((rbs) => {
                        //////console.log(rbs)
                        if (rbs.data.code == 200) {
                          this.dai_tijiao_ren(suiji);
                          this.$message({
                            message: "提交成功",
                            type: "success",
                          });
                          window.history.go(-1);
                        } else if (rbs.data.code == 500) {
                        } else if (rbs.data.code == 10246) {
                          this.$message({
                            message: "没有设置审批流程",
                            type: "warning",
                          });
                        }
                      });
                    } else if (rfs.data.code == 500) {
                    }
                  });
                } else if (res.data.code == 500) {
                }
              });
            } else {
              obj.funn_id = this.$bendi_naqu_cunchu(
                "mlbb_shenpi_biaodan_4",
                "暂时"
              ).funn_id;
              //////console.log(this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_4', '暂时').funn_id)
              //////console.log(this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_4', '暂时'))

              update_funn({ data: obj }).then((res) => {
                if (res.data.code == 200) {
                  add_appr_flow_step_list({
                    data: {
                      list: fasong_list,
                      flow_no: suiji,
                    },
                  }).then((rfs) => {
                    //////console.log(rfs)
                    if (rfs.data.code == 200) {
                      start_flow_action({
                        data: {
                          ent_id: this.$ent_id(),
                          user_id: this.jichuxinxi.user_id,
                          flow_id: this.date.flow_id,
                          dept_id: this.liucheng_dept_id,
                          flow_no: suiji,
                          cust_name: obj.cust_name,
                        },
                      }).then((rbs) => {
                        //////console.log(rbs)
                        if (rbs.data.code == 200) {
                          this.dai_tijiao_ren(suiji);
                          this.$message({
                            message: "提交成功",
                            type: "success",
                          });
                          this.bianji_jiekou();
                          window.history.go(-1);
                        } else if (rbs.data.code == 500) {
                        } else if (rbs.data.code == 10246) {
                          this.$message({
                            message: "没有设置审批流程",
                            type: "warning",
                          });
                        }
                      });
                    } else if (rfs.data.code == 500) {
                    }
                  });
                } else if (res.data.code == 500) {
                }
              });
            }
          }
        } else if (this.date.flow_id == "Eb3n7CKQzi") {
          // 派工单
          const suiji = new Date().getTime() + this.$func.randomstring(5);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id,
            flow_no: suiji,
            file_no: "",
            cust_name: "",
            cust_id: "",
            staff_id: "",
            dept_id: "",
            help_order_flag: "", // 是否为助单行为
            disp_staff_id: "", // 派工人员
            help_order_type: "", // 助单类型
            biz_trip_type: "", // 出差类型
            addr: "", // 地址
            contact: "", // 决策人
            mobile: "", // 电话
            // rel_funn:'',//
            // rel_con:'',//
            trip_start_time: "", //
            trip_end_time: "", //
            hour_long: "",
            remarks: "", // 备注
            list: [],
            extra: "",
          };
          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "客户"
              ) {
                obj.cust_name = this.list[i].value;
                obj.cust_id = this.list[i].kehu_list[this.list[i].zhanshi].cust_id;
                obj.file_no = this.list[i].kehu_list[this.list[i].zhanshi].file_no;
                // obj.staff_id=this.list[i].kehu_list[this.list[i].zhanshi].staff_id
                // obj.dept_id=this.list[i].kehu_list[this.list[i].zhanshi].dept_id
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
                // if(this.list[i].staff_id==''||this.list[i].staff_id==undefined){
                //     obj.staff_id=null
                // }else{
                //     obj.staff_id=this.list[i].staff_id
                // }
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  obj.dept_id = this.list[i].dept_id;
                }
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "派工人员"
              ) {
                if (this.list[i].staff_id == "" || this.list[i].staff_id == undefined) {
                  obj.disp_staff_id = null;
                } else {
                  obj.disp_staff_id = this.list[i].staff_id;
                }
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "是否为助单行为"
              ) {
                obj.help_order_flag = this.list[i].defaultOptions[
                  Number(this.list[i].value)
                ].text;
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "派工类型"
              ) {
                obj.help_order_type = this.list[i].defaultOptions[
                  Number(this.list[i].value)
                ].text;
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "出差类型"
              ) {
                obj.biz_trip_type = this.list[i].defaultOptions[
                  Number(this.list[i].value)
                ].text;
              }
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "决策人"
              ) {
                obj.contact = this.list[i].value;
              }
              if (
                this.list[i].componentName == "ddselectfield" &&
                this.list[i].defaultLable == "地址"
              ) {
                // addr
                obj.addr = this.list[i].value + "," + this.list[i].value_id;
              }
              if (
                this.list[i].componentName == "numberfield" &&
                this.list[i].defaultLable == "手机"
              ) {
                obj.mobile = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "textnote" &&
                this.list[i].defaultProps == "备注"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.remarks = null;
                } else {
                  obj.remarks = this.list[i].value;
                }
              }
              if (
                this.list[i].componentName == "dddaterangefield" &&
                this.list[i].defaultLable == "出差时间"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.trip_start_time = null;
                  obj.trip_end_time = null;
                  obj.hour_long = null;
                } else {
                  obj.trip_start_time =
                    this.list[i].value[0].getFullYear() +
                    "-" +
                    this.pa(this.list[i].value[0].getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value[0].getDate()) +
                    " " +
                    this.pa(this.list[i].value[0].getHours()) +
                    ":" +
                    this.pa(this.list[i].value[0].getMinutes());
                  obj.trip_end_time =
                    this.list[i].value[1].getFullYear() +
                    "-" +
                    this.pa(this.list[i].value[1].getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value[1].getDate()) +
                    " " +
                    this.pa(this.list[i].value[1].getHours()) +
                    ":" +
                    this.pa(this.list[i].value[1].getMinutes());
                  obj.hour_long = this.list[i].value_id + "";
                }
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "支持产品"
              ) {
                // let length=''
                // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                // for(let a=0;a<data.length;a++){
                //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                //         length=Number(data[a].components.length)+1
                //     }
                // }
                // let shuzu=this.group(this.list[i].components,length)
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    prod_id: null,
                    prod_name: null,
                    prod_code: null,
                    cat_id: null,
                    cat_name: null,
                    spec: null,
                    type: null,
                    unit: null,
                    price_unit: null,
                    disp_item: null,
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "product" &&
                      shuzu[d][e].defaultLable == "产品"
                    ) {
                      obj_a.prod_id = shuzu[d][e].chanpin_list.prod_id;
                      obj_a.prod_name = shuzu[d][e].chanpin_list.prod_name;
                      obj_a.prod_code = shuzu[d][e].chanpin_list.prod_code;
                      obj_a.cat_id = shuzu[d][e].chanpin_list.cat_id;
                      obj_a.cat_name = shuzu[d][e].chanpin_list.cat_name;
                      obj_a.spec = shuzu[d][e].chanpin_list.spec;
                      obj_a.type = shuzu[d][e].chanpin_list.type;
                      obj_a.unit = shuzu[d][e].chanpin_list.unit;
                      obj_a.price_unit = shuzu[d][e].chanpin_list.price_unit + "";
                    }
                    if (
                      shuzu[d][e].componentName == "ddselectfield" &&
                      shuzu[d][e].defaultLable == "派工项目"
                    ) {
                      obj_a.disp_item =
                        shuzu[d][e].defaultOptions[Number(shuzu[d][e].value)].text;
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.list = list_a;
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)
            insert_s_dispatch({ data: obj }).then((res) => {
              if (res.data.code == 200) {
                add_appr_flow_step_list({
                  data: {
                    list: fasong_list,
                    flow_no: suiji,
                  },
                }).then((rfs) => {
                  //////console.log(rfs)
                  if (rfs.data.code == 200) {
                    start_flow_action({
                      data: {
                        ent_id: this.$ent_id(),
                        user_id: this.jichuxinxi.user_id,
                        flow_id: this.date.flow_id,
                        dept_id: this.liucheng_dept_id,
                        flow_no: suiji,
                        cust_name: obj.cust_name,
                      },
                    }).then((rbs) => {
                      //////console.log(rbs)
                      if (rbs.data.code == 200) {
                        this.dai_tijiao_ren(suiji);
                        this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        window.history.go(-1);
                      } else if (rbs.data.code == 500) {
                      } else if (rbs.data.code == 10246) {
                        this.$message({
                          message: "没有设置审批流程",
                          type: "warning",
                        });
                      }
                    });
                  } else if (rfs.data.code == 500) {
                  }
                });
              } else if (res.data.code == 500) {
              }
            });
          }
        } else if (this.date.flow_id == "qIwxmsMTgv") {
          // 合同单
          let hetong_jine = "";
          let yuji_kaipiao_jine = "";
          for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].defaultLable == "合同金额") {
              hetong_jine = this.list[i].value;
            }
            if (this.list[i].defaultLable == "预计发票金额合计") {
              yuji_kaipiao_jine = this.list[i].value;
            }
          }
          const suiji = new Date().getTime() + this.$func.randomstring(5);
          if (hetong_jine == yuji_kaipiao_jine && hetong_jine != "") {
            const obj = {
              ent_id: this.$ent_id(),
              user_id: this.jichuxinxi.user_id,
              flow_id: this.date.flow_id,
              flow_no: suiji,
              file_no: "",
              date_con: "",
              cust_name: "",
              cust_id: "",
              staff_id: "",
              dept_id: "",
              belong_to_organ: "", // 所属公司
              province: "", // 省份
              city: "", // 城市
              industry: "", // 行业
              con_type: "", // 合同类型
              con_valid_term: "", // 日期区间
              amt_con: "", // 合同金额
              prod_list: [],
              amt_list: [],
              extra: "",
              flag: this.zhuangtai_shiyong_name == "作废" ? "0" : "1",
              flow_no_orig:
                this.zhuangtai_shiyong_name == "作废"
                  ? this.yi_fenpei_bianji.flow_no
                  : null,
            };
            if (
              this.hetong_duibi_jine != null &&
              this.hetong_duibi_jine.length != 0 &&
              this.zhuangtai_shiyong_name != "作废"
                ? !this.shifou_shi_loudou_dan
                  ? Number(this.hetong_duibi_jine) <= Number(hetong_jine)
                  : true
                : true
            ) {
              if (this.panduan(this.list)) {
                const extra = this.huoqu_zi_dingyi_kongjian();
                for (let i = 0; i < this.list.length; i++) {
                  if (
                    this.list[i].componentName == "textfield" &&
                    this.list[i].defaultLable == "客户"
                  ) {
                    obj.cust_name = this.list[i].value;
                    //////console.log(this.list[i].zhanshi)
                    //////console.log(this.list[i].kehu_list)
                    obj.cust_id = this.list[i].kehu_list[this.list[i].zhanshi].cust_id;
                    if (this.kehu_chongfu.name.length != 0) {
                      obj.file_no = "null";
                    } else {
                      obj.file_no = this.list[i].kehu_list[this.list[i].zhanshi].file_no;
                    }
                    // obj.staff_id=this.list[i].kehu_list[this.list[i].zhanshi].staff_id
                    // obj.dept_id=this.list[i].kehu_list[this.list[i].zhanshi].dept_id
                  }
                  if (
                    this.list[i].componentName == "contacts" &&
                    this.list[i].defaultLable == "负责人"
                  ) {
                    obj.staff_id = this.jichuxinxi.user_id;
                    // if(this.list[i].staff_id==''||this.list[i].staff_id==undefined){
                    //     obj.staff_id=null
                    // }else{
                    //     obj.staff_id=this.list[i].staff_id
                    // }
                  }
                  if (
                    this.list[i].componentName == "department" &&
                    this.list[i].defaultLable == "部门"
                  ) {
                    if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                      obj.dept_id = null;
                    } else {
                      obj.dept_id = this.list[i].dept_id;
                    }
                  }
                  if (
                    this.list[i].componentName == "calcform" &&
                    this.list[i].defaultLable == "合同金额"
                  ) {
                    if (this.list[i].value == "" || this.list[i].value == undefined) {
                      obj.amt_con = null;
                    } else {
                      obj.amt_con = this.list[i].value + "";
                    }
                  }
                  if (
                    this.list[i].componentName == "ddselectfield" &&
                    this.list[i].defaultLable == "所属公司"
                  ) {
                    obj.belong_to_organ = this.list[i].defaultOptions[
                      Number(this.list[i].value)
                    ].text;
                  }
                  if (
                    this.list[i].componentName == "ddselectfield" &&
                    this.list[i].defaultLable == "行业"
                  ) {
                    obj.industry = this.list[i].defaultOptions[
                      Number(this.list[i].value)
                    ].text;
                  }
                  if (
                    this.list[i].componentName == "ddselectfield" &&
                    this.list[i].defaultLable == "地址"
                  ) {
                    obj.province = this.list[i].value;
                    obj.city = this.list[i].value_id;
                  }
                  if (
                    this.list[i].componentName == "ddselectfield" &&
                    this.list[i].defaultLable == "合同类型"
                  ) {
                    obj.con_type = this.list[i].defaultOptions[
                      Number(this.list[i].value)
                    ].text;
                  }
                  if (
                    this.list[i].componentName == "dddatefield" &&
                    this.list[i].defaultLable == "合同日期"
                  ) {
                    if (this.list[i].value == "" || this.list[i].value == undefined) {
                      obj.date_con = null;
                    } else {
                      obj.date_con =
                        this.list[i].value.getFullYear() +
                        "-" +
                        this.pa(this.list[i].value.getMonth() + 1) +
                        "-" +
                        this.pa(this.list[i].value.getDate());
                    }
                  }
                  if (
                    this.list[i].componentName == "dddaterangefield" &&
                    this.list[i].defaultLable == "合同时间"
                  ) {
                    if (this.list[i].value == "" || this.list[i].value == undefined) {
                      obj.con_valid_term = null;
                    } else {
                      obj.con_valid_term =
                        this.list[i].value[0].getFullYear() +
                        "-" +
                        this.pa(this.list[i].value[0].getMonth() + 1) +
                        "-" +
                        this.pa(this.list[i].value[0].getDate()) +
                        "至" +
                        this.list[i].value[1].getFullYear() +
                        "-" +
                        this.pa(this.list[i].value[1].getMonth() + 1) +
                        "-" +
                        this.pa(this.list[i].value[1].getDate());
                    }
                  }
                  if (
                    this.list[i].componentName == "tablefield" &&
                    this.list[i].defaultLable == "产品"
                  ) {
                    // let length=''
                    // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                    // for(let a=0;a<data.length;a++){
                    //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                    //         length=Number(data[a].components.length)+1
                    //     }
                    // }
                    // let shuzu=this.group(this.list[i].components,length)
                    const shuzu = this.list[i].components_list;
                    //////console.log(shuzu)
                    const list_a = [];
                    for (let d = 0; d < shuzu.length; d++) {
                      const obj_a = {
                        prod_id: null,
                        prod_code: null,
                        cat_id: null,
                        cat_name: null,
                        prod_name: null,
                        spec: null,
                        type: null,
                        unit: null,
                        price_orig: null,
                        price_unit: null,
                        disc_rate: null,
                        price_sale: null,
                      };
                      for (let e = 0; e < shuzu[d].length; e++) {
                        if (
                          shuzu[d][e].componentName == "product" &&
                          shuzu[d][e].defaultLable == "产品"
                        ) {
                          obj_a.prod_id = shuzu[d][e].chanpin_list.prod_id;
                          obj_a.cat_name = shuzu[d][e].chanpin_list.cat_name;
                          obj_a.prod_name = shuzu[d][e].chanpin_list.prod_name;
                          obj_a.prod_code = shuzu[d][e].chanpin_list.prod_code;
                          obj_a.cat_id = shuzu[d][e].chanpin_list.cat_id;
                          obj_a.spec = shuzu[d][e].chanpin_list.spec;
                          obj_a.type = shuzu[d][e].chanpin_list.type;
                          obj_a.unit = shuzu[d][e].chanpin_list.unit;
                          obj_a.price_unit = shuzu[d][e].chanpin_list.price_unit + "";
                        }
                        if (
                          shuzu[d][e].componentName == "numberfield" &&
                          shuzu[d][e].defaultLable == "数量"
                        ) {
                          obj_a.qty = shuzu[d][e].value + "";
                        }
                        if (
                          shuzu[d][e].componentName == "calcform" &&
                          shuzu[d][e].defaultLable == "售价"
                        ) {
                          obj_a.price_sale = shuzu[d][e].value + "";
                        }
                        if (
                          shuzu[d][e].componentName == "calcform" &&
                          shuzu[d][e].defaultLable == "原价"
                        ) {
                          obj_a.price_orig = shuzu[d][e].value + "";
                        }
                        if (
                          shuzu[d][e].componentName == "calcform" &&
                          shuzu[d][e].defaultLable == "折扣率"
                        ) {
                          obj_a.disc_rate = Number(shuzu[d][e].value) + "";
                        }
                      }
                      list_a.push(obj_a);
                    }
                    obj.prod_list = list_a;
                  }
                  if (
                    this.list[i].componentName == "tablefield" &&
                    this.list[i].defaultLable == "开票"
                  ) {
                    // let length=''
                    // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                    // for(let a=0;a<data.length;a++){
                    //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                    //         length=Number(data[a].components.length)+1
                    //     }
                    // }
                    // let shuzu=this.group(this.list[i].components,length)
                    const shuzu = this.list[i].components_list;
                    //////console.log(shuzu)
                    const list_a = [];
                    for (let d = 0; d < shuzu.length; d++) {
                      const obj_a = {
                        date_inv_est: null,
                        amt_inv_est: null,
                      };
                      for (let e = 0; e < shuzu[d].length; e++) {
                        if (
                          shuzu[d][e].componentName == "moneyfield" &&
                          shuzu[d][e].defaultLable == "预计发票金额"
                        ) {
                          obj_a.amt_inv_est = shuzu[d][e].value;
                        }
                        if (
                          shuzu[d][e].componentName == "dddatefield" &&
                          shuzu[d][e].defaultLable == "预计发票日期"
                        ) {
                          obj_a.date_inv_est =
                            shuzu[d][e].value.getFullYear() +
                            "-" +
                            this.pa(shuzu[d][e].value.getMonth() + 1) +
                            "-" +
                            this.pa(shuzu[d][e].value.getDate());
                        }
                      }
                      list_a.push(obj_a);
                    }
                    obj.amt_list = list_a;
                  }
                }
                const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
                obj.extra = extra2;
                if (this.zhuangtai_shiyong_name == "作废") {
                  obj.flag_cancel = "1";
                } else {
                  obj.flag_cancel = "0";
                }
                //////console.log(obj)
                if (this.zhuangtai_shiyong_name != "看板_编辑") {
                  insert_contract({ data: obj }).then((res) => {
                    //////console.log(res)
                    if (res.data.code == 200) {
                      add_appr_flow_step_list({
                        data: {
                          list: fasong_list,
                          flow_no: suiji,
                        },
                      }).then((rfs) => {
                        //////console.log(rfs)
                        if (rfs.data.code == 200) {
                          start_flow_action({
                            data: {
                              ent_id: this.$ent_id(),
                              user_id: this.jichuxinxi.user_id,
                              flow_id: this.date.flow_id,
                              dept_id: this.liucheng_dept_id,
                              flow_no: suiji,
                              cust_name: obj.cust_name,
                            },
                          }).then((rbs) => {
                            //////console.log(rbs)
                            if (rbs.data.code == 200) {
                              // if(this.zhuangtai_shiyong_name=='作废'){
                              //     form_cancel({data:{
                              //         ent_id:this.$ent_id(),
                              //         file_no:obj.file_no,
                              //         type:'contract'
                              //     }}).then(baofei=>{
                              //         if(baofei.data.code==200){
                              //             this.dai_tijiao_ren(suiji)
                              //             this.$message({
                              //                 message: '提交成功',
                              //                 type: 'success'
                              //             });
                              //             window.history.go(-1);
                              //         }else if(baofei.data.code==500){}
                              //     })
                              // }else{
                              this.dai_tijiao_ren(suiji);
                              this.$message({
                                message: "提交成功",
                                type: "success",
                              });
                              window.history.go(-1);
                              // }
                            } else if (rbs.data.code == 500) {
                            } else if (rbs.data.code == 10246) {
                              this.$message({
                                message: "没有设置审批流程",
                                type: "warning",
                              });
                            }
                          });
                        } else if (rfs.data.code == 500) {
                        }
                      });
                    } else if (res.data.code == 500) {
                    }
                  });
                } else {
                  update_contract({ data: obj }).then((res) => {
                    //////console.log(res)
                    if (res.data.code == 200) {
                      add_appr_flow_step_list({
                        data: {
                          list: fasong_list,
                          flow_no: suiji,
                        },
                      }).then((rfs) => {
                        //////console.log(rfs)
                        if (rfs.data.code == 200) {
                          start_flow_action({
                            data: {
                              ent_id: this.$ent_id(),
                              user_id: this.jichuxinxi.user_id,
                              flow_id: this.date.flow_id,
                              dept_id: this.liucheng_dept_id,
                              flow_no: suiji,
                              cust_name: obj.cust_name,
                            },
                          }).then((rbs) => {
                            //////console.log(rbs)
                            if (rbs.data.code == 200) {
                              // if(this.zhuangtai_shiyong_name=='作废'){
                              //     form_cancel({data:{
                              //         ent_id:this.$ent_id(),
                              //         file_no:obj.file_no,
                              //         type:'contract'
                              //     }}).then(baofei=>{
                              //         if(baofei.data.code==200){
                              //             this.dai_tijiao_ren(suiji)
                              //             this.$message({
                              //                 message: '提交成功',
                              //                 type: 'success'
                              //             });
                              //             window.history.go(-1);
                              //         }else if(baofei.data.code==500){}
                              //     })
                              // }else{
                              this.dai_tijiao_ren(suiji);
                              this.$message({
                                message: "提交成功",
                                type: "success",
                              });
                              window.history.go(-1);
                              // }
                            } else if (rbs.data.code == 500) {
                            } else if (rbs.data.code == 10246) {
                              this.$message({
                                message: "没有设置审批流程",
                                type: "warning",
                              });
                            }
                          });
                        } else if (rfs.data.code == 500) {
                        }
                      });
                    } else if (res.data.code == 500) {
                    }
                  });
                }
              }
            } else {
              this.$message({
                message: "当前合同金额少于已开票或已回款金额，请调整产品数量或种类",
                type: "error",
              });
            }
          } else {
            this.$message({
              message: "您当前合同金额与预计开票金额不符，请重新填写",
              type: "warning",
            });
          }
        } else if (this.date.flow_id == "VMCizuVOXb") {
          // 发票单
          if (this.fapiao_huikuan_shifou_tijiao) {
            const suiji = new Date().getTime() + this.$func.randomstring(5);
            const obj = {
              ent_id: this.$ent_id(),
              user_id: this.jichuxinxi.user_id,
              flow_id: this.date.flow_id,
              flow_no: suiji,
              file_no: "",
              cust_name: "",
              cust_id: "",
              staff_id: "",
              dept_id: "",
              belong_to_organ: "",
              flow_no_orig:
                this.zhuangtai_shiyong_name == "作废"
                  ? this.yi_fenpei_bianji.flow_no
                  : null,

              date_inv: "",
              inv_type: "",
              amt_inv: "",

              amt_no_tax_sum: "",
              amt_tax_sum: "",
              amt_inv: "",
              amt_inv_sum: "",
              amt_inv_un: "",

              tax_no: "",
              mobile: "",
              addr: "",
              bank: "",
              bank_acct: "",
              amt_list: [],
              extra: "",
              flag: this.zhuangtai_shiyong_name == "作废" ? "0" : "1",
            };
            if (this.panduan(this.list)) {
              const extra = this.huoqu_zi_dingyi_kongjian();
              for (let i = 0; i < this.list.length; i++) {
                if (
                  this.list[i].componentName == "textfield" &&
                  this.list[i].defaultLable == "客户"
                ) {
                  obj.cust_name = this.list[i].value;
                  obj.cust_id = this.list[i].kehu_list[this.list[i].zhanshi].cust_id;
                  obj.file_no = this.list[i].kehu_list[this.list[i].zhanshi].file_no;
                  // obj.staff_id=this.list[i].kehu_list[this.list[i].zhanshi].staff_id
                  // obj.dept_id=this.list[i].kehu_list[this.list[i].zhanshi].dept_id
                }
                if (
                  this.list[i].componentName == "contacts" &&
                  this.list[i].defaultLable == "负责人"
                ) {
                  obj.staff_id = this.jichuxinxi.user_id;
                  // if(this.list[i].staff_id==''||this.list[i].staff_id==undefined){
                  //     obj.staff_id=null
                  // }else{
                  //     obj.staff_id=this.list[i].staff_id
                  // }
                }
                if (
                  this.list[i].componentName == "department" &&
                  this.list[i].defaultLable == "部门"
                ) {
                  if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                    obj.dept_id = null;
                  } else {
                    obj.dept_id = this.list[i].dept_id;
                  }
                }
                // if(this.list[i].componentName=='textfield'&&this.list[i].defaultLable=='地址'){
                //     obj.addr=this.list[i].value
                // }
                if (
                  this.list[i].componentName == "calcform" &&
                  this.list[i].defaultLable == "合计发票金额"
                ) {
                  if (this.list[i].value == "" || this.list[i].value == undefined) {
                    obj.amt_inv = null;
                  } else {
                    obj.amt_inv = this.list[i].value + "";
                  }
                }
                // if(this.list[i].componentName=='moneyfield'&&this.list[i].defaultLable=='合同已开发票金额'){
                //     if(this.list[i].value==''||this.list[i].value==undefined){
                //         obj.amt_inv=null
                //     }else{
                //         obj.amt_inv=this.list[i].value+''
                //     }
                // }
                if (
                  this.list[i].componentName == "moneyfield" &&
                  this.list[i].defaultLable == "累计发票金额"
                ) {
                  if (this.list[i].value.length == 0 || this.list[i].value == undefined) {
                    obj.amt_inv_sum = null;
                  } else {
                    obj.amt_inv_sum = this.list[i].value + "";
                  }
                }
                if (
                  this.list[i].componentName == "moneyfield" &&
                  this.list[i].defaultLable == "合同未开发票金额"
                ) {
                  if (this.list[i].value == "" || this.list[i].value == undefined) {
                    obj.amt_inv_un = null;
                  } else {
                    obj.amt_inv_un = this.list[i].value + "";
                  }
                }
                if (
                  this.list[i].componentName == "calcform" &&
                  this.list[i].defaultLable == "合计不含税金额"
                ) {
                  if (this.list[i].value == "" || this.list[i].value == undefined) {
                    obj.amt_no_tax_sum = null;
                  } else {
                    obj.amt_no_tax_sum = this.list[i].value + "";
                  }
                }
                if (
                  this.list[i].componentName == "calcform" &&
                  this.list[i].defaultLable == "合计税额"
                ) {
                  if (this.list[i].value == "" || this.list[i].value == undefined) {
                    obj.amt_tax_sum = null;
                  } else {
                    obj.amt_tax_sum = this.list[i].value + "";
                  }
                }
                if (
                  this.list[i].componentName == "ddselectfield" &&
                  this.list[i].defaultLable == "所属公司"
                ) {
                  obj.belong_to_organ = this.list[i].defaultOptions[
                    Number(this.list[i].value)
                  ].text;
                }
                if (
                  this.list[i].componentName == "ddselectfield" &&
                  this.list[i].defaultLable == "发票类型"
                ) {
                  obj.inv_type = this.list[i].defaultOptions[
                    Number(this.list[i].value)
                  ].text;
                }
                if (
                  this.list[i].componentName == "dddatefield" &&
                  this.list[i].defaultLable == "发票日期"
                ) {
                  if (this.list[i].value == "" || this.list[i].value == undefined) {
                    obj.date_inv = null;
                  } else {
                    obj.date_inv =
                      this.list[i].value.getFullYear() +
                      "-" +
                      this.pa(this.list[i].value.getMonth() + 1) +
                      "-" +
                      this.pa(this.list[i].value.getDate());
                  }
                }
                if (
                  this.list[i].componentName == "tablefield" &&
                  this.list[i].defaultLable == "发票信息"
                ) {
                  const shuzu = this.list[i].components_list;
                  for (let a = 0; a < shuzu.length; a++) {
                    for (let b = 0; b < shuzu[a].length; b++) {
                      if (
                        shuzu[a][b].componentName == "textfield" &&
                        shuzu[a][b].defaultLable == "客户"
                      ) {
                        if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                          obj.cust_name_2 = null;
                        } else {
                          obj.cust_name_2 = shuzu[a][b].value;
                        }
                      }
                      if (
                        shuzu[a][b].componentName == "textfield" &&
                        shuzu[a][b].defaultLable == "地址"
                      ) {
                        if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                          obj.addr = null;
                        } else {
                          obj.addr = shuzu[a][b].value;
                        }
                      }
                      if (
                        shuzu[a][b].componentName == "textfield" &&
                        shuzu[a][b].defaultLable == "纳税识别号"
                      ) {
                        if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                          obj.tax_no = null;
                        } else {
                          obj.tax_no = shuzu[a][b].value;
                        }
                      }
                      if (
                        shuzu[a][b].componentName == "textfield" &&
                        shuzu[a][b].defaultLable == "电话"
                      ) {
                        if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                          obj.mobile = null;
                        } else {
                          obj.mobile = shuzu[a][b].value;
                        }
                      }
                      if (
                        shuzu[a][b].componentName == "textfield" &&
                        shuzu[a][b].defaultLable == "开户行"
                      ) {
                        if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                          obj.bank = null;
                        } else {
                          obj.bank = shuzu[a][b].value;
                        }
                      }
                      if (
                        shuzu[a][b].componentName == "numberfield" &&
                        shuzu[a][b].defaultLable == "开户行账号"
                      ) {
                        if (shuzu[a][b].value == "" || shuzu[a][b].value == undefined) {
                          obj.bank_acct = null;
                        } else {
                          obj.bank_acct = shuzu[a][b].value + "";
                        }
                      }
                    }

                    // if(this.list[i].components[a].componentName=='textfield'&&this.list[i].components[a].defaultLable=='客户'){
                    //     if(this.list[i].components[a].value==''||this.list[i].components[a].value==undefined){
                    //         obj.cust_name_2=null
                    //     }else{
                    //         obj.cust_name_2=this.list[i].components[a].value
                    //     }
                    // }
                    // if(this.list[i].components[a].componentName=='textfield'&&this.list[i].components[a].defaultLable=='地址'){
                    //     if(this.list[i].components[a].value==''||this.list[i].components[a].value==undefined){
                    //         obj.addr=null
                    //     }else{
                    //         obj.addr=this.list[i].components[a].value
                    //     }
                    // }
                    // if(this.list[i].components[a].componentName=='numberfield'&&this.list[i].components[a].defaultLable=='纳税识别号'){
                    //     if(this.list[i].components[a].value==''||this.list[i].components[a].value==undefined){
                    //         obj.tax_no=null
                    //     }else{
                    //         obj.tax_no=this.list[i].components[a].value
                    //     }
                    // }
                    // if(this.list[i].components[a].componentName=='numberfield'&&this.list[i].components[a].defaultLable=='纳税识别号'){
                    //     if(this.list[i].components[a].value==''||this.list[i].components[a].value==undefined){
                    //         obj.bank_acct=null
                    //     }else{
                    //         obj.bank_acct=this.list[i].components[a].value
                    //     }
                    // }
                    // if(this.list[i].components[a].componentName=='numberfield'&&this.list[i].components[a].defaultLable=='电话'){
                    //     if(this.list[i].components[a].value==''||this.list[i].components[a].value==undefined){
                    //         obj.mobile=null
                    //     }else{
                    //         obj.mobile=this.list[i].components[a].value
                    //     }
                    // }
                    // if(this.list[i].components[a].componentName=='textfield'&&this.list[i].components[a].defaultLable=='开户行'){
                    //     if(this.list[i].components[a].value==''||this.list[i].components[a].value==undefined){
                    //         obj.bank=null
                    //     }else{
                    //         obj.bank=this.list[i].components[a].value
                    //     }
                    // }
                  }
                }
                if (
                  this.list[i].componentName == "tablefield" &&
                  this.list[i].defaultLable == "发票内容"
                ) {
                  // let length=''
                  // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                  // for(let a=0;a<data.length;a++){
                  //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                  //         length=Number(data[a].components.length)+1
                  //     }
                  // }
                  // let shuzu=this.group(this.list[i].components,length)
                  const shuzu = this.list[i].components_list;
                  //////console.log(shuzu)
                  const list_a = [];
                  for (let d = 0; d < shuzu.length; d++) {
                    const obj_a = {
                      prod_code: null,
                      prod_name: null,
                      prod_id: null,
                      cat_id: null,
                      cat_name: null,
                      spec: null,
                      type: null,
                      unit: null,
                      price_unit: null,
                      price_orig: null,
                      qty: null,
                      price_unit_no_tax: null,
                      amt_no_tax: null,
                      rate_tax: null,
                      amt_tax: null,
                      amt_inv_detail: null,
                      disc_rate: null,
                      price_sale: null,
                    };
                    for (let e = 0; e < shuzu[d].length; e++) {
                      if (
                        shuzu[d][e].componentName == "product" &&
                        shuzu[d][e].defaultLable == "产品"
                      ) {
                        obj_a.prod_code = shuzu[d][e].chanpin_list.prod_code;
                        obj_a.spec = shuzu[d][e].chanpin_list.spec;
                        obj_a.type = shuzu[d][e].chanpin_list.type;
                        obj_a.unit = shuzu[d][e].chanpin_list.unit;
                        obj_a.cat_id = shuzu[d][e].chanpin_list.cat_id;
                        obj_a.prod_id = shuzu[d][e].chanpin_list.prod_id;
                        obj_a.prod_name = shuzu[d][e].chanpin_list.prod_name;
                        obj_a.cat_name = shuzu[d][e].chanpin_list.cat_name;
                        obj_a.price_unit = shuzu[d][e].chanpin_list.price_unit + "";
                      }
                      if (
                        shuzu[d][e].componentName == "numberfield" &&
                        shuzu[d][e].defaultLable == "数量"
                      ) {
                        obj_a.qty = shuzu[d][e].value + "";
                      }
                      if (
                        shuzu[d][e].componentName == "moneyfield" &&
                        shuzu[d][e].defaultLable == "不含税折后单价"
                      ) {
                        obj_a.price_unit_no_tax = shuzu[d][e].value + "";
                      }
                      if (
                        shuzu[d][e].componentName == "calcform" &&
                        shuzu[d][e].defaultLable == "原价"
                      ) {
                        obj_a.price_orig = shuzu[d][e].value + "";
                      }
                      if (
                        shuzu[d][e].componentName == "calcform" &&
                        shuzu[d][e].defaultLable == "折扣率"
                      ) {
                        obj_a.disc_rate = Number(shuzu[d][e].value) + "";
                      }
                      if (
                        shuzu[d][e].componentName == "calcform" &&
                        shuzu[d][e].defaultLable == "售价"
                      ) {
                        obj_a.price_sale = shuzu[d][e].value + "";
                      }
                      if (
                        shuzu[d][e].componentName == "moneyfield" &&
                        shuzu[d][e].defaultLable == "不含税金额"
                      ) {
                        obj_a.amt_no_tax = shuzu[d][e].value + "";
                      }
                      if (
                        shuzu[d][e].componentName == "ddselectfield" &&
                        shuzu[d][e].defaultLable == "税率"
                      ) {
                        obj_a.rate_tax = this.zifuchuan_2(
                          shuzu[d][e].defaultOptions[Number(shuzu[d][e].value)].text,
                          0
                        );
                      }
                      if (
                        shuzu[d][e].componentName == "moneyfield" &&
                        shuzu[d][e].defaultLable == "税额"
                      ) {
                        obj_a.amt_tax = shuzu[d][e].value + "";
                      }
                      if (
                        shuzu[d][e].componentName == "moneyfield" &&
                        shuzu[d][e].defaultLable == "发票金额"
                      ) {
                        obj_a.amt_inv_detail = shuzu[d][e].value + "";
                      }
                    }
                    list_a.push(obj_a);
                  }
                  obj.prod_list = list_a;
                }
                if (
                  this.list[i].componentName == "tablefield" &&
                  this.list[i].defaultLable == "回款"
                ) {
                  // let length=''
                  // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                  // for(let a=0;a<data.length;a++){
                  //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                  //         length=Number(data[a].components.length)+1
                  //     }
                  // }
                  // let shuzu=this.group(this.list[i].components,length)
                  const shuzu = this.list[i].components_list;
                  //////console.log(shuzu)
                  const list_a = [];
                  for (let d = 0; d < shuzu.length; d++) {
                    const obj_a = {
                      date_rec_est: null,
                      amt_rec_est: null,
                    };
                    for (let e = 0; e < shuzu[d].length; e++) {
                      if (
                        shuzu[d][e].componentName == "moneyfield" &&
                        shuzu[d][e].defaultLable == "预计回款金额"
                      ) {
                        obj_a.amt_rec_est = shuzu[d][e].value;
                      }
                      if (
                        shuzu[d][e].componentName == "dddatefield" &&
                        shuzu[d][e].defaultLable == "预计回款日期"
                      ) {
                        obj_a.date_rec_est =
                          shuzu[d][e].value.getFullYear() +
                          "-" +
                          this.pa(shuzu[d][e].value.getMonth() + 1) +
                          "-" +
                          this.pa(shuzu[d][e].value.getDate());
                      }
                    }
                    list_a.push(obj_a);
                  }
                  obj.amt_list = list_a;
                }
              }
              const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
              obj.extra = extra2;
              //////console.log(obj)
              if (this.zhuangtai_shiyong_name == "作废") {
                obj.flag_cancel = "1";
              } else {
                obj.flag_cancel = "0";
              }
              insert_invoice({ data: obj }).then((res) => {
                if (res.data.code == 200) {
                  add_appr_flow_step_list({
                    data: {
                      list: fasong_list,
                      flow_no: suiji,
                    },
                  }).then((rfs) => {
                    //////console.log(rfs)
                    if (rfs.data.code == 200) {
                      start_flow_action({
                        data: {
                          ent_id: this.$ent_id(),
                          user_id: this.jichuxinxi.user_id,
                          flow_id: this.date.flow_id,
                          dept_id: this.liucheng_dept_id,
                          flow_no: suiji,
                          cust_name: obj.cust_name,
                        },
                      }).then((rbs) => {
                        //////console.log(rbs)
                        if (rbs.data.code == 200) {
                          // if(this.zhuangtai_shiyong_name=='作废'){
                          //     form_cancel({data:{
                          //         ent_id:this.$ent_id(),
                          //         file_no:obj.file_no,
                          //         type:'invoice'
                          //     }}).then(baofei=>{
                          //         if(baofei.data.code==200){
                          //             this.dai_tijiao_ren(suiji)
                          //             this.$message({
                          //                 message: '提交成功',
                          //                 type: 'success'
                          //             });
                          //             window.history.go(-1);
                          //         }else if(baofei.data.code==500){}
                          //     })
                          // }else{
                          this.dai_tijiao_ren(suiji);
                          this.$message({
                            message: "提交成功",
                            type: "success",
                          });
                          window.history.go(-1);
                          // }
                        } else if (rbs.data.code == 500) {
                        } else if (rbs.data.code == 10246) {
                          this.$message({
                            message: "没有设置审批流程",
                            type: "warning",
                          });
                        }
                      });
                    } else if (rfs.data.code == 500) {
                    }
                  });
                } else if (res.data.code == 500) {
                } else if (res.data.code == 12100) {
                  this.$message({
                    message: "纳税识别号已存在",
                    type: "warning",
                  });
                }
              });
            }
          } else {
            this.$message({
              message: "当前开票金额已超过合同剩余可开票金额",
              type: "error",
            });
          }
        } else if (this.date.flow_id == "He5ln0bdDO") {
          // 提交回款单
          const suiji = new Date().getTime() + this.$func.randomstring(5);
          const obj = {
            ent_id: this.$ent_id(), //企业id
            user_id: this.jichuxinxi.user_id, //用户id
            flow_id: this.date.flow_id, //
            cust_name: "", //客户名称
            cust_id: "", //客户id
            file_no: "", //归档号
            staff_id: "", //负责人id
            dept_id: "", // 负责人部门
            flow_no: suiji, //流程号
            extra: "", //自定义控件
            date_rec: "", //回款日期
            inv_code: null, //发票代码
            inv_no: null, //发票号码
            amt_rec: "", //回款金额
            amt_rec_sum: "", //累计回款金额
            amt_inv_sum: "", //累计开票金额
            amt_rec_unrec: "", //应收金额
            mc_status: this.hui_kuanData.mc_status,
            mc_valid_date: this.hui_kuanData.mc_valid_date,
            flag_cancel: this.zhuangtai_shiyong_name == "作废" ? "0" : "1", //作废标志
            flow_no_orig:
              this.zhuangtai_shiyong_name == "作废"
                ? this.yi_fenpei_bianji.flow_no
                : null, //原流程号
            mc_level: this.hui_kuanData.mc_level, //会员等级   **
            mc_discount: this.hui_kuanData.mc_discount, //会员折扣   **
            amt_bgn_mc: this.hui_kuanData.amt_bgn_mc, //会员卡余额  **
            amt_end_mc: JSON.stringify(this.hui_kuanData.amt_end_mc), //会员卡余额支付后 **
            list: [],
            list_pay: [],
            vip_sum: "",
            //list_pay:this.hui_kuanData.list_pay, //** */付款方式
            //提交在这里
          };
          
          let list_pay = [];
          // earl S
          // for (let i of this.list) {
          //   if (i.defaultProps === "vip") {
          //     i.defaultOptions.map((item) => {
          //       item.pay_type = item.text;
          //     });
          //     list_pay = i.defaultOptions;
          //   }
          // }
          // earl E

          // list_pay[0].pay_type = 'vip'

          // console.log(list_pay[0],"2222222----222")
          const xiangqing_message = this.$bendi_naqu_cunchu(
            "mlbb_shenpi_biaodan_4",
            "暂时"
          );
          // earl S
          // obj.list_pay = list_pay;
          // earl E

          for (let i of this.list) {
            if (i.defaultLable === "回款金额") {
              obj.amt_rec = i.value;
            }
            if (i.defaultLable === "会员卡金额") {
              obj.vip_sum = i.value;
            }
            console.log('i.defaultLablei.defaultLable', i.defaultLable)
            if (i.defaultLable === "会员卡余额") {
              obj.amt_end_mc = i.value + "";
            }
          }

          console.log('list_paylist_paylist_pay',this.list, obj, list_pay)

          // earl S
          // if (
          //   list_pay.reduce((a, b) => (a = a + Number(b.amt_pay)), 0) !=
          //   Number(obj.amt_rec)
          // )
          //   return this.$message.warning("结算金额必须等于回款金额");
          // earl E

          for (let i of obj.list_pay) {
            if (i.pay_type === "会员卡") {
              if (Number(i.amt_pay) > obj.vip_sum)
                return this.$message.warning("无法使用会员卡支付");
              if (this.hui_kuanData.zhekou !== "null") {
                if (Number(i.rate_pay) > this.hui_kuanData.zhekou)
                  return this.$message.warning(
                    `使用会员卡结账时，单次不能超过整单的${this.hui_kuanData.zhekou}%`
                  );
              }
              console.log(Number(i.amt_end_mc), "这是我额的进----");
              if (Number(i.amt_end_mc) < 0)
                return this.$message.warning("会员卡余额不足支付,请调整支付金额");
            }
            // console.log(Number(i.rate_pay), Number(this.hui_kuanData.zhekou),'这是支付方式')
          }
          
          // earl S
          // if (list_pay[0].pay_type === "会员卡" && list_pay[0].rate_pay !== 0) {
          //   list_pay[0].pay_type = "vip";
          // }
          // earl E

          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "客户"
              ) {
                obj.cust_name = this.list[i].value;
                obj.cust_id = this.list[i].kehu_list[this.list[i].zhanshi].cust_id;
                obj.file_no = this.list[i].kehu_list[this.list[i].zhanshi].file_no;
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = xiangqing_message.dept_id;
                } else {
                  obj.dept_id = this.list[i].dept_id;
                }
              }
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "发票代码"
              ) {
                if (this.list[i].value != "") {
                  obj.inv_code = this.list[i].value;
                }
              }
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "发票号码"
              ) {
                if (this.list[i].value != "") {
                  obj.inv_no = this.list[i].value;
                }
              }
              if (
                this.list[i].componentName == "moneyfield" &&
                this.list[i].defaultLable == "累计回款金额"
              ) {
                obj.amt_rec_sum = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "moneyfield" &&
                this.list[i].defaultLable == "累计发票金额"
              ) {
                obj.amt_inv_sum = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "回款金额"
              ) {
                obj.amt_rec = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "moneyfield" &&
                this.list[i].defaultLable == "应收金额"
              ) {
                obj.amt_rec_unrec = this.list[i].value + "";
              }
              if (
                this.list[i].componentName == "dddatefield" &&
                this.list[i].defaultLable == "回款日期"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.date_rec = null;
                } else {
                  obj.date_rec =
                    this.list[i].value.getFullYear() +
                    "-" +
                    this.pa(this.list[i].value.getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value.getDate());
                }
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "产品回款"
              ) {
                // let length=''
                // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                // for(let a=0;a<data.length;a++){
                //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                //         length=Number(data[a].components.length)+1
                //     }
                // }
                // let shuzu=this.group(this.list[i].components,length)
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    prod_id: null,
                    prod_code: null,
                    cat_id: null,
                    spec: null,
                    type: null,
                    unit: null,
                    price_orig: null,
                    price_unit: null,
                    disc_rate: null,
                    price_sale: null,
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "product" &&
                      shuzu[d][e].defaultLable == "产品"
                    ) {
                      obj_a.prod_id = shuzu[d][e].chanpin_list.prod_id;
                      obj_a.prod_code = shuzu[d][e].chanpin_list.prod_code;
                      obj_a.cat_id = shuzu[d][e].chanpin_list.cat_id;
                      obj_a.spec = shuzu[d][e].chanpin_list.spec;
                      obj_a.type = shuzu[d][e].chanpin_list.type;
                      obj_a.unit = shuzu[d][e].chanpin_list.unit;
                      obj_a.price_unit = shuzu[d][e].chanpin_list.price_unit + "";
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "数量"
                    ) {
                      obj_a.qty = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "售价"
                    ) {
                      obj_a.price_sale = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "原价"
                    ) {
                      obj_a.price_orig = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "折扣率"
                    ) {
                      obj_a.disc_rate = Number(shuzu[d][e].value) + "";
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.list = list_a;
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)
            //////console.log('剩余回款差', this.huikuan_jine_cha)
            //////console.log('当前表单回款金额', obj.amt_rec)
            //////console.log('剩余回款差》=当前表单回款金额')
            if (this.zhuangtai_shiyong_name == "作废") {
              obj.flag_cancel = "1";
            } else {
              obj.flag_cancel = "0";
            }
            // if (this.huikuan_jine_cha != null && this.zhuangtai_shiyong_name != '作废' && Number() ? Number(this.huikuan_jine_cha) >= Number(obj.amt_rec) : true) {
            if (
              this.huikuan_jine_cha != null && this.zhuangtai_shiyong_name != "作废"
                ? Number(this.huikuan_jine_cha) >= Number(obj.amt_rec)
                : true
            ) {
              console.log(obj, "这是我提交的回款单");
              insert_received_cash({ data: obj }).then((res) => {
                console.log(res, "这是返回的数据");
                if (res.data.code == 200) {
                  add_appr_flow_step_list({
                    data: {
                      list: fasong_list,
                      flow_no: suiji,
                    },
                  }).then((rfs) => {
                    //////console.log(rfs)
                    if (rfs.data.code == 200) {
                      start_flow_action({
                        data: {
                          ent_id: this.$ent_id(),
                          user_id: this.jichuxinxi.user_id,
                          flow_id: this.date.flow_id,
                          dept_id: this.liucheng_dept_id,
                          flow_no: suiji,
                          cust_name: obj.cust_name,
                        },
                      }).then((rbs) => {
                        //////console.log(rbs)
                        if (rbs.data.code == 200) {
                          // if(this.zhuangtai_shiyong_name=='作废'){
                          //         form_cancel({data:{
                          //             ent_id:this.$ent_id(),
                          //             file_no:obj.file_no,
                          //             type:'received'
                          //         }}).then(baofei=>{
                          //             if(baofei.data.code==200){
                          //                 this.dai_tijiao_ren(suiji)
                          //                 this.$message({
                          //                     message: '提交成功',
                          //                     type: 'success'
                          //                 });
                          //                 window.history.go(-1);
                          //             }else if(baofei.data.code==500){}
                          //         })
                          //     }else{
                          this.dai_tijiao_ren(suiji);
                          this.$message({
                            message: "提交成功",
                            type: "success",
                          });
                          window.history.go(-1);
                          // }
                        } else if (rbs.data.code == 500) {
                        } else if (rbs.data.code == 10246) {
                          this.$message({
                            message: "没有设置审批流程",
                            type: "warning",
                          });
                        }
                      });
                    } else if (rfs.data.code == 500) {
                    }
                  });
                } else if (res.data.code == 500) {
                }
              });
            } else {
              this.$message({
                message: "当前回款金额已超过合同剩余可回款金额",
                type: "error",
              });
            }
          }
        } else if (this.date.flow_id == "cvRyWGiUQH") {
          // 外出单
          const suiji = new Date().getTime() + this.$func.randomstring(5);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id,
            flow_no: suiji,
            file_no: "",
            cust_name: "",
            cust_id: "",
            staff_id: "",
            dept_id: "",
            start_time: "",
            ent_time: "",
            hour_long: "",
            year_hour_long: "", // 未连接
            go_out_cause: "",
            extra: "",
          };
          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "客户"
              ) {
                obj.cust_name = this.list[i].value;
                obj.cust_id = this.list[i].kehu_list[this.list[i].zhanshi].cust_id;
                obj.file_no = this.list[i].kehu_list[this.list[i].zhanshi].file_no;
                // obj.staff_id=this.list[i].kehu_list[this.list[i].zhanshi].staff_id
                // obj.dept_id=this.list[i].kehu_list[this.list[i].zhanshi].dept_id
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
                // if(this.list[i].staff_id==''||this.list[i].staff_id==undefined){
                //     obj.staff_id=null
                // }else{
                //     obj.staff_id=this.list[i].staff_id
                // }
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  obj.dept_id = this.list[i].dept_id;
                }
              }
              if (
                this.list[i].componentName == "textnote" &&
                this.list[i].defaultProps == "外出事由"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.go_out_cause = null;
                } else {
                  obj.go_out_cause = this.list[i].value;
                }
              }
              if (
                this.list[i].componentName == "dddaterangefield" &&
                this.list[i].defaultLable == "外出时间"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.start_time = null;
                  obj.end_time = null;
                  obj.hour_long = null;
                } else {
                  obj.start_time =
                    this.list[i].value[0].getFullYear() +
                    "-" +
                    this.pa(this.list[i].value[0].getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value[0].getDate()) +
                    "      " +
                    this.pa(this.list[i].value[0].getHours()) +
                    ":" +
                    this.pa(this.list[i].value[0].getMinutes());
                  obj.end_time =
                    this.list[i].value[1].getFullYear() +
                    "-" +
                    this.pa(this.list[i].value[1].getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value[1].getDate()) +
                    "      " +
                    this.pa(this.list[i].value[0].getHours()) +
                    ":" +
                    this.pa(this.list[i].value[0].getMinutes());
                  obj.hour_long = this.list[i].value_id + "";
                }
              }
              if (
                this.list[i].componentName == "numberfield" &&
                this.list[i].defaultLable == "年累计外出时长（小时）"
              ) {
                obj.year_hour_long = this.list[i].value + "";
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)
            insert_s_go_out({ data: obj }).then((res) => {
              if (res.data.code == 200) {
                add_appr_flow_step_list({
                  data: {
                    list: fasong_list,
                    flow_no: suiji,
                  },
                }).then((rfs) => {
                  //////console.log(rfs)
                  if (rfs.data.code == 200) {
                    start_flow_action({
                      data: {
                        ent_id: this.$ent_id(),
                        user_id: this.jichuxinxi.user_id,
                        flow_id: this.date.flow_id,
                        dept_id: this.liucheng_dept_id,
                        flow_no: suiji,
                        cust_name: obj.cust_name,
                      },
                    }).then((rbs) => {
                      //////console.log(rbs)
                      if (rbs.data.code == 200) {
                        this.dai_tijiao_ren(suiji);
                        this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        window.history.go(-1);
                      } else if (rbs.data.code == 500) {
                      } else if (rbs.data.code == 10246) {
                        this.$message({
                          message: "没有设置审批流程",
                          type: "warning",
                        });
                      }
                    });
                  } else if (rfs.data.code == 500) {
                  }
                });
              } else if (res.data.code == 500) {
              }
            });
          }
        } else if (this.date.flow_id == "GBh7N2jMsi") {
          // 出差单
          const suiji = new Date().getTime() + this.$func.randomstring(5);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id,
            flow_no: suiji,
            file_no: "",
            cust_name: "",
            cust_id: "",
            staff_id: "",
            dept_id: "",

            remarks: "",
            trip_days: "",
            list: [],
            year_trip_days: "",
            extra: "",
          };
          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "客户"
              ) {
                obj.cust_name = this.list[i].value;
                obj.cust_id = this.list[i].kehu_list[this.list[i].zhanshi].cust_id;
                obj.file_no = this.list[i].kehu_list[this.list[i].zhanshi].file_no;
                // obj.staff_id=this.list[i].kehu_list[this.list[i].zhanshi].staff_id
                // obj.dept_id=this.list[i].kehu_list[this.list[i].zhanshi].dept_id
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
                // if(this.list[i].staff_id==''||this.list[i].staff_id==undefined){
                //     obj.staff_id=null
                // }else{
                //     obj.staff_id=this.list[i].staff_id
                // }
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  obj.dept_id = this.list[i].dept_id;
                }
              }
              if (
                this.list[i].componentName == "textnote" &&
                this.list[i].defaultProps == "出差备注"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.remarks = null;
                } else {
                  obj.remarks = this.list[i].value;
                }
              }
              if (
                this.list[i].componentName == "numberfield" &&
                this.list[i].defaultLable == "出差天数"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.trip_days = null;
                } else {
                  obj.trip_days = this.list[i].value + "";
                }
              }
              if (
                this.list[i].componentName == "numberfield" &&
                this.list[i].defaultLable == "年累计出差天数"
              ) {
                if (this.list[i].value.length == 0 || this.list[i].value == undefined) {
                  obj.year_trip_days = null;
                } else {
                  obj.year_trip_days = this.list[i].value + "";
                }
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "行程"
              ) {
                // let length=''
                // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                // for(let a=0;a<data.length;a++){
                //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                //         length=Number(data[a].components.length)+1
                //     }
                // }
                // let shuzu=this.group(this.list[i].components,length)
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    transport: null,
                    leave_city: null,
                    arrive_city: null,
                    start_time: null,
                    end_time: null,
                    hour_long: null,
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "ddselectfield" &&
                      shuzu[d][e].defaultLable == "交通工具"
                    ) {
                      obj_a.transport =
                        shuzu[d][e].defaultOptions[Number(shuzu[d][e].value)].text;
                    }
                    if (
                      shuzu[d][e].componentName == "ddselectfield" &&
                      shuzu[d][e].defaultLable == "出发城市"
                    ) {
                      obj_a.leave_city = shuzu[d][e].value + "," + shuzu[d][e].value_id;
                    }
                    if (
                      shuzu[d][e].componentName == "ddselectfield" &&
                      shuzu[d][e].defaultLable == "目的城市"
                    ) {
                      obj_a.arrive_city = shuzu[d][e].value + "," + shuzu[d][e].value_id;
                    }
                    if (
                      shuzu[d][e].componentName == "dddaterangefield" &&
                      shuzu[d][e].defaultLable == "出差时间"
                    ) {
                      if (shuzu[d][e].value == "" || shuzu[d][e].value == undefined) {
                        obj_a.start_time = null;
                        obj_a.end_time = null;
                        obj_a.hour_long = null;
                        obj_a.start_noon = null;
                        obj_a.end_noon = null;
                      } else {
                        //////console.log(shuzu[d][e])
                        obj_a.start_time =
                          shuzu[d][e].value.getFullYear() +
                          "-" +
                          this.pa(shuzu[d][e].value.getMonth() + 1) +
                          "-" +
                          this.pa(shuzu[d][e].value.getDate());
                        obj_a.end_time =
                          shuzu[d][e].value_id.getFullYear() +
                          "-" +
                          this.pa(shuzu[d][e].value_id.getMonth() + 1) +
                          "-" +
                          this.pa(shuzu[d][e].value_id.getDate());
                        obj_a.hour_long = shuzu[d][e].day + "";
                        obj_a.start_noon = shuzu[d][e].value_start;
                        obj_a.end_noon = shuzu[d][e].value_id_end;
                        //////console.log(shuzu[d][e])
                      }
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.list = list_a;
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)
            insert_s_biz_trip({ data: obj }).then((res) => {
              if (res.data.code == 200) {
                add_appr_flow_step_list({
                  data: {
                    list: fasong_list,
                    flow_no: suiji,
                  },
                }).then((rfs) => {
                  //////console.log(rfs)
                  if (rfs.data.code == 200) {
                    start_flow_action({
                      data: {
                        ent_id: this.$ent_id(),
                        user_id: this.jichuxinxi.user_id,
                        flow_id: this.date.flow_id,
                        dept_id: this.liucheng_dept_id,
                        flow_no: suiji,
                        cust_name: obj.cust_name,
                      },
                    }).then((rbs) => {
                      //////console.log(rbs)
                      if (rbs.data.code == 200) {
                        this.dai_tijiao_ren(suiji);
                        this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        window.history.go(-1);
                      } else if (rbs.data.code == 500) {
                      } else if (rbs.data.code == 10246) {
                        this.$message({
                          message: "没有设置审批流程",
                          type: "warning",
                        });
                      }
                    });
                  } else if (rfs.data.code == 500) {
                  }
                });
              } else if (res.data.code == 500) {
              }
            });
          }
        } else if (this.date.flow_id == "H49d4edc3T") {
          // 交付单
          const suiji = new Date().getTime() + this.$func.randomstring(5);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id,
            flow_no: suiji,
            file_no: "",
            cust_name: "",
            cust_id: "",
            staff_id: "",
            dept_id: "",
            date_deliver: "",
            list: [],
            amt_deliver: "",
            extra: "",
            flow_no_orig:
              this.zhuangtai_shiyong_name == "作废"
                ? this.yi_fenpei_bianji.flow_no
                : null,
          };
          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            for (let i = 0; i < this.list.length; i++) {
              if (
                this.list[i].componentName == "textfield" &&
                this.list[i].defaultLable == "客户"
              ) {
                obj.cust_name = this.list[i].value;
                obj.cust_id = this.list[i].kehu_list[this.list[i].zhanshi].cust_id;
                obj.file_no = this.list[i].kehu_list[this.list[i].zhanshi].file_no;
                // obj.staff_id=this.list[i].kehu_list[this.list[i].zhanshi].staff_id
                // obj.dept_id=this.list[i].kehu_list[this.list[i].zhanshi].dept_id
              }
              if (
                this.list[i].componentName == "contacts" &&
                this.list[i].defaultLable == "负责人"
              ) {
                obj.staff_id = this.jichuxinxi.user_id;
                // if(this.list[i].staff_id==''||this.list[i].staff_id==undefined){
                //     obj.staff_id=null
                // }else{
                //     obj.staff_id=this.list[i].staff_id
                // }
              }
              if (
                this.list[i].componentName == "department" &&
                this.list[i].defaultLable == "部门"
              ) {
                if (this.list[i].dept_id == "" || this.list[i].dept_id == undefined) {
                  obj.dept_id = null;
                } else {
                  obj.dept_id = this.list[i].dept_id;
                }
              }
              if (
                this.list[i].componentName == "calcform" &&
                this.list[i].defaultLable == "交付金额"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.amt_deliver = null;
                } else {
                  obj.amt_deliver = this.list[i].value + "";
                }
              }
              if (
                this.list[i].componentName == "dddatefield" &&
                this.list[i].defaultLable == "日期"
              ) {
                if (this.list[i].value == "" || this.list[i].value == undefined) {
                  obj.date_deliver = null;
                } else {
                  obj.date_deliver =
                    this.list[i].value.getFullYear() +
                    "-" +
                    this.pa(this.list[i].value.getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value.getDate());
                }
              }
              if (
                this.list[i].componentName == "tablefield" &&
                this.list[i].defaultLable == "产品明细"
              ) {
                // let length=''
                // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
                // for(let a=0;a<data.length;a++){
                //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
                //         length=Number(data[a].components.length)+1
                //     }
                // }
                // let shuzu=this.group(this.list[i].components,length)
                const shuzu = this.list[i].components_list;
                //////console.log(shuzu)
                const list_a = [];
                for (let d = 0; d < shuzu.length; d++) {
                  const obj_a = {
                    prod_id: null,
                    prod_name: null,
                    prod_code: null,
                    cat_id: null,
                    cat_name: null,
                    spec: null,
                    type: null,
                    unit: null,
                    qty: null,
                    disc_rate: null,
                    price_sale: null,
                  };
                  for (let e = 0; e < shuzu[d].length; e++) {
                    if (
                      shuzu[d][e].componentName == "product" &&
                      shuzu[d][e].defaultLable == "产品"
                    ) {
                      obj_a.prod_id = shuzu[d][e].chanpin_list.prod_id;
                      obj_a.prod_code = shuzu[d][e].chanpin_list.prod_code;
                      obj_a.cat_id = shuzu[d][e].chanpin_list.cat_id;
                      obj_a.prod_name = shuzu[d][e].chanpin_list.prod_name;
                      obj_a.cat_name = shuzu[d][e].chanpin_list.cat_name;
                      obj_a.spec = shuzu[d][e].chanpin_list.spec;
                      obj_a.type = shuzu[d][e].chanpin_list.type;
                      obj_a.unit = shuzu[d][e].chanpin_list.unit;
                      obj_a.price_unit = shuzu[d][e].chanpin_list.price_unit + "";
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "数量"
                    ) {
                      obj_a.qty = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "calcform" &&
                      shuzu[d][e].defaultLable == "售价"
                    ) {
                      obj_a.price_sale = shuzu[d][e].value + "";
                    }
                    if (
                      shuzu[d][e].componentName == "numberfield" &&
                      shuzu[d][e].defaultLable == "折扣率"
                    ) {
                      obj_a.disc_rate = Number(shuzu[d][e].value) + "";
                    }
                  }
                  list_a.push(obj_a);
                }
                obj.list = list_a;
              }
            }
            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            //////console.log(obj)
            //////console.log(obj.amt_deliver)
            if (this.zhuangtai_shiyong_name == "作废") {
              obj.flag_cancel = "1";
            } else {
              obj.flag_cancel = "0";
            }
            insert_s_deliver({ data: obj }).then((res) => {
              if (res.data.code == 200) {
                add_appr_flow_step_list({
                  data: {
                    list: fasong_list,
                    flow_no: suiji,
                  },
                }).then((rfs) => {
                  //////console.log(rfs)
                  if (rfs.data.code == 200) {
                    start_flow_action({
                      data: {
                        ent_id: this.$ent_id(),
                        user_id: this.jichuxinxi.user_id,
                        flow_id: this.date.flow_id,
                        dept_id: this.liucheng_dept_id,
                        flow_no: suiji,
                        cust_name: obj.cust_name,
                      },
                    }).then((rbs) => {
                      //////console.log(rbs)
                      if (rbs.data.code == 200) {
                        this.dai_tijiao_ren(suiji);
                        this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        window.history.go(-1);
                      } else if (rbs.data.code == 500) {
                      } else if (rbs.data.code == 10246) {
                        this.$message({
                          message: "没有设置审批流程",
                          type: "warning",
                        });
                      }
                    });
                  } else if (rfs.data.code == 500) {
                  }
                });
              } else if (res.data.code == 500) {
              }
            });
            // }else{
            //     this.$message({
            //         message: '当前交付金额已超过合同未交付金额',
            //         type: 'error'
            //     });
            // }
          }
        } else if (this.date.flow_id == "AY3cli7BAb") {
          //vip充值单
          this.query_insert_member_topup(fasong_list);
        } else {
          const suiji = new Date().getTime() + this.$func.randomstring(5);
          const obj = {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            flow_id: this.date.flow_id,
            flow_name: this.date.flow_name,
            flow_no: suiji,
            extra: "",
          };
          if (this.panduan(this.list)) {
            const extra = this.huoqu_zi_dingyi_kongjian();
            //////console.log(extra)

            const extra2 = extra.length != 0 ? JSON.stringify(extra) : null;
            obj.extra = extra2;
            let kehu_name = "";
            for (let ia = 0; ia < extra.length; ia++) {
              if (extra[ia].type == "cust" && extra[ia].value.length != 0) {
                kehu_name = extra[ia].value;
                break;
              }
            }
            //////console.log(obj)
            if (this.zhuangtai_shiyong_name == "再次提交") {
              obj.id = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_4", "暂时").id;
            }
            insert_cust_form_data({ data: obj }).then((res) => {
              //console.log(res)
              if (res.data.code == 200) {
                add_appr_flow_step_list({
                  data: {
                    list: fasong_list,
                    flow_no: suiji,
                  },
                }).then((rfs) => {
                  //////console.log(rfs)
                  if (rfs.data.code == 200) {
                    start_flow_action({
                      data: {
                        ent_id: this.$ent_id(),
                        user_id: this.jichuxinxi.user_id,
                        flow_id: this.date.flow_id,
                        dept_id: this.liucheng_dept_id,
                        flow_no: suiji,
                        cust_name: kehu_name.length != 0 ? kehu_name : null,
                      },
                    }).then((rbs) => {
                      //////console.log(rbs)
                      if (rbs.data.code == 200) {
                        this.dai_tijiao_ren(suiji);
                        this.$message({
                          message: "提交成功",
                          type: "success",
                        });
                        window.history.go(-1);
                      } else if (rbs.data.code == 500) {
                      } else if (rbs.data.code == 10246) {
                        this.$message({
                          message: "没有设置审批流程",
                          type: "warning",
                        });
                      }
                    });
                  } else if (rfs.data.code == 500) {
                  }
                });
              } else if (res.data.code == 500) {
              }
            });
          }
        }
      } else {
        this.$message({
          message: "流程不完整",
          type: "warning",
        });
      }
      // }else{
      //     this.$message({
      //         message: '请填写流程信息',
      //         type: 'warning'
      //     });
      // }
      // add_appr_flow_step_list
      // }else{
      //     this.$message({
      //         message: '流程未加载完毕，请稍后重试',
      //         type: 'warning'
      //     });
      // }
    },
    // 带别人填写单子要调的接口
    dai_tijiao_ren(flow_no) {
      if (this.dai_taren_tijiao) {
        set_replace_write_user({
          data: {
            ent_id: this.$ent_id(),
            flow_no,
            flag_replace_write: "1",
            replace_write_user_id: this.$jichuxinxi().user_id,
            replace_write_user_name: this.$jichuxinxi().name,
          },
        });
      }
    },
    // 获取自定义控件
    huoqu_zi_dingyi_kongjian() {
      const extra = [];
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].siyou == "22") {
          if (
            this.list[i].value != undefined &&
            this.list[i].componentName == "ddattachment"
          ) {
            extra.push({
              type: "ddattachment",
              idx: this.list[i].idx,
              name: this.list[i].defaultLable,
              fujian_list: this.list[i].list.length != 0 ? this.list[i].list : [],
            });
          } else if (
            this.list[i].value != undefined &&
            this.list[i].componentName == "ddselectfield"
          ) {
            extra.push({
              type: "ddselectfield",
              idx: this.list[i].idx,
              name: this.list[i].defaultLable,
              value: this.list[i].defaultOptions[Number(this.list[i].value)].text,
              liebiao: this.list[i].defaultOptions,
              value_ids: this.list[i].value,
            });
          } else if (
            this.list[i].value != undefined &&
            this.list[i].componentName == "province"
          ) {
            extra.push({
              type: "province",
              idx: this.list[i].idx,
              name: this.list[i].defaultLable,
              value:
                this.list[i].value.length != 0
                  ? this.list[i].value +
                    "-" +
                    this.list[i].value_id +
                    "-" +
                    this.list[i].value_ids
                  : "",
              value_id: this.list[i].value.length != 0 ? this.list[i].value : "", // 省
              value_ids: this.list[i].value.length != 0 ? this.list[i].value_id : "", // 市
              value_idss: this.list[i].value.length != 0 ? this.list[i].value_ids : "", // 区
            });
          } else if (
            this.list[i].value_list != undefined &&
            this.list[i].componentName == "assappform"
          ) {
            extra.push({
              type: "assappform",
              idx: this.list[i].idx,
              name: this.list[i].defaultLable,
              // value:this.list[i].value_list!=undefined&&this.list[i].value_list.length!=0?this.list[i].value_list.map(ite=>ite.flow_no).join(','):'',
              liebiao:
                this.list[i].value_list.length != 0
                  ? this.list[i].value_list
                  : this.list[i].value_list,
            });
          } else if (
            this.list[i].value != undefined &&
            this.list[i].componentName == "cust" &&
            this.list[i].value.length != 0
          ) {
            extra.push({
              type: "cust",
              idx: this.list[i].idx,
              name: this.list[i].defaultLable,
              value: this.list[i].value.length != 0 ? this.list[i].value : "",
              liebiao: this.list[i].kehu_list,
              value_id:
                this.list[i].value.length != 0
                  ? this.list[i].kehu_list[this.list[i].zhanshi].cust_id
                  : "",
              value_ids:
                this.list[i].value.length != 0
                  ? this.list[i].kehu_list[this.list[i].zhanshi].file_no
                  : "",
              teshu: false,
              zhanshi: this.list[i].zhanshi,
            });
            // file_nocalcform
            //////console.log(this.list[i])
          } else if (
            this.list[i].value != undefined &&
            this.list[i].componentName == "calcform"
          ) {
            extra.push({
              type: "calcform",
              idx: this.list[i].idx,
              name: this.list[i].defaultLable,
              value: this.list[i].value,
              liebiao: this.list[i].gongshi,
            });
            // file_no
            //////console.log(this.list[i])
            //
          } else if (
            this.list[i].value != undefined &&
            this.list[i].componentName == "product"
          ) {
            const arr = [];
            if (this.list[i].value.length != 0) {
              arr.push(this.list[i].chanpin_list);
            }
            extra.push({
              type: "product",
              idx: this.list[i].idx,
              name: this.list[i].defaultLable,
              value: this.list[i].value.length != 0 ? arr[0].prod_name : "",
              liebiao: arr,
            });
          } else if (this.list[i].componentName == "ddmultiselectfield") {
            const duoxuan_zhi = [];
            this.list[i].defaultOptions.forEach((duoxuan) => {
              if (duoxuan.zhi) {
                duoxuan_zhi.push(duoxuan.text);
              }
            });
            // if(duoxuan_zhi.length!=0){
            extra.push({
              type: "ddmultiselectfield",
              idx: this.list[i].idx,
              name: this.list[i].defaultLable,
              value: duoxuan_zhi.length != 0 ? duoxuan_zhi.join(",") : "",
              liebiao: this.list[i].defaultOptions,
            });
            // }
          } else if (
            this.list[i].value != undefined &&
            this.list[i].componentName == "dddaterangefield"
          ) {
            extra.push({
              type: "dddaterangefield",
              name: this.list[i].defaultLable,
              idx: this.list[i].idx,
              value:
                this.list[i].value.length != 0
                  ? this.list[i].value[0].getFullYear() +
                    "-" +
                    this.pa(this.list[i].value[0].getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value[0].getDate()) +
                    "至" +
                    this.list[i].value[1].getFullYear() +
                    "-" +
                    this.pa(this.list[i].value[1].getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value[1].getDate())
                  : "",
              value_ids: "",
            });
          } else if (
            this.list[i].value != undefined &&
            this.list[i].componentName == "dddatefield"
          ) {
            extra.push({
              type: "dddatefield",
              name: this.list[i].defaultLable,
              idx: this.list[i].idx,
              value:
                this.list[i].value.length != 0
                  ? this.list[i].value.getFullYear() +
                    "-" +
                    this.pa(this.list[i].value.getMonth() + 1) +
                    "-" +
                    this.pa(this.list[i].value.getDate())
                  : "",
              value_ids: "",
            });
          } else if (
            this.list[i].dept_id != undefined &&
            this.list[i].componentName == "department"
          ) {
            //////console.log(this.list[i])
            extra.push({
              type: "department",
              name: this.list[i].defaultLable,
              idx: this.list[i].idx,
              value:
                this.list[i].option.length != 0
                  ? this.list[i].option
                      .map((item) => {
                        if (item.dept_id == this.list[i].dept_id) {
                          return item.dept_name;
                        }
                      })
                      .join(",")
                  : "",
              value_ids:
                this.list[i].option.length != 0 && this.list[i].dept_id.length != 0
                  ? this.list[i].dept_id
                  : "",
              liebiao: this.list[i].option.length != 0 ? this.list[i].option : [],
            });
          } else if (
            this.list[i].staff_id != undefined &&
            this.list[i].componentName == "contacts"
          ) {
            extra.push({
              type: "contacts",
              name: this.list[i].defaultLable,
              idx: this.list[i].idx,
              value: this.list[i].staff_id.length != 0 ? this.list[i].value : "",
              value_ids: this.list[i].staff_id.length != 0 ? this.list[i].staff_id : "",
            });
          } else if (
            this.list[i].xin != undefined &&
            this.list[i].componentName == "ddphotofield"
          ) {
            extra.push({
              type: "ddphotofield",
              name: this.list[i].defaultLable,
              idx: this.list[i].idx,
              tu_b64: this.list[i].xin.length != 0 ? this.list[i].xin : "",
              tu_type: this.list[i].xin.length != 0 ? this.list[i].lei : "",
            });
          } else if (
            this.list[i].value != undefined &&
            this.list[i].componentName == "textnote"
          ) {
            extra.push({
              type: "textnote",
              idx: this.list[i].idx,
              name: this.list[i].defaultProps,
              value: this.list[i].value.length != 0 ? this.list[i].value : "",
            });
          } else if (
            this.list[i].componentName == "tablefield" &&
            this.list[i].components_list[0].length != 0
          ) {
            const mingxi_list = [];
            let id = 0;
            this.list[i].components_list.forEach((item) => {
              const mingxi_arr = [];
              item.forEach((ite) => {
                // if(ite.value!=undefined&&ite.componentName=="ddattachment"&&ite.list.length!=0){
                //     mingxi_arr.push({
                //         type:'ddattachment',
                //         idx:ite.idx,
                //         name:ite.defaultLable,
                //         fujian_list:ite.list,
                //     })
                // }else
                // if(ite.value_list!=undefined&&ite.componentName=="assappform"&&ite.value_list.length!=0){
                //     mingxi_arr.push({
                //         type:'assappform',
                //         idx:ite.idx,
                //         name:ite.defaultLable,
                //         // value:ite.value_list!=undefined&&ite.value_list.length!=0?ite.value_list.map(ite=>ite.flow_no).join(','):'',
                //         liebiao:ite.value_list
                //     })
                // }else  if(ite.dept_id!=undefined&&ite.componentName=="department"&&ite.option.length!=0){
                //     //////console.log(ite)
                //     mingxi_arr.push({
                //         type:'department',
                //         name:ite.defaultLable,
                //         idx:ite.idx,
                //         value:ite.option.map(item=>{
                //             if(item.dept_id==ite.dept_id){
                //                 return item.dept_name
                //             }
                //         }).join(','),
                //         value_ids:ite.dept_id.length!=0?ite.dept_id:'',
                //         liebiao:ite.option
                //     })
                // }else
                if (ite.value != undefined && ite.componentName == "ddselectfield") {
                  mingxi_arr.push({
                    type: "ddselectfield",
                    idx: ite.idx,
                    name: ite.defaultLable,
                    value: ite.defaultOptions[Number(ite.value)].text,
                    liebiao: ite.defaultOptions,
                    value_ids: ite.value,
                  });
                } else if (ite.value != undefined && ite.componentName == "province") {
                  mingxi_arr.push({
                    type: "province",
                    idx: ite.idx,
                    name: ite.defaultLable,
                    value:
                      ite.value.length != 0
                        ? ite.value + "-" + ite.value_id + "-" + ite.value_ids
                        : "",
                    value_id: ite.value.length != 0 ? ite.value : "", // 省
                    value_ids: ite.value.length != 0 ? ite.value_id : "", // 市
                    value_idss: ite.value.length != 0 ? ite.value_ids : "", // 区
                  });
                } else if (
                  ite.value != undefined &&
                  ite.componentName == "product" &&
                  ite.value.length != 0
                ) {
                  const arr = [];
                  arr.push(ite.chanpin_list);
                  mingxi_arr.push({
                    type: "product",
                    idx: ite.idx,
                    name: ite.defaultLable,
                    value: arr[0].prod_name,
                    liebiao: arr,
                  });
                } else if (ite.componentName == "ddmultiselectfield") {
                  const duoxuan_zhi = [];
                  ite.defaultOptions.forEach((duoxuan) => {
                    if (duoxuan.zhi) {
                      duoxuan_zhi.push(duoxuan.text);
                    }
                  });
                  // if(duoxuan_zhi.length!=0){
                  mingxi_arr.push({
                    type: "ddmultiselectfield",
                    idx: ite.idx,
                    name: ite.defaultLable,
                    value: duoxuan_zhi.length != 0 ? duoxuan_zhi.join(",") : "",
                    liebiao: ite.defaultOptions,
                  });
                  // }
                } else if (
                  ite.value != undefined &&
                  ite.componentName == "dddaterangefield"
                ) {
                  mingxi_arr.push({
                    type: "dddaterangefield",
                    name: ite.defaultLable,
                    idx: ite.idx,
                    value:
                      ite.value.length != 0
                        ? ite.value[0].getFullYear() +
                          "-" +
                          this.pa(ite.value[0].getMonth() + 1) +
                          "-" +
                          this.pa(ite.value[0].getDate()) +
                          "至" +
                          ite.value[1].getFullYear() +
                          "-" +
                          this.pa(ite.value[1].getMonth() + 1) +
                          "-" +
                          this.pa(ite.value[1].getDate())
                        : "",
                    value_ids: "",
                  });
                } else if (ite.value != undefined && ite.componentName == "dddatefield") {
                  mingxi_arr.push({
                    type: "dddatefield",
                    name: ite.defaultLable,
                    idx: ite.idx,
                    value:
                      ite.value.length != 0
                        ? ite.value.getFullYear() +
                          "-" +
                          this.pa(ite.value.getMonth() + 1) +
                          "-" +
                          this.pa(ite.value.getDate())
                        : "",
                    value_ids: "",
                  });
                } else if (ite.staff_id != undefined && ite.componentName == "contacts") {
                  mingxi_arr.push({
                    type: "contacts",
                    name: ite.defaultLable,
                    idx: ite.idx,
                    value: ite.staff_id.length != 0 ? ite.value : "",
                    value_ids: ite.staff_id.length != 0 ? ite.staff_id : "",
                  });
                } else if (ite.xin != undefined && ite.componentName == "ddphotofield") {
                  mingxi_arr.push({
                    type: "ddphotofield",
                    name: ite.defaultLable,
                    idx: ite.idx,
                    tu_b64: ite.xin.length != 0 ? ite.xin : "",
                    tu_type: ite.xin.length != 0 ? ite.lei : "",
                  });
                } else if (ite.value != undefined && ite.componentName == "textnote") {
                  mingxi_arr.push({
                    type: "textnote",
                    idx: ite.idx,
                    name: ite.defaultProps,
                    value: ite.value.length != 0 ? ite.value : "",
                  });
                } else {
                  if (
                    ite.componentName != "cust" &&
                    ite.value != undefined &&
                    ite.componentName != "product"
                  ) {
                    mingxi_arr.push({
                      type: ite.componentName,
                      idx: ite.idx,
                      name: ite.defaultLable,
                      value: ite.value.length != 0 ? ite.value : "",
                    });
                  }
                }
              });
              // mingxi_list.push(mingxi_arr)
              mingxi_list.push({
                type: "tablefield",
                idx: this.list[i].idx,
                name: this.list[i].defaultLable,
                index: id + 1,
                mingxi_list: mingxi_arr,
              });
              id = id + 1;
            });
            extra.push({
              type: "tablefield",
              idx: this.list[i].idx,
              name: this.list[i].defaultLable,
              mingxi_list: mingxi_list,
            });
          } else {
            if (
              this.list[i].componentName != "department" &&
              this.list[i].componentName != "cust" &&
              this.list[i].componentName != "ddattachment" &&
              this.list[i].componentName != "product"
            ) {
              if (this.list[i].value != undefined) {
                extra.push({
                  type: this.list[i].componentName,
                  idx: this.list[i].idx,
                  name: this.list[i].defaultLable,
                  value: this.list[i].value.length != 0 ? this.list[i].value : "",
                });
              }
            }
          }
        }
      }
      //////console.log(extra)

      // setTimeout(() => {
      return extra;
      // }, 0)
    },
    // 点击选择客户
    dianji_kehu(i) {
      query_rel_customer_list({
        data: {
          ent_id: this.$ent_id(),
          staff_id: this.date.flow_id != "hXstgwxhzh" ? this.jichuxinxi.user_id : null,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          //////console.log(res)
          const date = JSON.parse(res.data.body.data);
          if (date.length == 0) {
            this.$message({
              message: "您当前没有客户",
              type: "warning",
            });
          } else {
            this.$set(i, "kehu_list", date);
            this.$set(i, "zhanshi", 0);
            this.$set(i, "teshu", true);
          }
        } else if (res.data.code == 500) {
          alert("小虎哥报错了");
        }
      });
    },

    dianji_xuanze_juece_ren(i) {
      let name = "";
      for (let a = 0; a < this.list.length; a++) {
        if (
          this.list[a].defaultLable == "客户" &&
          this.list[a].componentName == "textfield"
        ) {
          name = this.list[a].kehu_list[this.list[a].zhanshi].cust_name;
        }
      }
      //////console.log(i)
      query_rel_customer_decision_list({
        data: {
          ent_id: this.$ent_id(),
          cust_name: name,
        },
      }).then((res) => {
        //////console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data);
          //////console.log(date)
          i.teshu = true;
          i.defaultOptions = date;
        } else if (res.data.code == 500) {
        }
      });
    },
    xuanze_juece(i) {
      i.value_id = i.defaultOptions[i.value].c_name;
      i.teshu = false;
    },
    dakai_shenpi_dan(i) {
      //////console.log(i)
    },
    // 打开产品
    dakai_chanpin(index, it, liebiao, mingxi, table) {
      let dept_id = "";
      this.list.forEach((item) => {
        if (
          item.componentName == "department" &&
          item.dept_id != undefined &&
          item.dept_id.length != 0
        ) {
          dept_id = item.dept_id;
        }
      });
      if (
        (this.date.flow_id == "7TvJHPyovM" || // 客户录入单
          this.date.flow_id == "Z7VDOK9HGu" || // 访客计划单
          this.date.flow_id == "453y6pLD52" || // 访客记录单
          this.date.flow_id == "PLOQ9ku3Ip" || // 漏斗单
          this.date.flow_id == "Eb3n7CKQzi" || // 派工单
          this.date.flow_id == "qIwxmsMTgv" || // 合同单
          this.date.flow_id == "VMCizuVOXb" || // 发票单
          this.date.flow_id == "He5ln0bdDO" || // 回款单
          this.date.flow_id == "cvRyWGiUQH" || // 外出单
          this.date.flow_id == "GBh7N2jMsi" || // 出差单
          this.date.flow_id == "H49d4edc3T") && // 交付单
        dept_id.length == 0
      ) {
        this.$message({
          message: "请选择部门再选择产品",
          type: "warning",
        });
      } else {
        // Xsk2pLBS9a  采购单
        if (this.date.flow_id != "5FotYgnu0Z" && this.date.flow_id != "Xsk2pLBS9a") {
          no_sale_prod_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.jichuxinxi.user_id,
              type: "staff",
              staff_ids: this.jichuxinxi.user_id,
              dept_ids: dept_id.length != 0 ? dept_id : null,
            },
          }).then((ras) => {
            //////console.log(ras)
            const date = JSON.parse(ras.data.body.data);
            const list = [];
            if (date != undefined) {
              for (let i = 0; i < date.length; i++) {
                list.push({
                  prod_id: date[i],
                  cat_id: date[i],
                });
              }
            }
            this.buneng_xuan_chanpin = list;
            this.chanpin_dalei_jiekou();
          });
        }
        if (this.date.flow_id == "Xsk2pLBS9a") {
          this.buneng_xuan_chanpin = [];
          this.chanpin_dalei_jiekou();
        }
        //////console.log(index)
        //////console.log(liebiao)

        //////console.log(it)
        //////console.log(mingxi)
        let file_no = "";
        if (this.date.flow_id == "hXstgwxhzh") {
          this.list.forEach((item) => {
            if (item.idx == "1600481695883") {
              // 查到是哪个归档号
              file_no = item.value;
            }
          });
        }
        if (index == undefined) {
          this.chanpin_danxuan = true;
          this.chanpin1 = true;
        } else {
          this.chanpin_danxuan = false;
          if (this.date.flow_id == "hXstgwxhzh" && file_no.length == 0) {
            this.$message({
              message: "请选择客户",
              type: "warning",
            });
          } else {
            this.chanpin2 = true;
          }
        }
        if (mingxi == undefined) {
          this.dangqian_mingxi_kuang_chanpin = [];
        } else {
          const biao_chanpin_list = []; // 表中产品列表

          mingxi.forEach((item) => {
            item.forEach((ite) => {
              if (ite.componentName == "product" && ite.chanpin_list != undefined) {
                biao_chanpin_list.push(ite.chanpin_list);
              }
            });
          });
          //////console.log(biao_chanpin_list)
          this.dangqian_mingxi_kuang_chanpin = biao_chanpin_list;
        }
        // dangqian_mingxi_kuang_chanpin
        // this.mianbao_list_chanpin.splice(1,this.mianbao_list_chanpin.length)

        this.dangqian_chanpin_id = index;
        this.chanpin_mingxi = it;
        this.chanpin_mingxi_liebiao = liebiao;
        this.dangqian_mingxi_liebiao = mingxi; // 明细框里的控件列表
        this.dangqian_mingxi = table; // 明细框
      }
      // query_category_info_list({data:{
      //     ent_id:this.$ent_id(),
      //     user_id:this.$jichuxinxi().user_id,
      //     parent_id:this.mianbao_list_chanpin[this.mianbao_list_chanpin.length-1].dept_id,
      //     active:'1'
      // }}).then(res=>{
      //     //////console.log(4444444,res)
      //     if(res.data.code==10193){
      //         let date=JSON.parse(res.data.body.data)
      //         //////console.log(date)
      //         var b = this.buneng_xuan_chanpin
      //         //////console.log(b)
      //         let deal = (arr1, arr2, key) => {
      //             let arr3 = []
      //             for (let index = 0; index < arr1.length; index++) {
      //                 let item = arr1[index]
      //                 if (!arr2.some(v => v[key] === item[key])) {
      //                     arr3.push(item)
      //                 }
      //             }
      //             return arr3
      //         }
      //         this.qiye_chanpin_leixing_list=deal(date,b,'cat_id')
      //     }else if(res.data.code==10194){
      //     }
      // })
      // query_prod_info_list({data:{
      //     ent_id:this.$ent_id(),
      //     user_id:this.$jichuxinxi().user_id,
      //     cat_id:this.mianbao_list_chanpin[this.mianbao_list_chanpin.length-1].dept_id,
      //     active:'1'
      // }}).then(res=>{
      //     if(res.data.code==10139){
      //         let date=JSON.parse(res.data.body.data)
      //         //////console.log(this.buneng_xuan_chanpin)
      //         var a = date
      //         var b = this.buneng_xuan_chanpin
      //         // var c = [];
      //         // for(let i of a){
      //         //     for(let j of b){
      //         //         if(i.prod_id != j.prod_id){
      //         //             c.push(i)
      //         //         }
      //         //     }
      //         // }
      //         let deal = (arr1, arr2, key) => {
      //             let arr3 = []
      //             for (let index = 0; index < arr1.length; index++) {
      //                 let item = arr1[index]
      //                 if (!arr2.some(v => v[key] === item[key])) {
      //                     arr3.push(item)
      //                 }
      //             }
      //             return arr3
      //         }
      //         //////console.log(deal(a,b,'prod_id'))
      //         this.qiye_chanpin_list=deal(a,b,'prod_id')
      //         // buneng_xuan_chanpin
      //     }else if(res.data.code==10140){
      //     }
      // })
    },
    // 单选人
    dianji_xuan_chanpin(index) {
      this.chanpin_num = index;
    },
    // 点击产品
    dianji_chanpin(i) {
      //////console.log(44444, i)
      query_category_info_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.jichuxinxi.user_id,
          parent_id: i.cat_id,
          active: "1",
        },
      }).then((res) => {
        //////console.log(4444444, res)
        if (res.data.code == 10193) {
          const date = JSON.parse(res.data.body.data);
          this.mianbao_list_chanpin.push({ name: i.cat_name, dept_id: i.cat_id });
          var b = this.buneng_xuan_chanpin;
          const deal = (arr1, arr2, key) => {
            const arr3 = [];
            for (let index = 0; index < arr1.length; index++) {
              const item = arr1[index];
              if (!arr2.some((v) => v[key] === item[key])) {
                arr3.push(item);
              }
            }
            return arr3;
          };
          this.qiye_chanpin_leixing_list = deal(date, b, "cat_id");
          query_prod_info_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.jichuxinxi.user_id,
              cat_id: i.cat_id,
              active: "1",
            },
          }).then((res) => {
            if (res.data.code == 10139) {
              const date = JSON.parse(res.data.body.data);
              var a = date;
              this.qiye_chanpin_list = deal(a, b, "prod_id");
              //////console.log(date)
              // this.dangqian_chanpin_id=i.idx
            } else if (res.data.code == 10140) {
            }
          });
        } else if (res.data.code == 10194) {
        }
      });
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian_chanpin(i, index) {
      if (this.mianbao_list_chanpin.length - 1 != index) {
        query_category_info_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            parent_id: i.dept_id,
            active: "1",
          },
        }).then((res) => {
          //////console.log(4444444, res)
          if (res.data.code == 10193) {
            const date = JSON.parse(res.data.body.data);
            var b = this.buneng_xuan_chanpin;
            const deal = (arr1, arr2, key) => {
              const arr3 = [];
              for (let index = 0; index < arr1.length; index++) {
                const item = arr1[index];
                if (!arr2.some((v) => v[key] === item[key])) {
                  arr3.push(item);
                }
              }
              return arr3;
            };
            this.qiye_chanpin_leixing_list = deal(date, b, "cat_id");
            query_prod_info_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.jichuxinxi.user_id,
                cat_id: i.dept_id,
                active: "1",
              },
            }).then((res) => {
              if (res.data.code == 10139) {
                const date = JSON.parse(res.data.body.data);
                var a = date;
                //////console.log(deal(a, b, 'prod_id'))
                this.qiye_chanpin_list = deal(a, b, "prod_id");
                this.mianbao_list_chanpin.splice(
                  index + 1,
                  this.mianbao_list_chanpin.length
                );
                //////console.log(date)
              } else if (res.data.code == 10140) {
              }
            });
          } else if (res.data.code == 10194) {
          }
        });
      }
    },
    // 发起人里成员点击确定
    chanpin_sure(item, data_zhi, con) {
      if (this.qiye_chanpin_list.length != 0) {
        // item.chanpin_list=this.qiye_chanpin_list[this.chanpin_num]
        // item.value=item.chanpin_list.prod_name
        //////console.log(con)
        //////console.log(this.dangqian_chanpin_id)
        // this.chanpin=false
        // item.chanpin_list=this.qiye_chanpin_list[this.chanpin_num]
        // item.value=item.chanpin_list.prod_name
        // //////console.log(item)
        // for(let i=0;i<data_zhi.length;i++){
        //     if(data_zhi[i].componentName=='moneyfield'&&data_zhi[i].defaultLable=='单价'){
        //         data_zhi[i].value=item.chanpin_list.price_unit
        //     }
        // }
        const list = con[this.dangqian_chanpin_id];
        //////console.log(list)
        for (let i = 0; i < list.length; i++) {
          if (list[i].componentName == "product") {
            list[i].chanpin_list = this.qiye_chanpin_list[this.chanpin_num];
            list[i].value = list[i].chanpin_list.prod_name;
          }
          if (list[i].componentName == "moneyfield" && list[i].defaultLable == "单价") {
            list[i].value = this.qiye_chanpin_list[this.chanpin_num].price_unit;
          }
        }
        this.chanpin = false;

        // this.list[i].components[a].value=this.list[i].components[a].chanpin_list.prod_name
        // let dangqian=''
        // for(let i=0;i<this.list.length;i++){
        //     if(this.list[i].componentName=='tablefield'){
        //         let length=''
        //         let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
        //         for(let a=0;a<data.length;a++){
        //             if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
        //                 length=Number(data[a].components.length)+1
        //             }
        //         }
        //         let shuzu=this.group(this.list[i].components,length)
        //         let zhi=''
        //         for(let a=0;a<this.list[i].components.length;a++){
        //             if(this.dangqian_chanpin_id==this.list[i].components[a].idx){
        //                 this.list[i].components[a].chanpin_list=this.qiye_chanpin_list[this.chanpin_num]
        //                 this.list[i].components[a].value=this.list[i].components[a].chanpin_list.prod_name
        //                 dangqian=this.list[i].components[a].dangqian
        //                 zhi=this.list[i].components[a].chanpin_list.price_unit
        //             }
        //         }
        //         for(let a=0;a<shuzu[dangqian].length;a++){
        //             //////console.log(shuzu[dangqian])
        //             if(shuzu[dangqian][a].componentName=='moneyfield'&&shuzu[dangqian][a].defaultLable=='单价'){
        //                 shuzu[dangqian][a].value=zhi
        //             }
        //         }
        //     }
        // }
      } else {
        this.$message({
          message: "还未选择产品",
          type: "warning",
        });
      }
    },
    // 字符串某个字符串前  或后
    zifuchuan(obj, state) {
      var index = obj.lastIndexOf("\.");
      if (state == 0) {
        obj = obj.substring(0, index);
      } else {
        obj = obj.substring(index + 1, obj.length);
      }
      return obj;
    },
    zifuchuan_2(obj, state) {
      var index = obj.lastIndexOf("\%");
      if (state == 0) {
        obj = obj.substring(0, index);
      } else {
        obj = obj.substring(index + 1, obj.length);
      }
      return obj;
    },
    // 计算公式
    jisuan() {
      for (let i = 0; i < this.list.length; i++) {
        if (
          this.list[i].componentName == "tablefield" &&
          this.list[i].defaultLable == "产品明细"
        ) {
          // let length=''
          // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
          // for(let a=0;a<data.length;a++){
          //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
          //         length=Number(data[a].components.length)+1
          //     }
          // }
          const shuzu = this.list[i].components_list;
          // let zong=''
          // // let dangqian=''
          // for(let a=0;a<this.list[i].components.length;a++){
          //     zong=Number(this.list[i].components.length)/length
          // }
          //////console.log(shuzu)
          for (let dangqian = 0; dangqian < shuzu.length; dangqian++) {
            //////console.log(shuzu[dangqian])
            for (let a = 0; a < shuzu[dangqian].length; a++) {
              if (
                shuzu[dangqian][a].componentName == "calcform" &&
                shuzu[dangqian][a].defaultLable == "原价"
              ) {
                //////console.log(shuzu[dangqian][a])
                let b = 0;
                const baocuo = [];
                for (let c = 0; c < shuzu[dangqian][a].gongshi.length; c++) {
                  // //////console.log(shuzu[dangqian])
                  for (let e = 0; e < shuzu[dangqian].length; e++) {
                    // //////console.log(shuzu[dangqian][e].defaultLable)
                    const nei_name = this.zifuchuan(
                      shuzu[dangqian][a].gongshi[c].name,
                      1
                    );
                    if (shuzu[dangqian][a].gongshi[c].zhi != "jisuan") {
                      if (nei_name == shuzu[dangqian][e].defaultLable) {
                        if (shuzu[dangqian][e].value == "") {
                          baocuo.push("1111");
                        } else {
                          b = b + shuzu[dangqian][e].value;
                        }
                      }
                    }
                  }
                  if (shuzu[dangqian][a].gongshi[c].zhi != undefined) {
                    let zhi = "";
                    if (shuzu[dangqian][a].gongshi[c].name == "x") {
                      zhi = "*";
                    }
                    b = Number(b) + zhi;
                  }
                }
                //////console.log(b)
                if (baocuo.length == 0) {
                  shuzu[dangqian][a].value = Number(eval(b).toFixed(2));
                }
              }
            }
          }
        }
        if (
          this.list[i].componentName == "tablefield" &&
          (this.list[i].defaultLable == "产品" ||
            this.list[i].defaultLable == "产品明细" ||
            this.list[i].defaultLable == "产品回款")
        ) {
          // let length=''
          // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
          // for(let a=0;a<data.length;a++){
          //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
          //         length=Number(data[a].components.length)+1
          //     }
          // }
          // let shuzu=this.group(this.list[i].components,length)
          // //////console.log(length)
          // let zong=''
          // // let dangqian=''
          // for(let a=0;a<this.list[i].components.length;a++){
          //     zong=Number(this.list[i].components.length)/length
          // }
          const shuzu = this.list[i].components_list;
          for (let dangqian = 0; dangqian < shuzu.length; dangqian++) {
            for (let a = 0; a < shuzu[dangqian].length; a++) {
              if (this.zheng_fan) {
                if (
                  (shuzu[dangqian][a].componentName == "calcform" &&
                    shuzu[dangqian][a].defaultLable == "原价") ||
                  shuzu[dangqian][a].defaultLable == "售价"
                ) {
                  //////console.log(shuzu[dangqian][a])
                  let b = "";
                  const baocuo = [];
                  for (let c = 0; c < shuzu[dangqian][a].gongshi.length; c++) {
                    // //////console.log(shuzu[dangqian])
                    for (let e = 0; e < shuzu[dangqian].length; e++) {
                      // //////console.log(shuzu[dangqian][e].defaultLable)
                      const nei_name = this.zifuchuan(
                        shuzu[dangqian][a].gongshi[c].name,
                        1
                      );
                      if (shuzu[dangqian][a].gongshi[c].zhi != "jisuan") {
                        if (nei_name == shuzu[dangqian][e].defaultLable) {
                          if (shuzu[dangqian][e].value == "") {
                            baocuo.push("1111");
                          } else {
                            b = b + shuzu[dangqian][e].value;
                          }
                        }
                      }
                    }
                    if (shuzu[dangqian][a].gongshi[c].zhi != undefined) {
                      let zhi = "";
                      if (shuzu[dangqian][a].gongshi[c].name == "x") {
                        zhi = "*";
                      } else if (shuzu[dangqian][a].gongshi[c].name == "÷") {
                        zhi = "/";
                      }
                      b = b + zhi;
                    }
                  }
                  //////console.log(b)
                  if (baocuo.length == 0) {
                    shuzu[dangqian][a].value = Number(eval(b).toFixed(2));
                  }
                }
              } else {
                if (
                  (shuzu[dangqian][a].componentName == "calcform" &&
                    shuzu[dangqian][a].defaultLable == "原价") ||
                  (shuzu[dangqian][a].defaultLable == "折扣率" &&
                    this.date.flow_id != "H49d4edc3T")
                ) {
                  //////console.log(shuzu[dangqian][a])
                  let b = "";
                  const baocuo = [];
                  for (let c = 0; c < shuzu[dangqian][a].gongshi.length; c++) {
                    // //////console.log(shuzu[dangqian])
                    for (let e = 0; e < shuzu[dangqian].length; e++) {
                      // //////console.log(shuzu[dangqian][e].defaultLable)
                      const nei_name = this.zifuchuan(
                        shuzu[dangqian][a].gongshi[c].name,
                        1
                      );
                      if (shuzu[dangqian][a].gongshi[c].zhi != "jisuan") {
                        if (nei_name == shuzu[dangqian][e].defaultLable) {
                          if (shuzu[dangqian][e].value == "") {
                            baocuo.push("1111");
                          } else {
                            b = b + shuzu[dangqian][e].value;
                          }
                        }
                      }
                    }
                    if (shuzu[dangqian][a].gongshi[c].zhi != undefined) {
                      let zhi = "";
                      if (shuzu[dangqian][a].gongshi[c].name == "x") {
                        zhi = "*";
                      } else if (shuzu[dangqian][a].gongshi[c].name == "÷") {
                        zhi = "/";
                      }
                      b = b + zhi;
                    }
                  }
                  //////console.log(b)
                  if (baocuo.length == 0) {
                    shuzu[dangqian][a].value = Number(eval(b).toFixed(2));
                  }
                }
              }
            }
          }
        }
        if (
          this.list[i].componentName == "tablefield" &&
          this.list[i].defaultLable != "产品明细" &&
          this.list[i].defaultLable != "产品" &&
          this.list[i].defaultLable != "产品明细" &&
          this.list[i].defaultLable != "产品回款" &&
          this.list[i].defaultLable != "发票内容"
        ) {
          const shuzu = this.list[i].components_list;
          //////console.log(shuzu)
          for (let dangqian = 0; dangqian < shuzu.length; dangqian++) {
            //////console.log(shuzu[dangqian])
            for (let a = 0; a < shuzu[dangqian].length; a++) {
              if (shuzu[dangqian][a].componentName == "calcform") {
                // //////console.log(shuzu[dangqian][a])
                let b = "";
                const baocuo = [];
                for (let c = 0; c < shuzu[dangqian][a].gongshi.length; c++) {
                  // //////console.log(shuzu[dangqian])
                  for (let e = 0; e < shuzu[dangqian].length; e++) {
                    //////console.log(shuzu[dangqian][e].defaultLable)
                    const nei_name = this.zifuchuan(
                      shuzu[dangqian][a].gongshi[c].name,
                      1
                    );
                    if (shuzu[dangqian][a].gongshi[c].zhi != "jisuan") {
                      if (nei_name == shuzu[dangqian][e].defaultLable) {
                        if (shuzu[dangqian][e].value == "") {
                          baocuo.push("1111");
                        } else {
                          b = b + shuzu[dangqian][e].value;
                        }
                      }
                    }
                  }
                  if (shuzu[dangqian][a].gongshi[c].zhi != undefined) {
                    let zhi = "";
                    if (shuzu[dangqian][a].gongshi[c].name == "x") {
                      zhi = "*";
                    } else {
                      zhi = shuzu[dangqian][a].gongshi[c].name;
                    }
                    b = b + zhi;
                  }
                }
                setTimeout(() => {
                  //////console.log(b)
                  if (baocuo.length == 0) {
                    shuzu[dangqian][a].value = Number(eval(b).toFixed(2));
                    // Number(eval(b).toFixed(2))
                  } else {
                    shuzu[dangqian][a].value = 0;
                  }
                }, 0);
              }
            }
          }
        }
        if (
          this.list[i].componentName == "tablefield" &&
          this.list[i].defaultLable == "发票内容"
        ) {
          const shuzu = this.list[i].components_list;
          for (let dangqian = 0; dangqian < shuzu.length; dangqian++) {
            for (let a = 0; a < shuzu[dangqian].length; a++) {
              if (this.zheng_fan) {
                if (
                  (shuzu[dangqian][a].componentName == "calcform" &&
                    shuzu[dangqian][a].defaultLable == "原价") ||
                  shuzu[dangqian][a].defaultLable == "售价"
                ) {
                  //////console.log(shuzu[dangqian][a])
                  let b = "";
                  const baocuo = [];
                  for (let c = 0; c < shuzu[dangqian][a].gongshi.length; c++) {
                    // //////console.log(shuzu[dangqian])
                    for (let e = 0; e < shuzu[dangqian].length; e++) {
                      // //////console.log(shuzu[dangqian][e].defaultLable)
                      const nei_name = this.zifuchuan(
                        shuzu[dangqian][a].gongshi[c].name,
                        1
                      );
                      if (shuzu[dangqian][a].gongshi[c].zhi != "jisuan") {
                        if (nei_name == shuzu[dangqian][e].defaultLable) {
                          if (shuzu[dangqian][e].value == "") {
                            baocuo.push("1111");
                          } else {
                            b = b + shuzu[dangqian][e].value;
                          }
                        }
                      }
                    }
                    if (shuzu[dangqian][a].gongshi[c].zhi != undefined) {
                      let zhi = "";
                      if (shuzu[dangqian][a].gongshi[c].name == "x") {
                        zhi = "*";
                      } else if (shuzu[dangqian][a].gongshi[c].name == "÷") {
                        zhi = "/";
                      }
                      b = b + zhi;
                    }
                  }
                  //////console.log(b)
                  if (baocuo.length == 0) {
                    shuzu[dangqian][a].value = Number(eval(b).toFixed(2));
                  }
                }
              } else {
                if (
                  (shuzu[dangqian][a].componentName == "calcform" &&
                    shuzu[dangqian][a].defaultLable == "原价") ||
                  shuzu[dangqian][a].defaultLable == "折扣率"
                ) {
                  //////console.log(shuzu[dangqian][a])
                  let b = "";
                  const baocuo = [];
                  for (let c = 0; c < shuzu[dangqian][a].gongshi.length; c++) {
                    // //////console.log(shuzu[dangqian])
                    for (let e = 0; e < shuzu[dangqian].length; e++) {
                      // //////console.log(shuzu[dangqian][e].defaultLable)
                      const nei_name = this.zifuchuan(
                        shuzu[dangqian][a].gongshi[c].name,
                        1
                      );
                      if (shuzu[dangqian][a].gongshi[c].zhi != "jisuan") {
                        if (nei_name == shuzu[dangqian][e].defaultLable) {
                          if (shuzu[dangqian][e].value == "") {
                            baocuo.push("1111");
                          } else {
                            b = b + shuzu[dangqian][e].value;
                          }
                        }
                      }
                    }
                    if (shuzu[dangqian][a].gongshi[c].zhi != undefined) {
                      let zhi = "";
                      if (shuzu[dangqian][a].gongshi[c].name == "x") {
                        zhi = "*";
                      } else if (shuzu[dangqian][a].gongshi[c].name == "÷") {
                        zhi = "/";
                      }
                      b = b + zhi;
                    }
                  }
                  //////console.log(b)
                  if (baocuo.length == 0) {
                    shuzu[dangqian][a].value = Number(eval(b).toFixed(2));
                  }
                }
              }
            }
            let danjia = "";
            let shuilv = "";
            let zhekou_lv = "";
            for (let a = 0; a < shuzu[dangqian].length; a++) {
              if (
                shuzu[dangqian][a].componentName == "ddselectfield" &&
                shuzu[dangqian][a].defaultLable == "税率"
              ) {
                shuilv =
                  Number(
                    this.zifuchuan_2(
                      shuzu[dangqian][a].defaultOptions[shuzu[dangqian][a].value].text,
                      0
                    )
                  ) / 100;
              }
              if (
                shuzu[dangqian][a].componentName == "product" &&
                shuzu[dangqian][a].defaultLable == "产品"
              ) {
                if (shuzu[dangqian][a].chanpin_list != undefined) {
                  danjia = shuzu[dangqian][a].chanpin_list.price_unit;
                }
              }
              if (
                shuzu[dangqian][a].componentName == "calcform" &&
                shuzu[dangqian][a].defaultLable == "折扣率"
              ) {
                zhekou_lv = shuzu[dangqian][a].value;
              }
            }
            for (let a = 0; a < shuzu[dangqian].length; a++) {
              if (
                shuzu[dangqian][a].componentName == "moneyfield" &&
                shuzu[dangqian][a].defaultLable == "不含税折后单价"
              ) {
                shuzu[dangqian][a].value = (
                  (Number(danjia) * Number(zhekou_lv)) /
                  (1 + Number(shuilv))
                ).toFixed(3);
              }
            }
            if (this.zheng_fan) {
              let buhan_shui_zhehou_danjia = "";
              let shuliang = "";
              let buhan_shui_jine = "";
              let shuie = "";
              for (let a = 0; a < shuzu[dangqian].length; a++) {
                if (
                  shuzu[dangqian][a].componentName == "moneyfield" &&
                  shuzu[dangqian][a].defaultLable == "不含税折后单价"
                ) {
                  buhan_shui_zhehou_danjia = Number(shuzu[dangqian][a].value);
                }
                if (
                  shuzu[dangqian][a].componentName == "numberfield" &&
                  shuzu[dangqian][a].defaultLable == "数量"
                ) {
                  shuliang = Number(shuzu[dangqian][a].value);
                }
              }
              for (let a = 0; a < shuzu[dangqian].length; a++) {
                if (
                  shuzu[dangqian][a].componentName == "moneyfield" &&
                  shuzu[dangqian][a].defaultLable == "不含税金额"
                ) {
                  shuzu[dangqian][a].value = (
                    buhan_shui_zhehou_danjia * shuliang
                  ).toFixed(2);
                  buhan_shui_jine = Number(shuzu[dangqian][a].value);
                }
              }
              for (let a = 0; a < shuzu[dangqian].length; a++) {
                if (
                  shuzu[dangqian][a].componentName == "moneyfield" &&
                  shuzu[dangqian][a].defaultLable == "税额"
                ) {
                  console.log('shuzushuzushuzushuzu', shuzu)
                  let amt_a = 0 // 售价
                  let amt_b = 0 // 不含税金额
                  // 先遍历出售价和不含税金额，然后再进行计算
                  shuzu.map(child=>{
                    child.map(item=>{
                      if(item.defaultLable=='售价') amt_a = Number(item.value)
                      if(item.defaultLable=='不含税金额') amt_b = Number(item.value)
                    })
                  })
                  
                  console.log('amt_b > amt_a', amt_b, amt_a)
                  // 如果不含税金额大于售价，则重新计算
                  if(amt_b > amt_a) {
                    // 税额 = 售价 - 不含税金额
                    shuzu[dangqian][a].value = (buhan_shui_jine * shuilv).toFixed(2);
                  } else {
                    shuzu[dangqian][a].value = (amt_a - amt_b).toFixed(2)
                  }
                  
                  shuie = Number(shuzu[dangqian][a].value);
                }
              }
              for (let a = 0; a < shuzu[dangqian].length; a++) {
                if (
                  shuzu[dangqian][a].componentName == "moneyfield" &&
                  shuzu[dangqian][a].defaultLable == "发票金额"
                ) {
                  shuzu[dangqian][a].value = (buhan_shui_jine + shuie).toFixed(2);
                }
              }
            } else {
              let fapiao_jine = "";
              let shuie = "";
              for (let a = 0; a < shuzu[dangqian].length; a++) {
                if (
                  shuzu[dangqian][a].componentName == "moneyfield" &&
                  shuzu[dangqian][a].defaultLable == "发票金额"
                ) {
                  fapiao_jine = shuzu[dangqian][a].value;
                }
              }
              for (let a = 0; a < shuzu[dangqian].length; a++) {
                if (
                  shuzu[dangqian][a].componentName == "moneyfield" &&
                  shuzu[dangqian][a].defaultLable == "税额"
                ) {
                  shuzu[dangqian][a].value = (
                    (Number(fapiao_jine) * Number(shuilv)) /
                    (1 + Number(shuilv))
                  ).toFixed(2);
                  shuie = Number(shuzu[dangqian][a].value);
                }
              }
              for (let a = 0; a < shuzu[dangqian].length; a++) {
                if (
                  shuzu[dangqian][a].componentName == "moneyfield" &&
                  shuzu[dangqian][a].defaultLable == "不含税金额"
                ) {
                  shuzu[dangqian][a].value = (
                    Number(fapiao_jine) - Number(shuie)
                  ).toFixed(2);
                }
              }
            }
          }
        }
        if (
          this.list[i].componentName == "tablefield" &&
          this.list[i].defaultLable == "列表"
        ) {
          const shuzu = this.list[i].components_list;
          for (let dangqian = 0; dangqian < shuzu.length; dangqian++) {
            let fapiao_jine = "";
            let shuilv = "";
            for (let a = 0; a < shuzu[dangqian].length; a++) {
              if (
                shuzu[dangqian][a].componentName == "ddselectfield" &&
                shuzu[dangqian][a].defaultLable == "税率"
              ) {
                shuilv =
                  Number(
                    this.zifuchuan_2(
                      shuzu[dangqian][a].defaultOptions[shuzu[dangqian][a].value].text,
                      0
                    )
                  ) / 100;
              }
              if (
                shuzu[dangqian][a].componentName == "calcform" &&
                shuzu[dangqian][a].defaultLable == "发票金额"
              ) {
                fapiao_jine = shuzu[dangqian][a].value;
              }
            }
            for (let a = 0; a < shuzu[dangqian].length; a++) {
              if (
                shuzu[dangqian][a].componentName == "moneyfield" &&
                shuzu[dangqian][a].defaultLable == "税额"
              ) {
                shuzu[dangqian][a].value = (
                  (Number(fapiao_jine) / (1 + Number(shuilv))) *
                  Number(shuilv)
                ).toFixed(2);
              }
            }
          }
        }
        if (
          this.list[i].componentName == "calcform" &&
          this.list[i].defaultLable == "漏斗金额"
        ) {
          // //////console.log(this.list[i])
          // for(let b=0;b<this.list.length;b++){
          //     if(this.list[b].componentName=='tablefield'&&this.list[b].defaultLable=='产品明细'){
          //         for(let c=0;c<this.list[b].components.length;c++){
          //             if(this.list[b].components[c].defaultLable=='售价'){
          //                 a=Number(a)+Number(this.list[b].components[c].value)
          //             }
          //         }
          //     }
          // }
          // this.list[i].value=a
          // let shuzu=this.list[i].components_list
          let zhi = 0;
          for (let b = 0; b < this.list.length; b++) {
            if (
              this.list[b].componentName == "tablefield" &&
              this.list[b].defaultLable == "产品明细"
            ) {
              // for(let c=0;c<this.list[b].components.length;c++){
              //     if(this.list[b].components[c].defaultLable=='售价'){
              //         a=Number(a)+Number(this.list[b].components[c].value)
              //     }
              // }
              const shuzu = this.list[b].components_list;
              for (let dangqian = 0; dangqian < shuzu.length; dangqian++) {
                for (let a = 0; a < shuzu[dangqian].length; a++) {
                  if (shuzu[dangqian][a].defaultLable == "售价") {
                    //////console.log(Number(shuzu[dangqian][a].value))
                    zhi = Number(zhi) + Number(shuzu[dangqian][a].value);
                  }
                }
              }
            }
          }
          //////console.log(zhi)
          this.list[i].value = zhi;
          this.liucheng_jiekou();
        }
        // if(this.list[i].componentName=='moneyfield'&&this.list[i].defaultLable=='累计回款金额'){
        //     let a=0
        //     for(let b=0;b<this.list.length;b++){
        //         //////console.log(1)
        //         if(this.list[b].componentName=='tablefield'&&this.list[b].defaultLable=='产品回款'){
        //             for(let c=0;c<this.list[b].components.length;c++){
        //                 //////console.log(2)
        //                 if(this.list[b].components[c].componentName=='moneyfield'&&this.list[b].components[c].defaultLable=='回款金额'){
        //                     a=Number(a)+Number(this.list[b].components[c].value)
        //                 }
        //             }
        //         }
        //     }
        //     this.list[i].value=Number(this.list[i].value_id)+a
        // }
        if (
          this.list[i].componentName == "moneyfield" &&
          this.list[i].defaultLable == "合计不含税金额"
        ) {
          let zhi = 0;
          for (let b = 0; b < this.list.length; b++) {
            if (
              this.list[b].defaultLable ==
                this.zifuchuan(this.list[i].gongshi[0].name, 0) &&
              this.list[b].componentName == "tablefield"
            ) {
              // for(let c=0;c<this.list[b].components.length;c++){
              //     if(this.list[b].components[c].defaultLable==this.zifuchuan(this.list[i].gongshi[0].name,1)){
              //         a=Number(a)+Number(this.list[b].components[c].value)
              //     }
              // }
              const shuzu = this.list[b].components_list;
              for (let dangqian = 0; dangqian < shuzu.length; dangqian++) {
                for (let a = 0; a < shuzu[dangqian].length; a++) {
                  if (
                    shuzu[dangqian][a].defaultLable ==
                    this.zifuchuan(this.list[i].gongshi[0].name, 1)
                  ) {
                    //////console.log(Number(shuzu[dangqian][a].value))
                    zhi = Number(zhi) + Number(shuzu[dangqian][a].value);
                  }
                }
              }
            }
          }
          this.list[i].value = zhi;
          this.liucheng_jiekou();
        }
        // dddaterangefield
        if (
          this.list[i].componentName == "numberfield" &&
          this.list[i].defaultLable == "出差天数"
        ) {
          const shijian_baocuo = [];
          let zhi = 0;
          for (let b = 0; b < this.list.length; b++) {
            //////console.log(1)
            if (
              this.list[b].componentName == "tablefield" &&
              this.list[b].defaultLable == "行程"
            ) {
              // for(let c=0;c<this.list[b].components.length;c++){
              //     if(this.list[b].components[c].componentName=='dddaterangefield'&&this.list[b].components[c].defaultLable=='开始时间'&&this.list[b].components[c].day=='时间选择错误'){
              //         shijian_baocuo.push('1111')
              //     }
              // }
              // for(let c=0;c<this.list[b].components.length;c++){
              //     if(this.list[b].components[c].componentName=='dddaterangefield'&&this.list[b].components[c].defaultLable=='开始时间'&&shijian_baocuo.length==0){
              //         a=Number(a)+Number(this.list[b].components[c].day)
              //     }
              // }
              const shuzu = this.list[b].components_list;
              for (let dangqian = 0; dangqian < shuzu.length; dangqian++) {
                for (let a = 0; a < shuzu[dangqian].length; a++) {
                  if (
                    shuzu[dangqian][a].componentName == "dddaterangefield" &&
                    shuzu[dangqian][a].defaultLable == "出差时间" &&
                    shuzu[dangqian][a].day == "时间选择错误"
                  ) {
                    shijian_baocuo.push("1111");
                  }
                }
                for (let a = 0; a < shuzu[dangqian].length; a++) {
                  if (
                    shuzu[dangqian][a].componentName == "dddaterangefield" &&
                    shuzu[dangqian][a].defaultLable == "出差时间" &&
                    shijian_baocuo.length == 0
                  ) {
                    zhi = Number(zhi) + Number(shuzu[dangqian][a].day);
                  }
                }
              }
            }
          }
          this.list[i].value = zhi;
        }
        // if(this.list[i].componentName=='tablefield'&&this.list[i].defaultLable=='产品明细'){          不知道为啥
        //     let length=''
        //     let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
        //     for(let a=0;a<data.length;a++){
        //         if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
        //             length=Number(data[a].components.length)+1
        //         }
        //     }
        //     let shuzu=this.group(this.list[i].components,length)
        //     let zong=''
        //     // let dangqian=''
        //     for(let a=0;a<this.list[i].components.length;a++){
        //         zong=Number(this.list[i].components.length)/length
        //     }
        // }
        if (
          this.list[i].componentName == "calcform" &&
          this.list[i].defaultLable != "漏斗金额"
        ) {
          //////console.log(this.list[i])
          if (this.list[i].gongshi.length == 1) {
            let zhi = 0;
            for (let b = 0; b < this.list.length; b++) {
              if (
                this.list[b].componentName == "tablefield" &&
                this.list[b].defaultLable ==
                  this.zifuchuan(this.list[i].gongshi[0].name, 0)
              ) {
                const shuzu = this.list[b].components_list;
                for (let dangqian = 0; dangqian < shuzu.length; dangqian++) {
                  for (let a = 0; a < shuzu[dangqian].length; a++) {
                    if (
                      shuzu[dangqian][a].defaultLable ==
                      this.zifuchuan(this.list[i].gongshi[0].name, 1)
                    ) {
                      zhi = Number(zhi) + Number(shuzu[dangqian][a].value);
                    }
                  }
                }
              }
            }
            for (let b = 0; b < this.list.length; b++) {
              if (this.list[b].idx == "tablefield" && this.list[i].gongshi[0].idx) {
                zhi = this.list[b].value;
              }
            }
            this.list[i].value = zhi.toFixed(2);
            this.liucheng_jiekou();
          } else {
            let c = "";
            const baocuo = [];
            for (let b = 0; b < this.list[i].gongshi.length; b++) {
              for (let d = 0; d < this.list.length; d++) {
                const nei_name = this.list[i].gongshi[b].name;
                if (this.list[i].gongshi[b].zhi != "jisuan") {
                  if (nei_name == this.list[d].defaultLable) {
                    if (
                      this.list[d].value != undefined &&
                      this.list[d].value.length == 0
                    ) {
                      baocuo.push("1111");
                    } else {
                      c = c + this.list[d].value;
                    }
                  }
                  if (
                    this.list[d].componentName == "tablefield" &&
                    this.list[d].defaultLable ==
                      this.zifuchuan(this.list[i].gongshi[b].name, 0)
                  ) {
                    const shuzu = this.list[d].components_list;
                    let zhi = 0;
                    for (let dangqian = 0; dangqian < shuzu.length; dangqian++) {
                      for (let a = 0; a < shuzu[dangqian].length; a++) {
                        if (
                          shuzu[dangqian][a].defaultLable ==
                          this.zifuchuan(this.list[i].gongshi[b].name, 1)
                        ) {
                          zhi = Number(zhi) + Number(shuzu[dangqian][a].value);
                        }
                      }
                    }
                    c = c + zhi;
                  }
                }
              }
              if (this.list[i].gongshi[b].zhi != undefined) {
                let zhi = this.list[i].gongshi[b].name;
                if (this.list[i].gongshi[b].name == "x") {
                  zhi = "*";
                } else if (this.list[i].gongshi[b].name == "÷") {
                  zhi = "/";
                }
                c = c + zhi;
              }
            }
            if (baocuo.length == 0) {
              this.list[i].value = eval(c);
              this.liucheng_jiekou();
            }
          }
        }
        if (
          this.list[i].componentName == "moneyfield" &&
          this.list[i].defaultLable == "合同未开发票金额"
        ) {
          let hetong_jine = "";
          let leiji_fapiao_jine = "";
          let heji_fapiao_jine = "";
          let dangqian_hetong_jine = "";
          for (let a = 0; a < this.list.length; a++) {
            if (
              this.list[a].componentName == "calcform" &&
              this.list[a].defaultLable == "合计发票金额"
            ) {
              heji_fapiao_jine = this.list[a].value;
            }
            if (
              this.list[a].componentName == "moneyfield" &&
              this.list[a].defaultLable == "累计发票金额"
            ) {
              leiji_fapiao_jine = this.list[a].value;
              hetong_jine = this.list[a].hetong_jine;
              dangqian_hetong_jine = this.list[a].dangqian_hetong_jine;
            }
          }
          // if(this.zhuangtai_shiyong_name!='作废'){
          if (
            Number(dangqian_hetong_jine) != 0 &&
            Number(dangqian_hetong_jine) -
              Number(leiji_fapiao_jine) -
              Number(heji_fapiao_jine) <
              0
          ) {
            this.fapiao_huikuan_shifou_tijiao = false;
          } else {
            this.fapiao_huikuan_shifou_tijiao = true;
          }
          this.list[i].value = (Number(hetong_jine) - Number(heji_fapiao_jine)).toFixed(
            2
          );
          // }else{
          //     this.list[i].value=(Number(hetong_jine)-Number(leiji_fapiao_jine)-Number(heji_fapiao_jine)).toFixed(2)
          // }

          //////console.log('合同金额', hetong_jine)
          //////console.log('累计发票金额', leiji_fapiao_jine)
          //////console.log('合计发票金额', heji_fapiao_jine)
          //////console.log('合同未开发票金额', this.list[i].value)
          //////console.log('这边判断的是  合同金额-累计发票金额-合计发票金额《0  不能提交')
          // -Number(leiji_fapiao_jine)

          this.liucheng_jiekou();
        }
        if (
          this.list[i].componentName == "moneyfield" &&
          this.list[i].defaultLable == "应收金额"
        ) {
          let leiji_kaipiao_jine = "";
          let leiji_huikuan_jine = "";
          let huikuan_jine = "";
          for (let a = 0; a < this.list.length; a++) {
            if (
              this.list[a].componentName == "calcform" &&
              this.list[a].defaultLable == "回款金额"
            ) {
              huikuan_jine = this.list[a].value;
            }
            if (
              this.list[a].componentName == "moneyfield" &&
              this.list[a].defaultLable == "累计发票金额"
            ) {
              leiji_kaipiao_jine = this.list[a].value;
            }
            if (
              this.list[a].componentName == "moneyfield" &&
              this.list[a].defaultLable == "累计回款金额"
            ) {
              leiji_huikuan_jine = this.list[a].value;
            }
          }
          // if(hetong_jine>0){
          //     if((Number(hetong_jine)-Number(leiji_fapiao_jine)-Number(heji_fapiao_jine))<0){
          //         this.fapiao_huikuan_shifou_tijiao=false
          //     }else{
          //         this.fapiao_huikuan_shifou_tijiao=true
          //     }
          // }
          this.list[i].value = (
            Number(leiji_kaipiao_jine) -
            Number(leiji_huikuan_jine) -
            Number(huikuan_jine)
          ).toFixed(2);
          this.liucheng_jiekou();
        }
        if (
          this.list[i].componentName == "moneyfield" &&
          this.list[i].defaultLable == "入库金额"
        ) {
          let shifou_zengzhi_shui_zhuanpiao = false; // 是否是增值税专票   如果是：不含税金额；不是：发票金额
          this.list.forEach((item) => {
            if (
              item.componentName == "ddselectfield" &&
              item.defaultLable == "发票类型"
            ) {
              shifou_zengzhi_shui_zhuanpiao = item.value == 0;
            }
          });
          let jine = "";
          this.list.forEach((item) => {
            if (item.componentName == "tablefield" && item.defaultLable == "列表") {
              item.components_list.forEach((ite) => {
                ite.forEach((it) => {
                  if (shifou_zengzhi_shui_zhuanpiao) {
                    if (
                      it.componentName == "calcform" &&
                      it.defaultLable == "不含税金额"
                    ) {
                      jine = Number(jine) + Number(it.value);
                      //////console.log(it.value)
                    }
                  } else {
                    if (it.componentName == "calcform" && it.defaultLable == "发票金额") {
                      jine = Number(jine) + Number(it.value);
                      //////console.log(it.value)
                    }
                  }
                });
              });
            }
          });
          //////console.log(jine)
          this.list[i].value = Number(jine).toFixed(2);
        }
        if (
          this.list[i].componentName == "moneyfield" &&
          this.list[i].defaultLable == "采购发票累计未结账金额"
        ) {
          this.list.forEach((item) => {
            if (item.componentName == "moneyfield" && item.defaultLable == "付款金额") {
              this.list[i].value = (
                Number(this.caigou_fapiao_jine) -
                Number(this.leiji_fukuan_jine) -
                Number(item.value)
              ).toFixed(2);
            }
          });
        }
        if (
          this.list[i].componentName == "moneyfield" &&
          this.list[i].defaultLable == "采购单累计未付款金额"
        ) {
          this.list.forEach((item) => {
            if (item.componentName == "moneyfield" && item.defaultLable == "付款金额") {
              this.list[i].value = (
                Number(this.caigou_dan_jine) -
                Number(this.leiji_fukuan_jine) -
                Number(item.value)
              ).toFixed(2);
            }
          });
        }
        // if(this.list[i].componentName=='moneyfield'&&this.list[i].defaultLable=='合同未开发票金额'){
        //     // let length=''
        //     // let data=this.$bendi_naqu_cunchu('mlbb_shenpi_biaodan_2','暂时')
        //     // for(let a=0;a<data.length;a++){
        //     //     if(data[a].componentName=='tablefield'&&this.list[i].defaultLable==data[a].defaultLable&&this.list[i].idx==data[a].idx){
        //     //         length=Number(data[a].components.length)+1
        //     //     }
        //     // }
        //     // let shuzu=this.group(this.list[i].components,length)
        //     // let zong=''
        //     // // let dangqian=''
        //     // for(let a=0;a<this.list[i].components.length;a++){
        //     //     zong=Number(this.list[i].components.length)/length
        //     // }
        // }
      }
    },
    phone_num(i, item) {
      if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(i)) {
        item.value_id = true;
      }
    },
    youxiang_num(i, item) {
      if (i.length == 0) {
        item.value_id = "";
      } else if (
        !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
          i
        )
      ) {
        item.value_id = true;
      }
    },
    // 打开决策人list
    dakai_juece_ren_list(i) {
      //////console.log(i)
      let dangqian_kehu_id = "";
      for (let a = 0; a < this.list.length; a++) {
        if (
          this.list[a].defaultLable == "客户" &&
          this.list[a].componentName == "textfield"
        ) {
          // dangqian_kehu_id=this.list[a]
          //////console.log(this.list[a])
          if (this.list[a].kehu_list != undefined) {
            dangqian_kehu_id = this.list[a].kehu_list[this.list[a].zhanshi].cust_id;
          }
        }
      }
      if (dangqian_kehu_id == "") {
        this.$message({
          message: "您当前还未选中客户",
          type: "warning",
        });
      } else {
        query_rel_customer_decision_list({
          data: {
            ent_id: this.$ent_id(),
            cust_id: dangqian_kehu_id,
          },
        }).then((res) => {
          //////console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data);
            if (date.length == 0) {
              this.$message({
                message: "您当前没有决策人",
                type: "warning",
              });
            } else {
              i.teshu = true;
              for (let a = 0; a < date.length; a++) {
                date[a].zhi = false;
              }
              i.juece_ren = date;
            }
          } else if (res.data.code == 500) {
          }
        });
      }
    },
    // 决策人确定
    xuanze_juece_ren(i) {
      //////console.log(i)
      const shuxing = [];
      for (let a = 0; a < i.juece_ren.length; a++) {
        if (i.juece_ren[a].zhi) {
          shuxing.push(i.juece_ren[a]);
        }
      }
      if (shuxing.length != 0) {
        i.value = shuxing.map((item, index) => item.name).join(",");
        i.value_id = shuxing.map((item, index) => item.cust_id).join(",");
        i.teshu = false;
      } else {
        this.$message({
          message: "未选择决策人",
          type: "warning",
        });
      }
    },
    xuan_juece_ren(it, index) {
      it[index].zhi = !it[index].zhi;
      this.$forceUpdate();
    },
    // 漏斗折算比例数字
    loudou_zhesuan_bili(i) {
      if (i.value <= 0) {
        i.value = 0;
      }
      if (i.value >= 1) {
        i.value = 1;
      }
      i.value = Number(i.value).toFixed(2);
    },
    // 时间区间选择完毕
    xuanze_shijian_wanbi(i) {
      //////console.log(i)
      const data = i.value[1];
      const data2 = i.value[0];
      const data3 = data.getTime() - data2.getTime();
      var days = Math.floor(data3 / (3600 * 1000 * 24));
      var leave1 = data3 % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      var leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      //////console.log(minutes)
      //////console.log(days)
      //////console.log(hours)
      let h = "";
      if (minutes != 0) {
        if (minutes >= 30) {
          h = 1;
        } else {
          h = 0;
        }
      } else {
        h = 0;
      }
      i.value_id = hours + h + Number(days) * 24;
    },
    xuanze_shang_xiawu(i) {
      //////console.log(i)
      // .getTime()
      if (i.value_id.length != 0 && i.value.length != 0) {
        let data = i.value;
        let data2 = i.value_id;
        const one = i.value.getTime();
        const two = i.value_id.getTime();
        if (two <= one) {
          let cunfang = "";
          cunfang = data2;
          data2 = data;
          data = cunfang;
          i.value = data;
          i.value_id = data2;
        }
        const data3 = data2.getTime() - data.getTime();
        const days = Math.floor(data3 / (24 * 3600 * 1000));
        if (days == 0) {
          if (i.value_start > i.value_id_end) {
            i.day = "时间选择错误";
          } else {
            if (i.value_start == i.value_id_end) {
              i.day = 0.5;
            } else {
              i.day = 1;
            }
          }
        } else {
          if (i.value_start == i.value_id_end) {
            i.day = days + 0.5;
          } else if (i.value_start > i.value_id_end) {
            i.day = days;
          } else {
            i.day = days + 1;
          }
        }
      } else {
        i.day = 0;
      }
      // let data3=data.getTime()-data2.getTime()
      // var days=Math.floor(data3/(24*3600*1000))
    },
    dianji_dakai_chanpin() {
      // query_ent_prod_list_tree({data:{
      //     ent_id:this.$ent_id()
      // }}).then(res=>{
      //     //////console.log(res)
      //     if(res.data.code==200){
      //         let date=JSON.parse(res.data.body.data)
      //         //////console.log(date)
      //         this.chanpin_lei_list=date
      //         this.chanpin_lei=true
      //     }else if(res.data.code==201){}
      // })
      // 2020.8.3让换成产品大类树
      query_ent_prod_cat_list_tree({
        data: {
          ent_id: this.$ent_id(),
        },
      }).then((res) => {
        //////console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data);
          //////console.log(date)
          date.forEach((item) => {
            item.children = [];
          });
          this.chanpin_lei_list = date;
          this.chanpin_lei = true;
        } else if (res.data.code == 201) {
        }
      });
    },
    chanpin_lei_sure(i) {
      //////console.log(this.$refs)
      if (
        this.$refs.chanpintree2[this.$refs.chanpintree2.length - 1].getCheckedNodes()
          .length != 0
      ) {
        i.value = this.$refs.chanpintree2[this.$refs.chanpintree2.length - 1]
          .getCheckedNodes()
          .map((item, index) => item.label)
          .join(",");
        i.value_id = this.$refs.chanpintree2[this.$refs.chanpintree2.length - 1]
          .getCheckedNodes()
          .map((item, index) => item.id)
          .join(",");
        this.chanpin_lei = false;
        //////console.log(i)
      }
    },
    // 整下一阶段
    xiayi_jieduan(item, index) {
      item.value = index;
      for (let i = 0; i < this.list.length; i++) {
        if (
          this.list[i].componentName == "tablefield" &&
          this.list[i].defaultLable == "拜访目标"
        ) {
          const xianjie_duan = item.defaultOptions[index].text;
          let suoyou = [
            {
              idx: 1,
              text: "初步接触",
            },
            {
              idx: 2,
              text: "需求确认",
            },
            {
              idx: 3,
              text: "报价及方案展示",
            },
            {
              idx: 4,
              text: "商务谈判",
            },
            {
              idx: 5,
              text: "赢单",
            },
            {
              idx: 6,
              text: "输单",
            },
          ];
          const shuzu = this.list[i].components_list;
          for (let a = 0; a < shuzu.length; a++) {
            for (let b = 0; b < shuzu[a].length; b++) {
              if (
                shuzu[a][b].defaultLable == "预计下一漏斗阶段" &&
                shuzu[a][b].componentName == "ddselectfield"
              ) {
                for (let b = 0; b < suoyou.length; b++) {
                  if (
                    (xianjie_duan == "赢单" || xianjie_duan == "输单") &&
                    suoyou[b].text == xianjie_duan
                  ) {
                    suoyou = suoyou.splice(b, 1);
                  } else if (suoyou[b].text == xianjie_duan) {
                    suoyou.splice(0, b);
                  }
                }
                shuzu[a][b].defaultOptions = suoyou;
                shuzu[a][b].value = 0;
              }
            }

            // if(this.list[i].components[a].defaultLable=='预计下一漏斗阶段'&&this.list[i].components[a].componentName=='ddselectfield'){
            //     for(let b=0;b<suoyou.length;b++){
            //         if((xianjie_duan=='赢单'||xianjie_duan=='输单')&&suoyou[b].text==xianjie_duan){
            //             suoyou=suoyou.splice(b,1)
            //         }else if(suoyou[b].text==xianjie_duan){
            //             suoyou.splice(0,b)
            //         }
            //     }
            //     this.list[i].components[a].defaultOptions=suoyou
            //     this.list[i].components[a].value=0
            // }
          }
        }
      }
    },
    jieduan_bianhua(item, index) {
      //////console.log(index)
      //////console.log(item.dangqian)
      item.value = index;
      for (let i = 0; i < this.list.length; i++) {
        if (
          this.list[i].componentName == "tablefield" &&
          this.list[i].defaultLable == "拜访目标结果"
        ) {
          let xianjie_duan = "";
          let suoyou = [
            {
              idx: 1,
              text: "初步接触",
            },
            {
              idx: 2,
              text: "需求确认",
            },
            {
              idx: 3,
              text: "报价及方案展示",
            },
            {
              idx: 4,
              text: "商务谈判",
            },
            {
              idx: 5,
              text: "赢单",
            },
            {
              idx: 6,
              text: "输单",
            },
          ];
          const shuzu = this.list[i].components_list;
          for (let a = 0; a < shuzu.length; a++) {
            for (let b = 0; b < shuzu[a].length; b++) {
              if (
                shuzu[a][b].defaultLable == "漏斗现阶段" &&
                shuzu[a][b].componentName == "textfield"
              ) {
                xianjie_duan = shuzu[a][b].value;
              }
            }

            // if(this.list[i].components[a].defaultLable=='漏斗现阶段'&&this.list[i].components[a].componentName=='textfield'){
            //     xianjie_duan=this.list[i].components[a].value
            // }
          }
          //////console.log(index)
          for (let a = 0; a < shuzu.length; a++) {
            for (let b = 0; b < shuzu[a].length; b++) {
              if (
                shuzu[a][b].defaultLable == "漏斗达成阶段" &&
                shuzu[a][b].componentName == "ddselectfield"
              ) {
                //////console.log(shuzu[a][b])
                for (let b = 0; b < suoyou.length; b++) {
                  if (
                    (xianjie_duan == "赢单" || xianjie_duan == "输单") &&
                    suoyou[b].text == xianjie_duan
                  ) {
                    suoyou = suoyou.splice(b, 1);
                  } else if (suoyou[b].text == xianjie_duan) {
                    if (index == 0) {
                      suoyou.splice(0, b + 1);
                    } else {
                      suoyou.splice(0, b);
                    }
                  }
                }
                if (item.dangqian == shuzu[a][b].dangqian) {
                  shuzu[a][b].defaultOptions = suoyou;
                  shuzu[a][b].value = 0;
                }
              }
            }

            // if(this.list[i].components[a].defaultLable=='漏斗达成阶段'&&this.list[i].components[a].componentName=='ddselectfield'){
            //     //////console.log(this.list[i].components[a])
            //     for(let b=0;b<suoyou.length;b++){
            //         if((xianjie_duan=='赢单'||xianjie_duan=='输单')&&suoyou[b].text==xianjie_duan){
            //             suoyou=suoyou.splice(b,1)
            //         }else if(suoyou[b].text==xianjie_duan){
            //             if(index==0){
            //                 suoyou.splice(0,b+1)
            //             }else{
            //                 suoyou.splice(0,b)
            //             }
            //         }
            //     }
            //     if(item.dangqian==this.list[i].components[a].dangqian){
            //         this.list[i].components[a].defaultOptions=suoyou
            //         this.list[i].components[a].value=0
            //     }
            // }
          }
        }
      }
    },
    // 点击否
    dianji_fou() {
      window.history.go(-1);
    },
    dianji_shi() {
      for (let i = 0; i < this.list.length; i++) {
        if (
          this.list[i].defaultLable == "漏斗阶段" &&
          this.list[i].componentName == "textfield"
        ) {
          this.list[i].value = "初步接触";
        }
      }
      if (this.date.flow_id != "qIwxmsMTgv") {
        for (let i = 0; i < this.list.length; i++) {
          if (
            this.list[i].defaultLable == "归档号" &&
            this.list[i].componentName == "textfield"
          ) {
            this.list.splice(i, 1);
            this.kehu_chongfu.shifou = true;
          }
        }
      } else {
        for (let a = 0; a < this.list.length; a++) {
          if (this.list[a].defaultLable == "客户") {
            this.list[a].value = "";
            this.list[a].zhanshi = 0;
          }
          if (this.list[a].defaultLable == "归档号") {
            this.list[a].value = "";
          }
          // if(this.list[a].defaultLable=='负责人'){
          //     this.list[a].value=''
          // }
          if (this.list[a].defaultLable == "部门") {
            this.list[a].value = "";
          }
        }
        this.kehu_chongfu.shifou = true;
      }
    },
    dianji_shi_loudou() {
      for (let a = 0; a < this.list.length; a++) {
        if (this.list[a].defaultLable == "客户") {
          this.list[a].value = "";
          this.list[a].zhanshi = 0;
        }
        if (this.list[a].defaultLable == "归档号") {
          this.list[a].value = "";
        }
        // if(this.list[a].defaultLable=='负责人'){
        //     this.list[a].value=''
        // }
        if (this.list[a].defaultLable == "部门") {
          this.list[a].value = "";
        }
      }
      this.kehu_you_loudou = false;
    },
    //  交付单点击否
    jiaofu_dianji_fou() {
      for (let a = 0; a < this.list.length; a++) {
        if (this.list[a].defaultLable == "客户") {
          this.list[a].value = "";
          this.list[a].zhanshi = 0;
        }
        if (this.list[a].defaultLable == "归档号") {
          this.list[a].value = "";
        }
        // if(this.list[a].defaultLable=='负责人'){
        //     this.list[a].value=''
        // }
        if (this.list[a].defaultLable == "部门") {
          this.list[a].value = "";
        }
      }
      this.shifou_you_hetong = false;
    },
    // 点击全选
    dianji_quanxuan() {
      this.renyuan_quanxuan = !this.renyuan_quanxuan;
      if (this.renyuan_quanxuan) {
        this.qiye_renyuan_list.map((item) => {
          item.zhi = true;
          this.qiye_renyuan_list_xuan.push(item);
        });
        this.qiye_renyuan_list_xuan = this.$func.Es5duplicate(
          this.qiye_renyuan_list_xuan,
          "staff_id"
        );
      } else {
        this.qiye_renyuan_list.map((item) => {
          item.zhi = false;
        });
        for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
          for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
            if (
              this.qiye_renyuan_list[i].staff_id ==
              this.qiye_renyuan_list_xuan[a].staff_id
            ) {
              this.qiye_renyuan_list_xuan.splice(a, 1);
            }
          }
        }
      }
    },
    panduan_yixuan() {
      const list = [];
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (
            this.qiye_renyuan_list[i].staff_id == this.qiye_renyuan_list_xuan[a].staff_id
          ) {
            this.qiye_renyuan_list[i].zhi = true;
            list.push("1111");
          }
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true;
        } else {
          this.renyuan_quanxuan = false;
        }
      }
    },
    dianji_xuanren(i) {
      i.zhi = !i.zhi;
      if (i.zhi) {
        if (
          (this.dangqian_lianxi_ren_idx == "tidai" &&
            this.qiye_renyuan_list_xuan.length == 0) ||
          this.dangqian_lianxi_ren_idx != "tidai"
        ) {
          this.qiye_renyuan_list_xuan.push(i);
        } else {
          i.zhi = false;
          this.$message({
            message: "只能替一个人代写",
            type: "warning",
          });
        }
      } else {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (i.staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
            this.qiye_renyuan_list_xuan.splice(a, 1);
          }
        }
      }
      this.panduan_shifou_quanxuan();
    },
    panduan_shifou_quanxuan() {
      const list = [];
      //////console.log(this.qiye_renyuan_list)
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        if (this.qiye_renyuan_list[i].zhi) {
          list.push("1111");
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true;
        } else {
          this.renyuan_quanxuan = false;
        }
      }
    },
    bianji_zhekou_lv(i) {
      // this.$forceUpdate()
    },
    bianji_zhekou(i) {
      if (i.value >= 1) {
        i.value = 1;
      } else if (i.value <= 0) {
        i.value = 0;
      }
      i.value = Number(i.value).toFixed(2);
    },
    dianji_tianjia_ren(i) {
      i.zhi = true;
      if (
        i.user_type == 2 ||
        (i.user_type == 1 && i.select_type == 4 && i.self_select_type == 1)
      ) {
        this.chaosong_bumen_renyuan_jiekou();
      } else {
        i.user_list.map((item) => {
          this.$set(item, "zhi", false);
        });
      }
      this.xuanze_shunxu_ren = [];
    },
    mianbao_chaosong_dian(i, index) {
      if (this.mianbao_chaosong_list.length - 1 != index) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length);
        this.chaosong_bumen_renyuan_jiekou();
      }
    },
    dianji_chaosong_quanxuan() {
      this.renyuan_chaosong_quanxuan = !this.renyuan_chaosong_quanxuan;
      if (this.renyuan_chaosong_quanxuan) {
        // for(let i=0;i<this.chaosong_qiye_bumen_list.length;i++){
        this.chaosong_qiye_bumen_list.map((item) => {
          item.zhi = true;
          this.$set(item, "name", item.dept_name);
          this.$set(item, "ids", item.dept_id);
          this.$set(item, "type", 1);
          this.chaosong_renyuan_list_xuan.push(item);
        });
        // }
        // for(let i=0;i<this.chaosong_qiye_renyuan_list.length;i++){
        this.chaosong_qiye_renyuan_list.map((item) => {
          item.zhi = true;
          this.$set(item, "name", item.staff_name);
          this.$set(item, "ids", item.staff_id);
          this.$set(item, "type", 2);
          this.chaosong_renyuan_list_xuan.push(item);
        });
        // }
      } else {
        this.chaosong_renyuan_list_xuan = [];
        this.chaosong_qiye_bumen_list.map((item) => {
          item.zhi = false;
        });
        this.chaosong_qiye_renyuan_list.map((item) => {
          item.zhi = false;
        });
      }
    },
    dianji_chaosong_bumen(i) {
      this.mianbao_chaosong_list.push({ name: i.dept_name, dept_id: i.dept_id });
      this.chaosong_bumen_renyuan_jiekou();
    },
    // 抄送人部门人员列表
    chaosong_bumen_renyuan_jiekou() {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          active: "1",
          parent_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1]
            .dept_id,
        },
      }).then((res) => {
        //////console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data);
          date.map((item) => {
            this.$set(item, "zhi", false);
          });
          //////console.log(date)
          this.chaosong_qiye_bumen_list = date;
          query_dept_staff_list({
            data: {
              ent_id: this.$ent_id(),
              dept_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1]
                .dept_id,
              active: "1",
            },
          }).then((ras) => {
            if (ras.data.code == 10149) {
              const listdata = JSON.parse(ras.data.body.data);
              listdata.map((item) => {
                this.$set(item, "zhi", false);
              });
              //////console.log(99999, listdata)
              this.chaosong_qiye_renyuan_list = listdata;
              this.panduan_youce_zuoce_have();
              this.panduan_chaosong_quanxuan();
            } else if (ras.data.code == 10150) {
            }
          });
        } else if (res.data.code == 10169) {
        }
      });
    },
    chaosong_bumen(i) {
      i.zhi = !i.zhi;
      if (i.zhi) {
        this.$set(i, "name", i.dept_name);
        this.$set(i, "ids", i.dept_id);
        this.$set(i, "type", 1);
        this.chaosong_renyuan_list_xuan.push(i);
      } else {
        for (let a = 0; a < this.chaosong_renyuan_list_xuan.length; a++) {
          if (this.chaosong_renyuan_list_xuan[a].ids == i.dept_id) {
            this.chaosong_renyuan_list_xuan.splice(a, 1);
          }
        }
      }
      this.panduan_chaosong_quanxuan();
    },
    chaosong_renyuan_sure(i) {
      const list = [];
      const bumen_list = [];
      // for(let a=0;a<this.chaosong_renyuan_list_xuan.length;a++){
      //     if(this.chaosong_renyuan_list_xuan[a].type==1){
      //         query_all_staff_list_of_dept({data:{
      //             ent_id:this.$ent_id(),
      //             dept_id:this.chaosong_renyuan_list_xuan[a].ids
      //         }}).then(res=>{
      //             //////console.log(res)
      //             if(res.data.code=200){
      //                 let date=JSON.parse(res.data.body.data)
      //                 //////console.log(date)
      //                 if(date!=undefined){
      //                     date.map(item=>{
      //                         this.$set(item,'name',item.staff_name)
      //                         this.$set(item,'ids',item.staff_id)
      //                         this.$set(item,'type',2)
      //                         list.push(item)
      //                     })
      //                 }
      //             }else if(res.data.code==500){}
      //         })
      //     }else{
      //         list.push(this.chaosong_renyuan_list_xuan[a])
      //     }
      // }
      this.chaosong_renyuan_list_xuan.forEach((item) => {
        if (item.type == 1) {
          query_all_staff_list_of_dept({
            data: {
              ent_id: this.$ent_id(),
              dept_id: item.ids,
            },
          }).then((res) => {
            //////console.log(res)
            if ((res.data.code = 200)) {
              const date = JSON.parse(res.data.body.data);
              //////console.log(date)
              if (date != undefined) {
                date.forEach((ite) => {
                  this.$set(ite, "name", ite.staff_name);
                  this.$set(ite, "ids", ite.staff_id);
                  this.$set(ite, "type", 2);
                  list.push(ite);
                });
              }
            } else if (res.data.code == 500) {
            }
          });
        } else {
          list.push(item);
        }
      });
      setTimeout(() => {
        //////console.log(list)

        const list2 = this.$func.Es5duplicate(list, "ids");
        let list3 = i.zixuan_list;
        list2.map((item) => {
          this.$set(item, "buke_shanchu", 0);
          list3.push(item);
        });
        list3 = this.$func.Es5duplicate(list3, "staff_id");
        //////console.log(list2)
        //////console.log(list3)
        i.zixuan_list = list3;
        i.zhi = false;
        this.liucheng_zidong_tongguo();
        this.chaosong_renyuan_list_xuan = [];
        this.$forceUpdate();
      }, 1000);
    },
    // 判断抄送是否全选
    panduan_chaosong_quanxuan() {
      const list = [];
      for (let i = 0; i < this.chaosong_qiye_bumen_list.length; i++) {
        if (!this.chaosong_qiye_bumen_list[i].zhi) {
          list.push("111");
        }
      }
      for (let i = 0; i < this.chaosong_qiye_renyuan_list.length; i++) {
        if (!this.chaosong_qiye_renyuan_list[i].zhi) {
          list.push("111");
        }
      }
      if (list.length == 0) {
        this.renyuan_chaosong_quanxuan = true;
      } else {
        this.renyuan_chaosong_quanxuan = false;
      }
    },
    // 判断右侧有的左侧是否有
    panduan_youce_zuoce_have() {
      for (let i = 0; i < this.chaosong_renyuan_list_xuan.length; i++) {
        for (let a = 0; a < this.chaosong_qiye_bumen_list.length; a++) {
          if (
            this.chaosong_renyuan_list_xuan[i].ids ==
            this.chaosong_qiye_bumen_list[a].dept_id
          ) {
            this.chaosong_qiye_bumen_list[a].zhi = true;
          }
        }
        for (let a = 0; a < this.chaosong_qiye_renyuan_list.length; a++) {
          if (
            this.chaosong_renyuan_list_xuan[i].ids ==
            this.chaosong_qiye_renyuan_list[a].staff_id
          ) {
            this.chaosong_qiye_renyuan_list[a].zhi = true;
          }
        }
      }
    },
    dianji_chaosong_xuanren(i, date) {
      if (
        date.user_type == 1 &&
        date.select_type == 4 &&
        date.self_select_type == 1 &&
        date.select_flag == 1
      ) {
        if (this.chaosong_renyuan_list_xuan.length != 0) {
          if (this.chaosong_renyuan_list_xuan[0].ids == i.staff_id) {
            i.zhi = false;
            this.chaosong_renyuan_list_xuan = [];
          } else {
            this.$message({
              message: "请您取消上一个审批后，再次选择审批人",
              type: "warning",
            });
          }
        } else {
          i.zhi = !i.zhi;
          this.$set(i, "name", i.staff_name);
          this.$set(i, "ids", i.staff_id);
          this.$set(i, "type", 2);
          this.chaosong_renyuan_list_xuan.push(i);
        }
      } else {
        //////console.log(i)
        i.zhi = !i.zhi;
        if (i.zhi) {
          this.$set(i, "name", i.staff_name);
          this.$set(i, "ids", i.staff_id);
          this.$set(i, "type", 2);
          this.chaosong_renyuan_list_xuan.push(i);
        } else {
          for (let a = 0; a < this.chaosong_renyuan_list_xuan.length; a++) {
            if (this.chaosong_renyuan_list_xuan[a].ids == i.staff_id) {
              this.chaosong_renyuan_list_xuan.splice(a, 1);
            }
          }
        }
        this.panduan_chaosong_quanxuan();
      }
    },
    // 抄送人搜索
    chaosong_sousuo() {
      if (this.chaosong_sousuo_text.length == 0) {
        this.chaosong_sousuo_text = null;
        this.chaosong_bumen_renyuan_jiekou();
      } else {
        this.chaosong_qiye_bumen_list = [];
        get_staff_list_like({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.jichuxinxi.user_id,
            staff_name: this.chaosong_sousuo_text,
            active: "1",
          },
        }).then((res) => {
          //////console.log(res)
          if (res.data.code == 10027) {
            //////console.log(JSON.parse(res.data.body.data))
            const date = JSON.parse(res.data.body.data);
            date.forEach((item) => {
              this.$set(item, "zhi", false);
              this.$set(item, "name", item.staff_name);
            });
            this.chaosong_qiye_renyuan_list = date;
            this.panduan_youce_zuoce_have();
          } else if (res.data.code == 10028) {
          }
        });
      }
    },
    // 发起人自选里的删除
    shanchu_ren(i, date) {
      //////console.log(i)
      //////console.log(date.zixuan_list)
      for (let a = 0; a < date.zixuan_list.length; a++) {
        if (i.staff_id == date.zixuan_list[a].staff_id) {
          date.zixuan_list.splice(a, 1);
        }
      }
      this.liucheng_zidong_tongguo();
    },
    // 审批人选择
    shenpi_xuanze_sure(i) {
      //////console.log(i)
      const list = this.xuanze_shunxu_ren;
      // date.user_type==1&&date.select_type==4&&date.select_flag==1
      // if(i.user_type==1&&i.select_type==4&&i.select_flag==1){
      //     list=xuanze_shunxu_ren
      //     // for(let a=0;a<i.user_list.length;a++){
      //     //     if(i.user_list[a].zhi){
      //     //         //////console.log(i.user_list[a])
      //     //         list.push(i.user_list[a])
      //     //     }
      //     // }
      // }else{
      //     list=i.zixuan_list
      //     for(let a=0;a<i.user_list.length;a++){
      //         if(i.user_list[a].zhi){
      //             //////console.log(i.user_list[a])
      //             list.push(i.user_list[a])
      //         }
      //     }
      // }
      i.zixuan_list = this.$func.Es5duplicate(list, "staff_id");
      i.zixuan_list.forEach((item) => {
        this.$set(item, "edit_list", i.edit_list);
      });
      i.zhi = false;
      i.user_list.map((item) => {
        this.$set(item, "zhi", false);
      });
      this.liucheng_zidong_tongguo();
      // zixuan_list
    },
    shenpi_xuanze(i, date) {
      //////console.log(date)
      if (date.user_type == 1 && date.select_type == 4 && date.select_flag == 1) {
        date.user_list.map((item) => {
          item.zhi = false;
        });
        i.zhi = true;
        this.xuanze_shunxu_ren = [];
        this.xuanze_shunxu_ren.push(i);
      } else {
        i.zhi = !i.zhi;
        if (i.zhi) {
          this.xuanze_shunxu_ren.push(i);
        } else {
          for (let a = 0; a < this.xuanze_shunxu_ren.length; a++) {
            if (this.xuanze_shunxu_ren[a].staff_id == i.staff_id) {
              this.xuanze_shunxu_ren.splice(a, 1);
              break;
            }
          }
        }
      }
    },
    shuzi_jianting(i) {
      this.liucheng_jiekou();
    },

    jine_jianting(i) {
      // i.value=this.$func.quchu_kongge(i.value+'')
      if (this.date.flow_id == "Xsk2pLBS9a" && i.idx == "1600152119353") {
        // 采购单
        if (i.value_num != 0) {
          if (Number(i.kucun_num) + Number(i.value) > Number(i.value_max)) {
            i.value = Number(i.value_max) - Number(i.kucun_num);
          }
          // else if((Number(i.kucun_num)+Number(i.value))<Number(i.value_min)){
          //     i.value=Number(i.value_min)-Number(i.kucun_num)
          // }
          else {
            i.value = i.value;
          }
          // if(Number(i.value)>Number(i.value_max)){
          //     i.value=i.value_max
          // }else if(Number(i.value)<=Number(i.value_min)){
          //     i.value=i.value_min
          // }else{
          //     i.value=i.value
          // }
        }
      } else if (this.date.flow_id == "hXstgwxhzh" && i.idx == "1600481814740") {
        // 出库单里的出库数量
        if (i.value_max == 0) {
          i.value = 0;
        } else {
          if (Number(i.value) > Number(i.value_max)) {
            i.value = i.value_max;
          } else if (Number(i.value) <= 1) {
            i.value = 1;
          } else {
            i.value = i.value;
          }
        }
      } else if (this.date.flow_id == "H49d4edc3T" && i.defaultLable == "数量") {
        // 交付单的数量
        if (i.value_max != undefined) {
          if (Number(i.value) > Number(i.value_max)) {
            i.value = i.value_max;
          } else if (Number(i.value) <= 1) {
            i.value = 1;
          } else {
            i.value = i.value;
          }
        }
      }
      this.liucheng_jiekou();
    },
    // 展示流程列表
    liucheng_jiekou() {
      console.log('this.date.flow_id', this.date.flow_id)
      if (this.panduan(this.list, "liucheng")) {
        const obj = {
          ent_id: this.$ent_id(),
          user_id: this.jichuxinxi.user_id,
          flow_id: this.date.flow_id,
          dept_id: this.liucheng_dept_id.length != 0 ? this.liucheng_dept_id : null,
          extra: [],
        };
        
        if (this.date.flow_id == "7TvJHPyovM") {
          // 客户录入单
        } else if (this.date.flow_id == "Z7VDOK9HGu") {
          // 访客计划单
        } else if (this.date.flow_id == "AY3cli7BAb") {
        } else if (this.date.flow_id == "453y6pLD52") {
          // 访客记录单
        } else if (this.date.flow_id == "PLOQ9ku3Ip") {
          // 漏斗单
          for (let i = 0; i < this.list.length; i++) {
            if (
              this.list[i].defaultLable == "漏斗金额" &&
              this.list[i].componentName == "calcform"
            ) {
              this.$set(
                obj,
                "amt_funn",
                this.list[i].value.length != 0 ? this.list[i].value + "" : null
              );
            }
            if (
              this.list[i].defaultLable == "漏斗折算比例" &&
              this.list[i].componentName == "numberfield"
            ) {
              this.$set(
                obj,
                "rate_funn_disc",
                this.list[i].value.length != 0 ? this.list[i].value + "" : null
              );
            }
          }
        } else if (this.date.flow_id == "Eb3n7CKQzi") {
          // 派工单
        } else if (this.date.flow_id == "qIwxmsMTgv") {
          // 合同单
          // Amt_con   合同金额
          const yuji_kaipiao_jine = "";
          for (let i = 0; i < this.list.length; i++) {
            if (
              this.list[i].defaultLable == "合同金额" &&
              this.list[i].componentName == "calcform"
            ) {
              this.$set(
                obj,
                "amt_con",
                this.list[i].value.length != 0
                  ? this.zhuangtai_shiyong_name == "作废"
                    ? Number(-this.$shuzi(this.list[i].value)) + ""
                    : this.list[i].value + ""
                  : null
              );
            }
            if (
              this.list[i].defaultLable == "预计发票金额合计" &&
              this.list[i].componentName == "calcform"
            ) {
              this.$set(
                obj,
                "amt_inv_est_sum",
                this.list[i].value.length != 0
                  ? this.zhuangtai_shiyong_name == "作废"
                    ? Number(-this.$shuzi(this.list[i].value)) + ""
                    : this.list[i].value + ""
                  : null
              );
            }
          }
        } else if (this.date.flow_id == "VMCizuVOXb") {
          // 发票单
          // Amt_no_tax_sum
          for (let i = 0; i < this.list.length; i++) {
            if (
              this.list[i].defaultLable == "合计发票金额" &&
              this.list[i].componentName == "calcform"
            ) {

              console.log('计算发票金额---------', this.list[i].value)
              this.$set(
                obj,
                "amt_inv",
                this.list[i].value.length != 0
                  ? this.zhuangtai_shiyong_name == "作废"
                    ? Number(-this.$shuzi(this.list[i].value)) + ""
                    : this.list[i].value + ""
                  : null
              );
            }
            if (
              this.list[i].defaultLable == "合计不含税金额" &&
              this.list[i].componentName == "calcform"
            ) {
              this.$set(
                obj,
                "amt_no_tax_sum",
                this.list[i].value.length != 0
                  ? this.zhuangtai_shiyong_name == "作废"
                    ? Number(-this.$shuzi(this.list[i].value)) + ""
                    : this.list[i].value + ""
                  : null
              );
            }
            if (
              this.list[i].defaultLable == "合计税额" &&
              this.list[i].componentName == "calcform"
            ) {
              this.$set(
                obj,
                "amt_tax_sum",
                this.list[i].value.length != 0
                  ? this.zhuangtai_shiyong_name == "作废"
                    ? Number(-this.$shuzi(this.list[i].value)) + ""
                    : this.list[i].value + ""
                  : null
              );
            }
            if (
              this.list[i].defaultLable == "累计发票金额" &&
              this.list[i].componentName == "moneyfield"
            ) {
              this.$set(
                obj,
                "amt_inv_est_sum",
                this.list[i].value.length != 0
                  ? this.zhuangtai_shiyong_name == "作废"
                    ? Number(-this.$shuzi(this.list[i].value)) + ""
                    : this.list[i].value + ""
                  : null
              );
            }
            if (
              this.list[i].defaultLable == "合同未开发票金额" &&
              this.list[i].componentName == "moneyfield"
            ) {
              this.$set(
                obj,
                "amt_inv_un",
                this.list[i].value.length != 0
                  ? this.zhuangtai_shiyong_name == "作废"
                    ? Number(-Number(this.list[i].value)) + ""
                    : this.list[i].value + ""
                  : null
              );
            }
          }
        } else if (this.date.flow_id == "He5ln0bdDO") {
          // 回款单
          for (let i = 0; i < this.list.length; i++) {
            if (
              this.list[i].defaultLable == "回款金额" &&
              this.list[i].componentName == "calcform"
            ) {
              this.$set(
                obj,
                "amt_rec",
                this.list[i].value.length != 0
                  ? this.zhuangtai_shiyong_name == "作废"
                    ? Number(-this.$shuzi(this.list[i].value)) + ""
                    : this.list[i].value + ""
                  : null
              );
            }
            if (
              this.list[i].defaultLable == "累计回款金额" &&
              this.list[i].componentName == "moneyfield"
            ) {
              this.$set(
                obj,
                "amt_rec_sum",
                this.list[i].value.length != 0
                  ? this.zhuangtai_shiyong_name == "作废"
                    ? Number(-this.$shuzi(this.list[i].value)) + ""
                    : this.list[i].value + ""
                  : null
              );
            }
            if (
              this.list[i].defaultLable == "累计发票金额" &&
              this.list[i].componentName == "moneyfield"
            ) {
              this.$set(
                obj,
                "amt_inv_sum",
                this.list[i].value.length != 0
                  ? this.zhuangtai_shiyong_name == "作废"
                    ? Number(-this.$shuzi(this.list[i].value)) + ""
                    : this.list[i].value + ""
                  : null
              );
            }
            if (
              this.list[i].defaultLable == "应收金额" &&
              this.list[i].componentName == "moneyfield"
            ) {
              this.$set(
                obj,
                "amt_rec_unrec",
                this.list[i].value.length != 0
                  ? this.zhuangtai_shiyong_name == "作废"
                    ? Number(-this.$shuzi(this.list[i].value)) + ""
                    : this.list[i].value + ""
                  : null
              );
            }
          }
        } else if (this.date.flow_id == "cvRyWGiUQH") {
          // 外出单
          for (let i = 0; i < this.list.length; i++) {
            if (
              this.list[i].defaultLable == "年累计外出时长（小时）" &&
              this.list[i].componentName == "numberfield"
            ) {
              this.$set(
                obj,
                "year_hour_long",
                this.list[i].value.length != 0 ? this.list[i].value + "" : null
              );
            }
          }
        } else if (this.date.flow_id == "GBh7N2jMsi") {
          // 出差单
          for (let i = 0; i < this.list.length; i++) {
            if (
              this.list[i].defaultLable == "出差天数" &&
              this.list[i].componentName == "numberfield"
            ) {
              this.$set(
                obj,
                "trip_days",
                this.list[i].value.length != 0 ? this.list[i].value + "" : null
              );
            }
            if (
              this.list[i].defaultLable == "年累计出差天数" &&
              this.list[i].componentName == "numberfield"
            ) {
              this.$set(
                obj,
                "year_trip_days",
                this.list[i].value.length != 0 ? this.list[i].value + "" : null
              );
            }
          }
        } else if (this.date.flow_id == "H49d4edc3T") {
          // 交付单
          for (let i = 0; i < this.list.length; i++) {
            if (
              this.list[i].defaultLable == "交付金额" &&
              this.list[i].componentName == "calcform"
            ) {
              this.$set(
                obj,
                "amt_deliver",
                this.list[i].value.length != 0 ? this.list[i].value : null
              );
            }
          }
        }
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].siyou == "22") {
            if (
              this.list[i].value != undefined &&
              this.list[i].componentName == "ddattachment" &&
              this.list[i].list.length != 0
            ) {
              obj.extra.push({
                type: "ddattachment",
                idx: this.list[i].idx,
                name: this.list[i].defaultLable,
                fujian_list: this.list[i].list,
              });
            } else if (
              this.list[i].value != undefined &&
              this.list[i].componentName == "ddselectfield"
            ) {
              obj.extra.push({
                type: "ddselectfield",
                idx: this.list[i].idx,
                name: this.list[i].defaultLable,
                value: this.list[i].defaultOptions[Number(this.list[i].value)].text,
                liebiao: this.list[i].defaultOptions,
                value_ids: this.list[i].value,
              });
            } else if (
              this.list[i].value != undefined &&
              this.list[i].componentName == "province" &&
              this.list[i].value.length != 0
            ) {
              obj.extra.push({
                type: "province",
                idx: this.list[i].idx,
                name: this.list[i].defaultLable,
                value:
                  this.list[i].value +
                  "-" +
                  this.list[i].value_id +
                  "-" +
                  this.list[i].value_ids,
                value_id: this.list[i].value, // 省
                value_ids: this.list[i].value_id, // 市
                value_idss: this.list[i].value_ids, // 区
              });
            } else if (
              this.list[i].value_list != undefined &&
              this.list[i].componentName == "assappform" &&
              this.list[i].value_list.length != 0
            ) {
              //////console.log(this.list[i].value_list)

              obj.extra.push({
                type: "assappform",
                idx: this.list[i].idx,
                name: this.list[i].defaultLable,
                value:
                  this.list[i].value_list != undefined &&
                  this.list[i].value_list.length != 0
                    ? this.list[i].value_list.map((ite) => ite.flow_no).join(",")
                    : "",
                liebiao: this.list[i].value_list,
              });
            } else if (
              this.list[i].value != undefined &&
              this.list[i].componentName == "cust" &&
              this.list[i].value.length != 0
            ) {
              //////console.log(this.list[i])

              obj.extra.push({
                type: "cust",
                idx: this.list[i].idx,
                name: this.list[i].defaultLable,
                value: this.list[i].value,
                liebiao: this.list[i].kehu_list,
                value_id: this.list[i].kehu_list[this.list[i].zhanshi].cust_id,
                value_ids: this.list[i].kehu_list[this.list[i].zhanshi].file_no,
                teshu: false,
                zhanshi: this.list[i].zhanshi,
              });
            } else if (
              this.list[i].value != undefined &&
              this.list[i].componentName == "product" &&
              this.list[i].value.length != 0
            ) {
              const arr = [];
              arr.push(this.list[i].chanpin_list);
              obj.extra.push({
                type: "product",
                idx: this.list[i].idx,
                name: this.list[i].defaultLable,
                value: arr[0].prod_name,
                liebiao: arr,
              });
            } else if (this.list[i].componentName == "ddmultiselectfield") {
              const duoxuan_zhi = [];
              this.list[i].defaultOptions.forEach((duoxuan) => {
                if (duoxuan.zhi) {
                  duoxuan_zhi.push(duoxuan.text);
                }
              });
              if (duoxuan_zhi.length != 0) {
                obj.extra.push({
                  type: "ddmultiselectfield",
                  idx: this.list[i].idx,
                  name: this.list[i].defaultLable,
                  value: duoxuan_zhi.join(","),
                  liebiao: this.list[i].defaultOptions,
                });
              }
            } else if (
              this.list[i].value != undefined &&
              this.list[i].componentName == "dddaterangefield" &&
              this.list[i].value.length != 0
            ) {
              obj.extra.push({
                type: "dddaterangefield",
                name: this.list[i].defaultLable,
                idx: this.list[i].idx,
                value:
                  this.list[i].value[0].getFullYear() +
                  "-" +
                  this.pa(this.list[i].value[0].getMonth() + 1) +
                  "-" +
                  this.pa(this.list[i].value[0].getDate()) +
                  "至" +
                  this.list[i].value[1].getFullYear() +
                  "-" +
                  this.pa(this.list[i].value[1].getMonth() + 1) +
                  "-" +
                  this.pa(this.list[i].value[1].getDate()),
                value_ids: "",
              });
            } else if (
              this.list[i].value != undefined &&
              this.list[i].componentName == "dddatefield" &&
              this.list[i].value.length != 0
            ) {
              obj.extra.push({
                type: "dddatefield",
                name: this.list[i].defaultLable,
                idx: this.list[i].idx,
                value:
                  this.list[i].value.getFullYear() +
                  "-" +
                  this.pa(this.list[i].value.getMonth() + 1) +
                  "-" +
                  this.pa(this.list[i].value.getDate()),
                value_ids: "",
              });
            } else if (
              this.list[i].dept_id != undefined &&
              this.list[i].componentName == "department" &&
              this.list[i].dept_id.length != 0
            ) {
              obj.extra.push({
                type: "department",
                name: this.list[i].defaultLable,
                idx: this.list[i].idx,
                value: this.list[i].option
                  .map((item) => {
                    if (item.dept_id == this.list[i].dept_id) {
                      return item.dept_name;
                    }
                  })
                  .join(","),
                value_ids: this.list[i].dept_id,
                liebiao: this.list[i].option,
              });
            } else if (
              this.list[i].staff_id != undefined &&
              this.list[i].componentName == "contacts" &&
              this.list[i].staff_id.length != 0
            ) {
              obj.extra.push({
                type: "contacts",
                name: this.list[i].defaultLable,
                idx: this.list[i].idx,
                value: this.list[i].value,
                value_ids: this.list[i].staff_id,
              });
            } else if (
              this.list[i].xin != undefined &&
              this.list[i].componentName == "ddphotofield" &&
              this.list[i].xin.length != 0
            ) {
              obj.extra.push({
                type: "ddphotofield",
                name: this.list[i].defaultLable,
                idx: this.list[i].idx,
                tu_b64: this.list[i].xin,
                tu_type: this.list[i].lei,
              });
            } else if (
              this.list[i].value != undefined &&
              this.list[i].componentName == "textnote" &&
              this.list[i].value.length != 0
            ) {
              obj.extra.push({
                type: "textnote",
                idx: this.list[i].idx,
                name: this.list[i].defaultProps,
                value: this.list[i].value,
              });
            } else if (
              this.list[i].componentName == "tablefield" &&
              this.list[i].components_list[0].length != 0
            ) {
              const mingxi_list = [];
              let id = 0;
              this.list[i].components_list.forEach((item) => {
                const mingxi_arr = [];
                item.forEach((ite) => {
                  if (
                    ite.value != undefined &&
                    ite.componentName == "ddattachment" &&
                    ite.list.length != 0
                  ) {
                    mingxi_arr.push({
                      type: "ddattachment",
                      idx: ite.idx,
                      name: ite.defaultLable,
                      fujian_list: ite.list,
                    });
                  } else if (
                    ite.value != undefined &&
                    ite.componentName == "ddselectfield"
                  ) {
                    mingxi_arr.push({
                      type: "ddselectfield",
                      idx: ite.idx,
                      name: ite.defaultLable,
                      value: ite.defaultOptions[Number(ite.value)].text,
                      liebiao: ite.defaultOptions,
                      value_ids: ite.value,
                    });
                  } else if (
                    ite.value != undefined &&
                    ite.componentName == "province" &&
                    ite.value.length != 0
                  ) {
                    mingxi_arr.push({
                      type: "province",
                      idx: ite.idx,
                      name: ite.defaultLable,
                      value: ite.value + "-" + ite.value_id + "-" + ite.value_ids,
                      value_id: ite.value, // 省
                      value_ids: ite.value_id, // 市
                      value_idss: ite.value_ids, // 区
                    });
                  } else if (
                    ite.value_list != undefined &&
                    ite.componentName == "assappform" &&
                    ite.value_list.length != 0
                  ) {
                    mingxi_arr.push({
                      type: "assappform",
                      idx: ite.idx,
                      name: ite.defaultLable,
                      // value:ite.value_list!=undefined&&ite.value_list.length!=0?ite.value_list.map(ite=>ite.flow_no).join(','):'',
                      liebiao: ite.value_list,
                    });
                  } else if (
                    ite.value != undefined &&
                    ite.componentName == "cust" &&
                    ite.value.length != 0
                  ) {
                    mingxi_arr.push({
                      type: "cust",
                      idx: ite.idx,
                      name: ite.defaultLable,
                      value: ite.value,
                      liebiao: ite.kehu_list,
                      value_ids: ite.value_id,
                      teshu: false,
                      zhanshi: "0",
                    });
                  } else if (
                    ite.chanpin_list != undefined &&
                    ite.componentName == "product" &&
                    ite.chanpin_list.length != 0
                  ) {
                    const arr = [];
                    arr.push(ite.chanpin_list);
                    mingxi_arr.push({
                      type: "product",
                      idx: ite.idx,
                      name: ite.defaultLable,
                      value: arr[0].prod_name,
                      liebiao: arr,
                    });
                  } else if (ite.componentName == "ddmultiselectfield") {
                    const duoxuan_zhi = [];
                    ite.defaultOptions.forEach((duoxuan) => {
                      if (duoxuan.zhi) {
                        duoxuan_zhi.push(duoxuan.text);
                      }
                    });
                    if (duoxuan_zhi.length != 0) {
                      mingxi_arr.push({
                        type: "ddmultiselectfield",
                        idx: ite.idx,
                        name: ite.defaultLable,
                        value: duoxuan_zhi.join(","),
                        liebiao: ite.defaultOptions,
                      });
                    }
                  } else if (
                    ite.value != undefined &&
                    ite.componentName == "dddaterangefield" &&
                    ite.value.length != 0
                  ) {
                    mingxi_arr.push({
                      type: "dddaterangefield",
                      name: ite.defaultLable,
                      idx: ite.idx,
                      value:
                        ite.value[0].getFullYear() +
                        "-" +
                        this.pa(ite.value[0].getMonth() + 1) +
                        "-" +
                        this.pa(ite.value[0].getDate()) +
                        "至" +
                        ite.value[1].getFullYear() +
                        "-" +
                        this.pa(ite.value[1].getMonth() + 1) +
                        "-" +
                        this.pa(ite.value[1].getDate()),
                      value_ids: "",
                    });
                  } else if (
                    ite.value != undefined &&
                    ite.componentName == "dddatefield" &&
                    ite.value.length != 0
                  ) {
                    mingxi_arr.push({
                      type: "dddatefield",
                      name: ite.defaultLable,
                      idx: ite.idx,
                      value:
                        ite.value.getFullYear() +
                        "-" +
                        this.pa(ite.value.getMonth() + 1) +
                        "-" +
                        this.pa(ite.value.getDate()),
                      value_ids: "",
                    });
                  } else if (
                    ite.dept_id != undefined &&
                    ite.componentName == "department" &&
                    ite.dept_id.length != 0
                  ) {
                    //////console.log(ite)
                    mingxi_arr.push({
                      type: "department",
                      name: ite.defaultLable,
                      idx: ite.idx,
                      value: ite.option
                        .map((item) => {
                          if (item.dept_id == ite.dept_id) {
                            return item.dept_name;
                          }
                        })
                        .join(","),
                      value_ids: ite.dept_id,
                      liebiao: ite.option,
                    });
                  } else if (
                    ite.staff_id != undefined &&
                    ite.componentName == "contacts" &&
                    ite.staff_id.length != 0
                  ) {
                    mingxi_arr.push({
                      type: "contacts",
                      name: ite.defaultLable,
                      idx: ite.idx,
                      value: ite.value,
                      value_ids: ite.staff_id,
                    });
                  } else if (
                    ite.xin != undefined &&
                    ite.componentName == "ddphotofield" &&
                    ite.xin.length != 0
                  ) {
                    mingxi_arr.push({
                      type: "ddphotofield",
                      name: ite.defaultLable,
                      idx: ite.idx,
                      tu_b64: ite.xin,
                      tu_type: ite.lei,
                    });
                  } else if (
                    ite.value != undefined &&
                    ite.componentName == "textnote" &&
                    ite.value.length != 0
                  ) {
                    mingxi_arr.push({
                      type: "textnote",
                      idx: ite.idx,
                      name: ite.defaultProps,
                      value: ite.value,
                    });
                  } else {
                    if (ite.value != undefined && ite.value.length != 0) {
                      mingxi_arr.push({
                        type: ite.componentName,
                        idx: ite.idx,
                        name: ite.defaultLable,
                        value: ite.value,
                      });
                    }
                  }
                });
                mingxi_list.push({
                  type: "tablefield",
                  idx: this.list[i].idx,
                  name: this.list[i].defaultLable,
                  index: id + 1,
                  mingxi_list: mingxi_arr,
                });
                id = id + 1;
              });
              obj.extra.push({
                type: "tablefield",
                idx: this.list[i].idx,
                name: this.list[i].defaultLable,
                mingxi_list: mingxi_list,
              });
            } else {
              if (
                this.list[i].componentName != "cust" &&
                this.list[i].componentName != "department"
              ) {
                if (this.list[i].value != undefined && this.list[i].value.length != 0) {
                  obj.extra.push({
                    type: this.list[i].componentName,
                    idx: this.list[i].idx,
                    name: this.list[i].defaultLable,
                    value: this.list[i].value,
                  });
                }
              }
            }
          }
        }
        obj.extra = JSON.stringify(obj.extra);
        //////console.log(obj.extra)
        query_form_appr_flow_list({ data: obj }).then((res) => {
          //////console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data);
            //////console.log(date)
            const cuowu = [];
            date.forEach((item) => {
              if (item.user_list != undefined) {
                for (let it = 0; it < item.user_list.length; it++) {
                  if (item.user_list[it] == null) {
                    item.user_list.splice(it, 1);
                  }
                }
              }
            });
            if (cuowu.length == 0) {
              for (let i = 0; i < date.length; i++) {
                this.$set(date[i], "name", date[i].user_type == 1 ? "审批人" : "抄送人");
                this.$set(
                  date[i],
                  "shunxu",
                  date[i].appr_sort != undefined
                    ? date[i].appr_sort == 1
                      ? "依次审批"
                      : date[i].appr_sort == 2
                      ? "会签"
                      : "或签"
                    : ""
                );
                // this.$set(date[i],'edit_list',date[i].edit_list!=undefined?date[i].edit_list:null)
                const arr = [];
                if (
                  date[i].user_type == 1 &&
                  (date[i].user_list == undefined || date[i].user_list.length == 0) &&
                  date[i].copy_self_select != 1
                ) {
                  // 这块新加的，2020、6、9审批人为空时自动通过
                  this.$set(date[i], "user_list", []);
                  this.$set(date[i], "shenpi_ren_kong", "111");
                } else if (
                  date[i].user_type == 2 ||
                  (date[i].user_type == 1 && date[i].select_type != 4)
                ) {
                  date[i].user_list.forEach((ite) => {
                    this.$set(ite, "buke_shanchu", "1");
                  });
                  this.$set(
                    date[i],
                    "zixuan_list",
                    date[i].user_list.map((item) => item)
                  );
                } else {
                  date[i].user_list.forEach((ite) => {
                    this.$set(ite, "buke_shanchu", "0");
                  });
                  // date[i].user_list.map(item=>item)
                  this.$set(date[i], "zixuan_list", arr);
                }
                this.$set(date[i], "zhi", false);
              }
              this.liucheng_list = date;
              this.liucheng_zidong_tongguo();
            } else {
              this.liucheng_jiazai_wanbi = false;
              this.$message({
                message: "审批流程下没有人",
                type: "warning",
              });
            }
          } else if (res.data.code == 500) {
          }
        });
      }
    },
    // 流程每个人打自动通过标签
    liucheng_zidong_tongguo() {
      const date = this.liucheng_list;
      const liebiao = [];
      const chongfu = [];
      const jiedian = [];
      const lianxu_quchong_jiedian = []; // 连续去重节点
      if (this.dangqian_danju_gaoji_shezhi.appr_auto == 1) {
        date.forEach((item) => {
          if (item.user_type == 1) {
            item.zixuan_list.forEach((ite) => {
              if (ite.staff_id == this.jichuxinxi.user_id) {
                this.$set(ite, "zidong_tongguo", true);
              }
            });
          }
        });
      }
      //////console.log(date)

      date.forEach((item, index) => {
        if (this.dangqian_danju_gaoji_shezhi.distinct_flag == 1) {
          if (item.user_type == 1) {
            // 要在审批人里面
            if (item.zixuan_list != undefined) {
              item.zixuan_list.forEach((ite) => {
                this.$set(ite, "jiedian", index);
                liebiao.push(ite);
              });
              jiedian.push({
                id: index,
                user_list: [],
              });
            }
          }
        } else if (this.dangqian_danju_gaoji_shezhi.distinct_flag == 2) {
          if (item.user_type == 1) {
            // 要在审批人里面
            if (item.zixuan_list != undefined) {
              this.$set(item, "jiedian", index);
              lianxu_quchong_jiedian.push(item);
              // item.zixuan_list.forEach(ite=>{
              //     this.$set(ite,'jiedian',index)
              //     liebiao.push(ite)
              // })
              // jiedian.push({
              //     id:index,
              //     user_list:[]
              // })
            }
          }
        }
      });
      if (this.dangqian_danju_gaoji_shezhi.distinct_flag == 1) {
        const cunfang = [];
        liebiao.forEach((item) => {
          if (
            chongfu[item.staff_id] ||
            (this.dangqian_danju_gaoji_shezhi.appr_auto == 1 &&
              item.staff_id == this.jichuxinxi.user_id)
          ) {
            this.$set(item, "zidong_tongguo", true);
          } else {
            this.$set(item, "zidong_tongguo", false);
            chongfu[item.staff_id] = true;
          }
          jiedian.forEach((ite) => {
            if (item.jiedian == ite.id) {
              ite.user_list.push(item);
            }
          });
        });
        date.forEach((item, index) => {
          if (item.user_type == 1) {
            jiedian.forEach((ite) => {
              if (index == ite.id) {
                // item.user_list=ite.user_list
                item.zixuan_list = ite.user_list;
              }
            });
            const quanbu_tongguo = [];
            item.zixuan_list.forEach((ite) => {
              if (ite.zidong_tongguo) {
                quanbu_tongguo.push("111");
              }
            });
            if (
              quanbu_tongguo.length == item.zixuan_list.length &&
              item.zixuan_list.length != 0
            ) {
              this.$set(item, "zidong_tongguo", true);
            } else {
              this.$set(item, "zidong_tongguo", false);
            }
          }
        });
        //////console.log(date)
      } else if (this.dangqian_danju_gaoji_shezhi.distinct_flag == 2) {
        if (lianxu_quchong_jiedian.length >= 1) {
          for (let i = 1; i < lianxu_quchong_jiedian.length; i++) {
            lianxu_quchong_jiedian[i].zixuan_list.forEach((item) => {
              lianxu_quchong_jiedian[i - 1].zixuan_list.forEach((ite) => {
                if (
                  item.staff_id == ite.staff_id ||
                  (this.dangqian_danju_gaoji_shezhi.appr_auto == 1 &&
                    item.staff_id == this.jichuxinxi.user_id)
                ) {
                  this.$set(item, "zidong_tongguo", true);
                }
              });
            });
          }
          date.forEach((item, index) => {
            if (item.user_type == 1) {
              lianxu_quchong_jiedian.forEach((ite) => {
                if (index == ite.jiedian) {
                  item = ite;
                }
              });
              const quanbu_tongguo = [];
              item.zixuan_list.forEach((ite) => {
                if (ite.zidong_tongguo) {
                  quanbu_tongguo.push("111");
                }
              });
              if (
                quanbu_tongguo.length == item.zixuan_list.length &&
                item.zixuan_list.length != 0
              ) {
                this.$set(item, "zidong_tongguo", true);
              } else {
                this.$set(item, "zidong_tongguo", false);
              }
            }
          });
        }
      }
      //////console.log(date)
      this.liucheng_list = date;
      this.liucheng_jiazai_wanbi = true;
    },
    // handleSelectionChange(val) {
    //     //////console.log(val)
    //     this.chanpin_xuanze = val;
    //     if(this.dangqian_mingxi_kuang_chanpin.length!=0){

    //     }else{
    //         this.dangqian_mingxi_kuang_chanpin=val
    //     }
    // },
    xuanze_chanpin(rows, row) {
      //////console.log(rows)
      //////console.log(row)
      const ses = rows.length && rows.indexOf(row) !== -1; // 判断是否打钩
      if (this.chanpin_danxuan) {
        if (rows.length > 1) {
          this.$refs.Tablea.forEach((i) => {
            i.clearSelection();
            i.toggleRowSelection(row);
          });
        }
        if (ses) {
          this.dangqian_mingxi_kuang_chanpin.push(row);
        } else {
          this.dangqian_mingxi_kuang_chanpin = [];
        }
      } else {
        if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
          if (ses) {
            this.dangqian_mingxi_kuang_chanpin.push(row);
          } else {
            for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
              if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == row.prod_id) {
                this.dangqian_mingxi_kuang_chanpin.splice(i, 1);
                i = i - 1;
              }
            }
          }
        } else {
          this.dangqian_mingxi_kuang_chanpin = rows;
        }
      }
      // return rows.length && rows.indexOf(row) !== -1
    },
    xuanze_chanpin_quanxuan(rows, row) {
      //////console.log(rows)
      if (rows.length != 0) {
        rows.forEach((item) => {
          this.dangqian_mingxi_kuang_chanpin.push(item);
        });
        this.dangqian_mingxi_kuang_chanpin = this.$func.Es5duplicate(
          this.dangqian_mingxi_kuang_chanpin,
          "prod_id"
        );
      } else {
        // chanpin_liebiao_list
        // this.dangqian_mingxi_kuang_chanpin
        for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
          // this.chanpin_liebiao_list.forEach(item=>{
          for (let a = 0; a < this.chanpin_liebiao_list.length; a++) {
            if (
              this.dangqian_mingxi_kuang_chanpin[i].prod_id ==
              this.chanpin_liebiao_list[a].prod_id
            ) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1);
              i = i - 1;
              break;
            }
          }
          // })
        }
      }
    },
    // chooseMcaterialChange(val){
    //     //////console.log(val)
    //     //////console.log(this.chanpin_danxuan)
    //     // if(this.chanpin_danxuan){
    //         this.$refs.Tablea[0].toggleRowSelection(val)
    //     // }

    // },
    xuanze_chanpin_dalei(val) {
      //////console.log(val)
      this.dangqian_chanpin = val;
      if (val.id.length != 0) {
        if (this.date.flow_id == "Xsk2pLBS9a") {
          query_all_prod_list_of_category_rule_pur({
            data: {
              ent_id: this.$ent_id(),
              cat_id: val.id + "",
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              // let date=JSON.parse(res.data.body.data)
              //////console.log(date)
              //////console.log(this.buneng_xuan_chanpin)
              const date = JSON.parse(res.data.body.data);
              //////console.log(this.buneng_xuan_chanpin)
              var a = date;
              var b = this.buneng_xuan_chanpin;
              const deal = (arr1, arr2, key) => {
                const arr3 = [];
                for (let index = 0; index < arr1.length; index++) {
                  const item = arr1[index];
                  if (!arr2.some((v) => v[key] === item[key])) {
                    arr3.push(item);
                  }
                }
                return arr3;
              };
              //////console.log(deal(a, b, 'prod_id'))
              this.chanpin_liebiao_list = deal(a, b, "prod_id");
              //////console.log(this.chanpin_liebiao_list)
              if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
                this.$nextTick(function () {
                  this.chanpin_liebiao_list.forEach((item) => {
                    this.dangqian_mingxi_kuang_chanpin.forEach((ite) => {
                      if (ite.prod_id == item.prod_id) {
                        //////console.log(item)
                        this.$refs.Tablea.forEach((i) => {
                          i.toggleRowSelection(item);
                        });
                      }
                    });
                  });
                });
              }
              this.$forceUpdate();
            } else if (res.data.code == 500) {
            }
          });
        } else if (this.date.flow_id == "hXstgwxhzh") {
          const file_no = "";
          this.list.forEach((item) => {
            if (item.idx == "1600481695883") {
              // 查到是哪个归档号
              file_no == item.value;
            }
          });
          query_all_prod_list_of_category_out_store({
            data: {
              ent_id: this.$ent_id(),
              file_no: file_no,
              cat_id: val.id + "",
              search_type: this.chuku_kehu_no_chanpin ? "2" : "1", // 1第一次查询  2  没有交付单的时候所有产品查询
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              // let date=JSON.parse(res.data.body.data)
              //////console.log(date)
              //////console.log(this.buneng_xuan_chanpin)
              const date = JSON.parse(res.data.body.data);
              //////console.log(this.buneng_xuan_chanpin)
              var a = date;
              var b = this.buneng_xuan_chanpin;
              const deal = (arr1, arr2, key) => {
                const arr3 = [];
                for (let index = 0; index < arr1.length; index++) {
                  const item = arr1[index];
                  if (!arr2.some((v) => v[key] === item[key])) {
                    arr3.push(item);
                  }
                }
                return arr3;
              };
              //////console.log(deal(a, b, 'prod_id'))
              this.chanpin_liebiao_list = deal(a, b, "prod_id");
              //////console.log(this.chanpin_liebiao_list)
              if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
                this.$nextTick(function () {
                  this.chanpin_liebiao_list.forEach((item) => {
                    this.dangqian_mingxi_kuang_chanpin.forEach((ite) => {
                      if (ite.prod_id == item.prod_id) {
                        //////console.log(item)
                        this.$refs.Tablea.forEach((i) => {
                          i.toggleRowSelection(item);
                        });
                      }
                    });
                  });
                });
              }
              this.$forceUpdate();
            } else if (res.data.code == 500) {
            }
          });
        } else {
          query_all_prod_list_of_category({
            data: {
              ent_id: this.$ent_id(),
              cat_id: val.id + "",
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              // let date=JSON.parse(res.data.body.data)
              //////console.log(date)
              //////console.log(this.buneng_xuan_chanpin)
              const date = JSON.parse(res.data.body.data);
              //////console.log(this.buneng_xuan_chanpin)
              var a = date;
              var b = this.buneng_xuan_chanpin;
              const deal = (arr1, arr2, key) => {
                const arr3 = [];
                for (let index = 0; index < arr1.length; index++) {
                  const item = arr1[index];
                  if (!arr2.some((v) => v[key] === item[key])) {
                    arr3.push(item);
                  }
                }
                return arr3;
              };
              //////console.log(deal(a, b, 'prod_id'))
              this.chanpin_liebiao_list = deal(a, b, "prod_id");
              //////console.log(this.chanpin_liebiao_list)
              if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
                this.$nextTick(function () {
                  this.chanpin_liebiao_list.forEach((item) => {
                    this.dangqian_mingxi_kuang_chanpin.forEach((ite) => {
                      if (ite.prod_id == item.prod_id) {
                        //////console.log(item)
                        this.$refs.Tablea.forEach((i) => {
                          i.toggleRowSelection(item);
                        });
                      }
                    });
                  });
                });
              }
              this.$forceUpdate();
            } else if (res.data.code == 500) {
            }
          });
        }
      }
    },
    // 点击选择产品保存
    dianji_xuanze_chanpin_baocun(data, data_zhi) {
      //////console.log(data)
      if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
        if (this.chanpin_danxuan) {
          const list = this.chanpin_mingxi_liebiao;
          //////console.log(list)
          // if(list!=undefined){
          //     for(let i=0;i<list.length;i++){
          //         if(list[i].componentName=='product'){
          //             list[i].chanpin_list=this.chanpin_xuanze[0]
          //             list[i].value=list[i].chanpin_list.prod_name
          //         }
          //         if(list[i].componentName=='moneyfield'&&list[i].defaultLable=='单价'){
          //             list[i].value=this.chanpin_xuanze[0].price_unit
          //         }
          //     }
          //     this.chanpin=false
          // }else{
          //////console.log(this.chanpin_mingxi)
          this.list.forEach((item) => {
            if (item.idx == this.chanpin_mingxi.idx) {
              //////console.log(item)
              this.$set(item, "chanpin_list", this.dangqian_mingxi_kuang_chanpin[0]);
              this.$set(item, "value", item.chanpin_list.prod_name);
            }
          });
          this.chanpin1 = false;
          // }
        } else {
          // dangqian_mingxi_kuang_chanpin
          //////console.log(this.dangqian_mingxi_liebiao)
          //////console.log(this.dangqian_mingxi_kuang_chanpin)
          const buke_shan_xiabiao = [];
          const shengyu_chanpin = JSON.parse(
            JSON.stringify(this.dangqian_mingxi_kuang_chanpin)
          );
          let dangqian_mingxi_xiabiao = 0; // 当前明细的下标  用来从哪开始
          for (let i = 0; i < this.dangqian_mingxi_liebiao.length; i++) {
            this.dangqian_mingxi_liebiao[i].forEach((ite) => {
              if (ite.componentName == "product") {
                for (let a = 0; a < shengyu_chanpin.length; a++) {
                  if (
                    ite.chanpin_list != undefined &&
                    ite.chanpin_list.prod_id == shengyu_chanpin[a].prod_id
                  ) {
                    buke_shan_xiabiao.push(i);
                    shengyu_chanpin.splice(a, 1);
                    a = a - 1;
                  }
                }
              }
            });
          }
          //////console.log(shengyu_chanpin)
          // 下面是处理增删改明细框里面的东西
          const shanchu_xiabiao = [];
          for (var i = 0; i < this.dangqian_mingxi_liebiao.length; i++) {
            var num = i;
            var isExist = false;
            for (var j = 0; j < buke_shan_xiabiao.length; j++) {
              var n = buke_shan_xiabiao[j];
              if (n == num) {
                isExist = true;
                break;
              }
            }
            if (!isExist) {
              if (shanchu_xiabiao.length != 0) {
                shanchu_xiabiao.push(num - shanchu_xiabiao.length);
                //////console.log(num - shanchu_xiabiao.length)
              } else {
                shanchu_xiabiao.push(num);
              }
            }
          }
          //////console.log(this.dangqian_mingxi_liebiao)
          //////console.log(shanchu_xiabiao)
          for (let i = 0; i < shanchu_xiabiao.length; i++) {
            for (let a = 0; a < this.dangqian_mingxi_liebiao.length; a++) {
              if (a == shanchu_xiabiao[i]) {
                this.dangqian_mingxi_liebiao.splice(shanchu_xiabiao[i], 1);
                a = a - 1;
                shanchu_xiabiao.splice(i, 1);
                i = i - 1;
              }
            }
          }
          //////console.log(shanchu_xiabiao)

          dangqian_mingxi_xiabiao = this.dangqian_mingxi_liebiao.length;
          setTimeout(() => {
            shengyu_chanpin.forEach((ite) => {
              this.tianjia_mingxi(this.dangqian_mingxi, 1);
            });
            // for(let ite=0;ite<shengyu_chanpin.length;ite++){
            //     query_prod_stock_info({data:{
            //         ent_id:this.$ent_id(),
            //         prod_id:shengyu_chanpin[a].prod_id
            //     }}).then(res=>{
            //         //////console.log(res)
            //         if(res.data.code==200){
            //             let date=JSON.parse(res.data.body.data)
            //             //////console.log(date)
            //             if(date.purchase_num=='no_limit'){
            //                 this.tianjia_mingxi(this.dangqian_mingxi,1)
            //             }else{
            //                 // date.purchase_num最小数量
            //                 // date.stock_num库存数量
            //                 if(Number(date.purchase_num)<=Number(date.stock_num)){
            //                     shengyu_chanpin.splice(ite,1)
            //                     ite=ite-1
            //                 }
            //             }
            //         }else if(res.data.code==500){}
            //     })
            // }
            //////console.log(this.dangqian_mingxi)
            if (shengyu_chanpin.length != 0) {
              for (
                let i = dangqian_mingxi_xiabiao, a = 0;
                i < this.dangqian_mingxi.components_list.length;
                i++, a++
              ) {
                this.dangqian_mingxi.components_list[i].forEach((ite) => {
                  if (ite.componentName == "product") {
                    ite.chanpin_list = shengyu_chanpin[a];
                    ite.value = ite.chanpin_list.prod_name;
                  }
                  if (
                    ite.componentName == "moneyfield" &&
                    (ite.defaultLable == "单价" || ite.defaultLable == "产品单价")
                  ) {
                    ite.value = Number(shengyu_chanpin[a].price_unit).toFixed(2);
                  }
                  if (ite.componentName == "textfield" && ite.defaultLable == "货位号") {
                    ite.value = shengyu_chanpin[a].site_no;
                  }
                  if (ite.componentName == "textfield" && ite.defaultLable == "仓库") {
                    const value_list = [];
                    if (shengyu_chanpin[a].site_id != undefined) {
                      shengyu_chanpin[a].site_id.split(",").forEach((it, id) => {
                        value_list.push({
                          site_id: it,
                          site_name: shengyu_chanpin[a].site_name.split(",")[id],
                          site_no:
                            shengyu_chanpin[a].site_no != undefined
                              ? shengyu_chanpin[a].site_no.split(",")[id]
                              : "",
                        });
                      });
                    }
                    // let value_list=[
                    //     {
                    //         site_id:shengyu_chanpin[a].site_id,
                    //         site_name:shengyu_chanpin[a].site_name,
                    //     }
                    // ]
                    this.$set(ite, "value_list", value_list);
                    this.$set(ite, "value_id", "");
                    this.$set(ite, "value", "");
                  }
                });
                if (
                  this.date.flow_id == "Xsk2pLBS9a" ||
                  this.date.flow_id == "u1SCMMoUwK"
                ) {
                  query_quotation_list({
                    data: {
                      ent_id: this.$ent_id(),
                      prod_id: shengyu_chanpin[a].prod_id,
                      user_id: this.jichuxinxi.user_id,
                    },
                  }).then((res) => {
                    //////console.log(res)
                    if (res.data.code == 200) {
                      const date = JSON.parse(res.data.body.data);
                      //////console.log(date)
                      this.dangqian_mingxi.components_list[i].forEach((ite) => {
                        if (
                          ite.componentName == "suppliername" &&
                          ite.defaultLable == "供应商"
                        ) {
                          if (date != undefined && date.length != 0) {
                            this.$set(ite, "value_list", date);
                            const min = Math.min.apply(
                              Math,
                              date.map((item) => {
                                return item.quoted_price;
                              })
                            );
                            for (let i = 0; i < date.length; i++) {
                              if (date[i].quoted_price == min) {
                                this.$set(ite, "value_id", date[i].supply_id);
                                this.$set(ite, "value", date[i].supply_name);
                                break;
                              }
                            }
                            //////console.log(ite)
                          }
                          // else{
                          //     this.$set(ite,'value','')
                          //     this.$set(ite,'value_list',[])
                          // }
                        }
                        if (
                          ite.componentName == "moneyfield" &&
                          ite.defaultLable == "产品报价"
                        ) {
                          if (date != undefined && date.length != 0) {
                            const min = Math.min.apply(
                              Math,
                              date.map((item) => {
                                return item.quoted_price;
                              })
                            );
                            //////console.log(min)
                            this.$set(ite, "value", min);
                          } else {
                            this.$set(ite, "value", "");
                          }
                        }
                      });
                    } else if (res.data.code == 500) {
                      alert("系统报错");
                    }
                  });
                  query_prod_stock_info({
                    data: {
                      ent_id: this.$ent_id(),
                      prod_id: shengyu_chanpin[a].prod_id,
                    },
                  }).then((res) => {
                    //////console.log(res)
                    if (res.data.code == 200) {
                      const date = JSON.parse(res.data.body.data);
                      this.dangqian_mingxi.components_list[i].forEach((ite) => {
                        if (
                          ite.componentName == "numberfield" &&
                          ite.defaultLable == "库存数量"
                        ) {
                          this.$set(
                            ite,
                            "value",
                            date.stock_num != undefined && date.stock_num.length != 0
                              ? date.stock_num
                              : 0
                          );
                        }
                        if (
                          ite.componentName == "numberfield" &&
                          ite.defaultLable == "采购数量"
                        ) {
                          if (date.purchase_num == "no_limit") {
                            this.$set(ite, "value", 0);
                            this.$set(ite, "value_num", date.rule_stock_flag); // 是否有无库存   1  有 0 无
                            this.$set(ite, "value_max", date.stock_max); // 最大库存
                            this.$set(ite, "value_min", 0); // 最小
                            this.$set(ite, "kucun_num", date.stock_num);
                          } else {
                            this.$set(ite, "value", date.purchase_num);
                            this.$set(ite, "value_num", date.rule_stock_flag);
                            this.$set(ite, "value_max", date.stock_max); // 最大库存
                            this.$set(ite, "value_min", date.purchase_num); // 最小库存
                            this.$set(ite, "kucun_num", date.stock_num);
                          }
                        }
                        // if(ite.componentName=="calcform"&&ite.defaultLable=='预计库存'){
                        //     // this.$set(ite,'value_id',date.rule_stock_flag==0?0:date.stock_max)
                        // }
                      });
                    } else if (res.data.code == 500) {
                      alert("系统报错");
                    }
                  });
                }
              }
            }
            this.chanpin2 = false;
          }, 0);
          // shengyu_chanpin

          // dangqian_mingxi_xiabiao
        }
      } else {
        this.$message({
          message: "还未选择产品",
          type: "warning",
        });
      }
    },
    dianji_quanbu_ke_caigou_chanpin(data, data_zhi) {
      query_all_prod_list_of_category_rule_pur({
        data: {
          ent_id: this.$ent_id(),
        },
      }).then((all) => {
        //////console.log(all)
        if (all.data.code == 200) {
          const shengyu_chanpin = JSON.parse(all.data.body.data);
          this.dangqian_mingxi_kuang_chanpin = shengyu_chanpin;
          if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
            this.$nextTick(function () {
              this.chanpin_liebiao_list.forEach((item) => {
                this.dangqian_mingxi_kuang_chanpin.forEach((ite) => {
                  if (ite.prod_id == item.prod_id) {
                    //////console.log(item)
                    this.$refs.Tablea.forEach((i) => {
                      i.toggleRowSelection(item);
                    });
                  }
                });
              });
            });
          }
          this.$forceUpdate();
        } else if (all.data.code == 500) {
        }
      });
    },
    chanpin_sousuo() {
      // sousuo_chanpin_text
      if (this.sousuo_chanpin_text.length != 0) {
        get_prod_list_like({
          data: {
            active: "1",
            ent_id: this.$ent_id(),
            prod_name:
              this.sousuo_chanpin_text.length != 0 ? this.sousuo_chanpin_text : null,
            user_id: this.jichuxinxi.user_id,
          },
        }).then((res) => {
          //////console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data);
            for (let i = 0; i < date.length; i++) {
              for (let a = 0; a < this.buneng_xuan_chanpin.length; a++) {
                if (date[i].prod_id == this.buneng_xuan_chanpin[a]) {
                  date.splice(i, 1);
                  i = i - 1;
                }
              }
            }
            this.chanpin_liebiao_list = date;
            //console.log(this.chanpin_liebiao_list, 69076786786)
            if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
              this.$nextTick(function () {
                this.chanpin_liebiao_list.forEach((item) => {
                  this.dangqian_mingxi_kuang_chanpin.forEach((ite) => {
                    if (ite.prod_id == item.prod_id) {
                      //////console.log(item)
                      this.$refs.Tablea.forEach((i) => {
                        i.toggleRowSelection(item);
                      });
                    }
                  });
                });
              });
            }
            this.$forceUpdate();
          } else if (res.data.code == 10032) {
          }
        });
      } else {
        if (this.dangqian_chanpin.id.length != 0) {
          query_all_prod_list_of_category({
            data: {
              ent_id: this.$ent_id(),
              cat_id: this.dangqian_chanpin.id + "",
            },
          }).then((res) => {
            //////console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data);
              //////console.log(date)
              //////console.log(this.buneng_xuan_chanpin)
              for (let i = 0; i < date.length; i++) {
                for (let a = 0; a < this.buneng_xuan_chanpin.length; a++) {
                  if (date[i].prod_id == this.buneng_xuan_chanpin[a]) {
                    date.splice(i, 1);
                    i = i - 1;
                  }
                }
              }
              this.chanpin_liebiao_list = date;
              //////console.log(this.chanpin_liebiao_list)
              if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
                this.$nextTick(function () {
                  this.chanpin_liebiao_list.forEach((item) => {
                    this.dangqian_mingxi_kuang_chanpin.forEach((ite) => {
                      if (ite.prod_id == item.prod_id) {
                        //////console.log(item)
                        this.$refs.Tablea.forEach((i) => {
                          i.toggleRowSelection(item);
                        });
                      }
                    });
                  });
                });
              }
              this.$forceUpdate();
            } else if (res.data.code == 500) {
            }
          });
        } else {
          this.chanpin_liebiao_list = [];
        }
      }
    },
    dianji_dakai_shangchuan_wenjian(i) {
      var event = event || window.event;
      var file = event.target.files[0];
      //////console.log(file)
      var reader = new FileReader();
      const lei = file.name.substring(file.name.lastIndexOf("."), file.name.length);
      // 转base64
      if (
        lei == ".doc" ||
        lei == ".pdf" ||
        lei == ".docx" ||
        lei == ".pptx" ||
        lei == ".ppt" ||
        lei == ".xls" ||
        lei == ".xlsx"
      ) {
        const arr = [];
        var _this = this;
        reader.onload = function (e) {
          arr.push(e.target.result);
          const obj = {
            name: file.name,
            b64: e.target.result.substring(
              e.target.result.indexOf(",") + 1,
              e.target.result.length
            ),
            type: lei,
          };
          i.list.push(obj);
        };
        reader.readAsDataURL(file);
      } else {
        this.$message({
          message: "请上传.doc/.docx/.pptx/.ppt/.xls/.xlsx/.pdf",
          type: "warning",
        });
      }
    },
    dianji_shanchu_fujian(i, index) {
      i.list.splice(index, 1);
    },
    wuyong() {},
    dianji_sousuo_kehu(i) {
      query_rel_customer_list({
        data: {
          ent_id: this.$ent_id(),
          staff_id: this.date.flow_id != "hXstgwxhzh" ? this.jichuxinxi.user_id : null,
          cust_name: this.sousuo_kehu_name.length != 0 ? this.sousuo_kehu_name : null,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          //////console.log(res)
          const date = JSON.parse(res.data.body.data);
          this.$set(i, "kehu_list", date);
          this.$set(i, "zhanshi", 0);
        } else if (res.data.code == 500) {
          alert("小虎哥报错了");
        }
      });
    },
    dianji_xuanze_shenpi_dan(i) {
      //////console.log(this.$refs)
      this.$refs.shenpi_dan_guanlian[0].jichu(i);
    },
    // 关联表单处理
    guanlian_biaodan_chuli(i) {
      //////console.log(i)
      if (JSON.stringify(i) != "{}") {
        this.list.forEach((item) => {
          //////console.log(item)
          if (item.idx == i.idx) {
            this.$set(item, "value_list", i.list);
          }
        });
      }
    },
    // 点击删除已选表单
    dianji_shanchu_biaodan(i, index) {
      i.value_list.splice(index, 1);
    },
    // 点击表单详情
    biaodan_xiangqing(i) {
      this.$refs.biaodan_xiangqing_zhanshi[0].jichu(i);
    },
    liucheng_huoqu() {
      //console.log(this.list, 870124213)
      console.log("123");
      this.list.forEach((item) => {
        if (item.defaultLable === "部门") {
          item.value = this.liucheng_bumen_list[0].dept_name;
        }
      });
      // console.log('panduan--------', this.list)
      if (this.panduan(this.list, "liucheng")) {
        
      } else {
        //console.log(this.list, 6755)
        this.liucheng_dept_id = "";

        if(this.errorList.length ==1 && this.errorList[0] == "纳税识别号的问题") {
          this.$message({
            message: "纳税识别号必须为18位",
            type: "warning",
          });
          return
        } 
        this.$message({
          message: "请填完所有必填项再获取流程",
          type: "warning",
        });
      }
    },
    quxiao_dai_tijiao() {
      this.jichuxinxi = this.$jichuxinxi();
      this.dai_taren_tijiao = false;
    },
    chaxun_gongying_shang(i) {
      if (i != undefined && i.length != 0) {
        const date = this.$bendi_naqu_cunchu("mlbb_shenpi_biaodan_4", "暂时");
        //////console.log(date)

        if (
          this.zhuangtai_shiyong_name == "公海池_编辑" &&
          this.date.flow_id == "5FotYgnu0Z" &&
          date.supply_name == i
        ) {
        } else {
          supply_have_exist({
            data: {
              ent_id: this.$ent_id(),
              supply_name: i,
            },
          }).then((res) => {
            if (res.data.code == 600) {
              this.$message({
                message: "该供应商名称已存在",
                type: "warning",
              });
            } else if (res.data.code == 700) {
            }
          });
        }
      }
    },
    // 选择供应商
    xuanze_gongying_shang(i) {
      //////console.log(i)
      i.option.forEach((item) => {
        if (item.supply_id == i.value_id) {
          i.value = item.supply_name;
        }
      });
      if (this.date.flow_id == "fZ0x5qedzn") {
        // 入库单
        query_all_prod_list_of_category_in_store({
          data: {
            ent_id: this.$ent_id(),
            supply_id: i.value_id,
            // user_id:this.jichuxinxi.user_id
          },
        }).then((res) => {
          //////console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data);
            //////console.log(date)
            if (date != undefined && date.length != 0) {
              this.xuanze_gongying_shang_jiazai_chanpin(date);
            } else {
              i.value_id = "";
              this.$message({
                message: "该供应商下没有需入库的产品",
                type: "warning",
              });
            }
          } else if (res.data.code == 500) {
          }
        });
      } else if (this.date.flow_id == "u1SCMMoUwK") {
        // 采购发票单
        query_all_prod_list_of_category_pur_inv({
          data: {
            ent_id: this.$ent_id(),
            supply_id: i.value_id,
            // user_id:this.jichuxinxi.user_id
          },
        }).then((res) => {
          //////console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data);
            //////console.log(date)
            if (date != undefined && date.length != 0) {
              this.xuanze_gongying_shang_jiazai_chanpin(date);
            } else {
              i.value_id = "";
              this.$message({
                message: "该供应商下没有可以开具采购发票的产品",
                type: "warning",
              });
            }
          } else if (res.data.code == 500) {
          }
        });
      } else if (this.date.flow_id == "vY9ubhTO89") {
        // 付款单
        query_amts_of_payment({
          data: {
            ent_id: this.$ent_id(),
            supply_id: i.value_id,
            // user_id:this.jichuxinxi.user_id
          },
        }).then((res) => {
          //////console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data);
            //////console.log(date)
            this.fukuan_dan_chaxun_jine(date);
          } else if (res.data.code == 500) {
          }
        });
      }
    },
    // 选择供应商加载产品
    xuanze_gongying_shang_jiazai_chanpin(shengyu_chanpin) {
      let dangqian_mingxi_kuang = {};
      this.list.forEach((item) => {
        // 1600331810240
        // <!-- 1600331810240  采购发票单的列表 -->  1600414902905入库单列表   1600481729268出库单的列表详情
        if (
          item.idx == "1600414902905" ||
          item.idx == "1600331810240" ||
          item.idx == "1600481729268"
        ) {
          dangqian_mingxi_kuang = item;
          item.components_list = [];
        }
      });
      setTimeout(() => {
        shengyu_chanpin.forEach((ite) => {
          this.tianjia_mingxi(dangqian_mingxi_kuang, 1);
        });
        //////console.log(shengyu_chanpin)
        //////console.log(dangqian_mingxi_kuang)

        if (shengyu_chanpin.length != 0) {
          for (
            let i = 0, a = 0;
            i < dangqian_mingxi_kuang.components_list.length;
            i++, a++
          ) {
            dangqian_mingxi_kuang.components_list[i].forEach((ite) => {
              if (ite.componentName == "product") {
                ite.chanpin_list = shengyu_chanpin[a];
                ite.value = ite.chanpin_list.prod_name;
              }
              if (
                ite.componentName == "moneyfield" &&
                (ite.defaultLable == "单价" || ite.defaultLable == "产品单价")
              ) {
                ite.value = Number(shengyu_chanpin[a].price_unit).toFixed(2);
              }
              if (
                ite.componentName == "numberfield" &&
                (ite.defaultLable == "采购数量" ||
                  ite.defaultLable == "入库数量" ||
                  ite.defaultLable == "出库数量")
              ) {
                ite.value =
                  shengyu_chanpin[a].qty != undefined ? shengyu_chanpin[a].qty : "0";
                // if(ite.defaultLable=='出库数量'){
                //     this.$set(ite,'value_max',shengyu_chanpin[a].stock_num)
                // }
              }
              if (ite.componentName == "textfield" && ite.defaultLable == "货位号") {
                ite.value =
                  shengyu_chanpin[a].site_no != undefined
                    ? shengyu_chanpin[a].site_no
                    : "";
              }
              if (ite.componentName == "suppliername" && ite.defaultLable == "供应商") {
                const value_list = [
                  {
                    supply_id: shengyu_chanpin[a].supply_id,
                    supply_name: shengyu_chanpin[a].supply_name,
                  },
                ];
                this.$set(ite, "value_list", value_list);
                this.$set(ite, "value_id", shengyu_chanpin[a].supply_id);
                this.$set(ite, "value", shengyu_chanpin[a].supply_name);
                this.$set(
                  ite,
                  "value_ids",
                  shengyu_chanpin[a].pur_id != undefined ? shengyu_chanpin[a].pur_id : ""
                );
                //////console.log(ite)
              }
              if (ite.componentName == "textfield" && ite.defaultLable == "仓库") {
                const value_list = [];
                if (shengyu_chanpin[a].site_id != undefined) {
                  shengyu_chanpin[a].site_id.split(",").forEach((it, id) => {
                    value_list.push({
                      site_id: it,
                      site_name: shengyu_chanpin[a].site_name.split(",")[id],
                      site_no:
                        shengyu_chanpin[a].site_no != undefined
                          ? shengyu_chanpin[a].site_no.split(",")[id]
                          : "",
                    });
                  });
                }
                // let value_list=[
                //     {
                //         site_id:shengyu_chanpin[a].site_id,
                //         site_name:shengyu_chanpin[a].site_name,
                //     }
                // ]
                this.$set(ite, "value_list", value_list);
                this.$set(ite, "value_id", "");
                this.$set(ite, "value", "");
              }
              if (ite.componentName == "moneyfield" && ite.defaultLable == "产品报价") {
                ite.value = shengyu_chanpin[a].quoted_price;
              }
            });
          }
        }
        this.chanpin2 = false;
      }, 0);
    },
    // 付款单  查询各种金额
    fukuan_dan_chaxun_jine(jine) {
      this.caigou_fapiao_jine = jine.amt_pur_inv; // 采购发票金额
      this.caigou_dan_jine = jine.amt_pur; // 采购金额
      this.leiji_fukuan_jine = jine.amt_payment_sum; // 付款单金额
      const leiji_ruku_jine =
        jine.amt_in_store != undefined && jine.amt_in_store.length != 0
          ? jine.amt_in_store
          : 0; // 入库单金额

      this.list.forEach((item) => {
        if (item.idx == "1600334556634") {
          // 累计入库金额
          this.$set(item, "value", leiji_ruku_jine);
        }
        if (item.idx == "1600334582162") {
          // 累计付款
          this.$set(item, "value", this.leiji_fukuan_jine);
        }
      });
    },
    xuanze_bumen(val) {
      // //////console.log(val)
      // //////console.log(this.liucheng_bumen_list)
      // let list=[]
      // this.suoyou_bumen.forEach(item=>{
      //     if(item.dept_id==val){
      //         list.push(item)
      //     }
      // })
      const list = [];
      val.option.forEach((item) => {
        if (item.dept_id == val.dept_id) {
          list.push(item);
        }
      });
      this.liucheng_bumen_list = list;
    },
    xuanze_cangku(i, date) {
      //////console.log(i)
      i.value_list.forEach((item) => {
        if (i.value_id == item.site_id) {
          i.value = item.site_name;
        }
      });
      //////console.log(date)
      // 1600481740146  出库单的产品idx  1605233266233  出库单当前库存   hXstgwxhzh 出库单   1600481814740  出库数量
      if (this.date.flow_id == "hXstgwxhzh") {
        date.forEach((item) => {
          if (item.idx == "1600481740146") {
            query_prod_stock_num({
              data: {
                ent_id: this.$ent_id(),
                prod_id: item.chanpin_list.prod_id,
                site_id: i.value_id,
              },
            }).then((res) => {
              //////console.log(res)
              if (res.data.code == 200) {
                // stock_num
                const data = JSON.parse(res.data.body.data);
                //////console.log(data)
                date.forEach((ite) => {
                  if (ite.idx == "1605233266233") {
                    this.$set(ite, "value", data.stock_num);
                  }
                  if (ite.idx == "1600481814740") {
                    this.$set(ite, "value_max", data.stock_num);
                  }
                });
              } else if (res.data.code == 500) {
              }
            });
          }
        });
      }
    },
    xuanze_liebiao_gongying_shang(i, a) {
      //////console.log(i)
      let baojia = "";
      i.value_list.forEach((item) => {
        if (i.value_id == item.supply_id) {
          baojia = item.quoted_price;
          i.value = item.supply_name;
        }
      });
      //////console.log(a)
      a.forEach((item) => {
        if (item.idx == "1600152102625") {
          this.$set(item, "value", baojia);
        }
      });
    },
    xuanze_tijiao_ren() {
      this.dangqian_lianxi_ren_idx = "tidai";
      this.dakai_bumen();
      this.lianxiren = true;
    },
    shanchu_yixuan_chanpin(i, index) {
      this.chanpin_liebiao_list.forEach((ite) => {
        if (ite.prod_id == i.prod_id) {
          // this.$refs.Tablea.toggleRowSelection(ite)
          this.$refs.Tablea.forEach((i) => {
            // i.clearSelection()
            i.toggleRowSelection(ite);
          });
        }
      });
      this.dangqian_mingxi_kuang_chanpin.splice(index, 1);
    },
  },

  //
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./biaodan/biaodanys.scss";
.zhedie {
  display: none;
}
.select-ywry-input {
  position: relative;
}
.select-ywry {
  position: absolute;
  top: 0.6rem;
  left: 1.2rem;
  width: 6rem;
  height: 2rem;
  z-index: 999;
  background: #fff;
  border-radius: 10px;
  padding: 0.2rem;
  overflow: none;
  -moz-box-shadow: 2px 2px 5px #c7c7c7;
  -webkit-box-shadow: 2px 2px 5px #c7c7c7;
  box-shadow: 2px 2px 5px #c7c7c7;
  cursor: pointer;
  li {
    .select-ywry-li {
      padding-left: 0.2rem;
      width: 100%;
      height: 0.3rem;
      line-height: 0.3rem;
      p {
        height: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.14rem;
        &:hover {
          color: #9a86db;
        }
      }
    }
    .select-ywry-bumen {
      display: flex;
      align-items: center;
      //  border:1px solid red;
      p {
        height: 0.3rem;
        line-height: 0.3rem;
        font-size: 0.14rem;
        &:hover {
          color: #9a86db;
        }
      }
      span {
        transition: 0.5s;
        width: 0px; /*设置宽高为0，所以div的内容为空，从才能形成三角形尖角*/
        height: 0px;
        margin-left: 0.1rem;
        border-bottom: 0.06rem solid #666666;
        border-left: 0.06rem solid transparent; /*transparent 表示透明*/
        border-right: 0.06rem solid transparent;
      }
      span.Sanjiao1 {
        transform: rotateZ(-90deg);
      }
    }
  }
}
.select-ywry {
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
//设置百分号
#fuhao {
  position: relative;

  .wai_div-input {
    width: 100px !important;
  }
}
</style>
